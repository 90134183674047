import moment from "moment";
import React, { useRef } from "react";

type ReviewProps = {
  parentReview?: any;
};

const Review_Popup: React.FC<ReviewProps> = ({ parentReview }) => {
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollableDivRef.current) {
      if (scrollableDivRef.current.scrollLeft > 200) {
        scrollableDivRef.current.scrollLeft -= 200; 
      } else {
        scrollableDivRef.current.scrollLeft = 0;
      }
    }
  };

  const scrollRight = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollLeft += 200; 
    }
  };

  return (
    <div className="mt-5 flex flex-col gap-4">
      <div>
        <p className="text-[18px] font-semibold">Reviews</p>
      </div>

      <div className="flex gap-3">
        <button
          className=" px-3 py-1 bg-gray-200 rounded-l-md"
          onClick={scrollLeft}
        >
          {"<"}
        </button>
        <button
          className=" px-3 py-1 bg-gray-200 rounded-r-md"
          onClick={scrollRight}
        >
          {">"}
        </button>
      </div>
      
      <div
        ref={scrollableDivRef}
        id="scroll-image"
        className="flex gap-5 scroll-containers Axiforma text-[14px] p-1"
      >
        {parentReview.reviews.length > 0 ? (
          parentReview.reviews.map((reviewItem: any, index: any) => (
            <div className="">
              <div className="bg-white min-h-full flex flex-col gap-5 w-[320px] xs:w-[350px] sm:w-[397px] p-[25px] text-[14px] rounded-[10px] shadow shadow-[#f17400b3]">
                <div className="flex gap-2">
                  <div className="flex top-2 justify-center">
                    <div className="w-16 h-16 rounded-[35px] bg-slate-300 "></div>
                  </div>

                  <div className="w-full relative">
                    <div className="font-semibold flex gap-2 top-2 absolute text-[17px]">
                      <p> {reviewItem?.reviewer?.firstName}</p>
                      <p>{reviewItem?.reviewer?.lastName}</p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-start">
                  <p className="leading-7">{reviewItem.review}</p>
                </div>

                <div className="flex gap-2 justify-end font-semibold items-center">
                  <p>{reviewItem?.createdAt?.split("T")[0]}</p>
                  <div className="bg-gray-400 min-w-[8px] w-2 h-2 rounded-full"></div>
                  <p> {moment(reviewItem?.createdAt)?.format("hh:mm a")} </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No reviews found.</p>
        )}
      </div>
    </div>
  );
};

export default Review_Popup;
