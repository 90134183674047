import React, { useState } from "react";
import illustration from "../../../assets/img/Illustration.png";
import logo from "../../../assets/icons/postcine_logo.png";
import { useNavigate } from "react-router-dom";

const OnBoard = () => {
    const navigate = useNavigate()

  return (
    <div>
      <div className="border-b h-[70px] w-full flex items-center px-5">
        <div className="">
          <img src={logo} className="h-12 w-14" alt="" />
        </div>
      </div>

      <div className="flex flex-col items-center p-6">
        <img src={illustration} />

        <div className="mt-7 flex flex-col items-center w-fit gap-3">
            <button
              className={`flex shadow w-[260px] hover:bg-[#F17300] hover:text-white shadow-[#F17300] h-[38px] font-semibold justify-center items-center rounded-[5px]`}
              onClick={() => navigate("/Skills")}
            >
              Proceed to Onboarding
            </button>

            <div>
              <p
                onClick={() => navigate("/Dashboard")}
                className="font-semibold cursor-pointer hover:text-orange-400"
              >
                Skip Onboarding
              </p>
            </div>
          </div>
      </div>
    </div>
  );
};

export default OnBoard;
