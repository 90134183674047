import React from "react";
import backArrow from "../../../assets/icons/Frame (3).svg";
import { useNavigate } from "react-router-dom";
import dp from "../../../assets/icons/Ellipse 42 (2).svg";

const FollowPage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex gap-3 items-center px-5 mt-4">
        <div
          onClick={() => navigate("/User-Profile")}
          className="bg-[#f174005f] flex justify-center font-semibold text-[14px] items-center text-red-500 w-6 h-6 rounded-[30px]"
        >
          X
        </div>
      </div>

      <div className="flex w-full flex-col md:flex-row gap-20 md:gap-0 mb-10 lg:mb-0">
        <div className="w-full">
          <div className="w-[85%] sm:w-[65%] md:w-[80%]">
            <div className="border-b border-[#905a27] flex justify-center mb-[25px]">
              <p className="text-[18px] font-semibold text-[#905a27]">
                Followers
              </p>
            </div>

            <div className="px-1 Axiforma text-[14px] flex flex-col gap-3">
              <div className="flex flex-col w-full shadow shadow-[#f174007a] rounded-[5px]  px-4 py-2">
                <div className="flex items-center gap-4 ">
                  <img src={dp} className="h-[45px]" alt="/" />
                  <p>Julia Julia</p>
                </div>
              </div>

              <div className="flex flex-col w-full shadow shadow-[#f174007a] rounded-[5px]  px-4 py-2">
                <div className="flex items-center gap-4 ">
                  <img src={dp} className="h-[45px]" alt="/" />
                  <p>Julia Julia</p>
                </div>
              </div>

              <div className="flex flex-col w-full shadow shadow-[#f174007a] rounded-[5px]  px-4 py-2">
                <div className="flex items-center gap-4 ">
                  <img src={dp} className="h-[45px]" alt="/" />
                  <p>Julia Julia</p>
                </div>
              </div>

              <div className="flex flex-col w-full shadow shadow-[#f174007a] rounded-[5px]  px-4 py-2">
                <div className="flex items-center gap-4 ">
                  <img src={dp} className="h-[45px]" alt="/" />
                  <p>Julia Julia</p>
                </div>
              </div>
            </div>

            <div className="flex lg:hidden justify-end mt-8">
              <button className="bg-[#f174007a] text-white shadow font-semibold rounded-[5px] h-8 w-20 text-sm">
                See more
              </button>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-end">
          <div className="w-[85%] sm:w-[65%] md:w-[80%]">
            <div className="border-b border-[#905a27] flex justify-center mb-[25px]">
              <p className="text-[18px] font-semibold text-[#905a27]">
                Following
              </p>
            </div>

            <div className="px-1 Axiforma text-[14px] flex flex-col gap-3">
              <div className="flex flex-col w-full shadow shadow-[#f174007a] rounded-[5px]  px-4 py-2">
                <div className="flex items-center gap-4 ">
                  <img src={dp} className="h-[45px]" alt="/" />
                  <p>Julia Julia</p>
                </div>
              </div>

              <div className="flex flex-col w-full shadow shadow-[#f174007a] rounded-[5px]  px-4 py-2">
                <div className="flex items-center gap-4 ">
                  <img src={dp} className="h-[45px]" alt="/" />
                  <p>Julia Julia</p>
                </div>
              </div>

              <div className="flex flex-col w-full shadow shadow-[#f174007a] rounded-[5px]  px-4 py-2">
                <div className="flex items-center gap-4 ">
                  <img src={dp} className="h-[45px]" alt="/" />
                  <p>Julia Julia</p>
                </div>
              </div>
            </div>

            <div className="flex lg:hidden justify-start mt-8">
              <button className="bg-[#f174007a] text-white shadow font-semibold rounded-[5px] h-8 w-20 text-sm">
                See more
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowPage;
