import React, { useState } from "react";
import { Toptrends, TrendList, suggestedFollow } from "../../utils/data";
import dp from "../../assets/icons/profile_dp.svg";
import { useNavigate } from "react-router-dom";

type TrendClickProps = {
  onTrendClick?: () => void;
  onTips_Guide?: () => void;
  onCommunity?: () => void;
  TipsActive?: boolean;
};

const CommunitySideNav: React.FC<TrendClickProps> = ({
  onTrendClick,
  onTips_Guide,
  TipsActive,
  onCommunity,
}) => {
  const [showLess, showMore] = useState(true);
  const navigate = useNavigate();
  const User_id = localStorage.getItem("ids");

  const toggleMenu = () => {
    showMore(!showLess);
  };

  return (
    <div className="w-full flex flex-col gap-[10px]">
      <div className="border-[#9b979772] border-b-4 rounded-[10px]">
        <div className="rounded-[10px] shadow shadow-[#F1730080] p-[10px] flex flex-col gap-[20px]">
          <div className="flex justify-between items-end">
            <p className="font-semibold">Trending Community</p>

            {showLess ? (
              <p onClick={toggleMenu} className="thin text-xs cursor-pointer">
                See more{" "}
              </p>
            ) : (
              <p onClick={toggleMenu} className="thin text-xs cursor-pointer">
                See less{" "}
              </p>
            )}
          </div>

          {showLess ? (
            <div className="flex flex-col gap-[16px]">
              {Toptrends.map(({ name, users }) => (
                <div
                  onClick={onTrendClick}
                  className="flex justify-between items-center h-[30px] cursor-pointer"
                >
                  <p className="text-[#00000080] font-semibold text-[12px] lg:text-[14px]">
                    {name}
                  </p>
                  <p className="text-[8px] xl:text-[9px] text-[#00000033]">
                    {users} Users in Conversation
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col gap-[16px]">
              {TrendList.map(({ name, users }) => (
                <div
                  onClick={onTrendClick}
                  className="flex justify-between items-center h-[30px] cursor-pointer"
                >
                  <p className="text-[#00000080] font-semibold text-[12px] lg:text-[14px]">
                    {name}
                  </p>
                  <p className="text-[8px] xl:text-[9px] text-[#00000033]">
                    {users} Users in Conversation
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="border-[#9b979772] border-b-4 rounded-[10px] h-[100px]">
        <div className="rounded-[10px] shadow text-[14px] lg:text-[16px] shadow-[#F1730080] p-[10px] h-[100px] font-semibold flex justify-center items-center">
          <p>Watch City Police On Youtube</p>
        </div>
      </div>

      <div className="lg:hidden flex justify-center">
        {TipsActive ? (
          <button
            onClick={onCommunity}
            className="font-semibold shadow border-[#9b979772] border-b-4 shadow-[#F1730080] rounded-[8px] text-[15px] lg:text-[17px] w-full py-[8px]"
          >
            Community
          </button>
        ) : (
          <button
            onClick={onTips_Guide}
            className="font-semibold shadow border-[#9b979772] border-b-4 shadow-[#F1730080] rounded-[8px] text-[15px] lg:text-[17px] w-full py-[8px]"
          >
            Tips & Guide
          </button>
        )}
      </div>

      <div className="border-[#9b979772] border-b-4 rounded-[10px]">
        <div className="rounded-[10px] shadow shadow-[#F1730080] p-[10px] font-semibold flex flex-col gap-1 justify-center items-center">
          <p className="Lato-Light text-[#00000080] text-[13px] lg:text-[15px] w-[85%]">
            join post city premium memebership from $30/month and elevate your
            experience{" "}
          </p>
          <div className="flex w-full justify-end">
            <button className="font-semibold shadow shadow-[#F1730080] rounded-[8px] text-[10px] lg:text-[12px] px-[15px] py-[5px]">
              Subscribe
            </button>
          </div>
        </div>
      </div>

      <div className="mt-10">
        <div className="w-full bg-blue-50 font-semibold rounded-lg flex justify-center items-center h-[320px]">
          Advertisement
        </div>
      </div>

      {/* {User_id && (
        <div className="border-[#9b979772] border-b-4 rounded-[10px]">
          <div className="rounded-[10px] flex flex-col gap-[20px] shadow shadow-[#F1730080] p-[10px] font-semibold justify-center items-center">
            <div className="flex justify-start w-full">
              <p className="text-[#00000080] font-semibold">
                Suggested Follows
              </p>
            </div>

            <div className="w-full flex flex-col gap-[12px]">
              {suggestedFollow.map(({ name, details }) => (
                <div className="w-full p-[10px] rounded-[10px] shadow shadow-gray-300 border-grey-100 border-t flex justify-between items-center">
                  <div className="flex gap-2">
                    <img className="h-7" src={dp} />

                    <div className="flex flex-col">
                      <p className="font-semibold text-[#00000080] text-[14px] lg:text-[16px]">
                        {name}
                      </p>
                      <p className="Lato-Light text-[10px] text-[#00000060]">
                        {details}
                      </p>
                    </div>
                  </div>

                  <div>
                    <button className="shadow shadow-[#F1730080] text-[10px] lg:text-xs rounded-[6px] px-2 p-1">
                      Follow
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default CommunitySideNav;
