import React, { useState } from "react";
import AxiosInstance from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import auth_arrow from "../../assets/icons/auth_arrow.svg";

type CreateCommunityLayoutProps = {
  handleBack?: () => void;
};

type CrewFormType = {
  role: string;
  name: string;
};

type SoundtrackFormType = {
  artiste: string;
  music: string;
};

type TopCastFormType = {
  cast: string;
};

type crewType = "role" | "name";
type SoundtrackType = "artiste" | "music";
type TopCastType = "cast";

const CreateCommuity: React.FC<CreateCommunityLayoutProps> = ({
  handleBack,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const User_id = localStorage.getItem("ids");

  const EMPTY_FORM = {
    role: "",
    name: "",
  };

  const EMPTY_FORM_2 = {
    artiste: "",
    music: "",
  };

  const EMPTY_FORM_3 = {
    cast: "",
  };

  const [formData1, setFormData1] = useState([
    { ...EMPTY_FORM },
  ] as CrewFormType[]);

  const [formData2, setFormData2] = useState([
    { ...EMPTY_FORM_2 },
  ] as SoundtrackFormType[]);

  const [formData3, setFormData3] = useState([
    { ...EMPTY_FORM_3 },
  ] as TopCastFormType[]);

  const handleAddDep = () => {
    setFormData1([...formData1, EMPTY_FORM]);
  };

  const handleAddSound = () => {
    setFormData2([...formData2, EMPTY_FORM_2]);
  };

  const handleAddCast = () => {
    setFormData3([...formData3, EMPTY_FORM_3]);
  };

  const handleDelete = (i: number) => {
    const filterCrew = formData1.filter((crew, index) => index !== i);
    setFormData1([...filterCrew]);
  };

  const handleDeleteSound = (i: number) => {
    const filterSound = formData2.filter((soundTrack, index) => index !== i);
    setFormData2([...filterSound]);
  };

  const handleDeleteTopCast = (i: number) => {
    const filterTopCrew = formData3.filter((topCast, index) => index !== i);
    setFormData3([...filterTopCrew]);
  };

  const handleChange = (e: any, i: number, field: crewType) => {
    if (field === "role") {
      formData1[i][field] = e.target.value;
    } else {
      formData1[i][field] = e.target.value;
    }
    setFormData1([...formData1]);
  };

  const handleChangeSound = (e: any, i: number, field: SoundtrackType) => {
    if (field === "artiste") {
      formData2[i][field] = e.target.value;
    } else {
      formData2[i][field] = e.target.value;
    }
    setFormData2([...formData2]);
  };

  const handleChangeTopCast = (e: any, i: number, field: TopCastType) => {
    formData3[i][field] = e.target.value;
    setFormData3([...formData3]);
  };

  const [selectedIconPicture, setSelectedIconPicture] = useState<File | null>(
    null
  );
  const [previewIconImage, setPreviewIconImage] = useState<string | null>(null);

  const handleChangeIconPic = (e: any) => {
    setSelectedIcon(e.target.files[0]);

    const newImage = e.target.files?.[0];

    if (newImage) {
      setSelectedIconPicture(newImage);

      const reader = new FileReader();
      reader.onload = (e) =>
        setPreviewIconImage((e.target as any).result as string);
      reader.readAsDataURL(newImage);
    } else {
      setSelectedIconPicture(null);
    }
  };

  const [selectedDisplayPicture, setSelectedDisplayPicture] = useState<File[]>(
    []
  );
  const [previewDisplayImage, setPreviewDisplayImage] = useState<string | null>(
    null
  );

  const [selectedDisplay, setSelectedDisplay] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");

  const handleChangeDisplayPic = (e: any) => {
    setSelectedDisplay(e.target.files[0]);

    const newImages = e.target.files || [];
    setSelectedDisplayPicture([...selectedDisplayPicture, ...newImages]);

    if (newImages.length > 0 && previewDisplayImage === null) {
      const reader = new FileReader();
      reader.onload = (e) =>
        setPreviewDisplayImage((e.target as any).result as string);
      reader.readAsDataURL(newImages[0]);
    }
  };

  const [companyName, setCompanyName] = useState("");
  const [MovieCreator, setMovieCreator] = useState("");
  const [storyLine, setStoryLine] = useState("");

  const handleSubmit = async () => {
    setIsLoading(true);
    // const crewArray = formData1.map((crewMember) => crewMember);
    // const soundtrackArray = formData2.map((soundtrack) => soundtrack);

    const topCastArray = formData3.map((TopCast) => TopCast);

    const formData = new FormData();
    formData.append("displayImage", selectedDisplay);
    formData.append("iconImage", selectedIcon);
    formData.append("name", companyName);
    formData.append("storyLine", storyLine);
    formData.append("movieCreator", MovieCreator);

    topCastArray.forEach((crewObject: TopCastFormType) => {
      formData.append("topCast[]", `${[crewObject.cast]}`);
    });

    // crewArray.forEach((crewObject: CrewFormType) => {
    //   formData.append("crew[]", `${[crewObject.role]},${[crewObject.name]}`);
    // });

    // soundtrackArray.forEach((soundtrackObject: SoundtrackFormType) => {
    //   formData.append(
    //     "soundtrack[]",
    //     `${[soundtrackObject.artiste]},${[soundtrackObject.music]}`
    //   );
    // });

    try {
      const response = await AxiosInstance.post(
        `/community/createCommunity/${User_id}`,
        formData
      ).then((response) => {
        if (response.status === 200) {
          toast.success(`new community created`);
        }
        setCompanyName("");
        setSelectedIcon("");
        setSelectedDisplay("");
        setPreviewDisplayImage(null);
        setPreviewIconImage(null);
        setFormData1([EMPTY_FORM]);
        setFormData2([EMPTY_FORM_2]);
        setFormData3([EMPTY_FORM_3]);
        setMovieCreator("");
        setStoryLine("");
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const validate =
    !formData3[0].cast ||
    !storyLine ||
    !companyName ||
    !selectedDisplay ||
    !selectedIcon;

  return (
    <div className="flex flex-col gap-7">
      <div className="flex items-center gap-2">
        <img
          onClick={handleBack}
          className="cursor-pointer"
          src={auth_arrow}
          alt=""
        />
        <div>
          <p className="font-semibold text-[24px]">Create Commuity</p>
        </div>
      </div>

      <div className="flex flex-col gap-7">
        <div className="w-full">
          <div className="flex flex-col gap-2 w-[100%]">
            <p className="text-[14px] font-semibold">Community Name</p>
            <div>
              <input
                className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                type="text"
                name="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex gap-3">
          <div className="flex flex-col gap-2 w-[65%] md:w-[70%]">
            <p className="text-[14px] font-semibold">Display Image</p>
            <div className="shadow shadow-orange-400 h-[220px] rounded-md flex justify-center items-center">
              {previewDisplayImage ? (
                <img
                  src={previewDisplayImage}
                  alt="Selected Icon"
                  className="w-full h-full object-cover rounded-md"
                />
              ) : (
                <>
                  <label>
                    <p className="flex justify-center items-center text-[38px] cursor-pointer font-black bg-[#f174005f] text-white w-[60px] rounded-[40px]">
                      +
                    </p>
                    <input
                      name="displayImage"
                      id="displayImage"
                      type="file"
                      accept="image/*"
                      onChange={handleChangeDisplayPic}
                      className="hidden"
                    />
                  </label>
                </>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-2 w-[35%] md:w-[30%]">
            <p className="text-[14px] font-semibold">Icon Image</p>
            <div className="shadow shadow-orange-400 h-[220px] rounded-md flex justify-center items-center">
              {previewIconImage ? (
                <img
                  src={previewIconImage}
                  alt="Selected Icon"
                  className="w-full h-full object-cover rounded-md"
                />
              ) : (
                <>
                  <label>
                    <p className="flex justify-center items-center text-[38px] cursor-pointer font-black bg-[#f174005f] text-white w-[60px] rounded-[40px]">
                      +
                    </p>
                    <input
                      name="iconImage"
                      id="iconImage"
                      type="file"
                      accept="image/*"
                      onChange={handleChangeIconPic}
                      className="hidden"
                    />
                  </label>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="w-full">
          <div className="flex flex-col gap-2 w-[100%]">
            <p className="text-[14px] font-semibold">Movie Creator</p>
            <div>
              <input
                className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                type="text"
                name="MovieCreator"
                value={MovieCreator}
                onChange={(e) => setMovieCreator(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <p className="text-[14px] font-semibold">Add Top Cast</p>
          {formData3.map(({ cast }, i) => (
            <div>
              <div key={i} className="">
                <div className="w-full flex gap-4">
                  <div className="flex flex-col gap-2 w-full">
                    <p className="text-[14px] Lato-Italics">Top Cast</p>
                    <div>
                      <input
                        className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                        type="text"
                        name="cast"
                        value={cast}
                        onChange={(e) => handleChangeTopCast(e, i, "cast")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                {formData3.length > 1 && (
                  <button
                    onClick={() => handleDeleteTopCast?.(i)}
                    className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                  >
                    - Remove
                  </button>
                )}
              </div>
            </div>
          ))}

          <div className="mt-1">
            <p
              onClick={handleAddCast}
              className="font-semibold text-orange-400 cursor-pointer text-[14px] w-fit"
            >
              + Add top crew
            </p>
          </div>
        </div>

        <div className="w-full">
          <div className="flex flex-col gap-2 w-[100%]">
            <p className="text-[14px] font-semibold">Storyline</p>
            <div>
              <textarea
                className="border bg-[#f4f5f5] h-[212px] rounded-lg w-full p-[10px] nonResizableTextarea"
                name="MovieCreator"
                value={storyLine}
                onChange={(e) => setStoryLine(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* <div className="flex flex-col gap-3">
          <p className="text-[14px] font-semibold">Add Crew</p>
          {formData1.map(({ role, name }, i) => (
            <div>
              <div key={i} className="">
                <div className="w-full flex gap-4">
                  <div className="flex flex-col gap-2 w-[50%]">
                    <p className="text-[14px] Lato-Italics">Crew Role</p>
                    <div>
                      <input
                        className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                        type="text"
                        name="role"
                        value={role}
                        onChange={(e) => handleChange(e, i, "role")}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 w-[50%]">
                    <p className="text-[14px] Lato-Italics">Name</p>
                    <div>
                      <input
                        className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                        type="text"
                        value={name}
                        name="name"
                        onChange={(e) => handleChange(e, i, "name")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                {formData1.length > 1 && (
                  <button
                    onClick={() => handleDelete?.(i)}
                    className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                  >
                    - Remove
                  </button>
                )}
              </div>
            </div>
          ))}

          <div className="mt-1">
            <p
              onClick={handleAddDep}
              className="font-semibold text-orange-400 text-[14px] cursor-pointer w-fit"
            >
              + Add crew
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-3">
          <p className="text-[14px] font-semibold">Add Sound Track</p>
          {formData2.map(({ artiste, music }, i) => (
            <div>
              <div key={i} className="">
                <div className="w-full flex gap-4">
                  <div className="flex flex-col gap-2 w-[50%]">
                    <p className="text-[14px] Lato-Italics">Sound Track</p>
                    <div>
                      <input
                        className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                        type="text"
                        name="artiste"
                        value={artiste}
                        onChange={(e) => handleChangeSound(e, i, "artiste")}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 w-[50%]">
                    <p className="text-[14px] Lato-Italics">Music</p>
                    <div>
                      <input
                        className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                        type="text"
                        value={music}
                        name="music"
                        onChange={(e) => handleChangeSound(e, i, "music")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                {formData1.length > 1 && (
                  <button
                    onClick={() => handleDeleteSound?.(i)}
                    className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                  >
                    - Remove
                  </button>
                )}
              </div>
            </div>
          ))}

          <div className="mt-1">
            <p
              onClick={handleAddSound}
              className="font-semibold text-orange-400 cursor-pointer text-[14px] w-fit"
            >
              + Add sound track
            </p>
          </div>
        </div> */}

        <div className="mt-7 flex flex-col items-end w-full gap-3">
          <button
            onClick={handleSubmit}
            disabled={validate}
            className={`flex shadow w-[260px] shadow-[#F17300] ${
              !validate ? "bg-[#F17300] text-white" : ""
            } h-[38px] font-semibold justify-center items-center rounded-[5px]`}
          >
            {isLoading ? "Loading..." : "Continue"}
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateCommuity;
