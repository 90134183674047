import React from "react";
import auth_arrow from "../../../assets/icons/auth_arrow.svg";
import search from "../../../assets/icons/search.svg";
import { communityData } from "../../../utils/data";
import dp from "../../../assets/icons/profile_dp.svg";
import { useNavigate } from "react-router-dom";

type SearchTrendProps = {
  onBackClick?: () => void;
}

const SearhTrendPage: React.FC<SearchTrendProps> = ({ onBackClick }) => {

  return (
    <div>
      <div className="flex justify-between items-center pb-2">
        <img onClick={onBackClick} className="cursor-pointer" src={auth_arrow} />
        <div className="flex border w-[300px] h-[32px] rounded-[10px]">
          <input
            placeholder="search"
            className="w-full text-sm px-[10px] border-r-0 rounded-[10px]"
          />
          <div className="rounded-r-[10px] w-[35px] flex justify-center cursor-pointer items-center">
            <img src={search} className="w-[23px] h-[23px]" />
          </div>
        </div>
        <div className="opacity-0">.</div>
      </div>

      <div id="scroll-image" className="flex flex-col pt-1 gap-[20px] my-5 overflow-y-scroll h-[640px]">
        {communityData.map((items) => (
          <div className="flex flex-col gap-1">
            <div className="flex border-l border-l-[#9b979738] gap-[20px] border-[#9b979738] border-b shadow shadow-[#F1730080] p-[10px] w-fit px-3 rounded-t-[10px]">
              <img className="h-9" src={dp} />
              <div>
                <p className="font-semibold text-[14px] lg:text-[16px]">{items.name}</p>
                <p className="text-[10px] Lato-Light">{items.status}</p>
              </div>
            </div>
            <div className="w-[100%] xs:w-[90%] rounded-r-[10px] border-l border-l-[#9b979738] rounded-b-[10px] border-[#9b979772] border-b-4 shadow shadow-[#F1730080] pt-[20px]">
              {items.img ? (
                <div className=" w-full flex justify-center">
                  <img src={items.img} className="w-[95%] h-[220px]" />
                </div>
              ) : (
                ""
              )}

              <p className="text-[13px] lg:text-[14px] text-[#00000090] p-5">
                {items.content}
              </p>

              <div className="flex justify-between shadow shadow-gray-400 rounded-b-[10px] h-[27px] text-[11px] lg:text-sm items-center">
                <div className="flex justify-center w-full border-r border-gray-300">
                  Like
                </div>
                <div className="flex justify-center w-full border-r border-gray-300">
                  Comment
                </div>
                <div className="flex justify-center w-full">Share</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearhTrendPage;
