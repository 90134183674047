import React, { useState } from "react";
import illustration from "../../assets/img/Illustration.png";
import arrow from "../../assets/icons/auth_arrow.svg";
import { useNavigate } from "react-router-dom";
import SidebarItem from "../Profile/employerProfile/becomeAnEmployer/sidebar";
import { toast } from "react-toastify";
import AxiosInstance from "../../api/axios";

const ChooseType = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const User_id = localStorage.getItem("ids");

  const handleEmployer = async () => {
    setIsLoading(true);
    try {
      const res = await AxiosInstance.post(`/user/chooseType/${User_id}`, {
        type: "recruiter",
      });
      navigate("/User-Type");
    } catch (error) {
      toast.error("error selecting type", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSeeker = async () => {
    setIsLoading(true);
    try {
      const res = await AxiosInstance.post(`/user/chooseType/${User_id}`, {
        type: "seeker",
      });
      navigate("/User-Type");
    } catch (error) {
      toast.error("error selecting type", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex gap-1">
      <div className="w-[30%] justify-center items-center rounded-[15px] hidden border lg:flex shadow shadow-[#f17400a0] ">
        <SidebarItem />
      </div>

      <div className="bg-white w-full">
        <div className="bg-white w-full">
          <div className="w-full flex-col flex gap-5 items-center h-[100vh] justify-center">
            <div className="gap-5 sm:gap-0 flex flex-col items-center justify-center sm:flex-row xs:justify-around w-full">
              <div
                onClick={handleSeeker}
                className="border items-center justify-center rounded-lg bg-[#FAFAFA]  border-b border-b-gray-300 shadow h-44 w-64 sm:w-[300px] lg:w-64 gap-2 p-2 flex flex-col cursor-pointer hover:border-orange-300"
              >
                <div className="flex flex-col items-center gap-1">
                  <p className="text-[20px] font-semibold">
                    Join as a creative
                  </p>
                  <p className="Lato-Italics text-[12px] text-center">
                    Editor,cinematographer,voice over artist,sound
                    engineer,colorist,motion graphics
                  </p>
                </div>
              </div>

              <div
                onClick={handleEmployer}
                className="border items-center justify-center rounded-lg bg-[#FAFAFA]  border-b border-b-gray-300 shadow h-44 w-64 sm:w-[300px] lg:w-64 gap-2 p-2 flex flex-col cursor-pointer hover:border-orange-300"
              >
                <div className="flex flex-col gap-1">
                  <p className="text-[20px] font-semibold">
                    Become an employer
                  </p>
                  <p className="Lato-Italics text-[12px] text-center">
                    create jobs and hire creatives
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseType;
