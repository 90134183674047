import React, { useEffect, useRef, useState } from "react";
import search from "../../assets/icons/search.svg";
import loadingPic from "../../assets/icons/loading-1.gif";
import TalentpoolComponent from "./TalentpoolComponent";
import AxiosInstance from "../../api/axios";
import Empty_State from "../../comonent/Empty_State";

const TalentPoolPage = () => {
  const [loading, setLoading] = useState(false);
  const [parentItem, setParentItem] = useState([] as any);
  const [showCategory, setShowCategory] = useState("");
  const [showSkill, setShowSkill] = useState("");
  const User_id = localStorage.getItem("ids");

  const fetchTalents = async () => {
    try {
      setLoading(true);
      const res = await AxiosInstance.get(`/user/getAll`);

      if (Array.isArray(res.data)) {
        const filteredUsers = res.data.filter(
          (item) => item.role === "user" && item._id !== User_id
        );
        setParentItem(filteredUsers);
      } else {
        console.warn("Unexpected data format. Expected an array.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTalents();
  }, []);

  const handleFindFilter = async () => {
    setLoading(true);
    try {
      const categories = showCategory
      const searchContent = {
        categories: showCategory,
        "skills.skills": showSkill,
      };

      const res = await AxiosInstance.get(
        // "user/categoriesSkill/categoriesSkill",
        `user/categories/${categories}`,
        // categories/Colorist
        {
          params: searchContent,
        }
      );

      if (Array.isArray(res.data)) {
        setParentItem(res.data);
      }
    } catch (error) {
      console.error("Error fetching Talent:", error);
    } finally { 
      setLoading(false); 
      setShowSkill("");
      setShowCategory("");
    }
  };

  const handleClearFilters = () => {
    window.location.reload();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {
        loading ? (
          <div className="h-[80vh] w-full flex justify-center items-center">
            <img src={loadingPic} />
          </div>
        ) : (
          //  parentItem.length > 0 ? (
          <div className="mt-7 px-[10px] sm:px-[20px] lg:px-[35px] flex flex-col gap-[15px] sm:gap-[30px]">
            <div>
              <p className="text-[32px] Axiforma-bold">
                Find your next great hire
              </p>
            </div>

            <div className="flex items-center rounded-[10px] shadow-lg border-t border-gray-100">
              <div className="p-2 py-5 w-full flex">
                <div className="flex flex-col gap-2 w-full px-2">
                  <p className="font-bold">Category</p>
                  <input
                    className="w-[95%] p-1"
                    placeholder="Select..."
                    type="text"
                    value={showCategory}
                    onChange={(e) => setShowCategory(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full px-2 border-x border-gray-200">
                  <p className="font-bold">Must have skill</p>
                  <input
                    className="w-[95%] p-1"
                    placeholder="Select..."
                    type="text"
                    value={showSkill}
                    onChange={(e) => setShowSkill(e.target.value)}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full px-2">
                  <p className="font-bold">Years of experience</p>
                  <input
                    className="w-[95%] p-1"
                    placeholder="Select..."
                    type="text"
                  />
                </div>
              </div>
              <div>
                <img
                  onClick={handleFindFilter}
                  className="cursor-pointer"
                  src={search}
                  alt="/"
                />
              </div>
            </div>

            <div className="flex gap-3">
              <div className="hidden lg:flex">
                <div className=" bg-[#f7f7f7] h-[700px] shadow min-w-[280px] p-5 text-[13px]">
                  <div className="flex flex-col gap-1">
                    <p className="font-semibold">Keyword</p>
                    <input
                      className="border w-full bg-transparent p-[5px] rounded-[5px] text-sm"
                      type="text"
                      placeholder="Search by keyword"
                      name=""
                      id=""
                    />
                  </div>

                  <div></div>
                </div>
              </div>

              <div className="w-full">
                <div className="flex justify-between">
                  <p>
                    We have{" "}
                    <span className="font-semibold">{parentItem.length}</span>{" "}
                    candidates in the pool
                  </p>
                  <p
                    onClick={handleClearFilters}
                    className="font-light cursor-pointer"
                  >
                    Clear all filters
                  </p>
                </div>

                <div>
                  <TalentpoolComponent parentItem={parentItem} />
                </div>
              </div>
            </div>
          </div>
        )
        // ) : (
        //   <Empty_State />
        // )
      }
    </>
  );
};

export default TalentPoolPage;
