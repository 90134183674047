import React, { useState } from "react";
import CommunityLayout from "../../comonent/CommunityLayout";
import LoggedIn_Layout from "../../comonent/loggedIn_Layout";
import Layout from "../../comonent/Layout";

const Community = () => {
  const User_id = localStorage.getItem("ids");

  const handleService = () => {
    console.log("handle service working")
  }; 
  return (
    <>
      {User_id ? (
        <LoggedIn_Layout>
          <CommunityLayout />
        </LoggedIn_Layout>
      ) : (
        <Layout handleService={handleService}>
          <CommunityLayout />
        </Layout>
      )}
    </>
  );
};

export default Community;
