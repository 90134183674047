import React, { ReactNode, useState } from "react";
import SidebarItem from "../../pages/Profile/employerProfile/becomeAnEmployer/sidebar";

interface DashboardLayoutProps {
  children: ReactNode;
}

const BecomeEmployerLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  return (
    <div className="flex mt-10 gap-[16px] px-[10px] xs:px-[30px] mb-14">
      <div className="w-[25%] hidden lg:flex justify-center shadow shadow-[#f174008c] rounded-[4px]">
        <div className="mt-72">
          <SidebarItem />
        </div>
      </div>
      <main className="w-full lg:w-[70%] xl:w-[75%] shadow shadow-[#f174008c] rounded-[4px] ">
        {children}
      </main>
    </div>
  );
};

export default BecomeEmployerLayout;
