import React, { useState } from "react";
import illustration from "../../assets/img/Illustration.png";
import arrow from "../../assets/icons/auth_arrow.svg";
import { useNavigate } from "react-router-dom";
import google from "../../assets/icons/Google svg.svg";
import { premiumBenefit } from "../../utils/data";

const PremiumPage = () => {
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);
  };

  const User_id = localStorage.getItem("ids");

  return (
    <div className="flex">
      <div className="bg-[#f1730066] px-7 lg:px-0 w-[500px] md:w-[570px] lg:w-[900px] hidden md:flex justify-center items-center h-[100vh]">
        <div className="flex flex-col items-center justify-center gap-[50px]">
          <img src={illustration} />
          <p className="text-[26px] text-white lg:text-[31px] font-semibold lg:w-[95%] text-center">
            Online Community For Post-production Professionals
          </p>
        </div>
      </div>
      <div className="bg-white w-full">
        <div className="pt-[30px] px-[10px] xs:px-[20px] sm:px-[40px]">
          <img
            onClick={() => navigate(`${User_id ? "/Dashboard" : "/"}`)}
            className="cursor-pointer"
            src={arrow}
          />
        </div>

        <div className="w-full flex justify-center mt-10">
          <div className="w-[90%] md:w-[96%] lg:w-[80%] pb-10 xl:w-[70%] flex flex-col gap-[32px]">
            <div className="flex flex-col items-center">
              <p className="text-[24px] font-semibold text-center text-[#FF800C]">
                Start your Premium Membership now!
              </p>
              <p className="text-[15px]">no commitments, cancel anytime.</p>
            </div>

            <div className="flex flex-col xs:flex-row gap-12 xs:gap-1 lg:gap-3 w-full">
              <div className="flex flex-col gap-[48px] w-full">
                <div className="flex flex-col gap-[20px] font-semibold">
                  <div className="gap-3 justify-center flex text-[14px]">
                    <div className="flex gap-1">
                      <input
                        type="radio"
                        value="option1"
                        checked={selectedOption === "option1"}
                        onChange={handleOptionChange}
                      />
                      <p>Credit or Debit card</p>
                    </div>

                    <div className="flex gap-1">
                      <input
                        type="radio"
                        value="option2"
                        checked={selectedOption === "option2"}
                        onChange={handleOptionChange}
                      />
                      <p>Paypal</p>
                    </div>
                  </div>

                  <div className="flex flex-col gap-[8px]">
                    <p>Card Number</p>
                    <input
                      className="w-full h-[35px] text-[14px] border-b border-black p-[16px] font-normal bg-[#F6F6F6] text-[#ABABAB]"
                      placeholder="Enter your email address"
                    />
                  </div>

                  <div className="flex gap-4">
                    <div className="flex flex-col w-[85%] gap-[8px]">
                      <p>Security code</p>
                      <input className="w-full h-[35px] text-[14px] border-b border-black p-[16px] font-normal bg-[#F6F6F6] text-[#ABABAB]" />
                    </div>

                    <div className="flex flex-col gap-[8px]">
                      <p>Expiration</p>
                      <input className="w-full h-[35px] text-[14px] border-b border-black p-[16px] font-normal bg-[#F6F6F6] text-[#ABABAB]" />
                    </div>
                  </div>

                  <div className="flex flex-col items-center gap-[20px]">
                    <div className="flex justify-center">
                      <p className="text-center">
                        Pick a plan to start the premium plan
                      </p>
                    </div>
                    <div className="flex gap-[24px]">
                      <button className="shadow shadow-[#F17300] text-[13px] w-[100px] h-[28px] rounded-[10px]">
                        US $10{" "}
                        <span className="text-[9px] font-thin">monthly</span>
                      </button>

                      <button className="shadow shadow-[#F17300] text-[13px] w-[100px] h-[28px] rounded-[10px]">
                        US $100{" "}
                        <span className="text-[9px] font-thin">annually</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col gap-[32px]">
                <div>
                  <p className="font-semibold text-center text-[20px]">
                    Premium Membership Benefit
                  </p>
                </div>

                <div className="flex items-center gap-3 flex-col font-semibold">
                  {premiumBenefit.map(({ name, color }) => (
                    <div
                      className={`${color} rounded-[8px] h-[36px] w-[85%] xs:w-[175px] text-[14px] flex justify-center items-center shadow shadow-[#66758F]`}
                    >
                      {name}
                    </div>
                  ))}
                  <button className="bg-white rounded-[5px] text-[14px] shadow shadow-[#F17300] mt-2 w-[100px] h-[28px] font-normal">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumPage;
