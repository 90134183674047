import React from "react";
import fb from "../../../assets/icons/ic_baseline-facebook.svg";
import yt from "../../../assets/icons/mdi_youtube.svg";
import ig from "../../../assets/icons/mdi_instagram.svg";
import { scrolls } from "../../../utils/data";
import { NavLink, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const User_id = localStorage.getItem("ids");
  // const validate = !User_id;

  // xs:pb-5 sm:px-10 xl:px-[40px]

  return (
    <div
      id="Contact"
      className="pt-10 px-[12px] xs:px-[20px] sm:px-10 xl:px-[40px] py-[40px] Axiforma"
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-[40px] sm:gap-[20px]">
        <div className="flex flex-col gap-[50px]">
          <div className="text-[30px] xs:text-[36px] sm:text-[40px] Montserrat">
            <p>POST CITY</p>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex gap-[15px]">
              <img src={fb} />
              <img src={ig} />
              <img src={yt} />
            </div>
            <p className="text-[18px] font-semibold text-gray-900">hello@postcine.com</p>
          </div>
        </div>

        <div className="hidden sm:flex flex-col text-[14px] sm:sm:text-center p-4 gap-[20px] md:gap-[15px]">
          <div>
            <p className="text-[20px]">Categories</p>
          </div>

          <div className="gap-[15px] flex flex-col sm:items-center">
            {scrolls.map((items) => (
              <p
                className="cursor-pointer"
                onClick={() => navigate(items.link)}
              >
                {items.name}
              </p>
            ))}
          </div>
        </div>

        <div className="flex flex-col text-[14px] sm:text-center p-4 gap-[20px] md:gap-[15px]">
          <div>
            <p className="text-[20px]">About</p>
          </div>

          <div className="gap-[15px] flex flex-col sm:items-center">
            <p className="cursor-pointer w-fit ">job opportunities</p>
            <p className="cursor-pointer w-fit">partnerships</p>
            <p className="cursor-pointer w-fit">Privacy Policy</p>
            <p className="cursor-pointer w-fit">Terms of Services</p>
          </div>
        </div>

        <div className="flex flex-col text-[14px] sm:text-center p-4 gap-[20px] md:gap-[15px]">
          <div>
            <p className="text-[20px]">community</p>
          </div>

          <div className="gap-[15px] flex flex-col sm:items-center">
            <div className="flex justify-start w-fit">
              <button
                // disabled={validate}
                onClick={() => navigate("/Community")}
                className="cursor-pointer w-fit flex relative right-3 sm:right-0"
              >
                community hub
              </button>
            </div>
            <p className="cursor-pointer w-fit">become an employer</p>
            <p className="cursor-pointer w-fit">podcast</p>
            <p className="cursor-pointer w-fit">events</p>
            <p>community standards</p>
            <p>invite a friend</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
