import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  pages: { name: string; url: string }[];
};

const Stepper: FC<Props> = ({ pages }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [completedPages, setCompletedPages] = useState(0);

  const getCompletedPages = () => {
    const path = pathname.slice(pathname.lastIndexOf("/") + 1);
    const pageIndex = pages.findIndex((item) => path === item.url);
    setCompletedPages(pageIndex === -1 ? 0 : pageIndex);
  };

  useEffect(() => {
    getCompletedPages();
    return () => {
      setCompletedPages(pages.findIndex((item) => pathname === item.url));
    };
  }, [pathname, pages]);

  const navigateToUrl = (url: string) => {
    navigate(url);
  };

  const style = {
    active: "text-orange-400 border-orange-400",
    inactive: "text-[#2E3031] border-[#EFF0F0]",
  };

  return (
    <div>
      {pages.map(({ name, url }, index) => (
        <p
          key={index}
          // onClick={() => navigateToUrl(url)}
          className={`${
            completedPages >= index ? style.active : style.inactive
          } ml-4 text-[14px] border-l-2 pl-[14px] py-[10px] `}
        >
          {name}
        </p>
      ))}
    </div>
  );
};

export default Stepper;
