import React, { useState } from "react";
import logo from "../../assets/icons/postcine_logo.png";
import ImgUploader from "../../comonent/ImgUploader";
import { useNavigate } from "react-router-dom";

type JobsFormType = {
  image: string;
};

type JobType = "image";

const UploadJobs = () => {
  const EMPTY_FORM = {
    image: "",
  };

  const [formData, setFormData] = useState([
    { ...EMPTY_FORM },
  ] as JobsFormType[]);

  const handleAddJob = () => {
    setFormData([...formData, EMPTY_FORM]);
  };

  const handleDelete = (i: number) => {
    const filteredImages = formData.filter((image, index) => index !== i);
    setFormData([...filteredImages]);
  };

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()

  return (
    <div>
      <div className="border-b h-[70px] w-full flex items-center px-5">
        <div className="">
          <img src={logo} className="h-12 w-14" alt="" />
        </div>
      </div>

      <div className="flex justify-center p-6">
        <div className="w-[95%] xs:w-[85%] sm:w-[75%]">
          <div>
            <p className="text-[28px] xs:text-[38px] font-semibold">
              Upload Jobs
            </p>
            <p className="text-[14px] xs:text-[16px]">
              Kindly past employment along with duration{" "}
            </p>
          </div>

          <>
            <div className="flex flex-col gap-5 pt-[40px]">
              {formData.map(({ image }, i) => (
                <div className="flex flex-col gap-2">
                  <div className="grid grid-cols-3 gap-2 justify-items-center">
                    <>
                      <div className="shadow shadow-[#f174005f] w-full rounded-[20px] h-[195px] flex justify-center items-center">
                        <ImgUploader />
                      </div>
                      <div className="shadow shadow-[#f174005f] w-full rounded-[20px] h-[195px] flex justify-center items-center">
                        <ImgUploader />
                      </div>
                      <div className="shadow shadow-[#f174005f] w-full rounded-[20px] h-[195px] flex justify-center items-center">
                        <ImgUploader />
                      </div>
                    </>
                  </div>

                  <div className="flex justify-end">
                    {formData.length > 1 && (
                      <button
                        onClick={() => handleDelete?.(i)}
                        className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                      >
                        - Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-5">
              <p
                onClick={handleAddJob}
                className="font-semibold text-orange-400 cursor-pointer w-fit"
              >
                + Add job
              </p>
            </div>
          </>

          <div className="mt-7 flex flex-col items-center w-fit gap-3">
            <button
              className={`flex shadow w-[260px] shadow-[#F17300] h-[38px] font-semibold justify-center items-center rounded-[5px]`}
             onClick={() => navigate('/User-Profile')}
            >
              {isLoading ? "Loading..." : "Upload"}
            </button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default UploadJobs;
