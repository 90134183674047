import React from "react";
import backArrow from "../../assets/icons/Frame (3).svg";
import { useNavigate } from "react-router-dom";
import fa from "../../assets/icons/image 9.png";
import Settings_Layout from "../../comonent/settingsLayout";

const SecuritySettings = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex flex-col md:flex-row w-full px-[20px] xs:px-[30px] mt-[80px]">
        <div className="w-full border-0 md:border-r flex flex-col items-center gap-[35px]">
          <div className="flex items-center gap-1">
            <p className="font-semibold">Two Factor Authentication</p>
            <img src={fa} alt="/" />
          </div>
          <div className="flex gap-2">
            <button className="shadow shadow-[#F17300] rounded-[5px] text-xs p-1 w-[70px]">
              Agree
            </button>
            <button className="shadow shadow-[#F17300] rounded-[5px] text-xs p-1 w-[70px]">
              Disagree
            </button>
          </div>
        </div>

        <div className="flex flex-col w-full justify-end xs:px-[30px] mt-[80px]">
          <div className="w-full flex flex-col">
            <div className="pb-[20px] border-b w-[95%] xs:w-[85%]">
              <p className="font-semibold">Change Password</p>
            </div>
          </div>

          <div className="flex flex-col gap-[16px] mt-[23px]">
            <div className="flex flex-col gap-[7px]">
              <p className="Lato-Italics">Current Password</p>
              <div className="w-full">
                <input className="p-[10px] w-[95%] xs:w-[85%] h-[37px] shadow shadow-[#F17300]" />
              </div>
            </div>

            <div className="flex flex-col gap-[7px]">
              <p className="Lato-Italics">New Password</p>
              <div className="w-full">
                <input className="p-[10px] w-[95%] xs:w-[85%] h-[37px] shadow shadow-[#F17300]" />
              </div>
            </div>

            <div className="flex flex-col gap-[7px]">
              <p className="Lato-Italics">Confirm Password</p>
              <div className="w-full">
                <input className="p-[10px] w-[95%] xs:w-[85%] h-[37px] shadow shadow-[#F17300]" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center px-5 xs:px-10 mt-[40px]">
        <button className="shadow bg-[#F17300] text-white text-sm font-semibold h-[35px] w-[130px] rounded-[8px]">
          Save
        </button>
      </div>
    </div>
  );
};

export default SecuritySettings;
