import React from "react";
import communityIcon from "../../assets/icons/iconoir_community.svg";
import { NavLink } from "react-router-dom";
import follow from "../../assets/icons/mingcute_user-follow-fill.png";
import tutorial from "../../assets/icons/icons8_idea.png";
import comms from "../../assets/icons/comms.png";
import search from '../../assets/icons/Vector-search.png'

type SideModalProps = {
  onCommunity: () => void;
  onTips: () => void;
  onTrend: () => void;
  onFollower: () => void;
  isActive: boolean;
  isActive2: boolean;
  isActive3: boolean;
  isActive4: boolean;
};

const MobileModal: React.FC<SideModalProps> = ({
  onCommunity,
  onTips,
  onFollower,
  onTrend,
  isActive,
  isActive2,
  isActive3,
  isActive4,
}) => {
  return (
    <div className="flex w-full justify-between h-[50px] border-y border-gray-300 ">
      <div className="flex justify-center items-center w-full">
        <div
          onClick={onCommunity}
          className={
            isActive
              ? "bg-slate-200 w-[40px] h-[40px] rounded-[30px] shadow shadow-orange-200 border-b border-gray-400  flex justify-center items-center p-[3px]"
              : "bg-gray-50 cursor-pointer w-[40px] h-[40px] rounded-[30px] shadow shadow-orange-200 border-b border-gray-400  flex justify-center items-center p-[3px]"
          }
        >
          <img className="h-[15px]" src={comms} alt="/" />
        </div>
      </div>

      <div className="flex justify-center items-center w-full">
        <div
          onClick={onTrend}
          className={
            isActive3
              ? "bg-slate-200 w-[40px] h-[40px] rounded-[30px] shadow shadow-orange-200 border-b border-gray-400  flex justify-center items-center p-[3px]"
              : "bg-gray-50 cursor-pointer w-[40px] h-[40px] rounded-[30px] shadow shadow-orange-200 border-b border-gray-400  flex justify-center items-center p-[3px]"
          }
        >
         <img className="h-[15px]" src={search} alt="/" />
        </div>
      </div>
      

      <div className="flex justify-center items-center w-full">
        <div
          onClick={onTips}
          className={
            isActive2
              ? "bg-slate-200 w-[40px] h-[40px] rounded-[30px] shadow shadow-orange-200 border-b border-gray-400  flex justify-center items-center p-[3px]"
              : "bg-gray-50 cursor-pointer w-[40px] h-[40px] rounded-[30px] shadow shadow-orange-200 border-b border-gray-400  flex justify-center items-center p-[3px]"
          }
        >
          <img className="h-[19px]" src={tutorial} alt="/" />
        </div>
      </div>

      {/* <div className="flex justify-center items-center w-full">
        <div
          onClick={onFollower}
          className={
            isActive4
              ? "bg-slate-200 w-[40px] h-[40px] rounded-[30px] shadow shadow-orange-200 border-b border-gray-400  flex justify-center items-center p-[3px]"
              : "bg-gray-50 cursor-pointer w-[40px] h-[40px] rounded-[30px] shadow shadow-orange-200 border-b border-gray-400  flex justify-center items-center p-[3px]"
          }
        >
          <img className="h-[15px]" src={follow} alt="/" />
        </div>
      </div> */}
    </div>
  );
};

export default MobileModal;
