import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type AwardFormType = {
  award: string;
  category: string;
  link: string;
};

type AwardType = "award" | "category" | "link";

const AwardPage = () => {
  const EMPTY_FORM = {
    award: "",
    category: "",
    link: "",
  };

  const [formData, setFormData] = useState([
    { ...EMPTY_FORM },
  ] as AwardFormType[]);

  const handleAddAward = () => {
    setFormData([...formData, EMPTY_FORM]);
  };

  const handleDelete = (i: number) => {
    const filteredAward = formData.filter((award, index) => index !== i);
    setFormData([...filteredAward]);
  };

  const handleChange = (e: any, i: number, field: AwardType) => {
    if (field === "category") {
      formData[i][field] = e;
    } else {
      formData[i][field] = e.target.value;
    }
    setFormData([...formData]);
  };

  const data = [
    { name: "Add skills", url: "Skills" },
    { name: "Add awards", url: "Awards" },
    { name: "Add experience", url: "Experience" },
  ];

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [isLastPage, setIsLastPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState([] as any);

  useEffect(() => {
    const path = pathname.slice(
      pathname.lastIndexOf("/add_your_team") + 1,
      pathname.length
    );

    const totalPages = data ? data.length - 1 : 0;
    const isFirstPage = data
      ? data.findIndex((element) => element.url === path)
      : -1;
    setIsFirstPage(isFirstPage === 0);
    setIsLastPage(data && path === data[totalPages]?.url);
  }, [pathname, data]);

  return (
    <>
      <div className="p-[10px] pb-[40px]">
        <div className="flex justify-center text-orange-400">
          <p className="font-semibold text-[28px] underline">Awards</p>
        </div>

        <div className="flex flex-col gap-2 pt-[40px] w-[90%]">
          {formData.map(({ category, award, link }, i) => (
            <>
              <div className="flex flex-col gap-3">
                <div className="w-full flex gap-4">
                  <div className="flex flex-col gap-2 w-[40%]">
                    <p className="text-[14px] font-semibold">Award</p>
                    <div>
                      <input
                        className="border bg-[#f4f5f5] p-2 h-[42px] rounded-lg w-full"
                        type="text"
                        name="award"
                        value={award}
                        onChange={(e) => handleChange(e, i, "award")}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 w-[60%]">
                    <p className="text-[14px] font-semibold">Category</p>
                    <div>
                      <input
                        className="border bg-[#f4f5f5] p-2 h-[42px] rounded-lg w-full"
                        type="text"
                        value={category}
                        onChange={(e) => handleChange(e, i, "category")}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-2 w-full">
                  <p className="text-[14px] font-semibold">Award Link</p>
                  <div>
                    <input
                      className="border bg-[#f4f5f5] p-2 h-[42px] rounded-lg w-full"
                      type="text"
                      name="link"
                      value={link}
                      onChange={(e) => handleChange(e, i, "link")}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                {formData.length > 1 && (
                  <button
                    onClick={() => handleDelete?.(i)}
                    className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                  >
                    - Remove
                  </button>
                )}
              </div>
            </>
          ))}
        </div>

        <div className="mt-5">
          <p
            onClick={handleAddAward}
            className="font-semibold text-orange-400 cursor-pointer w-fit"
          >
            + Add award
          </p>
        </div>

        <div className="mt-7 flex flex-col items-end w-full gap-3">
        <button
          className={`flex shadow w-[260px] shadow-[#F17300] h-[38px] font-semibold justify-center items-center rounded-[5px]`}
          // onClick={next}
        >
          {isLoading ? "Loading..." : "Continue"}
        </button>
        </div>
      </div>
    </>
  );
};

export default AwardPage;
