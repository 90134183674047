import React from 'react'
import LoggedIn_Layout from '../../../../comonent/loggedIn_Layout'
import IndividualOptionPage from './individualOption'

const IndividualOption = () => {
  return (
    <LoggedIn_Layout>
        <IndividualOptionPage/>
    </LoggedIn_Layout>
  )
}

export default IndividualOption