import React from "react";
import DashboardNotif from "../../comonent/DashboardNotif";
import CloseBtn from "../../comonent/CloseButton";

const Notif_Popup = () => {
  return (
    <div className="w-full p-2">
      {/* <CloseBtn/> */}
      <DashboardNotif />
    </div>
  );
};

export default Notif_Popup;
