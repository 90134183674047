import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type uploadVideoProps = {
  onclose?: () => void;
  getRequest?: () => void;
  parentCommunity?: any;
};

const UploadVideoPopup: React.FC<uploadVideoProps> = ({
  onclose,
  getRequest,
  parentCommunity,
}) => {

  const ids = parentCommunity?._id
  const [previewUploadedVideo, setPreviewUploadedVideo] = useState<
    string | null
  >(null);
  const [loading, setLoading] = useState(false);
  const User_id = localStorage.getItem("ids");

  const [selectedUploadedVideo, setSelectedUploadedVideo] = useState<File[]>(
    []
  );

  const [selectedVideo, setSelectedVideo] = useState("");

  const handleChangeUploadedVideos = (e: any) => {
    setSelectedVideo(e.target.files[0]);

    const newVideo = e.target.files || [];
    setSelectedUploadedVideo([...selectedUploadedVideo, ...newVideo]);

    if (newVideo.length > 0 && previewUploadedVideo === null) {
      const reader = new FileReader();
      reader.onload = (e) =>
        setPreviewUploadedVideo((e.target as any).result as string);
      reader.readAsDataURL(newVideo[0]);
    }
  };

  const validate = !selectedVideo;



  const postVideo = async () => {
    setLoading(false)

    const formData = new FormData();
    formData.append("video", selectedVideo);

    try {
      AxiosInstance.patch(`/community/videos/${ids}`, formData)
      .then((response) => {
        if (response.status === 200) {
          toast.success(`videos uploaded`);
          setSelectedVideo("")
          if (onclose) {
            onclose()
          }
          if (getRequest) {
            getRequest()
          }
        }
      })
      .catch((error) => {
        toast.error(error.response);
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }

  };

  return (
    <div className="w-[500px] bg-white rounded-[5px] drop-shadow-lg">
      <div className="h-14 flex items-center">
        <p className="font-semibold text-[22px] px-5 w-full">Upload Video</p>
      </div>

      <div className="flex justify-center items-center my-5">
        <div className=" w-[80%] h-[250px] shadow shadow-[#f174005f] flex justify-center items-center rounded-[8px]">
          {previewUploadedVideo ? (
            <video
              src={previewUploadedVideo}
              controls
              className="w-full h-full object-cover rounded-md"
            />
          ) : (
            <>
              <label>
                <p className="flex justify-center items-center text-[38px] cursor-pointer font-black bg-[#f174005f] text-white w-[60px] rounded-[40px]">
                  +
                </p>
                <input
                  name="displayVideos"
                  id="displayVideos"
                  type="file"
                  accept="video/*"
                  onChange={handleChangeUploadedVideos}
                  className="hidden"
                />
              </label>
            </>
          )}
        </div>
      </div>

      <div className="h-14 flex items-center">
        <div className="flex justify-end w-full">
          <button onClick={onclose} className="font-semibold">
            Cancel
          </button>
          <button
            className={`flex shadow w-[110px] shadow-[#F17300] ${
              !validate ? "bg-[#F17300] text-white" : ""
            } h-[38px] font-semibold justify-center items-center rounded-[5px]`}
            onClick={postVideo}
            disabled={validate}
          >
            Continue
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UploadVideoPopup;
