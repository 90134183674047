import React, { useState } from "react";
import illustration from "../../assets/img/Illustration.png";
import arrow from "../../assets/icons/auth_arrow.svg";
import { useNavigate } from "react-router-dom";
import google from "../../assets/icons/Google svg.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import eye from "../../assets/icons/eye.png";

type SignupProps = {
  ActiveSignupProps?: () => void;
  ToggleLogin?: any;
};

const Signup_Pop: React.FC<SignupProps> = ({
  ActiveSignupProps,
  ToggleLogin,
}) => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [phone_no, setPhone_no] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const validate =
    !firstName ||
    !lastName ||
    !email ||
    !password ||
    !confirmPassword ||
    !phone_no ||
    !isChecked;

  const handleJoin = () => {
    setIsLoading(true);
    const newUserData = {
      firstName: firstName,
      lastName: lastName,
      phone_no: phone_no,
      email: email,
      password: password,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/signup`, newUserData)

      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          localStorage.setItem("email", email);
          localStorage.setItem("firstName", firstName);
          localStorage.setItem("lastName", lastName);
          localStorage.setItem(
            "verificationToken",
            response.data.verificationToken
          );
          localStorage.setItem("password", password);
          localStorage.setItem("phone_no", phone_no);
          // setIsLoading(true);
          navigate("/Is+Loading")
        }
      })
      .catch(({ error, response }) => {
        // console.log(response);
        if (!response) {
          toast("Please check internet connection", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.fields.email, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className=" w-[95%] p-3 sm:p-0 sm:w-[520px] h-[600px] shadow shadow-[#F17300] flex flex-col justify-center items-center bg-gray-50 rounded-[6px]">
      <div className="w-full flex justify-end pt-5 mb-3 pr-4">
        <div
          onClick={ActiveSignupProps}
          className="bg-[#f174005f] cursor-pointer flex justify-center font-semibold text-[14px] items-center text-red-500 w-6 h-6 rounded-[30px]"
        >
          X
        </div>
      </div>
      <div className="w-full flex justify-center overflow-y-scroll">
        <div className="w-[85%] flex flex-col gap-[32px]">
          <div className="flex flex-col gap-[35px] pb-10">
            <div className="flex justify-center pt-4">
              <button className="flex gap-[8px] h-[40px] w-[90%] justify-center items-center shadow shadow-[#f17400b4] rounded-[12px]">
                <img src={google} />
                Signup with Google
              </button>
            </div>

            <div className="flex flex-col gap-[20px] xs:gap-[30px] font-semibold">
              <div className="flex flex-col gap-[8px]">
                <p>First Name</p>
                <input
                  className="w-full h-[40px] p-[5px] font-semibold border-b border-black text-[#ABABAB] text-[14px]"
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>

              <div className="flex flex-col gap-[8px]">
                <p>Last Name</p>
                <input
                  className="w-full h-[40px] p-[5px] font-semibold border-b border-black text-[#ABABAB] text-[14px]"
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className="flex flex-col gap-[8px]">
                <p>Email</p>
                <input
                  type="email"
                  className="w-full h-[40px] p-[5px] border-b border-black font-semibold text-[#ABABAB] text-[14px]"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="flex flex-col gap-[8px]">
                <p>Phone Number</p>
                <input
                  className="w-full h-[47px] p-[5px] border-b border-black font-semibold text-[#ABABAB]"
                  required
                  value={phone_no}
                  onChange={(e) => setPhone_no(e.target.value)}
                />
              </div>

              <div className="flex flex-col gap-[8px]">
                <p>Password</p>
                <div className="flex items-center pr-1 bg-[#fff] border-b border-black">
                  <input
                    className="w-full h-[40px] p-[5px] font-normal  text-[#ABABAB] rounded-[5px]"
                    placeholder="Enter your Password"
                    required
                    value={password}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div onClick={togglePasswordVisibility} className="pl-1">
                    <img src={eye} className="w-4" alt="/" />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-[8px]">
                <p>Confirm Password</p>
                <div className="flex items-center pr-1 bg-[#fff] border-b border-black">
                  <input
                    className="w-full h-[40px] p-[5px] font-semibold text-[#ABABAB] text-[14px]"
                    required
                    value={confirmPassword}
                    type={showPassword2 ? "text" : "password"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <div onClick={togglePasswordVisibility2} className="pl-1">
                    <img src={eye} className="w-4" alt="/" />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between">
              <div className="text-[#757575] text-[10px] flex gap-2 items-center">
                <input
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  type="checkbox"
                />
                <p>I accept the terms and condition</p>
              </div>

              <button
                onClick={handleJoin}
                disabled={validate}
                className={`flex shadow w-[110px] shadow-[#F17300] ${
                  !validate ? "bg-[#F17300] text-white" : ""
                } h-[38px] font-semibold justify-center items-center rounded-[5px]`}
              >
                {isLoading ? "Loading..." : "Join in"}
              </button>
            </div>

            <div className="flex justify-center w-full text-[12px]">
              <p>
                Own an Account?{" "}
                <span
                  onClick={ToggleLogin}
                  className="underline cursor-pointer"
                >
                  log in
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Signup_Pop;
