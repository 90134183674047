import React, { useEffect, useState } from "react";
import backIcon from "../../../assets/icons/Frame (5).svg";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;

      if (scrollPosition > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={`header-content z-[999] px-[20px] sm:px-[40px] lg:px-[30px] bg-white shadow ${
        isSticky
          ? "sticky flex justify-between items-center h-[80px] "
          : " flex justify-between items-center h-[80px]"
      } `}
    >
      <div className="flex gap-10">
        <img src={backIcon} alt="" />
        <div className="text-[30px] xs:text-[36px] cursor-pointer sm:text-[40px] Montserrat">
          <p>POST CITY</p>
        </div>
      </div>
    </div>
  );
};

export default Header;
