import React, { useState } from "react";
import icon1 from "../../assets/icons/Ellipse 61.svg";
import icon2 from "../../assets/icons/Ellipse 62.svg";
import icon3 from "../../assets/icons/Ellipse 63.svg";
import icon4 from "../../assets/icons/Ellipse 64.svg";
import like from "../../assets/icons/image 12.png";
import { commentArray } from "../../utils/data";

type CommentType = {
    name: string;
    commentNum: string;
  comment: string;
  id: string
};

const CommentSection = () => {
  const [showCommentInput, setShowCommentInput] = useState([] as any);
  const [showComment, setShowComment] = useState(false);
  const [activeComment, setActiveComment] = useState<CommentType | null>(null)

  const handleClick = (id: number) => {
    if (id) {
      setShowComment(!showComment);
    }
    console.log(id);
 
  };

  const handleReply = (id: number) => {

    // console.log(id);
  };

  return (
    <div className="md:h-[590px] pb-3 pt-[2px] md:overflow-y-scroll rounded-[10px] flex flex-col gap-[10px] md:w-[40%] lg:w-[35%] xl:w-[30%] Axiforma">
      {commentArray?.map((items) => (
        <div
          // key={id}
          className="shadow md:border-x flex flex-col bg-white gap-[10px] shadow-gray-400 w-full text-[12px] rounded-[8px] p-2"
        >
          <div className="flex gap-2 items-end justify-between">
            <p className="font-bold text-[16px] hover:underline hover:cursor-pointer">
              {items.name}
            </p>
            <p className="text-[#454242] Lato-Light text-[11px]">
              Updated 1d Ago
            </p>
          </div>

          <p className="">{items.comment}</p>

          <>
            <div className="flex gap-[15px]">
              <p
                onClick={() => handleReply(items.id)}
                className="hover:underline text-[16px] hover:font-semibold cursor-pointer hover:text-gray-400"
              >
                Reply
              </p>

              <img src={like} className="h-[18px] cursor-pointer" alt="/" />
            </div>

            {showCommentInput ? (
              ""
            ) : (
              <div className="flex flex-col gap-2 items-end">
                <input
                  className="border rounded-[5px] w-full p-[5px] h-[30px]"
                  type="text"
                />
                <button className="bg-gray-400 text-white text-[14px] font-[10px] h-[26px] w-[70px] rounded-[5px]">
                  Send
                </button>
              </div>
            )}
 
            {showComment && (
              <div className="flex flex-col gap-1 ml-[40px]">
                <div className="flex gap-[10px] items-center">
                  <img src={icon2} alt="/" />
                  <div className="flex items-end">
                    <p className="text-[16px] font-semibold  hover:underline">
                      Josh
                    </p>
                  </div>
                  <p className="text-[#454242] Lato-Light text-[11px]">
                    4hrs Ago
                  </p>
                </div>

                <div className="flex flex-col">
                  <p className="">Sounds Good, we can proceed</p>
                </div>

                <div className="flex gap-[10px]">
                  <p className="hover:underline hover:font-semibold cursor-pointer hover:text-gray-400">
                    Reply
                  </p>

                  <img src={like} className="h-[15px] cursor-pointer" alt="/" />
                </div>
              </div>
            )}
          </>

          <div className="flex items-end gap-[15px] md:gap-[6px] lg:gap-5 text-[11px] lg:text-[12px]">
            <div className="flex gap-[2px] lg:gap-1">
              <img
                className="cursor-pointer h-[24px] lg:h-[28px]"
                src={icon1}
                alt="/"
              />
              <img
                className="cursor-pointer h-[24px] lg:h-[28px]"
                src={icon2}
                alt="/"
              />
              <img
                className="cursor-pointer h-[24px] lg:h-[28px]"
                src={icon3}
                alt="/"
              />
              <img
                className="cursor-pointer h-[24px] lg:h-[28px]"
                src={icon4}
                alt="/"
              />
            </div>

            <>
              <div>
                <p
                  key={items.id}
                  // onClick={()=>setShowComment(items) }
                  className="hover:underline cursor-pointer hover:text-gray-400"
                >
                  <span>{items.commentNum}</span> Comments
                </p>
              </div>
              <div>
                <p className="cursor-pointer hover:text-gray-400">
                  File Details
                </p>
              </div>
            </>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommentSection;
