import React from 'react'
import LoggedIn_Layout from '../../comonent/loggedIn_Layout'
import CreateJobsPage from './createJobs'

const CreateJobs = () => {
  return (
    <LoggedIn_Layout>
      <CreateJobsPage/>
    </LoggedIn_Layout>
  )
}

export default CreateJobs