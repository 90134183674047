import React, { ReactNode, createContext, useState, useContext } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import CommunitySideNav from "../../pages/community/communitySideNav";
import CommunityPage from "../../pages/community/communityPage";
import Tips_Guides from "../../pages/community/Tips_Guides";
import SearhTrendPage from "../../pages/community/searchTrend/searhTrendPage";
import MobileModal from "./mobileModal";
import Trend_xs from "../../pages/community/trend_xs";
import SuggestedFollow from "../../pages/community/suggestedFollow";
import IndividualProfile from "../../pages/community/IndividualProfile";
import IndividualMovie from "../../pages/community/individualMovies";
import CreateCommuity from "./createCommuity";
import MoreCommunity from "../../pages/community/moreCommunity";

interface DashboardLayoutProps {}

const CommunityLayout: React.FC<DashboardLayoutProps> = ({}) => {
  const [showMobileModal, setShowMobileModal] = useState(true);
  const [showCommunityPage, setShowCommunityPage] = useState(true);
  const [showSearchPage, setShowSearchPage] = useState(false);
  const [showTipsAndGuides, setShowTipsAndGuides] = useState(false);
  const [showTrendXs, setShowTrendXs] = useState(false);
  const [showSugFollowers, setShowSugFollowers] = useState(false);
  const [showIndividualProfile, setShowIndividualProfile] = useState(false);
  const [showIndividualMovies, setShowIndividualMovies] = useState(false);
  const [showCreateCommuity, setShowCreateCommuity] = useState(false);
  const [showMoreCommunity, setShowMoreCommunity] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [tipsActive, setTipsActive] = useState(false);

  const handleJoin = () => {
    setShowTipsAndGuides(false);
    setShowCommunityPage(false);
    setShowSearchPage(true);
  };

  const handleCommunity = () => {
    setIsActive2(false);
    setIsActive3(false);
    setIsActive4(false);
    setShowTipsAndGuides(false);
    setShowSearchPage(false);
    setShowIndividualProfile(false);
    setShowTrendXs(false);
    setShowSugFollowers(false);
    setTipsActive(false);
    setShowIndividualMovies(false);
    setShowCreateCommuity(false);
    setShowMoreCommunity(false);
    setShowCommunityPage(true);
    setIsActive(true);
  };

  const handleTips = () => {
    setIsActive(false);
    setIsActive3(false);
    setIsActive4(false);
    setShowSearchPage(false);
    setShowCommunityPage(false);
    setShowSugFollowers(false);
    setShowIndividualProfile(false);
    setShowTrendXs(false);
    setShowIndividualMovies(false);
    setShowCreateCommuity(false);
    setShowMoreCommunity(false);
    setTipsActive(true);
    setShowTipsAndGuides(true);
    setIsActive2(true);
  };

  const handleCommunityForms = () => {
    setIsActive2(false);
    setIsActive3(false);
    setIsActive4(false);
    setShowSearchPage(false);
    setShowCommunityPage(false);
    setShowSugFollowers(false);
    setShowIndividualProfile(false);
    setShowTrendXs(false);
    setShowIndividualMovies(false);
    setShowCreateCommuity(false);
    setTipsActive(false);
    setShowMoreCommunity(false);
    setShowTipsAndGuides(false);
    setShowCreateCommuity(true);
  };

  const handleSeeMore = () => {
    setIsActive2(false);
    setIsActive3(false);
    setIsActive4(false);
    setShowSearchPage(false);
    setShowCommunityPage(false);
    setShowSugFollowers(false);
    setShowIndividualProfile(false);
    setShowTrendXs(false);
    setShowIndividualMovies(false);
    setShowCreateCommuity(false);
    setTipsActive(false);
    setShowTipsAndGuides(false);
    setShowCreateCommuity(false);
    setShowMoreCommunity(true);
  };

  const handleTrend = () => {
    setIsActive(false);
    setIsActive2(false);
    setIsActive4(false);
    setShowSearchPage(false);
    setShowCommunityPage(false);
    setShowTipsAndGuides(false);
    setShowSugFollowers(false);
    setShowIndividualMovies(false);
    setShowCreateCommuity(false);
    setShowMoreCommunity(false);
    setShowTrendXs(true);
    setIsActive3(true);
  };

  const handleFollow = () => {
    setIsActive(false);
    setIsActive2(false);
    setIsActive3(false);
    setShowSearchPage(false);
    setShowCommunityPage(false);
    setShowTipsAndGuides(false);
    setShowTrendXs(false);
    setShowIndividualMovies(false);
    setShowCreateCommuity(false);
    setShowMoreCommunity(false);
    setShowSugFollowers(true);
    setIsActive4(true);
  };

  // const handleViewCommunity = () => {
  //   setShowCommunityPage(false);
  //   setShowTipsAndGuides(false);         // twitter view
  //   setShowTrendXs(false);
  //   setShowIndividualMovies(false);
  //   setShowCreateCommuity(false);
  //   setShowIndividualProfile(true);
  // };

  const handleViewCommunity = () => {
    setShowCommunityPage(false);
    setShowTipsAndGuides(false);
    setShowTrendXs(false);
    setShowIndividualMovies(false);
    setShowIndividualProfile(false);
    setShowCreateCommuity(false);
    setShowMoreCommunity(false);
    setShowIndividualMovies(true);
  };

  return (
    <>
      <div>
        {/* <Header /> */}
        <div className="flex gap-[16px] mt-5 mb-14 px-[10px] xs:px-5 ">
          <div className="w-full flex flex-col gap-[16px]">
            <div className="flex justify-center items-center bg-gray-100 rounded-[5px] h-[50px]">
              Advertisment
            </div>
            <div className="flex w-full gap-[16px]">
              <div className="w-[30%] xl:w-[25%] hidden lg:flex rounded-[3px]">
                <Sidebar onTips={handleTips} onCommunity={handleCommunity} />
              </div>
              <main className="w-full rounded-[3px]">
                <>
                  {showCommunityPage && (
                    <CommunityPage
                      handleCommunityForm={handleCommunityForms}
                      viewCommunity={handleViewCommunity}
                      handleSeeMore={handleSeeMore}
                    />
                  )}
                  {showSearchPage && (
                    <SearhTrendPage onBackClick={handleCommunity} />
                  )}
                  {showTipsAndGuides && <Tips_Guides />}
                  {showTrendXs && <Trend_xs />}
                  {showSugFollowers && <SuggestedFollow />}
                  {showIndividualProfile && (
                    <IndividualProfile backClick={handleCommunity} />
                  )}
                  {showIndividualMovies && (
                    <IndividualMovie handleBack={handleCommunity} />
                  )}
                  {showCreateCommuity && (
                    <CreateCommuity handleBack={handleCommunity} />
                  )}
                  {showMoreCommunity && (
                    <MoreCommunity
                      viewCommunity={handleViewCommunity}
                      handleBack={handleCommunity}
                    />
                  )}
                </>
              </main>
            </div>
          </div>
          <div className="w-[40%] lg:w-[35%] xl:w-[30%] hidden md:flex rounded-[3px]">
            <CommunitySideNav
              onCommunity={handleCommunity}
              TipsActive={tipsActive}
              onTips_Guide={handleTips}
              onTrendClick={handleJoin}
            />
          </div>
        </div>
      </div>

      {showMobileModal && (
        <div className="fixed bottom-[1px] z-[999] w-full flex md:hidden bg-white">
          <MobileModal
            isActive2={isActive2}
            isActive={isActive}
            isActive3={isActive3}
            onCommunity={handleCommunity}
            isActive4={isActive4}
            onTips={handleTips}
            onFollower={handleFollow}
            onTrend={handleTrend}
          />
        </div>
      )}
    </>
  );
};

export default CommunityLayout;
