import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import check from "../../assets/icons/feCheck1.svg";
import { premiumPerks } from "../../utils/data";

const BannerPage = () => {
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowBanner((prevShowBanner) => !prevShowBanner);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-[#F0EDEE] relative flex justify-center min-h-[780px] sm:min-h-[750px] md:min-h-[680px] lg:min-h-[650px] max-h-[900px] space-y-[70px] md:space-y-[100px]">
      {showBanner ? (
        <div className="flex flex-col text-center gap-[20px] mt-32">
          <div className="flex flex-col items-center">
            <p className="sm:text-[20px] w-[50%] lg:w-[25%] medium">
              over 50,000 post-production professionals
            </p>
            <p className="text-[36px] sm:text-[40px] md:text-[54px] lg:text-[64px] w-[85%] lg:w-[75%] hire">
              hire creative post - production professionals around the globe.
            </p>
          </div>
          <div className="flex justify-center">
            <p className="w-[50%] lg:w-[30%] medium">
              Join a network comprising millions of highly-rated post-production
              experts.
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col justify-center items-center py-14 md:py-0 gap-14 md:gap-0 md:flex-row w-full">
            <div className="flex w-full justify-center">
              <div className="flex flex-col gap-[50px] text-center w-[80%]">
                <p>Post City Premium</p>
                <div className="flex flex-col gap-[20px] xs:gap-[30px]">
                  <p className="text-[30px] Montserrat_semibold font-semibold">
                    Subscribe To Elevate Your Experience
                  </p>
                  <p className="font-semibold">
                    post city premium includes access to tutorials, e-books.
                    personal training with professionals, unlimited downloads
                  </p>
                </div> 
              </div>
            </div>

            <div className="flex w-full justify-center">
              <div className="shadow shadow-[#F17300] rounded-[20px] w-[260px] flex flex-col items-center text-center">
                <div className="flex flex-col gap-[15px] py-[30px]">
                  <p className="text-[20px] font-semibold">From $30/month</p>

                  <div className="flex flex-col gap-[8px]">
                    {premiumPerks.map((items) => (
                      <p className="flex gap-3 items-center">
                        <img src={check} />
                        <span>{items.name}</span>
                      </p>
                    ))}

                    <button
                      onClick={() => navigate("/Premium-Payment")}
                      className="shadow shadow-[#F17300] mt-4 p-1 font-semibold rounded-[5px]"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-[120px] blue_gradient min-h-[30px] w-full rounded-full" />
        </>
      )}
    </div>
  );
};

export default BannerPage;
