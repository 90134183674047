import React, { useEffect, useState } from "react";
import Stepper from "../../../comonent/stepper/stepper";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/icons/postcine_logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AxiosInstance from "../../../api/axios";
import chevronup from "../../../assets/icons/chevronup.png";
import chevrondown from "../../../assets/icons/chevrondown.png";

type SkillFormType = {
  skills: string;
  year: string;
  category: string;
};

type SkillType = "skills" | "year" | "category";

const Skills = () => {
  const EMPTY_FORM = {
    skills: "",
    year: "",
    category: "",
  };

  const [formData2, setFormData2] = useState([
    { ...EMPTY_FORM },
  ] as SkillFormType[]);

  const [formDataMtn, setFormDataMtn] = useState([
    { ...EMPTY_FORM },
  ] as SkillFormType[]);

  const [formDataEditor, setFormDataEditor] = useState([
    { ...EMPTY_FORM },
  ] as SkillFormType[]);

  const [formDataAnimator, setFormDataAnimator] = useState([
    { ...EMPTY_FORM },
  ] as SkillFormType[]);

  const [formDataSoundDesign, setFormDataSoundDesign] = useState([
    { ...EMPTY_FORM },
  ] as SkillFormType[]);

  const [formDataScores_Audio, setFormDataScores_Audio] = useState([
    { ...EMPTY_FORM },
  ] as SkillFormType[]);

  const [formDataColorist, setFormDataColorist] = useState([
    { ...EMPTY_FORM },
  ] as SkillFormType[]);

  const [formDataCinematography, setFormDataCinematography] = useState([
    { ...EMPTY_FORM },
  ] as SkillFormType[]);

  const [formDataVisualEffect, setFormDataVisualEffect] = useState([
    { ...EMPTY_FORM },
  ] as SkillFormType[]);

  const [formDataVoiceOver, setFormDataVoiceOver] = useState([
    { ...EMPTY_FORM },
  ] as SkillFormType[]);

  // Add Functions

  const handleAddDep = () => {
    setFormData2([...formData2, EMPTY_FORM]);
  };

  const handleAddEditor = () => {
    setFormDataEditor([...formDataEditor, EMPTY_FORM]);
  };

  const handleAddMtn = () => {
    setFormDataMtn([...formDataMtn, EMPTY_FORM]);
  };

  const handleAddAnimator = () => {
    setFormDataAnimator([...formDataAnimator, EMPTY_FORM]);
  };

  const handleAddSound = () => {
    setFormDataSoundDesign([...formDataSoundDesign, EMPTY_FORM]);
  };

  const handleAddScores_Audio = () => {
    setFormDataScores_Audio([...formDataScores_Audio, EMPTY_FORM]);
  };

  const handleAddColorist = () => {
    setFormDataColorist([...formDataColorist, EMPTY_FORM]);
  };

  const handleAddCinematography = () => {
    setFormDataCinematography([...formDataCinematography, EMPTY_FORM]);
  };

  const handleAddVisualEffect = () => {
    setFormDataVisualEffect([...formDataVisualEffect, EMPTY_FORM]);
  };

  const handleAddVoiceOver = () => {
    setFormDataVoiceOver([...formDataVoiceOver, EMPTY_FORM]);
  };

  // Delete Functions

  const handleDelete = (i: number) => {
    const filteredDepartment = formData2.filter(
      (department, index) => index !== i
    );
    setFormData2([...filteredDepartment]);
  };

  const handleDeleteEditor = (i: number) => {
    const filteredEditor = formDataEditor.filter(
      (department, index) => index !== i
    );
    setFormDataEditor([...filteredEditor]);
  };

  const handleDeleteMtn = (i: number) => {
    const filteredMtn = formDataMtn.filter((department, index) => index !== i);
    setFormDataMtn([...filteredMtn]);
  };

  const handleDeleteAnimator = (i: number) => {
    const filteredAnimator = formDataAnimator.filter(
      (department, index) => index !== i
    );
    setFormDataAnimator([...filteredAnimator]);
  };

  const handleDeleteSound = (i: number) => {
    const filterSoundDesign = formDataSoundDesign.filter(
      (department, index) => index !== i
    );
    setFormDataSoundDesign([...filterSoundDesign]);
  };

  const handleDeleteScore_Audio = (i: number) => {
    const filterScore_Audio = formDataScores_Audio.filter(
      (department, index) => index !== i
    );
    setFormDataScores_Audio([...filterScore_Audio]);
  };

  const handleDeleteColorist = (i: number) => {
    const filterColorist = formDataColorist.filter(
      (department, index) => index !== i
    );
    setFormDataColorist([...filterColorist]);
  };

  const handleDeleteCinematography = (i: number) => {
    const filterCinematography = formDataCinematography.filter(
      (department, index) => index !== i
    );
    setFormDataCinematography([...filterCinematography]);
  };

  const handleDeleteVisualEffect = (i: number) => {
    const filterVisualEffect = formDataVisualEffect.filter(
      (department, index) => index !== i
    );
    setFormDataVisualEffect([...filterVisualEffect]);
  };

  const handleDeleteVoiceOver = (i: number) => {
    const filterVoiceOver = formDataVoiceOver.filter(
      (department, index) => index !== i
    );
    setFormDataVoiceOver([...filterVoiceOver]);
  };

  const handleMtnGraphicsChange = (e: any, i: number, field: SkillType) => {
    formDataMtn[i][field] = e.target.value;
    formDataMtn[i].category = "Motion Graphics";
    setFormDataMtn([...formDataMtn]);
  };

  const handleChangeStillgraphics = (e: any, i: number, field: SkillType) => {
    formData2[i][field] = e.target.value;
    formData2[i].category = "Still Graphics";
    setFormData2([...formData2]);
  };

  const handleChangeEditor = (e: any, i: number, field: SkillType) => {
    formDataEditor[i][field] = e.target.value;
    formDataEditor[i].category = "Editor";
    setFormDataEditor([...formDataEditor]);
  };

  const handleChangeAnimator = (e: any, i: number, field: SkillType) => {
    formDataAnimator[i][field] = e.target.value;
    formDataAnimator[i].category = "Animator";
    setFormDataAnimator([...formDataAnimator]);
  };

  const handleChangeSoundDesign = (e: any, i: number, field: SkillType) => {
    formDataSoundDesign[i][field] = e.target.value;
    formDataSoundDesign[i].category = "Sound Design";
    setFormDataSoundDesign([...formDataSoundDesign]);
  };

  const handleChangeScores_Audio = (e: any, i: number, field: SkillType) => {
    formDataScores_Audio[i][field] = e.target.value;
    formDataScores_Audio[i].category = "Scores & Audio Mix";
    setFormDataScores_Audio([...formDataScores_Audio]);
  };

  const handleChangeColorist = (e: any, i: number, field: SkillType) => {
    formDataColorist[i][field] = e.target.value;
    formDataColorist[i].category = "Colorist";
    setFormDataColorist([...formDataColorist]);
  };

  const handleChangeCinematography = (e: any, i: number, field: SkillType) => {
    formDataCinematography[i][field] = e.target.value;
    formDataCinematography[i].category = "Cinematography";
    setFormDataCinematography([...formDataCinematography]);
  };

  const handleChangeVisualEffect = (e: any, i: number, field: SkillType) => {
    formDataVisualEffect[i][field] = e.target.value;
    formDataVisualEffect[i].category = "Visual Effect";
    setFormDataVisualEffect([...formDataVisualEffect]);
  };

  const handleChangeVoiceOver = (e: any, i: number, field: SkillType) => {
    formDataVoiceOver[i][field] = e.target.value;
    formDataVoiceOver[i].category = "Voice Over";
    setFormDataVoiceOver([...formDataVoiceOver]);
  };

  const data = [
    { name: "Add skills", url: "" },
    { name: "Add awards", url: "Award" },
    { name: "Add experience", url: "Experience" },
  ];

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState([] as any);
  const User_id = localStorage.getItem("ids");

  const [categories, setCategories] = useState([]);
  const [ownerCategories, setOwnerCategories] = useState<string[]>([]);


  const [isEditorLoading, setIsEditorLoading] = useState(false)
  const [isMtnGraphicsLoading, setIsMtnGraphicsLoading] = useState(false)
  const [isAnimatorLoading, setIsAnimatorLoading] = useState(false)
  const [isStillGraphicsLoading, setIsStillGraphicsLoading] = useState(false)
  const [isSoundDesignLoading, setIsSoundDesignLoading] = useState(false)
  const [isScore_AudioLoading, setIsScore_AudioLoading] = useState(false)
  const [isColoristLoading, setIsColoristLoading] = useState(false)
  const [isCinematographyLoading, setIsCinematographyLoading] = useState(false)
  const [isVisualEffectLoading, setIsVisualEffectLoading] = useState(false)
  const [isVoiceOverLoading, setIsVoiceOverLoading] = useState(false)


  const getCategories = async () => {
    setIsLoading(true);
    try {
      const res = await AxiosInstance.get(`/user/categoriesArray/${User_id}`);
      setCategories(res.data);
    } catch (error) {
      console.error(error);
      toast.error("error getting categories", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const filterCategories = () => {
    const filteredCategories = categories.filter((category) => {
      return (
        category === "Editor" ||
        category === "Motion Graphics" ||
        category === "Animator" ||
        category === "Still Graphics" ||
        category === "Sound Design" ||
        category === "Scores & Audio Mix" ||
        category === "Colorist" ||
        category === "Cinematography" ||
        category === "Visual Effect" ||
        category === "Voice Over"
      );
    });
    setOwnerCategories(filteredCategories.map((category) => category));
  };

  useEffect(() => {
    filterCategories();
  }, [categories]);

  useEffect(() => {
    const path = pathname.slice(
      pathname.lastIndexOf("/add_your_team") + 1,
      pathname.length
    );

    const totalPages = data ? data.length - 1 : 0;
    const isFirstPage = data
      ? data.findIndex((element) => element.url === path)
      : -1;
    setIsFirstPage(isFirstPage === 0);
    setIsLastPage(data && path === data[totalPages]?.url);
  }, [pathname, data]);

  const next = () => {
    navigate("/Awards");
  };

  const handleSubmitEditor = async () => {
    setIsEditorLoading(true);
    try {
      const skills = Array.from(formDataEditor);
      const res = await AxiosInstance.patch(`/user/skills/${User_id}`, {
        skills,
      });
      toast.success("new editor skill created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormDataEditor([EMPTY_FORM]);
      // next();
    } catch (error) {
      console.error(error);
      toast.error("error creating editor skill", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsEditorLoading(false);
    }
  };

  const handleSubmitMtn = async () => {
    setIsMtnGraphicsLoading(true);
    try {
      const skills = Array.from(formDataMtn);
      const res = await AxiosInstance.patch(`/user/skills/${User_id}`, {
        skills,
      });
      toast.success("new motion graphics skill created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormDataMtn([EMPTY_FORM]);
      // next();
    } catch (error) {
      console.error(error);
      toast.error("error creating motion graphics skill", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsMtnGraphicsLoading(false);
    }
  }

  const handleSubmitAnimator = async () => {
    setIsAnimatorLoading(true);
    try {
      const skills = Array.from(formDataAnimator);
      const res = await AxiosInstance.patch(`/user/skills/${User_id}`, {
        skills,
      });
      toast.success("new animator skill created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormDataAnimator([EMPTY_FORM]);
      // next();
    } catch (error) {
      console.error(error);
      toast.error("error creating animator skill", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsAnimatorLoading(false);
    }
  }  

  const handleStillGraphics = async () => {
    setIsStillGraphicsLoading(true);
    try {
      const skills = Array.from(formData2);
      const res = await AxiosInstance.patch(`/user/skills/${User_id}`, {
        skills,
      });
      toast.success("new still graphics skill created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormData2([EMPTY_FORM]);
      // next();
    } catch (error) {
      console.error(error);
      toast.error("error creating still graphics skill", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsStillGraphicsLoading(false);
    }
  } 
  
  const handleSubmitSoundDesign = async () => {
    setIsSoundDesignLoading(true);
    try {
      const skills = Array.from(formDataSoundDesign);
      const res = await AxiosInstance.patch(`/user/skills/${User_id}`, {
        skills,
      });
      toast.success("new sound design skill created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormDataSoundDesign([EMPTY_FORM]);
      // next();
    } catch (error) {
      console.error(error);
      toast.error("error creating sound design skill", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsSoundDesignLoading(false);
    }
  } 

  const handleSubmitScores_Audio = async () => {
    setIsScore_AudioLoading(true);
    try {
      const skills = Array.from(formDataScores_Audio);
      const res = await AxiosInstance.patch(`/user/skills/${User_id}`, {
        skills,
      });
      toast.success("new score & audio mix skill created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormDataScores_Audio([EMPTY_FORM]);
      // next();
    } catch (error) {
      console.error(error);
      toast.error("error creating score & audio mix skill", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsScore_AudioLoading(false);
    }
  } 

  const handleSubmitColorist = async () => {
    setIsColoristLoading(true);
    try {
      const skills = Array.from(formDataColorist);
      const res = await AxiosInstance.patch(`/user/skills/${User_id}`, {
        skills,
      });
      toast.success("new colorist created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormDataColorist([EMPTY_FORM]);
      // next();
    } catch (error) {
      console.error(error);
      toast.error("error creating colorist", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsColoristLoading(false);
    }
  } 

  const handleSubmitCinematography = async () => {
    setIsCinematographyLoading(true);
    try {
      const skills = Array.from(formDataCinematography);
      const res = await AxiosInstance.patch(`/user/skills/${User_id}`, {
        skills,
      });
      toast.success("new cinematography skill created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormDataCinematography([EMPTY_FORM]);
      // next();
    } catch (error) {
      console.error(error);
      toast.error("error creating cinematography skill", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsCinematographyLoading(false);
    }
  } 

  const handleSubmitVisualEffect = async () => {
    setIsVisualEffectLoading(true);
    try {
      const skills = Array.from(formDataVisualEffect);
      const res = await AxiosInstance.patch(`/user/skills/${User_id}`, {
        skills,
      });
      toast.success("new visual effect skill created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormDataVisualEffect([EMPTY_FORM]);
      // next();
    } catch (error) {
      console.error(error);
      toast.error("error creating visual effect skill", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsVisualEffectLoading(false);
    }
  } 

  const handleSubmitVoiceOver = async () => {
    setIsVoiceOverLoading(true);
    try {
      const skills = Array.from(formDataVoiceOver);
      const res = await AxiosInstance.patch(`/user/skills/${User_id}`, {
        skills,
      });
      toast.success("new voice over skill created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormDataVoiceOver([EMPTY_FORM]);
      // next();
    } catch (error) {
      console.error(error);
      toast.error("error creating voice over skill", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsVoiceOverLoading(false);
    }
  }   

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  // const validate = !formData[0].skills || !formData[0].year;
  const validate2 = !formData2[0].skills || !formData2[0].year;
  const validateMtn = !formDataMtn[0].skills || !formDataMtn[0].year;
  const validateEditor = !formDataEditor[0].skills || !formDataEditor[0].year;
  const validateAnimator =
    !formDataAnimator[0].skills || !formDataAnimator[0].year;
  const validateSoundDesign =
    !formDataSoundDesign[0].skills || !formDataSoundDesign[0].year;
  const validateScores_Audio =
    !formDataScores_Audio[0].skills || !formDataScores_Audio[0].year;
  const validateColorist =
    !formDataColorist[0].skills || !formDataColorist[0].year;
  const validateCinematography =
    !formDataCinematography[0].skills || !formDataCinematography[0].year;
  const validateVisualEffect =
    !formDataVisualEffect[0].skills || !formDataVisualEffect[0].year;
  const validateVoiceOver =
    !formDataVoiceOver[0].skills || !formDataVoiceOver[0].year;

  const MainValidation = !isChecked;

  return (
    <div>
      <div className="border-b h-[70px] w-full flex items-center px-5">
        <div className="">
          <img src={logo} className="h-12 w-14" alt="" />
        </div>
      </div>

      <div className="flex justify-center p-6">
        <div className="w-[95%] xs:w-[85%] sm:w-[75%]">
          <div>
            <p className="text-[28px] xs:text-[38px] font-semibold">
              Add Skill
            </p>
            <p className="text-[14px] xs:text-[16px]">
              Kindly choose skill pertaining to your category{" "}
            </p>
          </div>

          <div className="flex flex-col gap-2 pt-[40px]">
            {ownerCategories.map((categoryName) => (
              <>
                <div className="flex flex-col gap-2 pt-[40px]">
                  <div
                    key={categoryName}
                    className="h-[52px] rounded-[8px] bg-orange-200 flex px-3 shadow-md items-center justify-between mb-5"
                  >
                    <p className="font-semibold text-[20px]">{categoryName}</p>
                    <div>
                      <img src={chevrondown} alt="" />
                    </div>
                  </div>

                  {categoryName === "Editor" && (
                    <div className="">
                      {formDataEditor.map(({ year, skills }, i) => (
                        <>
                          <div key={i} className="">
                            <div className="w-full flex gap-4">
                              <div className="flex flex-col gap-2 w-[70%]">
                                <p className="text-[14px] font-semibold">
                                  Skill
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    name="skills"
                                    value={skills}
                                    onChange={(e) =>
                                      handleChangeEditor(e, i, "skills")
                                    }
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col gap-2 w-[30%]">
                                <p className="text-[14px] font-semibold">
                                  Year Aquired
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    value={year}
                                    name="year"
                                    onChange={(e) =>
                                      handleChangeEditor(e, i, "year")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            {formDataEditor.length > 1 && (
                              <button
                                onClick={() => handleDeleteEditor?.(i)}
                                className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                              >
                                - Remove
                              </button>
                            )}
                          </div>

                          <input
                            type="hidden"
                            name="category"
                            value={categoryName}
                          />
                        </>
                      ))}

                      <div className="mt-5">
                        <p
                          onClick={handleAddEditor}
                          className="font-semibold text-orange-400 cursor-pointer w-fit"
                        >
                          + Add skill
                        </p>
                      </div>

                      <div className="mt-2 flex items-center w-full justify-end gap-3">
                        <button
                          onClick={handleSubmitEditor}
                          disabled={validateEditor}
                          className={`flex shadow w-[150px] shadow-[#F17300] ${
                            !validateEditor ? "bg-[#F17300] text-white" : ""
                          } h-[30px] text-xs font-semibold justify-center items-center rounded-[5px]`}
                        >
                          {isEditorLoading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  )}

                  {categoryName === "Colorist" && (
                    <div className="">
                      {formDataColorist.map(({ year, skills }, i) => (
                        <>
                          <div key={i} className="">
                            <div className="w-full flex gap-4">
                              <div className="flex flex-col gap-2 w-[70%]">
                                <p className="text-[14px] font-semibold">
                                  Skill
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    name="skills"
                                    value={skills}
                                    onChange={(e) =>
                                      handleChangeColorist(e, i, "skills")
                                    }
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col gap-2 w-[30%]">
                                <p className="text-[14px] font-semibold">
                                  Year Aquired
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    value={year}
                                    name="year"
                                    onChange={(e) =>
                                      handleChangeColorist(e, i, "year")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            {formDataColorist.length > 1 && (
                              <button
                                onClick={() => handleDeleteColorist?.(i)}
                                className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                              >
                                - Remove
                              </button>
                            )}
                          </div>

                          <input
                            type="hidden"
                            name="category"
                            value={categoryName}
                          />
                        </>
                      ))}

                      <div className="mt-5">
                        <p
                          onClick={handleAddColorist}
                          className="font-semibold text-orange-400 cursor-pointer w-fit"
                        >
                          + Add skill
                        </p>
                      </div>

                      <div className="mt-2 flex items-center w-full justify-end gap-3">
                        <button
                          onClick={handleSubmitColorist}
                          disabled={validateColorist}
                          className={`flex shadow w-[150px] shadow-[#F17300] ${
                            !validateColorist ? "bg-[#F17300] text-white" : ""
                          } h-[30px] text-xs font-semibold justify-center items-center rounded-[5px]`}
                        >
                          {isColoristLoading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  )}

                  {categoryName === "Motion Graphics" && (
                    <>
                      <div className="">
                        {formDataMtn.map(({ year, skills }, i) => (
                          <>
                            <div key={i} className="">
                              <div className="w-full flex gap-4">
                                <div className="flex flex-col gap-2 w-[70%]">
                                  <p className="text-[14px] font-semibold">
                                    Skill
                                  </p>
                                  <div>
                                    <input
                                      className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                      type="text"
                                      name="skills"
                                      value={skills}
                                      onChange={(e) =>
                                        handleMtnGraphicsChange(e, i, "skills")
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="flex flex-col gap-2 w-[30%]">
                                  <p className="text-[14px] font-semibold">
                                    Year Aquired
                                  </p>
                                  <div>
                                    <input
                                      className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                      type="text"
                                      value={year}
                                      name="year"
                                      onChange={(e) =>
                                        handleMtnGraphicsChange(e, i, "year")
                                      }
                                    />
                                  </div>

                                  <input
                                    type="text"
                                    className="hidden"
                                    name="category"
                                    value={formDataMtn[i].category}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="flex justify-end">
                              {formDataMtn.length > 1 && (
                                <button
                                  onClick={() => handleDeleteMtn?.(i)}
                                  className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                                >
                                  - Remove
                                </button>
                              )}
                            </div>
                          </>
                        ))}

                        <div className="mt-5">
                          <p
                            onClick={handleAddMtn}
                            className="font-semibold text-orange-400 cursor-pointer w-fit"
                          >
                            + Add skill
                          </p>
                        </div>

                        <div className="mt-2 flex items-center w-full justify-end gap-3">
                          <button
                            onClick={handleSubmitMtn}
                            disabled={validateMtn}
                            className={`flex shadow w-[150px] shadow-[#F17300] ${
                              !validateMtn ? "bg-[#F17300] text-white" : ""
                            } h-[30px] text-xs font-semibold justify-center items-center rounded-[5px]`}
                          >
                            {isMtnGraphicsLoading ? "Loading..." : "Submit"}
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  {categoryName === "Animator" && (
                    <div className="">
                      {formDataAnimator.map(({ year, skills }, i) => (
                        <>
                          <div key={i} className="">
                            <div className="w-full flex gap-4">
                              <div className="flex flex-col gap-2 w-[70%]">
                                <p className="text-[14px] font-semibold">
                                  Skill
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    name="name"
                                    value={skills}
                                    onChange={(e) =>
                                      handleChangeAnimator(e, i, "skills")
                                    }
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col gap-2 w-[30%]">
                                <p className="text-[14px] font-semibold">
                                  Year Aquired
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    value={year}
                                    name="year"
                                    onChange={(e) =>
                                      handleChangeAnimator(e, i, "year")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            {formDataAnimator.length > 1 && (
                              <button
                                onClick={() => handleDeleteAnimator?.(i)}
                                className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                              >
                                - Remove
                              </button>
                            )}
                          </div>

                          <input
                            type="hidden"
                            name="category"
                            value={categoryName}
                          />
                        </>
                      ))}

                      <div className="mt-5">
                        <p
                          onClick={handleAddAnimator}
                          className="font-semibold text-orange-400 cursor-pointer w-fit"
                        >
                          + Add skill
                        </p>
                      </div>

                      <div className="mt-2 flex items-center w-full justify-end gap-3">
                        <button
                          onClick={handleSubmitAnimator}
                          disabled={validateAnimator}
                          className={`flex shadow w-[150px] shadow-[#F17300] ${
                            !validateAnimator ? "bg-[#F17300] text-white" : ""
                          } h-[30px] text-xs font-semibold justify-center items-center rounded-[5px]`}
                        >
                          {isAnimatorLoading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  )}

                  {categoryName === "Still Graphics" && (
                    <div className="">
                      {formData2.map(({ year, skills }, i) => (
                        <>
                          <div key={i} className="">
                            <div className="w-full flex gap-4">
                              <div className="flex flex-col gap-2 w-[70%]">
                                <p className="text-[14px] font-semibold">
                                  Skill
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    name="name"
                                    value={skills}
                                    onChange={(e) =>
                                      handleChangeStillgraphics(e, i, "skills")
                                    }
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col gap-2 w-[30%]">
                                <p className="text-[14px] font-semibold">
                                  Year Aquired
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    value={year}
                                    name="year"
                                    onChange={(e) =>
                                      handleChangeStillgraphics(e, i, "year")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            {formData2.length > 1 && (
                              <button
                                onClick={() => handleDelete?.(i)}
                                className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                              >
                                - Remove
                              </button>
                            )}
                          </div>

                          <input
                            className="hidden"
                            name="category"
                            value={formData2[i].category}
                          />
                        </>
                      ))}

                      <div className="mt-5">
                        <p
                          onClick={handleAddDep}
                          className="font-semibold text-orange-400 cursor-pointer w-fit"
                        >
                          + Add skill
                        </p>
                      </div>

                      <div className="mt-2 flex items-center w-full justify-end gap-3">
                        <button
                          onClick={handleStillGraphics}
                          disabled={validate2}
                          className={`flex shadow w-[150px] shadow-[#F17300] ${
                            !validate2 ? "bg-[#F17300] text-white" : ""
                          } h-[30px] text-xs font-semibold justify-center items-center rounded-[5px]`}
                        >
                          {isStillGraphicsLoading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  )}

                  {categoryName === "Sound Design" && (
                    <div className="">
                      {formDataSoundDesign.map(({ year, skills }, i) => (
                        <>
                          <div key={i} className="">
                            <div className="w-full flex gap-4">
                              <div className="flex flex-col gap-2 w-[70%]">
                                <p className="text-[14px] font-semibold">
                                  Skill
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    name="name"
                                    value={skills}
                                    onChange={(e) =>
                                      handleChangeSoundDesign(e, i, "skills")
                                    }
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col gap-2 w-[30%]">
                                <p className="text-[14px] font-semibold">
                                  Year Aquired
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    value={year}
                                    name="year"
                                    onChange={(e) =>
                                      handleChangeSoundDesign(e, i, "year")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            {formDataSoundDesign.length > 1 && (
                              <button
                                onClick={() => handleDeleteSound?.(i)}
                                className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                              >
                                - Remove
                              </button>
                            )}
                          </div>

                          <input
                            type="hidden"
                            name="category"
                            value={categoryName}
                          />
                        </>
                      ))}

                      <div className="mt-5">
                        <p
                          onClick={handleAddSound}
                          className="font-semibold text-orange-400 cursor-pointer w-fit"
                        >
                          + Add skill
                        </p>
                      </div>

                      <div className="mt-2 flex items-center w-full justify-end gap-3">
                        <button
                          onClick={handleSubmitSoundDesign}
                          disabled={validateSoundDesign}
                          className={`flex shadow w-[150px] shadow-[#F17300] ${
                            !validateSoundDesign
                              ? "bg-[#F17300] text-white"
                              : ""
                          } h-[30px] text-xs font-semibold justify-center items-center rounded-[5px]`}
                        >
                          {isSoundDesignLoading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  )}

                  {categoryName === "Scores & Audio Mix" && (
                    <div className="">
                      {formDataScores_Audio.map(({ year, skills }, i) => (
                        <>
                          <div key={i} className="">
                            <div className="w-full flex gap-4">
                              <div className="flex flex-col gap-2 w-[70%]">
                                <p className="text-[14px] font-semibold">
                                  Skill
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    name="name"
                                    value={skills}
                                    onChange={(e) =>
                                      handleChangeScores_Audio(e, i, "skills")
                                    }
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col gap-2 w-[30%]">
                                <p className="text-[14px] font-semibold">
                                  Year Aquired
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    value={year}
                                    name="year"
                                    onChange={(e) =>
                                      handleChangeScores_Audio(e, i, "year")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            {formDataScores_Audio.length > 1 && (
                              <button
                                onClick={() => handleDeleteScore_Audio?.(i)}
                                className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                              >
                                - Remove
                              </button>
                            )}
                          </div>

                          <input
                            type="hidden"
                            name="category"
                            value={categoryName}
                          />
                        </>
                      ))}

                      <div className="mt-5">
                        <p
                          onClick={handleAddScores_Audio}
                          className="font-semibold text-orange-400 cursor-pointer w-fit"
                        >
                          + Add skill
                        </p>
                      </div>

                      <div className="mt-2 flex items-center w-full justify-end gap-3">
                        <button
                          onClick={handleSubmitScores_Audio}
                          disabled={validateScores_Audio}
                          className={`flex shadow w-[150px] shadow-[#F17300] ${
                            !validateScores_Audio
                              ? "bg-[#F17300] text-white"
                              : ""
                          } h-[30px] text-xs font-semibold justify-center items-center rounded-[5px]`}
                        >
                          {isScore_AudioLoading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  )}

                  {categoryName === "Cinematography" && (
                    <div className="">
                      {formDataCinematography.map(({ year, skills }, i) => (
                        <>
                          <div key={i} className="">
                            <div className="w-full flex gap-4">
                              <div className="flex flex-col gap-2 w-[70%]">
                                <p className="text-[14px] font-semibold">
                                  Skill
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    name="name"
                                    value={skills}
                                    onChange={(e) =>
                                      handleChangeCinematography(e, i, "skills")
                                    }
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col gap-2 w-[30%]">
                                <p className="text-[14px] font-semibold">
                                  Year Aquired
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    value={year}
                                    name="year"
                                    onChange={(e) =>
                                      handleChangeCinematography(e, i, "year")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            {formDataCinematography.length > 1 && (
                              <button
                                onClick={() => handleDeleteCinematography?.(i)}
                                className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                              >
                                - Remove
                              </button>
                            )}
                          </div>

                          <input
                            type="hidden"
                            name="category"
                            value={categoryName}
                          />
                        </>
                      ))}

                      <div className="mt-5">
                        <p
                          onClick={handleAddCinematography}
                          className="font-semibold text-orange-400 cursor-pointer w-fit"
                        >
                          + Add skill
                        </p>
                      </div>

                      <div className="mt-2 flex items-center w-full justify-end gap-3">
                        <button
                          onClick={handleSubmitCinematography}
                          disabled={validateCinematography}
                          className={`flex shadow w-[150px] shadow-[#F17300] ${
                            !validateCinematography
                              ? "bg-[#F17300] text-white"
                              : ""
                          } h-[30px] text-xs font-semibold justify-center items-center rounded-[5px]`}
                        >
                          {isCinematographyLoading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  )}

                  {categoryName === "Visual Effect" && (
                    <div className="">
                      {formDataVisualEffect.map(({ year, skills }, i) => (
                        <>
                          <div key={i} className="">
                            <div className="w-full flex gap-4">
                              <div className="flex flex-col gap-2 w-[70%]">
                                <p className="text-[14px] font-semibold">
                                  Skill
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    name="name"
                                    value={skills}
                                    onChange={(e) =>
                                      handleChangeVisualEffect(e, i, "skills")
                                    }
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col gap-2 w-[30%]">
                                <p className="text-[14px] font-semibold">
                                  Year Aquired
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    value={year}
                                    name="year"
                                    onChange={(e) =>
                                      handleChangeVisualEffect(e, i, "year")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            {formDataVisualEffect.length > 1 && (
                              <button
                                onClick={() => handleDeleteVisualEffect?.(i)}
                                className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                              >
                                - Remove
                              </button>
                            )}
                          </div>

                          <input
                            type="hidden"
                            name="category"
                            value={categoryName}
                          />
                        </>
                      ))}

                      <div className="mt-5">
                        <p
                          onClick={handleAddVisualEffect}
                          className="font-semibold text-orange-400 cursor-pointer w-fit"
                        >
                          + Add skill
                        </p>
                      </div>

                      <div className="mt-2 flex items-center w-full justify-end gap-3">
                        <button
                          onClick={handleSubmitVisualEffect}
                          disabled={validateVisualEffect}
                          className={`flex shadow w-[150px] shadow-[#F17300] ${
                            !validateVisualEffect
                              ? "bg-[#F17300] text-white"
                              : ""
                          } h-[30px] text-xs font-semibold justify-center items-center rounded-[5px]`}
                        >
                          {isVisualEffectLoading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  )}

                  {categoryName === "Voice Over" && (
                    <div className="">
                      {formDataVoiceOver.map(({ year, skills }, i) => (
                        <>
                          <div key={i} className="">
                            <div className="w-full flex gap-4">
                              <div className="flex flex-col gap-2 w-[70%]">
                                <p className="text-[14px] font-semibold">
                                  Skill
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    name="name"
                                    value={skills}
                                    onChange={(e) =>
                                      handleChangeVoiceOver(e, i, "skills")
                                    }
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col gap-2 w-[30%]">
                                <p className="text-[14px] font-semibold">
                                  Year Aquired
                                </p>
                                <div>
                                  <input
                                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                                    type="text"
                                    value={year}
                                    name="year"
                                    onChange={(e) =>
                                      handleChangeVoiceOver(e, i, "year")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-end">
                            {formDataVoiceOver.length > 1 && (
                              <button
                                onClick={() => handleDeleteVoiceOver?.(i)}
                                className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                              >
                                - Remove
                              </button>
                            )}
                          </div>

                          <input
                            type="hidden"
                            name="category"
                            value={categoryName}
                          />
                        </>
                      ))}

                      <div className="mt-5">
                        <p
                          onClick={handleAddVoiceOver}
                          className="font-semibold text-orange-400 cursor-pointer w-fit"
                        >
                          + Add skill
                        </p>
                      </div>

                      <div className="mt-2 flex items-center w-full justify-end gap-3">
                        <button
                          onClick={handleSubmitVoiceOver}
                          disabled={validateVoiceOver}
                          className={`flex shadow w-[150px] shadow-[#F17300] ${
                            !validateVoiceOver ? "bg-[#F17300] text-white" : ""
                          } h-[30px] text-xs font-semibold justify-center items-center rounded-[5px]`}
                        >
                          {isVoiceOverLoading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ))}
          </div>

          <div className=" border-b mt-14 flex gap-2 text-[14px]">
            <input
              type="checkbox"
              className="cursor-pointer"
              checked={isChecked}
              onChange={handleCheckChange}
            />
            <p>Confirm skills loaded</p>
          </div>
          <div className="w-full">
            <div className="mt-3 flex flex-col items-center w-fit gap-3">
              <button
                onClick={next}
                disabled={MainValidation}
                className={`flex shadow w-[260px] shadow-[#F17300] ${
                  !MainValidation ? "bg-[#F17300] text-white" : ""
                } h-[38px] font-semibold justify-center items-center rounded-[5px]`}
              >
                {isLoading ? "Loading..." : "Continue"}
              </button>
            </div>
          </div>
        </div>

        <div className="flex text-[12px] sm:text-[14px] items-center justify-center ">
          <div>
            <Stepper pages={data} />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Skills;
