import React, { useEffect, useState } from "react";
import lazy_loading from "../../assets/icons/loading-icon.gif";
import AxiosInstance from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loadingPic from "../../assets/icons/loading-1.gif";
import IndividualMovie from "./individualMovies";
import CloseBtn from "../../comonent/CloseButton";
import CommentPopup from "./individualMovies/commentPopup";

type TimelineProps = {};

const TimeLine: React.FC<TimelineProps> = ({}) => {
  const User_id = localStorage.getItem("ids");
  const [loading, setLoading] = useState(false);
  const [parentPosts, setParentPosts] = useState([] as any);
  const [displayedPostsCount, setDisplayedPostsCount] = useState(7);
  const [showCommentSection, setShowCommentSection] = useState(null);

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get(`/posts/all`);
      const postsData = res.data;

      if (!Array.isArray(postsData)) {
        console.error("Unexpected response format:", postsData);
        return;
      }

      const postsWithUsers = await Promise.all(
        postsData.map(async (post) => {
          const userId = post.user;
          const userRes = await AxiosInstance.get(`/user/${userId}`);

          if (!userRes.data) {
            console.warn("Failed to fetch user data for post:", post._id);
            return { ...post, user: null };
          }

          const hasLikedResponse = await AxiosInstance.get(
            `/posts/likes/${post._id}`
          );

          const isLikedArray = hasLikedResponse.data;

          const isLiked = isLikedArray.filter((id: any) => id === User_id);

          return { ...post, user: userRes.data, isLiked };
        })
      );

      const sortedPosts = postsWithUsers.sort(
        (a, b) =>
          new Date(b.timestamp!).getTime() - new Date(a.timestamp!).getTime()
      );
      setParentPosts(sortedPosts);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handleLike = async (item: any) => {
    if (User_id) {
      const postId = item._id;
      try {
        const response = await AxiosInstance.patch(`posts/${postId}`, {
          like: item.isLiked?.includes(User_id) ? 0 : 1,
          user: User_id,
        });

        if (response.status === 200) {
          setParentPosts((prevPosts: any) =>
            prevPosts.map((post: any) =>
              post._id === postId
                ? {
                    ...post,
                    like: item.isLiked?.includes(User_id)
                      ? post.like - 1
                      : post.like + 1,
                    isLiked: item.isLiked?.includes(User_id)
                      ? []
                      : [...item.isLiked, User_id],
                  }
                : post
            )
          );
          toast.success(
            `Post ${item.isLiked?.includes(User_id) ? "unliked" : "liked"}!`
          );
        } else {
          console.error("Like update failed:", response.statusText);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  function formatTimestamp(timestampString: string) {
    const formattedDate = new Date(timestampString);
    const formattedDateString = formattedDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    const formattedTimeString = formattedDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return `${formattedDateString} ${formattedTimeString}`;
  }

  const [showAllCommunity, setShowAllCommunity] = useState(true);
  const [showIndividualCommuity, setShowIndividualCommuity] = useState(false);
  const [parentCommunity, setParentCommunity] = useState([] as any);

  const handleStateBack = () => {
    setShowIndividualCommuity(false);
    setShowAllCommunity(true);
  };

  const handleViewCommunity = (item: any) => {
    if (User_id) {
      const communityId = item.communityId;
      getIndividualCommunity(communityId);
    }
  };

  const getIndividualCommunity = async (communityId: any) => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get(`/community/${communityId}`);
      const item = res.data;
      const parentCommunity = {
        displayImage: item.displayImage,
        iconImage: item.iconImage,
        name: item.name,
        crew: item.crew,
        soundtrack: item.soundtrack,
        id: item._id,
        user: item.user,
        members: item.members,
      };

      setParentCommunity(parentCommunity);
      setShowAllCommunity(false);
      setShowIndividualCommuity(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        {showAllCommunity && (
          <div>
            <>
              {loading ? (
                <div className="h-[50vh] w-full flex justify-center items-center">
                  <img src={loadingPic} />
                </div>
              ) : parentPosts.length > 0 ? (
                <ul className="flex flex-col gap-[30px] mt-5 ">
                  {/* {parentPosts?.map((item: any, i: number) => ( */}
                  {parentPosts
                    .slice(0, displayedPostsCount)
                    .map((item: any, i: number) => (
                      <div className="flex flex-col gap-2 px-[1px] shadow border-b rounded-b-[10px] border-[#615f5f] pt-2">
                        <div>
                          <div className="flex items-center gap-[3px]">
                            <div className="text-[10px] p-[2px] rounded-[5px]">
                              <p>
                                <span
                                  onClick={() => {
                                    handleViewCommunity(item);
                                  }}
                                  className="font-bold hover:underline bg-orange-100 cursor-pointer lg:font-semibold mr-[2px] text-[#F17300]"
                                >
                                  {item.communityName}{" "}
                                </span>{" "}
                                Created a post on{" "}
                                <span> {formatTimestamp(item.timestamp)}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="shadow shadow-[#cac5c5] w-full rounded-[10px] border-b">
                          <div className="p-1 flex gap-1">
                            <div>
                              <img
                                className="h-[25px] w-[25px] rounded-[30px]"
                                src={item.user?.dp}
                                alt=""
                              />
                            </div>
                            <div>
                              <p
                                onClick={() => {
                                  handleViewCommunity(item);
                                }}
                                className="font-semibold text-[13px] cursor-pointer hover:underline"
                              >
                                {item.communityName}
                              </p>
                              <p className="sm:thin text-[12px]">
                                {item.user?.firstName} {item.user?.lastName}
                              </p>
                            </div>
                          </div>

                          <div className="flex flex-col gap-1">
                            <div>
                              <p className="text-[11px] px-2 sm:Lato-Light">
                                {item.message}
                              </p>
                            </div>

                            {item.postImage &&
                            Array.isArray(item.postImage) &&
                            item.postImage.length > 0 ? (
                              <div>
                                <img
                                  className="rounded-[5px] w-full h-[240px]"
                                  src={item.postImage}
                                />
                              </div>
                            ) : (
                              <div />
                            )}
                          </div>

                          <div className="flex w-full mt-2 text-[13px] rounded-b-3xl rounded-[20px]">
                            <p
                              key={item._id}
                              onClick={() => handleLike(item)}
                              className="flex w-full justify-center border p-[1px] cursor-pointer"
                            >
                              {item.isLiked?.includes(User_id) ? (
                                <span className="text-orange-400 w-full flex justify-center items-center font-bold hover:bg-[#f1740077] hover:text-white">
                                  {item.like} Likes (You liked!)
                                </span>
                              ) : (
                                <span className="text-black w-full flex justify-center items-center hover:bg-[#f1740077] hover:text-white">
                                  {item.like} Likes (Like)
                                </span>
                              )}
                            </p>

                            <p
                              onClick={() => setShowCommentSection(item)}
                              className="flex w-full justify-center border p-[2px] cursor-pointer hover:text-white hover:bg-[#f1740077]"
                            >
                              {item.comments.length} Comments
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}

                  {displayedPostsCount >= parentPosts.length ? (
                    ""
                  ) : (
                    <button
                      onClick={() =>
                        setDisplayedPostsCount(displayedPostsCount + 5)
                      }
                    >
                      Load More
                    </button>
                  )}
                </ul>
              ) : (
                <div className="h-[60vh] Axiforma w-full flex justify-center items-center">
                  kindly check your internet connection...
                </div>
              )}
            </>
            {showCommentSection && (
              <>
                <div className=" fixed top-9 left-0 w-full h-full z-[999] flex flex-col items-center justify-center">
                  <div className="px-5 bg-white rounded-[5px] drop-shadow-lg w-full xs:w-[90%] sm:w-[65%] md:w-[60%] lg:w-[50%]">
                    <CloseBtn onClose={() => setShowCommentSection(null)} />
                    <CommentPopup commentDetails={showCommentSection} />
                  </div>
                </div>
                <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
              </>
            )}
          </div>
        )}

        {showIndividualCommuity && (
          <IndividualMovie
            parentCommuity={parentCommunity}
            handleBack={handleStateBack}
          />
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default TimeLine;
