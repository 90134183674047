import React, { FC, useState } from "react";
import { job_details } from "../../utils/data";
import bookmark from "../../assets/icons/Frame 277.svg";
import Login_Pop from "../../comonent/Auth_Popups/Login_Pop";
import moment from "moment";
import TimeCalculator from "../../comonent/TimeCalculator";

type JobDetailsProps = {
  onDetailsClick?: () => void;
  popupClick?: any;
  jobDetails?: any;
};

const JobDetails: React.FC<JobDetailsProps> = ({
  onDetailsClick,
  popupClick,
  jobDetails,
}) => {
  const User_id = localStorage.getItem("ids");

  // const validate = !User_id;
  const handleClick = () => {
    popupClick();
  };
  const handleApply = () => {
    User_id ? console.log("id is present") : handleClick();
  };

  return (
    <div className="w-full sm:h-[1200px] md:h-[1150px] lg:h-[980px] rounded-[5px] Axiforma">
      <div className="shadow shadow-[#F17300] w-full pb-8">
        <div className="p-2 flex flex-col gap-[40px]">
          <div className="flex flex-col">
            <div className="flex justify-end pt-5 mb-3 pr-2">
              <div
                onClick={onDetailsClick}
                className="bg-[#f174005f] cursor-pointer flex justify-center font-semibold text-[14px] items-center text-red-500 w-6 h-6 rounded-[30px]"
              >
                X
              </div>
            </div>

            <div>
              <p className="text-[20px] md:text-[22px] lg:text-[26px] font-bold ">
                {jobDetails?.company}
              </p>

              <div className="flex">
                <>
                  {" "}
                  <p>{jobDetails?.city}</p>, <p>{jobDetails?.country}</p>
                </>
              </div>
              {/* <p className="text-[12px] Axiforma">Fagba Lagos, Nigeria</p> */}
            </div>
          </div>

          <div className="mb-2">
            <div className="flex flex-col gap-2 mb-[27px]">
              <p className="text-[13px]">
                You must create indeed an account before continuing to the
                employer’s page to apply{" "}
              </p>

              <div className="flex gap-2 ">
                <button
                  onClick={handleApply}
                  className="bg-[#F17300] shadow shadow-[#3d3428] rounded-[10px] w-[160px] md:w-[200px] lg:w-[40%] font-semibold h-[27px] md:h-[30px] flex justify-center items-center text-[12px] md:text-[14px]"
                >
                  Apply on employer’s page
                </button>

                <div className="shadow shadow-[#a8a3a3]">
                  <img src={bookmark} />
                </div>
              </div>
            </div>

            <div>
              <p className=" flex gap-1 italic text-[9px] sm:text-[10px] Axiforma">
              <TimeCalculator createdAt={jobDetails.createdAt}/>
              </p>
            </div>
          </div>
        </div>

        <hr />

        <div className="p-2">
          <div className="flex flex-col gap-[16px] mb-[27px]">
            <div>
              <p>Job Details</p>
              <p className="text-[10px] Lato-Italics font-bold">
                Here’s how the job details align with your job preferences.
                Manage job preferences anytime in your Profile.
              </p>
            </div>

            <div className="grid grid-cols-3 gap-2 lg:gap-0 lg:grid-cols-4 justify-between px-4">
              <div className="flex flex-col justify-center items-center gap-[2px]">
                <p className="text-[10px] font-semibold">Contract</p>
                <div className="text-[8px] flex font-bold justify-center items-center w-[50px] rounded-[2px] shadow shadow-[#3d3428] bg-[#F17300]">
                  {jobDetails?.contract}
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-[2px]">
                <p className="text-[10px] font-semibold">Qualification</p>
                <div className="text-[8px] flex font-bold justify-center items-center w-[50px] rounded-[2px] shadow shadow-[#3d3428] bg-[#F17300]">
                  {jobDetails?.qualification}
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-[2px]">
                <p className="text-[10px] font-semibold">Payment</p>
                <div className="text-[8px] flex font-bold justify-center items-center w-[50px] rounded-[2px] shadow shadow-[#3d3428] bg-[#F17300]">
                  {jobDetails?.payment}
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-[2px]">
                <p className="text-[10px] font-semibold">Location</p>
                <div className="text-[8px] flex font-bold justify-center items-center w-[50px] rounded-[2px] shadow shadow-[#3d3428] bg-[#F17300]">
                  {jobDetails?.city}
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="p-2 flex flex-col gap-[8px] mb-[20px]">
          <div>
            <p className="font-semibold text-[20px]">Benefits</p>
            <p className="text-[12px] Axiforma">
              Pulled From The Full Job Description
            </p>
          </div>

          <div className="grid grid-cols-2 gap-2 text-[12px] md:text-[13px] px-4 Axiforma">
            {jobDetails?.benefit?.map((benefitItem: any, index: any) => (
              <li key={index}>{benefitItem}</li>
            ))}
          </div>
        </div>

        <hr />

        <div className="flex flex-col p-2 gap-[45px] mt-[20px] px-3">
          <div className="flex justify-between text-[12px] gap-[5px] md:gap-[10px]">
            <div className="flex flex-col gap-2">
              <div>
                <p className="font-semibold">Work Location</p>
                <p>{jobDetails.country}</p>
              </div>

              <div>
                <p className="font-semibold">City:</p>
                <p>{jobDetails.city}</p>
              </div>

              <div>
                <p className="font-semibold">Qualification:</p>
                <p>{jobDetails.qualification}</p>
              </div>
            </div>

            <div className="flex flex-col gap-[10px]">
              <div className="flex flex-col gap-[8px] Axiforma">
                <p>
                  you enjoy working in a fast-paced, guest facing environment.
                </p>
                <p>you are team-oriented, Energetic, friendly and outgoing.</p>
                <p>you enjoy variability in your job.</p>
                <p>
                  you enjoy the flexibility evenings and late night is chanced.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-[10px] text-[12px]">
            <p className="font-semibold text-[12px]">Additional Information</p>
            <p className="Axiforma">{jobDetails.additionalInfo}</p>
          </div>
        </div>

        <div className="flex justify-end px-5 mt-4">
          <button className="bg-[#F17300] mb-7 font-semibold rounded-[25px] h-[36px] w-[140px] shadow shadow-[#3d3428] sm:w-[190px] text-sm">
            REPORT PAGE
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
