import React, { useState } from "react";
import { NotifState } from "../../utils/data";
import All from "./all";
import Posts from "./posts";
import Offers from "./offer";
import Messages from "./messages/messages";

const DashboardNotif = () => {
  const [activeTab, setActiveTab] = useState(NotifState[0].name);

  return (
    <div className="w-full p-2">
      <div className="mb-6">
        <div className="flex gap-2 text-[12px]">
          {NotifState.map((items) => (
            <div
              onClick={() => setActiveTab(items.name)}
              className={`shadow shadow-[#f174008d] ${activeTab === items.name ? ' text-orange-400' : 'bg-white text-black'} cursor-pointer font-semibold px-2 rounded-[5px]`}
            >
              {items.name}
            </div>
          ))}
        </div>
      </div>

      {activeTab === NotifState[0].name && <All />}
      {activeTab === NotifState[1].name && <Posts />}
      {activeTab === NotifState[2].name && <Offers />}
      {activeTab === NotifState[3].name && <Messages />} 
    </div>
  );
};

export default DashboardNotif;
 