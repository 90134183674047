import React, { ReactNode, useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Notification from "../Notification";
import ProfileDropdowm from "../Profile_Dropdown";
import LogoutModal from "../logoutModal/logoutModal";

interface DashboardLayoutProps {
  children: ReactNode;
  handleService: () => void;
}

const Layout: React.FC<DashboardLayoutProps> = ({
  children,
  handleService,
}) => {
  const [showNotif, setShowNotif] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const contacts = useRef(null)
  const homes = useRef(null)

  const handleToggle = () => {
    setShowDropdown(false);
    setShowNotif(!showNotif);
  };

  const dropClick = () => {
    setShowNotif(false);
    setShowDropdown(!showDropdown);
  };

  const handleLockout = () => {
    setShowDropdown(false);
    setShowLogoutModal(!showLogoutModal);
  };

  const handleContact = () => {
    if (contacts.current) {
      (
        contacts.current as {
          scrollIntoView: (options: ScrollIntoViewOptions) => void;
        }
      ).scrollIntoView({ behavior: "smooth" });
    }
  }

  const handleHomes = () => {
    if (homes.current) {
      (
        homes.current as {
          scrollIntoView: (options: ScrollIntoViewOptions) => void;
        }
      ).scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <div ref={homes}>
      <Header
        DropdownClick={dropClick}
        NotifClick={handleToggle}
        onService={() => handleService()}
        onContact={() => handleContact()}
      />
      <main>{children}</main>
      <div ref={contacts}>
        <Footer />
      </div>

      {showNotif && <Notification />}
      {showDropdown && <ProfileDropdowm onDropdownClick={handleLockout} />}

      {showLogoutModal && (
        <>
          <div className=" fixed top-0 left-0 w-full h-full z-[999] flex items-center justify-center">
            <LogoutModal onClose={() => setShowLogoutModal(false)} />
          </div>
          <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
        </>
      )}
    </div>
  );
};

export default Layout;
