import React, { useEffect, useState } from "react";
import { LoggedOutSideNav, userSideNav } from "../../utils/data";
import { NavLink, useNavigate } from "react-router-dom";
import dp from "../../assets/icons/profile_dp.svg";
import LogoutModal from "../logoutModal/logoutModal";
import AxiosInstance from "../../api/axios";

type SideModalProps = {
  onClose: () => void;
  onLogoutClick: () => void;
};

const SIdeModal: React.FC<SideModalProps> = ({ onClose, onLogoutClick }) => {
  const navigate = useNavigate();
  const name = localStorage.getItem("firstName");
  const User_id = localStorage.getItem("ids");
  const [parentDp, setParentDp] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const fetchDp = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get(`/user/dp/${User_id}`);
      const imageURL = res.data;
      setParentDp(imageURL);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDp();
  }, []);

  return (
    <div
      id="scroll-image"
      className="w-[290px] xs:w-[300px] sm:w-[350px] bg-white rounded-l-md shadow h-[100vh] overflow-y-scroll"
    >
      <div className="flex justify-end pt-5 pr-3">
        <div
          onClick={onClose}
          className="bg-[#f174005f] cursor-pointer flex justify-center font-semibold text-[14px] items-center text-red-500 w-6 h-6 rounded-[30px]"
        >
          X
        </div>
      </div>

      <div className="w-full Axiforma mt-5">
        {User_id ? (
          <div className="flex flex-col gap-[30px] lg:gap-[44px] items-center pb-[70px]">
            <div className="flex flex-col gap-[15px] w-full justify-center">
              <div className=" w-full flex justify-center bg-[#f174005f] py-[20px] items-center">
                {parentDp ? (
                  <img className="h-32 lg:h-48 w-32 xl:w-40 rounded-[120px]" src={parentDp} />
                ) : (
                  <div className="h-28 lg:h-48 w-32 xl:w-40 rounded-[120px]" />
                )}
                {/* <img className="h-28 lg:h-48 w-32 xl:w-40" src={dp} /> */}
              </div>

              <p className="font-bold text-[#905a27] text-[14px] xl:text-[16px] text-center flex justify-center">
                {name} is an accomplished editor
              </p>
            </div>

            <div className="flex flex-col text-[13px] w-full">
              {userSideNav.map(({ name, linkk }) => (
                <NavLink
                  key={name}
                  to={linkk}
                  className={({ isActive }) =>
                    isActive
                      ? "hover:bg-[#f174005f] text-white w-full bg-[#f174005f] flex items-center justify-start px-4 h-[51px]"
                      : "hover:bg-[#f174005f] w-full hover:font-bold hover:text-white flex items-center justify-start px-4 h-[51px]"
                  }
                >
                  <div>{name}</div>
                </NavLink>
              ))}

              <div
                onClick={onLogoutClick}
                className="hover:bg-[#f174005f] w-full hover:font-bold hover:text-white flex items-center justify-start px-4 h-[51px]"
              >
                Logout
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-[30px] lg:gap-[44px] items-center pb-[70px]">
            <div className="flex flex-col gap-[15px] w-full justify-center">
              <div className=" w-full flex justify-center bg-[#f174005f] py-[20px] items-center">
                <img className="h-28 lg:h-48 w-28 xl:w-40 rounded-[60px] bg-white" />
              </div>
            </div>

            <div className="flex flex-col text-[13px] w-full">
              {LoggedOutSideNav.map(({ name, linkk }) => (
                <NavLink
                  key={name}
                  to={linkk}
                  className={({ isActive }) =>
                    isActive
                      ? "hover:bg-[#f174005f] text-white w-full bg-[#f174005f] flex items-center justify-start px-4 h-[51px]"
                      : "hover:bg-[#f174005f] w-full hover:font-bold hover:text-white flex items-center justify-start px-4 h-[51px]"
                  }
                >
                  <div>{name}</div>
                </NavLink>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SIdeModal;
