import React, { useEffect, useState } from "react";
import search from "../../../assets/icons/search.svg";
import { Link, useNavigate } from "react-router-dom";
import bell from "../../../assets/icons/bi_bell-fill.svg";
import dp from "../../../assets/icons/Ellipse 42 (2).svg";
import logo from "../../../assets/icons/postcine_logo.png";
import community from "../../../assets/icons/iconoir_community (1).svg";
import AxiosInstance from "../../../api/axios";

type HeaderProps = {
  NotifClick?: () => void;
  DropdownClick?: () => void;
};

const Header: React.FC<HeaderProps> = ({ DropdownClick, NotifClick }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [isSeeker, setIsSeeker] = useState(true);
  const navigate = useNavigate();
  const User_Name = localStorage.getItem("firstName");
  const User_id = localStorage.getItem("ids");
  const User_type = localStorage.getItem("userType");
  const [parentDp, setParentDp] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;

      if (scrollPosition > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
  }, []);

  const fetchDp = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get(`/user/dp/${User_id}`);
      const imageURL = res.data;
      setParentDp(imageURL);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;

      if (scrollPosition > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    fetchDp();
  }, []);

  return (
    <>
      <div
        className={`header-content z-[999] px-[10px] xs:px-[20px] sm:px-[40px] bg-white shadow ${
          isSticky
            ? "sticky flex justify-between items-center h-[70px] xs:h-[90px] "
            : " flex justify-between items-center h-[70px] xs:h-[90px] "
        } `}
      >
        <div className="flex gap-[15px] items-center">
          <img
            onClick={() => {
              navigate("/Dashboard");
            }}
            src={logo}
            className="h-14 w-16"
            alt=""
          />
        </div>

        <div className="flex gap-10">
          <div className="hidden md:flex gap-[15px] items-center">
            <p
              onClick={() => {
                navigate("/Dashboard");
              }}
              className="cursor-pointer hover:text-[#F17300] font-semibold"
            >
              Home
            </p>
            {User_type === "recruiter" ? (
              <Link
                to={"/Talent-Pool"}
                className="cursor-pointer hover:text-[#F17300] font-semibold"
              >
                Talent Pool
              </Link>
            ) : (
              <Link
                to={"/Explore-Jobs"}
                className="cursor-pointer hover:text-[#F17300] font-semibold"
              >
                Jobs Pool
              </Link>
            )}

            <Link
              to={"/Events"}
              className="cursor-pointer hover:text-[#F17300] font-semibold"
            >
              Event
            </Link>
            {isSeeker ? (
              <Link
                to={"/Create-Jobs"}
                className="cursor-pointer hover:text-[#F17300] font-semibold"
              >
                Create Jobs
              </Link>
            ) : (
              <Link
                to={"/Job-offers"}
                className="cursor-pointer hover:text-[#F17300] font-semibold"
              >
                Job offers
              </Link>
            )}
          </div>

          <div className="flex items-center gap-4">
            <div>
              <img
                className="cursor-pointer"
                onClick={() => navigate("/Community")}
                src={community}
                alt="/"
              />
            </div>

            <div className="flex md:hidden justify-center items-center">
              <img
                onClick={NotifClick}
                className="h-[18px] w-[18px] cursor-pointer"
                src={bell}
              />
              <div className="w-3 h-3 -ml-2 mb-4 rounded-full bg-[#F17300] text-white text-center text-[8px]">
                9
              </div>
            </div>

            <div
              onClick={DropdownClick}
              className="flex gap-2 justify-center items-center cursor-pointer md:shadow py-[4px] px-1 md:min-w-[140px] rounded-[7px]"
            >
              <div className="hidden md:flex">
                <p className="font-semibold">
                  Hi <span>{User_Name}</span>
                </p>
              </div>

              {parentDp ? (
                <img
                  className="h-[42px] xs:h-[48px] w-[50px] rounded-[70px] cursor-pointer"
                  src={parentDp}
                  alt="/"
                />
              ) : (
                // <img className="h-[42px] xs:h-[48px] cursor-pointer" src={dp} />
                <div className="rounded-[50px] h-[42px] xs:h-[48px] w-[65px] shadow cursor-pointer" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
