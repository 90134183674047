import React, { useState, ChangeEvent, useRef } from "react";

const FileUpload: React.FC = () => {
  const user_id = localStorage.getItem("ids");
  const validate = !user_id;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedFile(file || null);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    console.log('pushing')
  };

  const buttonText = selectedFile ? selectedFile.name : "Upload Your Resume ";
  // const fileText = selectedFile ? selectedFile.name : '';

  return (
    <div>
      <div>
        <input
          disabled={validate}
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <button className="text-[#F17300]" onClick={handleButtonClick}>
          {buttonText}
        </button>
      </div>
      {/* <p>{fileText}</p> */}
    </div>
  );
};

export default FileUpload;
