import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  categoryOption,
  contractOption,
  jobTypeOption,
  locatonOption,
  paymentOption,
} from "../../../../utils/data";
import AxiosInstance from "../../../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import auth_arrow from "../../../../assets/icons/auth_arrow.svg";

type BenefitFormType = {
  benefit: string;
};

type BenefitType = "benefit";

interface EmailError {
  message?: string;
}

const CompanyOptionsFirstPage: React.FC<EmailError> = ({ message }) => {
  const EMPTY_FORM = {
    benefit: "",
  };

  const [formData, setFormData] = useState([
    { ...EMPTY_FORM },
  ] as BenefitFormType[]);

  const handleAddEmp = () => {
    setFormData([...formData, EMPTY_FORM]);
  };

  const handleDelete = (i: number) => {
    const filteredAward = formData.filter((pastExp, index) => index !== i);
    setFormData([...filteredAward]);
  };

  const handleChange = (e: any, i: number) => {
    formData[i] = e.target.value;
    setFormData([...formData]);
  };

  const [selectedCategory, setSelectedCategory] = useState<any>([]);
  const handleSelectChange = (selected: any) => {
    setSelectedCategory(selected);
  };

  const [selectedCountry, setSelectedCountry] = useState<any>([]);
  const handleSelectCountry = (selected: any) => {
    setSelectedCountry(selected);
  };

  const [selectedContract, setSelectedContract] = useState<any>([]);
  const handleSelectContract = (selected: any) => {
    setSelectedContract(selected);
  };

  const [selectedPayment, setSelectedPayment] = useState<any>([]);
  const handleSelectPayment = (selected: any) => {
    setSelectedPayment(selected);
  };

  const [selectedWorkType, setSelectedWorkType] = useState<any>([]);
  const handleSelectWorkType = (selected: any) => {
    setSelectedWorkType(selected);
  };

  const [isLoading, setIsloading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState<string>("");
  const [externalLink, setExternalLink] = useState("");
  const [city, setCity] = useState("");
  const [priceRange, setPriceRange] = useState("");
  const [qualification, setQualification] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [error, setError] = useState<EmailError | null>(null);
  const User_id = localStorage.getItem("ids");
  const navigate = useNavigate();

  const validate =
    !name ||
    !email ||
    !city ||
    !priceRange ||
    !qualification ||
    selectedCategory.length === 0 ||
    selectedContract.length === 0 ||
    selectedCountry.length === 0 ||
    selectedPayment.length === 0 ||
    selectedWorkType.length === 0;

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    // Check email format and display an error message if needed
    const isValid = emailRegex.test(email);
    setError(
      isValid ? null : { message: "Please enter a valid email address." }
    );
  };

  const handleSubmit = () => {
    setIsloading(true);

    const benefirArray = Array.from(formData);
    const newJobs = {
      company: name,
      email: email,
      joblink: externalLink,
      city: city,
      country: selectedCountry.value,
      benefit: benefirArray,
      payment: selectedPayment.value,
      contract: selectedContract.value,
      pricerange: priceRange,
      workType: selectedWorkType.value,
      categories: selectedCategory.value,
      qualification: qualification,
      additionalInfo: additionalInfo,
      organization: "Company",
      status: "Open",
    };

    try {
      AxiosInstance.post(`/jobs/createJob/${User_id}`, newJobs)
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            toast.success(`new job created`);
          }
          navigate("/Create-Jobs");
        })
        .catch((error) => {
          // console.log(error);
          toast.error(error.response?.data?.message);
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      toast.error(`An error occured`);
    }
  };

  return (
    <div className="my-7 px-5 sm:px-10 gap-7 flex flex-col">
      <div className="text-[24px] font-semibold">
        <p>Register as a company</p>
      </div>

      <div className="drop-shadow-md h-[140px] rounded-[8px] bg-blue-100" />

      <div className="flex flex-col gap-10">
        <p className="font-semibold text-[16px]">Kindly fill the form below</p>

        <div className="flex flex-col gap-7">
          <div className="w-full">
            <div className="flex flex-col gap-2 w-[100%]">
              <p className="text-[14px] font-semibold">Company Name</p>
              <div>
                <input
                  className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="w-full flex gap-4">
            <div className="flex flex-col gap-2 w-[50%]">
              <p className="text-[14px] font-semibold">Company Mail</p>
              <div>
                <input
                  className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                  type="text"
                  name="email"
                  value={email}
                  placeholder="john.doe@company.com"
                  onChange={handleEmailChange}
                />
                {error && (
                  <p className="text-red-500 text-[14px]">{error.message}</p>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-2 w-[50%]">
              <p className="text-[14px] font-semibold">External Link</p>
              <div>
                <input
                  className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                  type="text"
                  name="externalLink"
                  value={externalLink}
                  onChange={(e) => setExternalLink(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="w-full flex gap-4">
            <div className="flex flex-col gap-2 w-[50%]">
              <p className="text-[14px] font-semibold">City</p>
              <div>
                <input
                  className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                  type="text"
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>

            <div className="flex flex-col gap-2 w-[50%]">
              <p className="text-[14px] font-semibold">Country</p>
              <div className="relative border bg-[#f4f5f5] h-[42px] rounded-lg w-full px-[1px]">
                <Select
                  options={locatonOption}
                  value={selectedCountry}
                  onChange={handleSelectCountry}
                  className="absolute top-[1px] rounded-lg"
                />
              </div>
            </div>
          </div>

          <div>
            <>
              {formData.map(({ benefit }, i) => (
                <>
                  <div className="flex flex-col gap-2 w-[100%]">
                    <p className="text-[14px] font-semibold">Benefits</p>
                    <div>
                      <input
                        className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                        type="text"
                        name="benefit"
                        value={benefit}
                        onChange={(e) => handleChange(e, i)}
                      />
                    </div>
                  </div>

                  <div className="flex justify-end">
                    {formData.length > 1 && (
                      <button
                        onClick={() => handleDelete?.(i)}
                        className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                      >
                        - Remove
                      </button>
                    )}
                  </div>
                </>
              ))}
            </>

            <div className="mt-5 flex justify-start w-full px-4">
              <p
                onClick={handleAddEmp}
                className="font-semibold text-orange-400 cursor-pointer w-fit"
              >
                + Add Benefit
              </p>
            </div>
          </div>

          <div className="w-full flex gap-4">
            <div className="flex flex-col gap-2 w-[50%]">
              <p className="text-[14px] font-semibold">Payment</p>
              <div className="relative border bg-[#f4f5f5] h-[42px] rounded-lg w-full px-[1px]">
                <Select
                  options={paymentOption}
                  value={selectedPayment}
                  onChange={handleSelectPayment}
                  className="absolute top-[1px] rounded-lg"
                />
              </div>
            </div>

            <div className="flex flex-col gap-2 w-[50%]">
              <p className="text-[14px] font-semibold">Contract</p>
              <div className="relative border bg-[#f4f5f5] h-[42px] rounded-lg w-full px-[1px]">
                <Select
                  options={contractOption}
                  value={selectedContract}
                  onChange={handleSelectContract}
                  className="absolute top-[1px] rounded-lg"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="flex flex-col gap-2 w-[100%]">
              <p className="text-[14px] font-semibold">Price Range</p>
              <div>
                <input
                  className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                  type="number"
                  name="priceRange"
                  value={priceRange}
                  onChange={(e) => setPriceRange(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="w-full flex gap-4">
            <div className="flex flex-col gap-2 w-[50%]">
              <p className="text-[14px] font-semibold">Work Type</p>
              <div className="relative border bg-[#f4f5f5] h-[42px] rounded-lg w-full px-[1px]">
                <Select
                  options={jobTypeOption}
                  value={selectedWorkType}
                  onChange={handleSelectWorkType}
                  className="absolute top-[1px] rounded-lg"
                />
              </div>
            </div>

            <div className="flex flex-col gap-2 w-[50%]">
              <p className="text-[14px] font-semibold">Category</p>
              <div className="relative border bg-[#f4f5f5] h-[42px] rounded-lg w-full px-[1px]">
                <Select
                  options={categoryOption}
                  value={selectedCategory}
                  onChange={handleSelectChange}
                  className="absolute top-[1px] rounded-lg"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2 w-[100%]">
            <p className="text-[14px] font-semibold">Qualification</p>
            <div>
              <input
                className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                type="text"
                name="qualification"
                value={qualification}
                onChange={(e) => setQualification(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col gap-2 w-[100%]">
            <p className="text-[14px] font-semibold">Additional Info</p>
            <div>
              <textarea
                className="border bg-[#f4f5f5] rounded-lg h-[120px] w-full p-[10px] nonResizableTextarea"
                name="additionalInfo"
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
              />
            </div>
          </div>

          <div className="flex w-full justify-between mt-7">
            <div
              onClick={() => navigate("/Create-Jobs")}
              className="w-[38px] h-[38px] rounded-[30px] shadow shadow-[#F17300] cursor-pointer"
            >
              <img src={auth_arrow} alt="" />
            </div>

            <div className="flex flex-col items-end gap-3">
              <button
                onClick={handleSubmit}
                disabled={validate}
                className={`flex shadow w-[260px] shadow-[#F17300] ${
                  !validate ? "bg-[#F17300] text-white" : ""
                } h-[38px] font-semibold justify-center items-center rounded-[5px]`}
              >
                {isLoading ? "Loading..." : "Create Job"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CompanyOptionsFirstPage;
