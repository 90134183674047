import React, { useState } from "react";
import { toast } from "react-toastify";
import AxiosInstance from "../../../api/axios";

type PostPopupProps = {
  postDetails?: any;
};

const EditPostPopup: React.FC<PostPopupProps> = ({ postDetails }) => {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState("")

  const validate = loading;

  const handleUpdatePost = async () => {
    const postId = postDetails._id;

    setLoading(true);
    try {
      console.log(posts)
      // AxiosInstance.patch(`/posts/${postId}`)
      //   .then((response) => {
      //     if (response.status === 200) {
      //       toast.success("Post updated", {
      //         position: toast.POSITION.TOP_RIGHT,
      //       });
      //     }
      
      //   })
      //   .catch((error) => {
      //     toast.error(error.response?.data?.message);
      //     toast.error(error, {
      //       position: toast.POSITION.TOP_RIGHT,
      //     });
      //   });
    } catch (error) {
      console.error("Error adding user:", error);
      toast.error("error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-[100px]">
      <p className="font-semibold text-[24px]">Edit Post</p>
      <div className="my-4 flex flex-col gap-5">
        <input
          className="w-full h-[45px] p-[16px] font-normal bg-[#F6F6F6] text-[#ABABAB] rounded-[5px]"
          placeholder="Enter your email address"
          value={postDetails.message}
          onChange={(e) => setPosts(e.target.value)}
        />

        <div className="flex justify-end">
          <button
            disabled={validate}
            // onClick={handleSubmit}
            className={`flex shadow shadow-[#F17300] h-[36px] w-40 ${
              !validate ? "bg-[#F17300] text-white" : ""
            } font-semibold justify-center items-center rounded-[5px]`}
          >
            {loading ? "loading..." : "Send"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditPostPopup;
