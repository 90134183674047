import React, { useState, ChangeEvent, useRef } from "react";

const ImgUploader: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  //   const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
  //     const file = event.target.files?.[0];
  //     setSelectedFile(file || null);
  //   };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      // Handle the case where the selected file is not an image
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const buttonText = selectedFile ? (
    selectedFile.name
  ) : (
    <p className="flex justify-center items-center text-[38px] cursor-pointer font-black bg-[#f174005f] text-white w-[60px] rounded-[40px]">
      +
    </p>
  );
  // const fileText = selectedFile ? selectedFile.name : '';

  return (
    <div>
      <div className="">
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
          accept="image/*"
        />
        <button className="text-[#F17300]" onClick={handleButtonClick}>
          {buttonText}
        </button>
      </div>
      {/* <p>{fileText}</p> */}
    </div>
  );
};

export default ImgUploader;
