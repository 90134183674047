import React, { useState } from "react";
import auth_arrow from "../../../assets/icons/auth_arrow.svg";
import blackbook from "../../../assets/img/netflix-blackbook.jpg";
import bell from "../../../assets/icons/bi_bell-fill.svg";
import { IndividualProfileState } from "../../../utils/data";
import Latest from "./latest";
import Top from "./top";
import About from "./about";

type IndividualProfileProps = {
  backClick?: () => void;
};

const IndividualProfile: React.FC<IndividualProfileProps> = ({ backClick }) => {
  const [activeTab, setActiveTab] = useState(IndividualProfileState[0].name);

  return (
    <div className="flex flex-col">
      <div className="flex gap-4 items-center pb-3">
        <img onClick={backClick} className="cursor-pointer" src={auth_arrow} />

        <div>
          <p className="text-[26px] font-semibold">Black Book</p>
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="w-full relative">
          <img src={blackbook} alt="" />

          <div className="h-[65px] px-3 text-white flex flex-col gap-1 w-full py-1 bg-[#a1a0a06c] absolute bottom-0 z-[9]">
            <div className="flex justify-between">
              <p className="text-[20px] Axiforma-bold">Black Book</p>

              <img src={bell} alt="/" />
            </div>
            <div className="flex justify-between gap-3 ">
              <div className="flex gap-2">
                <div className="flex">
                  <div className="h-5 w-5 rounded-[30px] bg-blue-100"></div>
                  <div className="h-5 w-5 rounded-[30px] bg-blue-100"></div>
                  <div className="h-5 w-5 rounded-[30px] bg-blue-100"></div>
                </div>

                <p>4.1K members</p>
              </div>

              <button className="text-[12px] shadow shadow-[#fff] h-[20px] rounded-[5px] px-2">
                Joined
              </button>
            </div>
          </div>
        </div>

        <div className="h-[40px] shadow shadow-[#f1740058] flex gap-1 justify-between border-t border-[#f1740058] rounded-[13px] font-semibold text-[18px] ">
          {IndividualProfileState.map((items) => (
            <div
              onClick={() => setActiveTab(items.name)}
              className={`shadow shadow-[#f174008d] cursor-pointer w-full rounded-[13px] flex justify-center items-center ${
                activeTab === items.name
                  ? "bg-orange-400 text-white"
                  : "bg-white text-black"
              } `}
            >
              {items.name}
            </div>
          ))}
        </div>

        {activeTab === IndividualProfileState[0].name && <Latest />}
        {activeTab === IndividualProfileState[1].name && <Top />}
        {activeTab === IndividualProfileState[2].name && <About />}
      </div>
    </div>
  );
};

export default IndividualProfile;
