import React from "react";
import Layout from "../../comonent/Layout";
import ExploreJobsPage from "./exploreJobsPage";
import LoggedIn_Layout from "../../comonent/loggedIn_Layout";

const ExploreJobs = () => {
  const User_id = localStorage.getItem("ids");

  const handleService = () => {
    console.log("handle service is working")
  }
  return (
    <>
      {User_id ? (
        <LoggedIn_Layout>
          <ExploreJobsPage />
        </LoggedIn_Layout>
      ) : (
        <Layout handleService={handleService}>
          <ExploreJobsPage />
        </Layout>
      )}
    </>
  );
};

export default ExploreJobs;