import moment from "moment";
import React from "react";

type ReviewProps = {
  ParentReview?: any;
};

const Review: React.FC<ReviewProps> = ({ ParentReview }) => {
  return (
    <div className="mt-5 flex flex-col gap-4">
      <div>
        <p className="text-[18px] font-semibold">Reviews</p>
      </div>
      <div
        id="scroll-image"
        className="flex gap-5 scroll-containers Axiforma text-[14px] p-1 w-full md:w-[430px] lg:w-[480px] xl:w-[700px]"
      >
        {ParentReview.map((item: any) => (
          <div className="">
            <div className="bg-white flex flex-col gap-5 w-[350px] h-full sm:w-[370px] p-[25px] text-[14px] rounded-[10px] shadow shadow-[#f17400b3]">
              <div className="flex gap-2">
                <div className="flex top-2 justify-center">
                  <div className="w-14 h-14 rounded-[35px] bg-slate-300 "></div>
                </div>

                <div className="w-full relative">
                  <div className="font-semibold flex gap-2 top-2 absolute text-[17px]">
                    <p> {item.reviewer_firstName}</p>
                    <p>{item.reviewer_lastName}</p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col text-[14px] items-start">
                <p className="leading-7">{item.review}</p>
              </div>

              <div className="flex gap-2 justify-end font-semibold items-center">
                <p>{item?.createdAt?.split("T")[0]}</p>
                <div className="bg-gray-400 min-w-[8px] w-2 h-2 rounded-full"></div>
                <p> {moment(item?.createdAt)?.format("hh:mm a")} </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Review;
