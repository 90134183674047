import React from "react";
import dp from "../../../assets/icons/Ellipse 42 (2).svg";
import { suggestedFollow } from "../../../utils/data";

const SuggestedFollow = () => {
  return (
    <div>
      <div className="rounded-[10px] flex flex-col gap-[20px]  font-semibold justify-center items-center">
        <div className="flex justify-start w-full">
          <p className="text-[#00000080] font-semibold">Suggested Follows</p>
        </div>

        <div className="w-full flex flex-col gap-[12px]">
          {suggestedFollow.map(({ name, details }) => (
            <div className="w-full sm:w-[85%] p-[10px] h-[60px] rounded-[10px] shadow shadow-gray-300 border-grey-100 border-t flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <img className="h-7" src={dp} />

                <div className="flex flex-col">
                  <p className="font-semibold text-[#00000080] text-[14px] lg:text-[16px]">
                    {name}
                  </p>
                  <p className="Lato-Light text-[10px] text-[#00000060]">
                    {details}
                  </p>
                </div>
              </div>

              <div>
                <button className="shadow shadow-[#F1730080] text-[10px] lg:text-xs rounded-[6px] px-2 p-1">
                  Follow
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SuggestedFollow;
