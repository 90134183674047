import React, { useState } from "react";
import SecuritySettings from "./securitySettings";
import AccountSettings from "./accountSettings";
import { settingsSideNav } from "../../utils/data";

const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState(settingsSideNav[0].name);

  return (
    <div className="mb-10">
      <div className="px-2 xs:px-5 mt-10">
        <div className="flex bg-white font-semibold p-[2px] text-[14px] rounded-[20px] h-[40px] w-[200px] shadow border-t">
          {settingsSideNav.map((items) => (
            <div onClick={() => setActiveTab(items.name)} className={`${activeTab === items.name ? 'bg-orange-200 text-white' : 'bg-white'} flex justify-center items-center w-full cursor-pointer rounded-[20px]`}>
              {items.name}
            </div>
          ))}
        </div>
      </div>

      <>
        {activeTab === settingsSideNav[0].name && <AccountSettings />}
        {activeTab === settingsSideNav[1].name && <SecuritySettings />} 
        {/* <SecuritySettings /> */}
      </>
    </div>
  );
};

export default SettingsPage;
