import React from 'react'
import LoggedIn_Layout from '../../comonent/loggedIn_Layout'
import EventPage from './eventPage'

const Event = () => {
  return (
  <LoggedIn_Layout>
      <EventPage/>
  </LoggedIn_Layout>
  )
}

export default Event