import React from "react";
import pic1 from "../../assets/img/Frame 356 (1).png";

const SectionThree = () => {
  return (
    <div className="Axiforma pt-5 xs:pt-14 px-[15px] xs:px-[40px] pb-5">
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-[45px] md:gap-[20px] lg:gap-[10px]">
        <div className="h-[310px] text-center flex flex-col items-center justify-center w-full gap-[30px]">
          <p className="text-[20px] text-center lg:text-[28px] font-semibold">
            introducing post city channel
          </p>
          <p className="text-[14px]">
            stay up to date with the latest in the post -production world.
          </p>
          <p className="text-[14px]">
            movie reviews, interviews, podcast and many more.
          </p>
          <p className="text-[14px]">
            dive into the elements of post productions
          </p>
        </div>

        {/* <div className="h-[310px] bg-[#ffd0a4b1]"></div> */}
        <div className="flex justify-center items-center">
          <img src={pic1} alt="" />
        </div>
        <div className="flex justify-center items-center">
          <img src={pic1} alt="" />
        </div>
        {/* <div className=" h-[310px] bg-[#ffd0a4b1]"></div> */}
      </div>
    </div>
  );
};

export default SectionThree;
