import React, { useEffect, useRef } from "react";
import Layout from "../../comonent/Layout";
import BannerPage from "./Banner";
import SectionOne from "./sectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./sectionThree";
import Advertisement from "../advertisment";
import { handleLogout } from "../../api/axios";

const HomePage = () => {
  // const myRef = useRef<HTMLDivElement>(null);

  // const handleService = () => {
  //   if (myRef.current) {
  //     myRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const User_id = localStorage.getItem("ids");
  const services = useRef(null);
  const homes = useRef(null);

  const hanldeBlank = () => {
    // console.log("nothing")
  };

  {
    User_id ? handleLogout() : hanldeBlank();
  }

  // handleLogout()

  const handleService = () => {
    if (services.current) {
      (
        services.current as {
          scrollIntoView: (options: ScrollIntoViewOptions) => void;
        }
      ).scrollIntoView({ behavior: "smooth" });
    }
  };

  // const handleHomes = () => {
  //   if (homes.current) {
  //     (
  //       homes.current as {
  //         scrollIntoView: (options: ScrollIntoViewOptions) => void;
  //       }
  //     ).scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  // useEffect(() => {
  //   handleHomes();
  // }, []);

  return (
    // <div ref={homes}>
      <Layout handleService={handleService}>
        <BannerPage />
        <div ref={services}>
          <SectionOne />
        </div>
        <Advertisement />
        <SectionTwo />
        <SectionThree />
      </Layout>
    // </div>
  );
};

export default HomePage;
