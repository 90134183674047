import React, { useState } from "react";
import rightdir from "../../../assets/icons/right_dir.svg";
import leftdir from "../../../assets/icons/left_dir.svg";
import heart from "../../../assets/icons/mdi_heart.svg";
import eye from "../../../assets/icons/eye.png";
import add from "../../../assets/icons/add_icon.svg";
import { useNavigate } from "react-router-dom";
import EmployeeSlide from "../../../comonent/employeeSlide";
import Empty_State from "../../../comonent/Empty_State";
import LogggedOutState from "../../../comonent/Empty_State/loggedOutState";

const EmployerProfilePage = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [showJobs, setShowJobs] = useState(false);
  const navigate = useNavigate();
  const User_id = localStorage.getItem("ids");

  const handleClick = () => {
    navigate("/WorkSpace");
  };

  return (
    <div className="p-[12px] xs:p-[20px] pb-14 xs:pb-5">
      {User_id ? (
        <div className="flex flex-col gap-5">
          <div className="font-semibold text-[20px]">
            <p>Projects Done</p>
          </div>

          <div className="flex flex-col relative gap-[14px] border-b border-[#1d1c1c29]">
            <div className="flex flex-col gap-[14px]">
              <div className="grid grid-cols-3 gap-2 justify-items-center">
                {showJobs ? (
                  <>
                    {" "}
                    <div className="shadow shadow-[#f174005f] w-full rounded-[20px] h-[195px] flex justify-center items-center">
                      <p className="flex justify-center items-center text-[38px] cursor-pointer font-black bg-[#f174005f] text-white w-[60px] rounded-[40px]">
                        +
                      </p>
                    </div>
                    <div className="shadow shadow-[#f174005f] w-full rounded-[20px] h-[195px] flex justify-center items-center">
                      <p className="flex justify-center items-center text-[38px] cursor-pointer font-black bg-[#f174005f] text-white w-[60px] rounded-[40px]">
                        +
                      </p>
                    </div>
                    <div className="shadow shadow-[#f174005f] w-full rounded-[20px] h-[195px] flex justify-center items-center">
                      <p className="flex justify-center items-center text-[38px] cursor-pointer font-black bg-[#f174005f] text-white w-[60px] rounded-[40px]">
                        +
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div
                      onClick={handleClick}
                      className="w-full cursor-pointer h-[170px] relative shadow Frame_one"
                    >
                      <div className="flex gap-2 text-[12px] absolute bottom-0 left-0 bg-white pr-1">
                        <div className="flex items-center">
                          <img className="h-3" src={eye} />
                          <span>100</span>
                        </div>

                        <div className="flex items-center">
                          <img className="h-3" src={heart} />
                          <span>200</span>
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={handleClick}
                      className="w-full cursor-pointer h-[170px] relative shadow Frame_two"
                    >
                      <div className="flex gap-2 text-[12px] absolute bottom-0 left-0 bg-white pr-1">
                        <div className="flex items-center">
                          <img className="h-3" src={eye} />
                          <span>100</span>
                        </div>

                        <div className="flex items-center">
                          <img className="h-3" src={heart} />
                          <span>200</span>
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={handleClick}
                      className="w-full cursor-pointer h-[170px] relative shadow Frame_three"
                    >
                      <div className="flex gap-2 text-[12px] absolute bottom-0 left-0 bg-white pr-1">
                        <div className="flex items-center">
                          <img className="h-3" src={eye} />
                          <span>100</span>
                        </div>

                        <div className="flex items-center">
                          <img className="h-3" src={heart} />
                          <span>200</span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className=" flex gap-7 text-[12px] items-center mb-3">
                <div className="flex gap-2 items-center cursor-pointer">
                  <img src={rightdir} />
                  <p>Previous</p>
                </div>

                <div className="flex gap-2 items-center cursor-pointer">
                  <p>Next</p>
                  <img src={leftdir} />
                </div>
              </div>
            </div>

            <div className="absolute right-0 bottom-5">
              <img className="h-14 cursor-pointer" src={add} />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-2 md:grid-cols-3 gap-20 sm:gap-10 md:gap-5 flex flex-col-reverse">
            {loggedIn ? (
              <div className="flex flex-col gap-[20px]">
                <div className="text-[20px] font-semibold">
                  <p>Employer's Details</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-[14px]">Employer's Full Name</p>
                  <input className="p-[2px] border-b border-black w-full h-[32px]" />
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-[14px]">employer area of specialization</p>
                  <input className="p-[2px] border-b border-black w-full h-[32px]" />
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-[14px]">Employer’s Location</p>
                  <input className="p-[2px] border-b border-black w-full h-[32px]" />
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-[14px]">Likely Benefits for Employees</p>
                  <input className="p-[2px] border-b border-black w-full h-[32px]" />
                </div>

                <div className="flex w-full justify-end items-end mt-8">
                  <button className="border border-black rounded-[8px] w-[20%] h-[29px] bg-[#D9D9D9] font-semibold">
                    Post
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-[32px] text-[14px] ">
                <div className="text-[20px] font-semibold">
                  <p>Employer's Details</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-[14px]">Employer's Full Name</p>
                  <p>Oracle Media Company</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-[14px]">employer area of specialization</p>
                  <p>Post Production</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-[14px]">Employer’s Location</p>
                  <p>Lagos, Nigeria</p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-[14px]">Likely Benefits for Employees</p>
                  <p>
                    flexible schedule, discount or free food, tuition assistance{" "}
                  </p>
                </div>
              </div>
            )}

            <div className="flex flex-col gap-[20px]">
              <div className="text-[20px] font-semibold">
                <p>Rendered Service</p>
              </div>

              <div className="flex flex-col gap-2">
                <p className="text-[14px]">name of company</p>
                <input className="p-[2px] border-b border-black w-full h-[32px]" />
              </div>

              <div className="flex flex-col gap-2">
                <p className="text-[14px]">about company</p>
                <input className="p-[2px] border-b border-black w-full h-[32px]" />
              </div>

              <div className="flex flex-col gap-2">
                <p className="text-[14px]">Job description</p>
                <input className="p-[2px] border-b border-black w-full h-[32px]" />
              </div>

              <div className="flex flex-col gap-2">
                <p className="text-[14px]">Job Requirements</p>
                <input className="p-[2px] border-b border-black w-full h-[32px]" />
              </div>

              <div className="flex w-full justify-between items-end">
                <div className="flex flex-col gap-2 w-[60%]">
                  <p className="text-[14px]">Job Requirements</p>
                  <input className="p-[2px] border-b border-black w-full h-[32px]" />
                </div>

                <button className="border border-black rounded-[8px] w-[20%] h-[29px] bg-[#D9D9D9] font-semibold">
                  Post
                </button>
              </div>
            </div>

            <EmployeeSlide />
          </div>
        </div>
      ) : (
        <LogggedOutState />
      )}
    </div>
  );
};

export default EmployerProfilePage;
