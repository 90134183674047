import React, { useEffect, useState } from "react";
import dp from "../../assets/icons/profile_dp.svg";
import imgg from "../../assets/img/Frame 483 (3).png";
import lazyLoading from "../../assets/icons/loading-1.gif";
import {
  TimeeLineProps,
  Toptrends,
  TrendList,
  communityData,
} from "../../utils/data";
import ComposeForm from "./composeForm";
import TimeLine from "./timeLine";
import { nanoid } from "nanoid";
import AxiosInstance from "../../api/axios";
import IndividualMovie from "./individualMovies";

type TimeLineProps = {
  viewCommunity?: () => void;
  handleCommunityForm?: () => void;
  handleSeeMore?: () => void;
};


const CommunityPage: React.FC<TimeLineProps> = ({
  handleCommunityForm,
  handleSeeMore,
}) => {
  const [loading, setLoading] = useState(false);
  const User_id = localStorage.getItem("ids");

  // console.log(communityData);

  const [parentFavourite, setParentFavourite] = useState([] as any);

  const getFavourite = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get(`/community/favourite/${User_id}`);
      if (Array.isArray(res.data)) {
        const parentFavourite = res.data.map((item) => ({
          displayImage: item.displayImage,
          iconImage: item.iconImage,
          name: item.name,
          crew: item.crew,
          soundtrack: item.soundtrack,
          id: item._id,
          user: item.user,
          members: item.members,
        }));
        setParentFavourite(parentFavourite);
      } else {
        console.error("Unexpected response format:", res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFavourite();
  }, []);

  const [selectedCommunityItem, setSelectedCommunityItem] = useState<
    any | null
  >(null);
  const [showCommunity, setShowCommunity] = useState(true);
  const [showIndividualCommuity, setShowIndividualCommuity] = useState(false);

  const handleViewCommunity = (item: any) => {
    setSelectedCommunityItem(item);
    setShowCommunity(false);
    setShowIndividualCommuity(true);
  };

  const handleStateBack = () => {
    setShowIndividualCommuity(false);
    setShowCommunity(true);
  };

  return (
    <div>
      {showCommunity && (
        <>
          {loading ? (
            <div className="flex justify-center items-center h-[80vh] w-full">
              <img src={lazyLoading} alt="loading" />
            </div>
          ) : (
            <div
              id="scroll-image"
              className="flex flex-col gap-[15px] sm:gap-[30px] min-h-[600px] max-h-[820px] overflow-y-scroll"
            >
              {User_id && (
                <div className="flex flex-col gap-1 w-full">
                  <div className="flex w-full">
                    <div className="flex flex-col gap-3 w-full">
                      <div className="border-b">
                        <p className="border-b border-b-orange-300 w-fit pb-[5px] font-semibold">
                          Favourite
                        </p>
                      </div>
                      <div className="grid grid-cols-3 gap-2">
                        {parentFavourite?.map((item: any, i: number) => (
                          <div
                            onClick={() => {
                              handleViewCommunity(item);
                            }}
                            className="relative cursor-pointer hover:opacity-70 w-full"
                          >
                            <div className="rounded-[5px] w-full h-[120px] bg-orange-200">
                              <img
                                src={item.iconImage}
                                className="w-full h-[120px]"
                                alt=""
                              />
                            </div>
                            <div className="bg-[#2524243d] text-white text-[14px] flex justify-center items-center font-semibold h-[30px] w-full absolute bottom-0 rounded-b-[5px] z-[99]">
                              <p>{item.name}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between border-t mt-2">
                    <p
                      onClick={handleCommunityForm}
                      className="text-[12px] cursor-pointer"
                    >
                      Create Commuity
                    </p>
                    <p
                      onClick={handleSeeMore}
                      className="text-[12px] cursor-pointer"
                    >
                      See more
                    </p>
                  </div>
                </div>
              )}

              <TimeLine />
            </div>
          )}
        </>
      )}

      {showIndividualCommuity && (
        <IndividualMovie
          parentCommuity={selectedCommunityItem}
          handleBack={handleStateBack}
        />
      )}
    </div>
  );
};

export default CommunityPage;
