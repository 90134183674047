import React, { ReactNode, useState } from "react";
// import Sidebar from "./sidebar";
import arrow from "../../assets/icons/auth_arrow.svg";
import Community_xs from "../Community_xs";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../logoutModal/logoutModal";
import SIdeModal from "../sideModal";
import Sidebar from "./Sidebar";

interface SideLayoutProps {
  children: ReactNode;
}

const SideLayout: React.FC<SideLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSideModal, setShowSideModal] = useState(false);
  const User_id = localStorage.getItem("ids");

  const handleClick = () => {
    setShowLoginModal(!showLoginModal);
  };

  const handleLogout = () => {
    setShowSideModal(false);
    setShowLoginModal(true);
  };

  return (
    <>
      <div className="flex gap-[16px] px-[10px] xs:px-[30px] mb-14 mt-10">
        <div className="w-[25%] hidden lg:flex shadow shadow-[#F17300] rounded-[10px]">
          <Sidebar onLogoutClick={handleClick} />
        </div>
        <main className="w-full lg:w-[70%] xl:w-[75%] shadow shadow-[#F17300] rounded-[10px] ">
          {children}
        </main>
        {/* <div className="w-[45%] lg:w-[45%] xl:w-[35%] hidden md:flex shadow shadow-[#F17300] rounded-[5px]">
          <Community_xs />
        </div> */}
      </div>

      {showLoginModal && (
        <>
          <div className=" fixed top-0 left-0 w-full h-full z-[999] flex items-center justify-center">
            <LogoutModal onClose={() => setShowLoginModal(false)} />
          </div>
          <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
        </>
      )}

      {showSideModal && (
        <>
          <div className="fixed top-0 right-0 z-[999] flex lg:hidden">
            <SIdeModal
              onLogoutClick={handleLogout}
              onClose={() => setShowSideModal(false)}
            />
          </div>
          <div className="fixed w-screen h-screen bg-[#00000097] top-0 left-0" />
        </>
      )}
    </>
  );
};

export default SideLayout;
