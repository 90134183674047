import React, { useEffect, useState } from "react";
import edit from "../../assets/icons/edit.svg";
import check from "../../assets/icons/feCheck1.svg";
import lazy_loading from "../../assets/icons/loading-icon.gif";
import Select from "react-select";
import AxiosInstance from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Option {
  value: string;
  label: string;
}

const ClosedJobs = () => {
  const [parentList, setParentList] = useState([] as any);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([
    parentList.status,
  ]);
  const [editItemId, setEditItemId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const User_id = localStorage.getItem("ids");

  const options = [
    { value: "In Progress", label: "In Progress" },
    { value: "Pause", label: "Pause" },
    { value: "Done", label: "Done" },
  ];

  const handleSelectChange = (selected: any) => {
    setSelectedOptions(selected);
  };

  const handleSubmit = async (selectedOptions: any, item: any) => {
    setLoading(true);
    try {
      const theStatus = selectedOptions.value;
      const Job_id = item.id;
      const res = await AxiosInstance.patch(`/jobs/${Job_id}`, {
        status: theStatus,
      });
      toast.success("job status updated", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEditItemId(null);
      fetchJob();
    } catch (error) {
      console.error(error);
      toast.error("error changing status", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchJob = async () => {
    try {
      setLoading(true);
      const res = await AxiosInstance.get(`/jobs/userStatus/${User_id}/Closed`);
      if (Array.isArray(res.data)) {
        const parentList = res.data.map((item) => ({
          categories: item.categories,
          city: item.city,
          country: item.country,
          status: item.status,
          id: item._id,
        }));
        setParentList(parentList);
      } else {
        console.error("Unexpected response format:", res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJob();
  }, []);

  return (
    <>
      {loading ? (
        <div className="h-[50vh] w-full flex justify-center items-center">
          <img src={lazy_loading} />
        </div>
      ) : parentList.length > 0 ? (
        <div className="flex flex-col gap-10">
          {parentList.map((item: any, i: number) => (
            <div className="border-t border-gray-100 shadow-lg rounded-[8px] flex flex-col gap-3 w-full p-5">
              <div className="flex justify-between">
                <p className="font-semibold text-[24px]">{item.categories}</p>

                {/* {editItemId === item.id ? (
                  <button
                    disabled={selectedOptions === undefined}
                    onClick={() => handleSubmit(selectedOptions, item)}
                    className="h-[25px] w-[25px] border border-[#f17400b3] flex justify-center items-center rounded-[25px] bg-gray-100"
                  >
                    <img src={check} className="cursor-pointer" alt="/" />
                  </button>
                ) : (
                  <img
                    key={item.id}
                    onClick={() => setEditItemId(item.id)}
                    src={edit}
                    className="cursor-pointer"
                    alt=""
                  />
                )} */}
              </div>

              <div className="flex text-[18px]">
                <p>{item.city}</p>
                <div className="flex gap-1">
                  , <p>{item.country}</p>
                </div>
              </div>

              {editItemId === item.id ? (
                <Select
                  options={options}
                  value={selectedOptions[i]}
                  onChange={handleSelectChange}
                  className="shadow text-[14px] Axiforma-light rounded-b-[8px] border-b-gray-200 border-b-2"
                />
              ) : (
                <div className="shadow border border-gray-300 text-[14px] h-[38px] flex items-center px-2 Axiforma-light rounded-[8px] border-b-gray-200 border-b-2">
                  {item.status}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="h-[60vh] Axiforma w-full flex justify-center items-center">
          No jobs done...
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default ClosedJobs;
