import React, { useEffect, useState } from "react";
import arrow from "../../../assets/icons/right_arrow.svg";
import nav_arrow from "../../../assets/icons/auth_arrow.svg";
import AxiosInstance from "../../../api/axios";
import lazy_loading from "../../../assets/icons/loading-icon.gif";
import { toast } from "react-toastify";

type SearchChatProps = {
  parentChat?: any;
  handleBack?: () => void;
};

const SearchChatPage: React.FC<SearchChatProps> = ({
  parentChat,
  handleBack,
}) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [parentItem, setParentItem] = useState([] as any);

  const User_id = localStorage.getItem("ids");

  const senderIds = User_id;
  const recieverIds = parentChat.id;
  const name = parentChat.name;

  const fetchChatData = async () => {
    // setLoading(true);
    try {
      const res = await AxiosInstance.get(
        `/chat/messages/${User_id}/${recieverIds}`
      );
      if (Array.isArray(res.data)) {
        const parentItem = res.data.map((item) => ({
          message: item.message,
          timestamp: item.timestamp,
          receiverId: item.receiverId,
          senderId: item.senderId,
        }));
        setParentItem(parentItem);
      }
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchChatData();
  }, []);

  const handleSendMessage = async () => {
    setLoading(true);
    const newMessage = {
      message: message,
      receiverId: recieverIds,
      senderId: User_id,
    };

    try {
      AxiosInstance.post(`/chat/createChat`, newMessage)
        .then((response) => {
          // console.log(response)
          if (response.status === 200) {
            toast.success(`new message created`);
            fetchChatData();
            setMessage("");
          }
        })
        .catch((error) => {
          toast.error(error.response?.data?.statusMessage);
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      toast.error(`An error occured`);
    } finally {
      setLoading(false)
    }
  };

  return (
    <>
      {loading ? (
        <div className="h-[50vh] w-full flex justify-center items-center">
          <img src={lazy_loading} />
        </div>
      ) : (
        <div className="flex flex-col gap-3 h-[70vh] relative">
        <div className="h-[69vh] overflow-y-scroll flex flex-col gap-2">
          <div className="flex gap-1 items-center pb-2">
            <img
              onClick={handleBack}
              className="h-7 cursor-pointer"
              src={nav_arrow}
              alt="/"
            />
            <p className="font-semibold">{name}</p>
          </div>

          <>
            {parentItem.map((items: any, i: number) => (
              <div
                key={i}
                className="flex flex-col gap-2 text-xs Axiforma px-[6px]"
              >
                <div
                  className={`flex w-full ${
                    items.senderId !== senderIds
                      ? "justify-start"
                      : "justify-end"
                  }`}
                >
                  <div
                    className={`shadow p-2 ${
                      items.senderId === senderIds
                        ? "bg-gray-100"
                        : "bg-orange-100"
                    } w-[70%] rounded-md`}
                  >
                    {items.message}
                  </div>
                </div>
              </div>
            ))}
          </>
        </div>

        <div className="absolute bottom-1 w-full flex gap-1">
          <input
            className="border bg-[#f4f5f5] h-[34px] rounded-xl w-full p-[10px]"
            type="text"
            name="chat"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div>
            <div className="h-[34px] w-[34px] cursor-pointer rounded-[15px] bg-orange-100 flex justify-center items-center">
              {loading ? "..." : <img
                onClick={handleSendMessage}
                className="h-4"
                src={arrow}
                alt="/"
              />}
           
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default SearchChatPage;
