import React from "react";
import LoggedIn_Layout from "../../../comonent/loggedIn_Layout";
import SideLayout from "../../../comonent/SideLayout";
import AwardPage from "./awardPage";

const Award = () => {
  return (
    <LoggedIn_Layout>
      <SideLayout>
        <AwardPage />
      </SideLayout>
    </LoggedIn_Layout>
  );
};

export default Award;
