import React, { FC, useState } from "react";
import illustration from "../../assets/img/Illustration.png";
import arrow from "../../assets/icons/auth_arrow.svg";
import { useNavigate } from "react-router-dom";
import google from "../../assets/icons/Google svg.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setAccessToken } from "../../api/axios";
import eye from "../../assets/icons/eye.png";
import GoogleLogin from "react-google-login";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validate = !email || !password;

  const handleLoginn = () => {
    setIsLoading(true);
    const existingUserData = {
      email: email,
      password: password,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/login`, existingUserData)
      .then((response) => {
        if (response.status === 200) {
          setAccessToken(response.data.token);
          localStorage.setItem("firstName", response.data.data[0].firstName);
          localStorage.setItem("lastName", response.data.data[0].lastName);
          localStorage.setItem("email", response.data.data[0].email);
          localStorage.setItem("ids", response.data.data[0]._id);
          localStorage.setItem("sessionId", response.data.token);
          localStorage.setItem("userType", response.data.data[0].userType);
          navigate("/Dashboard");
        }
      })
      .catch(({ error, response }) => {
        // console.error(response);
        if (!response) {
          toast("Please check internet connection", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleLogin = async (googleData: any) => {
    const res = await fetch("/api/v1/auth/google", {
        method: "POST",
        body: JSON.stringify({
        token: googleData.tokenId
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })
    const data = await res.json()
    // store returned user somehow
  }

  const clientId = '517444452032-8m1sst0s9ipbqeuvhdfrj932r3atprq8.apps.googleusercontent.com'

  return (
    <div className="flex h-screen">
      <div className="bg-[#f1730066] w-[500px] px-7 lg:px-0 md:w-[700px] lg:w-[900px] hidden md:flex justify-center items-center">
        <div className="flex flex-col items-center justify-center gap-[50px]">
          <img src={illustration} />
          <p className="text-[26px] text-white lg:text-[31px] font-semibold lg:w-[95%] text-center">
            Online Community For Post-production Professionals
          </p>
        </div>
      </div>
      <div className="bg-white w-full">
        <div className="pt-[30px] px-[15px] xs:px-[20px] sm:px-[40px]">
          <img
            onClick={() => navigate("/")}
            className="cursor-pointer"
            src={arrow}
          />
        </div>

        <div className="w-full flex justify-center mt-10">
          <div className="w-[80%] sm:w-[65%] lg:w-[60%] pb-10 xl:w-[50%] flex flex-col gap-[32px]">
            <div className="flex flex-col gap-[48px]">
              <div className="flex flex-col gap-[15px] sm:gap-[30px] font-semibold">
                <div className="flex flex-col gap-[8px]">
                  <p>Email</p>
                  <input
                    className="w-full h-[51px] p-[16px] font-normal bg-[#F6F6F6] text-[#ABABAB] rounded-[5px]"
                    placeholder="Enter your email address"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
 
                <div className="flex flex-col gap-[8px]">
                  <p>Password</p>
                  <div className="flex items-center pr-1 bg-[#F6F6F6]">
                    <input
                      className="w-full h-[51px] p-[16px] font-normal bg-[#F6F6F6] text-[#ABABAB] rounded-[5px]"
                      placeholder="Enter your Password"
                      required
                      value={password}
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                      onClick={togglePasswordVisibility}
                      className="pl-1 bg-[#f6f6f6]"
                    >
                      <img src={eye} className="w-4 cursor-pointer" alt="/" />
                    </div>
                  </div>
                </div>

                <div className="text-[12px] flex">
                  <p
                    onClick={() => navigate("/Forgot-Password")}
                    className="cursor-pointer"
                  >
                    FORGET PASSWORD?
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-[24px] font-semibold">
                <button
                  onClick={handleLoginn}
                  disabled={validate}
                  className={`flex shadow shadow-[#f17400b4] h-[48px] w-full ${
                    !validate ? "bg-[#F17300] text-white" : ""
                  } justify-center items-center rounded-[5px]`}
                >
                  {isLoading ? "Loading..." : "Log in"}
                </button>

                <div className="flex items-center justify-center text-[#ABABAB] gap-[12px] px-4">
                  <hr className="w-[70px] shadow" />
                  <p>or</p>
                  <hr className="w-[70px] shadow" />
                </div>

                <button className="flex gap-2 shadow shadow-[#f17400b4] justify-center items-center h-[48px] w-full rounded-[5px]">
                  <img src={google} />
                  Continue with Google
                </button>

{/* <GoogleLogin
    clientId={clientId}
    buttonText="Log in with Google"
    onSuccess={handleLogin}
    onFailure={handleLogin}
    cookiePolicy={'single_host_origin'}
/> */}

                <div className="flex justify-center w-full text-[12px]">
                  <p>
                    Don't have an account?{" "}
                    <span
                      onClick={() => navigate("/Signup")}
                      className="underline cursor-pointer"
                    >
                      Signup
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LoginPage;
