import React, { ReactNode, useEffect, useState } from "react";
import dp from "../../../assets/icons/profile_dp.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { userSideNav } from "../../../utils/data";
import AxiosInstance from "../../../api/axios";

interface DashboardLayoutProps {
  onLogoutClick: () => void;
}

const Sidebar: React.FC<DashboardLayoutProps> = ({ onLogoutClick }) => {
  const navigate = useNavigate();
  const User_id = localStorage.getItem("ids");
  const firstName = localStorage.getItem("firstName");
  const [parentDp, setParentDp] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const fetchDp = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get(`/user/dp/${User_id}`);
      const imageURL = res.data;
      setParentDp(imageURL);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDp();
  }, []);

  return (
    <div className="w-full Axiforma">
      <div className="flex flex-col gap-[44px] items-center pb-[70px]">
        <div className="flex flex-col gap-[15px] w-full justify-center">
          <div className=" w-full flex justify-center bg-[#f174005f] py-[20px] items-center">
            {User_id ? (
              <>
                  {parentDp ? (
                  <img className="h-36 lg:h-36 xl:h-40 w-36 xl:w-40 rounded-[80px]" src={parentDp} />
                ) : (
                  <div className="h-36 lg:h-48 w-32 xl:w-40 rounded-[70px]" />
                )}
              </>
              // <img className="h-36 lg:h-48 w-32 xl:w-40" src={dp} />
            ) : (
              <div className="h-40 lg:h-40 w-44 xl:w-40 bg-white rounded-[120px]"></div>
            )}
          </div>

          {User_id && (
            <p className="font-bold text-[#905a27] text-[14px] xl:text-[16px] text-center flex justify-center">
              {firstName} is an accomplished editor
            </p>
          )}
        </div>

        {User_id && (
          <>
            {" "}
            {/* <div className="flex justify-around w-full">
              <div
                onClick={() => navigate("/Follow")}
                className="cursor-pointer shadow shadow-[#F17300] flex flex-col items-center justify-center p-1 rounded-[3px]"
              >
                <p className="text-[14px]">10</p>
                <p className="text-[12px]">followers</p>
              </div>

              <div
                onClick={() => navigate("/Follow")}
                className="cursor-pointer shadow shadow-[#F17300] flex flex-col items-center justify-center p-1 rounded-[3px]"
              >
                <p className="text-[14px]">10</p>
                <p className="text-[12px]">following</p>
              </div>
            </div> */}
            <div className="flex flex-col text-[13px] w-full">
              {userSideNav.map(({ name, linkk }) => (
                <NavLink
                  key={name}
                  to={linkk}
                  className={({ isActive }) =>
                    isActive
                      ? "hover:bg-[#f174005f] text-white w-full bg-[#f174005f] flex items-center justify-start px-4 h-[51px]"
                      : "hover:bg-[#f174005f] w-full hover:font-bold hover:text-white flex items-center justify-start px-4 h-[51px]"
                  }
                >
                  <div>{name}</div>
                </NavLink>
              ))}

              <div
                onClick={onLogoutClick}
                className="hover:bg-[#f174005f] w-full hover:font-bold hover:text-white flex items-center justify-start px-4 h-[51px]"
              >
                Logout
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
