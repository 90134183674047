import React, { useEffect, useState } from "react";
import edit from "../../../../assets/icons/edit.svg";
import check from "../../../../assets/icons/feCheck1.svg";
import ExperiencePopup from "./experiencePopup";
import AxiosInstance from "../../../../api/axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type ExperienceProps = {
  parentList?: any;
};

const Experience: React.FC<ExperienceProps> = ({ parentList }) => {
  const [showPopup, setShowPopup] = useState(false);
  const User_id = localStorage.getItem("ids");
  const [parentExperience, setParentExperience] = useState([] as any);

  const fetchData = async () => {
    try {
      const res = await AxiosInstance.get(`/user/experiences/${User_id}`);
      if (Array.isArray(res.data)) {
        setParentExperience(res.data);
      } else {
        console.error("Unexpected response format:", res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClose = () => {
    setShowPopup(false);
    fetchData();
  };

  return (
    <>
      <div className="flex flex-col gap-[25px] py-7 shadow shadow-[#f174005f] p-3 text-[13px] md:text-[14px] rounded-[8px]">
        <div className="flex justify-between items-center">
          <p className="font-semibold text-[16px]">Experience</p>

          <img
            onClick={() => setShowPopup(true)}
            src={edit}
            className="cursor-pointer"
            alt="/"
          />
        </div>

        <div>
          <>
            {parentExperience.map((item: any) => (
              <div>
                <div className="flex justify-between">
                  <div className="flex items-center gap-1">
                    -<p className="text-[15px]">{item.company}</p>{" "}
                    <p className="Lato-Light text-[9px]">{item.role}</p>
                  </div>
                  <div className="flex items-center text-[10px] gap-[4px] xl:gap-[6px]">
                    {/* <p>{item.start_date}</p>-<p>{item.end_date}</p> */}
                    <p>[{item.pastEmployment}]</p>
                  </div>
                </div>
              </div>
            ))}
          </>
        </div>
        {showPopup && (
          <>
            <div className="drop-shadow-lg w-full xs:w-[95%] sm:w-[85%] md:w-[65%] lg:w-[50%] fixed m-auto top-0 xs:top-28 bottom-0 right-0 left-0 z-[999] ">
              <ExperiencePopup onClose={handleClose} parentList={parentList} />
            </div>
            <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
          </>
        )}

        <ToastContainer />
      </div>
    </>
  );
};

export default Experience;
