import React, { ReactNode, useState, useRef } from "react";
import Header from "./Header";
import Footer from "../Layout/Footer";
import ProfileDropdowm from "../Profile_Dropdown";
import LogoutModal from "../logoutModal/logoutModal";
import CloseBtn from "../../comonent/CloseButton";
import Notif_Popup from "../../pages/loggedIn-Homepage/notifiication-popup";
// import Footer from "./Footer";

interface LoggedInLayoutProps {
  children: ReactNode;
}

const LoggedIn_Layout: React.FC<LoggedInLayoutProps> = ({ children }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showSideNotifs, setShowSideNotifs] = useState(false);

  const handlePricing = () => {
    const pricingElement = document.getElementById("pricing-section");
    if (pricingElement) {
      pricingElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLockout = () => {
    setShowDropdown(false);
    setShowLogoutModal(!showLogoutModal);
  };
  const dropClick = () => {
    handlePricing();
    setShowDropdown(!showDropdown);
  };

  const NotifClick = () => {
    setShowDropdown(false);
    setShowSideNotifs(true);
  };
  return (
    <div id="pricing-section">
      <Header DropdownClick={dropClick} NotifClick={NotifClick} />
      <main>{children}</main>
      <Footer />

      {showDropdown && <ProfileDropdowm onDropdownClick={handleLockout} />}

      {showLogoutModal && (
        <>
          <div className=" fixed top-0 left-0 w-full h-full z-[999] flex items-center justify-center">
            <LogoutModal onClose={() => setShowLogoutModal(false)} />
          </div>
          <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
        </>
      )}

      {showSideNotifs && (
        <>
          <div className="fixed h-screen top-0 right-0 z-[999] w-[75%] xs:w-[50%] flex flex-col md:hidden bg-white shadow">
            <CloseBtn onClose={() => setShowSideNotifs(false)} />
            <Notif_Popup />
          </div>
          <div className="fixed w-screen h-screen bg-[#00000097] flex md:hidden top-0 left-0" />
        </>
      )}
    </div>
  );
};

export default LoggedIn_Layout;
