import React from "react";
import illustration from "../../assets/img/Illustration.png";
import arrow from "../../assets/icons/auth_arrow.svg";
import { useNavigate } from "react-router-dom";
import google from "../../assets/icons/Google svg.svg";

const ForgetPassword = () => {
  const navigate = useNavigate();

  return (
    <div className="flex">
      <div className="bg-[#f1730066] px-7 lg:px-0 w-[500px] md:w-[700px] lg:w-[900px] hidden md:flex justify-center items-center h-[100vh]">
        <div className="flex flex-col items-center justify-center gap-[50px]">
          <img src={illustration} />
          <p className="text-[26px] text-white lg:text-[31px] font-semibold lg:w-[95%] text-center">
            Online Community For Post-production Professionals
          </p>
        </div>
      </div>
      <div className="bg-white w-full">
        <div className="pt-[30px] px-[15px] xs:px-4 sm:px-[40px]">
          <img
            onClick={() => navigate("/Login")}
            className="cursor-pointer"
            src={arrow}
          />
        </div>

        <div className="w-full flex justify-center mt-24">
          <div className="w-[80%] sm:w-[65%] lg:w-[60%] pb-10 xl:w-[50%] flex flex-col gap-[32px]">
            <div className="flex flex-col gap-[20px] xs:gap-[30px]">
              <div className="flex justify-center text-[20px] text-center">
                <p className="w-[55%]">It’s okay! Reset Your Password Now</p>
              </div>

              <div className="flex flex-col gap-[8px]">
                <p>Email</p>
                <input
                  type="email"
                  className="w-full h-[51px] p-[16px] font-normal bg-[#F6F6F6] text-[#ABABAB] rounded-[5px]"
                  placeholder="Enter your email address"
                />
              </div>
            </div>

            <div onClick={() => navigate('/Reset-Password')} className="flex justify-center gap-[24px] font-semibold">
              <button className="flex shadow shadow-[#f17400b4] hover:bg-[#F8CDBA] hover:text-white w-[140px] h-[48px] justify-center items-center rounded-[5px]">
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
