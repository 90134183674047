import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

type createOptionProps = {
  onclose?: () => void;
};

const CreateOptionsPopup: React.FC<createOptionProps> = ({ onclose }) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleCardClick = (value: string) => {
    setSelectedOption(value);
  };

  const handleSubmit = () => {
    if (selectedOption === "manager") {
      navigate("/Company-job");
    } else {
      navigate("/Individual-job");
    }
  };

  const validate = !selectedOption;

  return (
    <div className="w-[500px] bg-white rounded-[5px] drop-shadow-lg">
      <div className="h-14 border-b flex items-center">
        <p className="font-semibold text-[22px] px-5 w-full">
          How can we help?
        </p>
      </div>
      <div className="p-3">
        <div>
          <p>Pick an option that describes you</p>
        </div>

        <div className="flex justify-center flex-col gap-4 mt-4 bg-gray-50 py-5 px-2 rounded-[15px]">
          <div
            onClick={() => handleCardClick("manager")}
            className={`flex gap-2 shadow-md ${
              selectedOption === "manager"
                ? "border-orange-300 border text-orange-300"
                : ""
            } bg-white rounded-[5px] p-3 cursor-pointer`}
          >
            <div className="w-12 h-12 rounded-[30px] bg-orange-200" />
            <div className="flex items-center font-semibold text-[15px]">
              <p>I am a manager recruiting from a company</p>
            </div>
          </div>

          <div
            onClick={() => handleCardClick("individual")}
            className={`flex gap-2 shadow-md ${
              selectedOption === "individual"
                ? "border-orange-300 border text-orange-300"
                : ""
            } bg-white rounded-[5px] p-3 cursor-pointer`}
          >
            <div className="w-12 h-12 rounded-[30px] bg-orange-200" />
            <div className="flex items-center font-semibold text-[15px]">
              <p>I am an individual recruiting for personal purpose</p>
            </div>
          </div>
        </div>
      </div>

      <div className="h-14 border-t flex items-center">
        <div className="flex justify-end w-full">
          <button onClick={onclose} className="font-semibold">
            Cancel
          </button>
          <button
            className={`flex shadow w-[110px] shadow-[#F17300] ${
              !validate ? "bg-[#F17300] text-white" : ""
            } h-[38px] font-semibold justify-center items-center rounded-[5px]`}
            onClick={handleSubmit}
            disabled={validate}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateOptionsPopup;
