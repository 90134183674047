import React from "react";

const ReferalsPage = () => {
  return (
    <div className="p-4 flex justify-center mt-16 pb-20 h-[70vh]">
      <div className="w-[85%] sm:w-[65%] flex flex-col gap-[60px]">
        <p className="font-semibold text-[24px] text-center">
          Julia takes the credit of referring friend to post city
        </p>

        <div className="flex flex-col gap-10 items-center">
          <div className="w-full flex gap-2 flex-col">
            <p className="text-[16px] Axiforma">Invite friends through email</p>
            <input
              className="border font-semibold text-gray-500 h-[32px] w-full p-[10px] text-[14px] rounded-[5px]"
              placeholder="Enter email"
            />
          </div>

          <div className="font-semibold">or</div>
          <div className="w-full flex gap-2 flex-col">
            <p className="text-[16px] Axiforma">Share a referral link</p>
            <input
              className="border font-semibold text-gray-500 h-[32px] w-full p-[10px] text-[14px] rounded-[5px]"
              placeholder="Enter email"
            />
          </div>
        </div>
      </div>

      {/* <form
        method="POST"
        action="/upload"
        encType="multipart/form-data"
      >
        <div>
          <label>Upload profile picture</label>
          <input type="file" name="profile-file" required />
        </div>
        <div>
          <input type="submit" value="Upload" />
        </div>
      </form> */}
    </div>
  );
};

export default ReferalsPage;
