import React, { useState } from "react";

const BecomeEmployerPage = () => {
  const [selectedOption, setSelectedOption] = useState<string>("");

  interface Option {
    value: string;
    label: string;
  }

  const options: Option[] = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
    // Add more options as needed
  ];

  // Event handler for when an option is selected
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="flex w-full p-3 xs:p-5">
      <div className="w-full flex flex-col gap-8">
        <div className="w-full gap-3 flex">
          <div className="w-[49%] flex flex-col gap-2">
            <p className="text-[14px] font-semibold">
              organisation/company name
            </p>
            <div className="w-full">
              <input
                className="border rounded-[5px] w-full h-[39px] p-[8px]"
                type="text"
              />
            </div>
          </div>
          <div />
        </div>

        <div className="w-full flex gap-3">
          <div className="flex flex-col gap-2 w-[100%]">
            <p className="text-[14px] font-semibold">City</p>
            <select
              value={selectedOption}
              onChange={handleSelectChange}
              className="border h-[39px] rounded-[5px] text-gray-500"
            >
              <option value="" className="bg-red-300">
                Select city...
              </option>

              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col gap-2 w-[100%]">
            <p className="text-[14px] font-semibold">Country</p>
            <select
              value={selectedOption}
              onChange={handleSelectChange}
              className="border h-[39px] rounded-[5px] text-gray-500"
            >
              <option value="" className="bg-red-300">
                Select country...
              </option>

              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>


        <div className="w-full flex gap-3">
          <div className="flex flex-col gap-2 w-[100%]">
            <p className="text-[14px] font-semibold">Job Type</p>
            <select
              value={selectedOption}
              onChange={handleSelectChange}
              className="border h-[39px] rounded-[5px] text-gray-500"
            >
              <option value="" className="bg-red-300">
                Select job type...
              </option>

              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col gap-2 w-[100%]">
            <p className="text-[14px] font-semibold">Category</p>
            <select
              value={selectedOption}
              onChange={handleSelectChange}
              className="border h-[39px] rounded-[5px] text-gray-500"
            >
              <option value="" className="bg-red-300">
                Select category...
              </option>

              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div>
          <p className="text-[14px] font-semibold">Benefits</p>
        </div>

        <div className="w-full flex ">
          <div className="w-full gap-3 flex">
            <div className="w-full flex flex-col gap-2">
              <p className="text-[14px] font-semibold">Qualification</p>
              <div className="w-full">
                <input
                  placeholder="BSc University of Boston"
                  className="border rounded-[5px] w-full h-[39px] p-[8px]"
                  type="text"
                />
              </div>
            </div>
            <div />
          </div>

          <div className="w-full gap-3 flex">
            <div className="w-full flex flex-col gap-2">
              <p className="text-[14px] font-semibold">Joblink</p>
              <div className="w-full">
                <input
                  placeholder="job link..."
                  className="border rounded-[5px] w-full h-[39px] p-[8px]"
                  type="text"
                />
              </div>
            </div>
            <div />
          </div>
        </div>

        <div className="w-full flex gap-3">
          <div className="flex flex-col gap-2 w-[100%]">
            <p className="text-[14px] font-semibold">Payment</p>
            <select
              value={selectedOption}
              onChange={handleSelectChange}
              className="border h-[39px] rounded-[5px] text-gray-500"
            >
              <option value="" className="bg-red-300">
                Select payment...
              </option>

              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col gap-2 w-[100%]">
            <p className="text-[14px] font-semibold">Contract</p>
            <select
              value={selectedOption}
              onChange={handleSelectChange}
              className="border h-[39px] rounded-[5px] text-gray-500"
            >
              <option value="" className="bg-red-300">
                Select contract...
              </option>

              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="w-full gap-3 flex">
          <div className="w-full flex flex-col gap-2">
            <p className="text-[14px] font-semibold">Additonal Information</p>
            {/* <div className="w-full"> */}
              <textarea
                style={{ resize: "none" }}
                className="border rounded-[5px] w-full h-[130px] p-[8px]"
              />
            {/* </div> */}
          </div>
          <div />
        </div>

        <div className="mt-7 flex flex-col items-end w-full gap-3">
            <button
              className={`flex shadow text-gray-500 w-[260px] shadow-[#F17300] h-[38px] font-semibold justify-center items-center rounded-[5px]`}
              // onClick={next}
            >
             Create Job
            </button>
      </div>
      </div>
    </div>
  );
};

export default BecomeEmployerPage;
