import React, { useState, useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';

interface CreatedAtProps {
  days: number;
  months: number;
}

interface TimeCalculatorProps {
  createdAt: any; // Assuming createdAt is a Date object from the backend
}

function TimeCalculator({ createdAt }: TimeCalculatorProps) {
  const [timeDifference, setTimeDifference] = useState<string | null>(null);

  useEffect(() => {
    // Calculate days and months difference
    const createdDate = new Date(createdAt);
    const now = new Date();
    const differenceInTime = now.getTime() - createdDate.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    const months = Math.floor(differenceInDays / 30);
    const days = differenceInDays % 30;

    // Calculate total days
    const totalDays = days + months * 30; // Assuming average month has 30 days
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const timeDiffInMillis = totalDays * millisecondsPerDay;

    // Calculate created time based on calculated total days
    const calculatedCreatedTime = new Date(now.getTime() - timeDiffInMillis);

    // Format the time difference using date-fns
    const formattedTimeDifference = formatDistanceToNow(calculatedCreatedTime, { addSuffix: true });

    setTimeDifference(formattedTimeDifference);
  }, [createdAt]);

  return (
    <div>
      {timeDifference}
    </div>
  );
}

export default TimeCalculator;
