import React, { ReactNode, useState } from "react";
import Sidebar from "./sidebar";
import LogoutModal from "../logoutModal/logoutModal";
import DashboardNotif from "../DashboardNotif";

interface DashboardSideModalProps {
  children: ReactNode;
}

const DashboardSideSideModal: React.FC<DashboardSideModalProps> = ({
  children,
}) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSideModal, setShowSideModal] = useState(false);

  const handleClick = () => {
    setShowLoginModal(!showLoginModal);
  };

  const handleLogout = () => {
    setShowSideModal(false);
    setShowLoginModal(true);
  };

  return (
    <>
      <div className="flex gap-[10px] px-[10px] xs:px-[30px] mb-14 mt-10">
        <div className="w-[28%] hidden lg:flex shadow shadow-[#F17300] rounded-[10px]">
          <Sidebar onLogoutClick={handleClick} />
        </div>
        <main className="w-full lg:w-[70%] xl:w-[75%] shadow shadow-[#F17300] rounded-[10px] ">
          {children}
        </main>
        <div className=" flex-col gap-20 hidden md:flex w-[40%] md:w-[38%] lg:w-[30%]">
          <div className="shadow shadow-[#f1740073] h-[85vh] rounded-[5px] hidden md:flex w-full">
            {/* <Community_xs /> */}
            <DashboardNotif />
          </div>

          {/* <div className="shadow rounded-[5px] h-[450px] font-semibold flex justify-center items-center w-full bg-blue-100">
            Advertisement
          </div> */}
        </div>
      </div>

      {showLoginModal && (
        <>
          <div className=" fixed top-0 left-0 w-full h-full z-[999] flex items-center justify-center">
            <LogoutModal onClose={() => setShowLoginModal(false)} />
          </div>
          <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
        </>
      )}
    </>
  );
};

export default DashboardSideSideModal;
