import React, { useRef, useState } from "react";
import "./ScrollableDivWithNavigation.css";
import rightdir from "../../../assets/icons/left_dir.svg";
import leftdir from "../../../assets/icons/right_dir.svg";
import CloseBtn from "../../../comonent/CloseButton";
import DeleteMedia from "./deleteMedia";

type PhotoNavProps = {
  photoNav?: any;
};

const PhotoNav: React.FC<PhotoNavProps> = ({ photoNav }) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState<any | null>(null);
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const scrollLeft = () => {
    if (scrollableDivRef.current) {
      if (scrollableDivRef.current.scrollLeft > 200) {
        scrollableDivRef.current.scrollLeft -= 200;
      } else {
        scrollableDivRef.current.scrollLeft = 0;
      }
    }
  };

  const scrollRight = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollLeft += 200;
    }
  };

  const handleDeteleIcon = (items: any, i: number) => {
    console.log(items, i);
    // setShowDeleteModal(true);
  };

  const handleToggle = (i: number, item: any) => {
    setSelectedItemIndex(i);
    setShowDeleteModal(!showDeleteModal);
  };

  return (
    <>
      <div className="flex items-center w-full">
        <button onClick={scrollLeft}>
          <img src={leftdir} className="h-5" alt="" />
        </button>
        <div
          id="scroll-image"
          className="scrollable-div flex gap-2 w-full"
          ref={scrollableDivRef}
        >
          {photoNav?.communityImages?.map((items: any, i: number) => (
            <>
              {" "}
              <img
                key={i}
                onClick={() => handleToggle?.(items, i)}
                className="h-[260px] w-[210px] hover:p-1 hover:opacity-50 cursor-pointer"
                src={items}
              />
              {showDeleteModal && (
                <>
                  <div className=" fixed top-0 left-0 w-full h-full z-[999] flex items-center justify-center">
                    <DeleteMedia
                      handleRemove={() => handleDeteleIcon(items, i)}
                      MediaItem={selectedItemIndex}
                      onClose={() => setShowDeleteModal(false)}
                    />
                  </div>

                  <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
                </>
              )}
            </>
          ))}
        </div>
        <button onClick={scrollRight}>
          <img src={rightdir} className="h-5" alt="" />
        </button>
      </div>
    </>
  );
};

export default PhotoNav;
