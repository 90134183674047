import React from "react";
import LoggedIn_Layout from "../../comonent/loggedIn_Layout";
import TalentPoolPage from "./TalentPoolPage";

const TalentPool = () => {
  return (
    <LoggedIn_Layout>
      <TalentPoolPage />
    </LoggedIn_Layout>
  );
};

export default TalentPool;
