import React, { useEffect, useRef, useState } from "react";
import rightdir from "../../assets/icons/right_dir.svg";
import leftdir from "../../assets/icons/left_dir.svg";
import add from "../../assets/icons/add_icon.svg";
import check from "../../assets/icons/feCheck1.svg";
import { useNavigate } from "react-router-dom";
import EmployeeSlide from "../../comonent/employeeSlide";
import Empty_State from "../../comonent/Empty_State";
import LogggedOutState from "../../comonent/Empty_State/loggedOutState";
import { createjobSideNav } from "../../utils/data";
import CreateOptionsPopup from "./createOptionsPopup";
import AxiosInstance from "../../api/axios";
import ClosedJobs from "../settings/closedJobs";
import OpenJobs from "../settings/openJobs";
import UploadJobsPopup from "./uploadJobsPopup";
import lazy_loading from "../../assets/icons/loading-icon.gif";

const CreateJobsPage = () => {
  const [showJobs, setShowJobs] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const User_id = localStorage.getItem("ids");

  const handleClick = () => {
    navigate("/WorkSpace");
  };
  const [activeTab, setActiveTab] = useState(createjobSideNav[0].name);
  const [loading, setLoading] = useState(false);
  const [parentJobs, setParentJobs] = useState<any>([]);
  const [showCreatePopup, setShowCreatePopup] = useState(false);

  useEffect(() => {
    const scrollContainer: HTMLElement | null =
      document.querySelector(".scroll-container");

    function autoScroll(): void {
      if (scrollContainer) {
        scrollContainer.scrollLeft += 70;

        if (
          scrollContainer.scrollLeft + scrollContainer.offsetWidth >=
          scrollContainer.scrollWidth
        ) {
          scrollContainer.scrollLeft = 0;
        }
      }
    }

    setInterval(autoScroll, 1700);
  }, []);

  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollableDivRef.current) {
      if (scrollableDivRef.current.scrollLeft > 200) {
        scrollableDivRef.current.scrollLeft -= 200;
      } else {
        scrollableDivRef.current.scrollLeft = 0;
      }
    }
  };

  const scrollRight = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollLeft += 200;
    }
  };

  const fetchProjects = async () => {
    setLoading(true);

    try {
      const res = await AxiosInstance.get(`/user/uploadedProjects/${User_id}`);
      const projectData = res.data;

      if (!Array.isArray(projectData)) {
        throw new Error(
          "Unexpected data format: Expected an array of project objects"
        );
      }

      const formattedProjects = projectData.map((project) => {
        return {
          url: project,
        };
      });

      setParentJobs(formattedProjects);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <div className="p-[12px] xs:p-[20px] pb-14 xs:pb-5 sm:px-10 xl:px-[40px]">
      {User_id ? (
        <div className="flex flex-col gap-7">
          <div className="font-semibold text-[20px]">
            <p>Projects Done</p>
          </div>

          <div className="flex flex-col relative gap-[14px] border-b border-[#1d1c1c29]">
            <div className="flex flex-col gap-[14px] ">
              {loading ? (
                <div className="h-[20vh] w-full flex justify-center items-center">
                  <img src={lazy_loading} />
                </div>
              ) : parentJobs.length > 0 ? (
                <div
                  id="scroll-image"
                  ref={scrollableDivRef}
                  className="flex gap-1 scroll-containers Axiforma text-[14px]"
                >
                  {parentJobs.map((item: any, i: number) => (
                    <div className="p-1">
                      <div
                        key={i}
                        className="shadow shadow-[#f174005f] w-[300px] rounded-[20px] flex justify-center items-center h-[195px]"
                      >
                        <video
                          src={item.url}
                          controls
                          className="w-full h-full object-cover rounded-md"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="h-[100px] flex justify-center items-center font-semibold Axiforma text-gray-500">
                  No jobs uploaded
                </div>
              )}

              <div className=" flex gap-7 text-[12px] items-center mb-3">
                <div
                  onClick={scrollLeft}
                  className="flex gap-2 items-center cursor-pointer"
                >
                  <img src={rightdir} />
                  <p>Previous</p>
                </div>

                <div
                  onClick={scrollRight}
                  className="flex gap-2 items-center cursor-pointer"
                >
                  <p>Next</p>
                  <img src={leftdir} />
                </div>
              </div>
            </div>

            <div className="absolute right-0 bottom-5">
              <img
                onClick={() => setShowCreatePopup(true)}
                className="h-14 cursor-pointer"
                src={add}
              />
            </div>
          </div>

          <div className="flex justify-between mt-5">
            <div className="flex bg-white font-semibold p-[2px] text-[14px] rounded-[10px] h-[40px] w-[200px] shadow border-t">
              {createjobSideNav.map((items) => (
                <div
                  onClick={() => setActiveTab(items.name)}
                  className={`${
                    activeTab === items.name
                      ? "bg-[#f17400a5] text-white"
                      : "bg-white"
                  } flex justify-center items-center w-full cursor-pointer rounded-[10px]`}
                >
                  {items.name}
                </div>
              ))}
            </div>

            <div>
              <button
                onClick={() => setShowPopup(true)}
                className={`flex shadow w-[100px] shadow-[#F17300] bg-[#f17400a5] text-white h-[35px] font-semibold justify-center items-center rounded-[5px]`}
              >
                Create Jobs
              </button>
            </div>
          </div>

          <>
            {activeTab === createjobSideNav[0].name && <OpenJobs />}

            {activeTab === createjobSideNav[1].name && <ClosedJobs />}
          </>
        </div>
      ) : (
        <LogggedOutState />
      )}
      {showCreatePopup && (
        <>
          <div className=" fixed top-0 left-0 w-full h-full z-[9999] flex items-center justify-center">
            <UploadJobsPopup
              onclose={() => setShowCreatePopup(false)}
              getRequest={() => fetchProjects()}
            />
          </div>
          <div className="fixed w-screen z-[999] h-screen bg-[#00000055] top-0 left-0" />
        </>
      )}
      {showPopup && (
        <>
          {" "}
          <div className=" fixed top-0 left-0 w-full h-full z-[9999] flex items-center justify-center">
            <CreateOptionsPopup onclose={() => setShowPopup(false)} />
          </div>{" "}
          <div className="fixed w-screen z-[999] h-screen bg-[#00000055] top-0 left-0" />
        </>
      )}
    </div>
  );
};

export default CreateJobsPage;
