import React from 'react'
import logo from "../../../../assets/icons/postcine_logo.png";

const SidebarItem = () => {
  return (
    <div>
        <div className='flex gap-2 items-center justify-center'>
            <p className='Montserrat text-[28px]'>POSTCINE</p>
            <img
            src={logo}
            className="h-12 w-14"
            alt=""
          />
        </div>

        <div className='flex justify-center'>
            <p className='text-[16px] w-[85%] text-center font-semibold'>
            Create your profile to hire creatives
            </p>
        </div>
    </div>
  )
}

export default SidebarItem