import React, { useEffect, useRef, useState } from "react";
import { Toptrends } from "../../utils/data";
import AxiosInstance from "../../api/axios";
import auth_arrow from "../../assets/icons/auth_arrow.svg";
import { useNavigate } from "react-router-dom";
import IndividualMovie from "./individualMovies";

type MoreCommunityLayoutProps = {
  handleBack?: () => void;
  viewCommunity?: () => void;
  selectedCommunity?: any;
};

const MoreCommunity: React.FC<MoreCommunityLayoutProps> = ({
  handleBack,
  viewCommunity,
}) => {
  const [parentCommunity, setParentCommunity] = useState([] as any);
  const [parentFavourite, setParentFavourite] = useState([] as any);
  const [parentMember, setParentMember] = useState([] as any);
  const [selectedCommunityItem, setSelectedCommunityItem] = useState<
    any | null
  >(null);
  const [loading, setLoading] = useState(false);
  const User_id = localStorage.getItem("ids");
  const [showMoreCommunity, setShowMoreCommunity] = useState(true);
  const [showIndividualCommuity, setShowIndividualCommuity] = useState(false);

  const handleViewCommunity = (item: any) => {
    // console.log(item)
    setSelectedCommunityItem(item);
    setShowMoreCommunity(false);
    setShowIndividualCommuity(true);
  };

  const fetchCommuity = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get(`/community/all`);
      if (Array.isArray(res.data)) {
        const parentItems = res.data.map((item) => ({
          displayImage: item.displayImage,
          iconImage: item.iconImage,
          name: item.name,
          crew: item.crew,
          soundtrack: item.soundtrack,
          id: item._id,
          user: item.user,
          members: item.members,
          communityImages: item.communityImages,
          communityVideos: item.communityVideos
        }));
        setParentCommunity(parentItems);
      } else {
        console.error("Unexpected response format:", res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCommuity();
  }, []);

  const handleStateBack = () => {
    setShowIndividualCommuity(false);
    setShowMoreCommunity(true);
    getFavourite();
  };

  const getFavourite = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get(`/community/favourite/${User_id}`);
      if (Array.isArray(res.data)) {
        const parentFavourite = res.data.map((item) => ({
          displayImage: item.displayImage,
          iconImage: item.iconImage,
          name: item.name,
          crew: item.crew,
          soundtrack: item.soundtrack,
          id: item._id,
          user: item.user,
          members: item.members,
          communityImages: item.communityImages,
          communityVideos: item.communityVideos
        }));
        setParentFavourite(parentFavourite);
      } else {
        console.error("Unexpected response format:", res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getMember = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get(`/community/member/${User_id}`);
      if (Array.isArray(res.data)) {
        const parentMember = res.data.map((item) => ({
          displayImage: item.displayImage,
          iconImage: item.iconImage,
          name: item.name,
          crew: item.crew,
          soundtrack: item.soundtrack,
          id: item._id,
          user: item.user,
          members: item.members,
          communityImages: item.communityImages,
          communityVideos: item.communityVideos
        }));
        setParentMember(parentMember);
      } else {
        console.error("Unexpected response format:", res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCommuity();
    getFavourite();
    getMember();
  }, []);

  useEffect(() => {
    const scrollContainer: HTMLElement | null =
      document.querySelector(".scroll-container");

    function autoScroll(): void {
      if (scrollContainer) {
        scrollContainer.scrollLeft += 70;

        if (
          scrollContainer.scrollLeft + scrollContainer.offsetWidth >=
          scrollContainer.scrollWidth
        ) {
          scrollContainer.scrollLeft = 0;
        }
      }
    }

    setInterval(autoScroll, 1700);
  }, []);

  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollableDivRef.current) {
      if (scrollableDivRef.current.scrollLeft > 200) {
        scrollableDivRef.current.scrollLeft -= 200; 
      } else {
        scrollableDivRef.current.scrollLeft = 0;
      }
    }
  };

  const scrollRight = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollLeft += 200; 
    }
  };

  return (
    <div>
      {showMoreCommunity && (
        <div className="flex flex-col gap-5">
          <div className="flex items-center gap-2">
            <img
              onClick={handleBack}
              className="cursor-pointer"
              src={auth_arrow}
              alt=""
            />
            <div>
              <p className="font-semibold text-[24px]">More Commuity</p>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <div className="border-b">
              <p className="border-b border-b-orange-300 w-fit pb-[5px] font-semibold">
                Member
              </p>
            </div>
            <div className="grid grid-cols-3 gap-2">
              {parentMember?.map((item: any, i: number) => (
                <div
                  onClick={() => {
                    handleViewCommunity(item);
                  }}
                  className="relative cursor-pointer hover:opacity-70 w-full"
                >
                  <div className="rounded-[5px] w-full h-[120px] bg-orange-200">
                    <img
                      src={item.iconImage}
                      className="w-full h-[120px]"
                      alt=""
                    />
                  </div>
                  <div className="bg-[#2524243d] text-white text-[14px] flex justify-center items-center font-semibold h-[30px] w-full absolute bottom-0 rounded-b-[5px] z-[99]">
                    <p>{item.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <div className="border-b">
              <p className="border-b border-b-orange-300 w-fit pb-[5px] font-semibold">
                Favourite
              </p>
            </div>
            <div className="grid grid-cols-3 gap-2">
              {parentFavourite?.map((item: any, i: number) => (
                <div
                  onClick={() => {
                    handleViewCommunity(item);
                  }}
                  className="relative cursor-pointer hover:opacity-70 w-full"
                >
                  <div className="rounded-[5px] w-full h-[120px] bg-orange-200">
                    <img
                      src={item.iconImage}
                      className="w-full h-[120px]"
                      alt=""
                    />
                  </div>
                  <div className="bg-[#2524243d] text-white text-[14px] flex justify-center items-center font-semibold h-[30px] w-full absolute bottom-0 rounded-b-[5px] z-[99]">
                    <p>{item.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <div className="border-b">
              <p className="border-b border-b-orange-300 w-fit pb-[5px] font-semibold">
                Others
              </p>
            </div>

            <div className="grid grid-cols-3 gap-2">
              {parentCommunity?.map((item: any, i: number) => (
                <div
                  onClick={() => {
                    handleViewCommunity(item);
                  }}
                  className="relative cursor-pointer hover:opacity-70 w-full"
                >
                  <div className="rounded-[5px] w-full h-[120px] bg-orange-200">
                    <img
                      src={item.iconImage}
                      className="w-full h-[120px]"
                      alt=""
                    />
                  </div>
                  <div className="bg-[#2524243d] text-white text-[14px] flex justify-center items-center font-semibold h-[30px] w-full absolute bottom-0 rounded-b-[5px] z-[99]">
                    <p>{item.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {showIndividualCommuity && (
        <IndividualMovie
          parentCommuity={selectedCommunityItem}
          handleBack={handleStateBack}
        />
      )}
    </div>
  );
};

export default MoreCommunity;
