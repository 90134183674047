import React, { useEffect, useState, useRef } from "react";
import JobDetails from "./jobDetails";
import FileUpload from "../../comonent/fileUploader";
import { useNavigate } from "react-router-dom";
import Options_component from "../../comonent/options_Component";
import Login_Pop from "../../comonent/Auth_Popups/Login_Pop";
import Signup_Pop from "../../comonent/Auth_Popups/Signup_Pop";
import AxiosInstance from "../../api/axios";
import loadingPic from "../../assets/icons/loading-1.gif";
import Empty_State from "../../comonent/Empty_State";
import rightdir from "../../assets/icons/right_dir.svg";
import leftdir from "../../assets/icons/left_dir.svg";
import SearchByCategory from "./searchByCategory";
import JobList from "./jobList";
import { JobsType } from "../../utils/data";
import TimeCalculator from "../../comonent/TimeCalculator";

const ExploreJobsPage = () => {
  const [loading, setLoading] = useState(false);
  const [screenSize, setScreenSize] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showSignupPopup, setShowSignupPopup] = useState(false);
  const navigate = useNavigate();
  const [parentJob, setParentJob] = useState([] as any);
  const [clickedItemDetails, setClickedItemDetails] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchContract, setSearchContract] = useState(null);
  const [parentItem, setParentItem] = useState([] as any);
  const [searchPayment, setSearchPayment] = useState(null);
  const [searchCategory, setSearchCategory] = useState(null);
  const [searchJobType, setSearchJobType] = useState(null);
  const [searchTimeframe, setSearchTimeframe] = useState(null);
  const [showWhat, setShowWhat] = useState("");
  const [showWhere, setShowWhere] = useState("");

  const topScroll = useRef(null);

  const handlePricing = () => {
    const pricingElement = document.getElementById("pricing-section");
    if (pricingElement) {
      pricingElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const allItems: JobsType[] = [
    /* Your array of job objects */
  ];

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    handlePricing()
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = parentJob.slice(startIndex, endIndex);
  const currentSearch = parentItem.slice(startIndex, endIndex);


  function handleResize() {
    const width = window.innerWidth;

    if (width < 640) {
      setScreenSize("small");
    } else {
      setScreenSize("large");
    }
  }

  window.addEventListener("resize", handleResize);

  const jobClick = (item: any) => {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 768) {
      handleDetailsPage(item);
    } else {
      setClickedItemDetails(item);
      handlePricing();
    }
  };

  const handleDetailsPage = (item: any) => {
    navigate("/Job-Details", { state: { jobDetails: item } });
  };

  const handlePop = () => {
    setShowPopup(true);
  };

  const handleLogginToggle = () => {
    setShowSignupPopup(false);
    setShowPopup(true);
  };

  const handleToggle = () => {
    setShowPopup(false);
    setShowSignupPopup(true);
  };

  const fetchJobs = async () => {
    try {
      setLoading(true);
      const res = await AxiosInstance.get(`/jobs/all`);
      if (Array.isArray(res.data)) {
        setParentJob(res.data);
        // console.log(parentJob);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const handleFIndJobs = async () => {
    setLoading(true);
    setShowSearch(true);
    try {
      const searchContent = {
        company: showWhat,
        city: showWhere,
      };

      const res = await AxiosInstance.get("jobs/whatWhere/whatWhere", {
        params: searchContent,
      });

      if (Array.isArray(res.data)) {
        setParentItem(res.data);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setShowWhat("");
      setShowWhere("");
      setLoading(false);
    }
  };

  const handleTimeframeState = (option: any) => {
    setSearchTimeframe(option);
    setShowSearch(true);
    fetchTimeframe(option);
  };
  const fetchTimeframe = async (option: any) => {
    const theTimeframe = option.value;
    try {
      const res = await AxiosInstance.get(`/jobs/timeframe/${theTimeframe}`);
      if (Array.isArray(res.data)) {
        setParentItem(res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleContractState = (option: any) => {
    setSearchContract(option);
    setShowSearch(true);
    fetchContract(option);
  };
  const fetchContract = async (option: any) => {
    const theContract = option.value;
    try {
      setLoading(true);
      const res = await AxiosInstance.get(`/jobs/contract/${theContract}`);
      if (Array.isArray(res.data)) {
        setParentItem(res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleJobTypeState = (option: any) => {
    setSearchJobType(option);
    setShowSearch(true);
    fetchJobType(option);
  };
  const fetchJobType = async (option: any) => {
    const jobType = option.value;
    try {
      setLoading(true);
      const res = await AxiosInstance.get(`/jobs/workType/${jobType}`);
      if (Array.isArray(res.data)) {
        setParentItem(res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryState = (option: any) => {
    setSearchCategory(option);
    setShowSearch(true);
    fetchCategory(option);
  };
  const fetchCategory = async (option: any) => {
    const theCategory = option.value;
    try {
      setLoading(true);
      const res = await AxiosInstance.get(`/jobs/categories/${theCategory}`);
      if (Array.isArray(res.data)) {
        setParentItem(res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentState = (option: any) => {
    setSearchPayment(option);
    setShowSearch(true);
    fetchPayment(option);
  };
  const fetchPayment = async (option: any) => {
    const thePayment = option.value;
    try {
      setLoading(true);
      const res = await AxiosInstance.get(`/jobs/payment/${thePayment}`);
      if (Array.isArray(res.data)) {
        setParentItem(res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        id="pricing-section"
        className="mt-7 px-[10px] sm:px-[20px] flex flex-col gap-[15px] sm:gap-[30px] Axiforma"
      >
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-2 ">
          <div className="w-full sm:w-[80%] flex gap-2 sm:gap-3">
            <div className="  flex justify-end w-full">
              <input
                className="w-full sm:w-[80%] rounded-[20px] h-[32px] p-[10px] text-[14px] shadow shadow-[#bebdba]"
                placeholder="What"
                value={showWhat}
                onChange={(e) => setShowWhat(e.target.value)}
              />
            </div>
            <div className="w-full sm:w-[80%]">
              <input
                className="w-full rounded-[20px] h-[32px] p-[10px] text-[14px] shadow shadow-[#bebdba]"
                placeholder="Where"
                value={showWhere}
                onChange={(e) => setShowWhere(e.target.value)}
              />
            </div>
          </div>

          <div className="flex justify-center">
            <button
              onClick={handleFIndJobs}
              className="bg-[#F17300] h-[32px] rounded-[25px] w-[220px] xs:w-[180px] shadow shadow-[#5d5555] sm:w-[190px] text-sm"
            >
              Find Jobs
            </button>
          </div>
        </div>

        <div className="flex justify-center">
          <Options_component
            onSendPayment={handlePaymentState}
            onSendContract={handleContractState}
            onSendCategory={handleCategoryState}
            onSendJobType={handleJobTypeState}
            onSendTimeframe={handleTimeframeState}
          />
        </div>

        {loading ? (
          <div className="h-[80vh] w-full flex justify-center items-center">
            <img src={loadingPic} />
          </div>
        ) : parentJob.length > 0 ? (
          <div className="">
            <div className="flex w-full gap-2">
              <div className="flex flex-col w-full items-center">
                <div className="text-[12px] xs:text-[14px] md:text-[15px] lg:text-[18px] flex flex-col gap-[15px] w-full">
                  <div className="font-semibold">
                    <p className="flex gap-1 sm:gap-2">
                      <FileUpload />
                      And Easily Apply To Jobs Indeed!
                    </p>
                  </div>
                  <hr />
                  <div className="flex flex-col text-[15px]">
                    <p className="thin">Part Time Jobs In Lagos, Nigeria</p>
                    <p className="font-semibold">
                      Sort By: Relevance{" "}
                      <span className="text-[#F17300] thin">- Date</span>
                    </p>
                  </div>
                </div>

                {showSearch ? (
                  <>
                    {currentSearch && (
                      <div className="flex justify-end w-full text-[13px]">
                        <div className="flex items-end gap-[25px] xs:gap-[35px] px-4">
                          <p className="Lato-Italics">
                            {currentSearch.length}{" "}
                            {currentSearch.length > 1 ? "Jobs" : "Job"}
                          </p>

                          <div className="bg-black h-[25px] w-[25px] rounded-[30px] text-white flex justify-center items-center font-bold">
                            ?
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <SearchByCategory onJobClick={() => jobClick(items)} parentList={parentItem} /> */}
                    <div className="flex flex-col gap-[50px] items-center mt-[50px]">
                      {currentSearch.map((items: JobsType) => (
                        <div
                          onClick={() => jobClick(items)}
                          className="relative w-full xs:w-[95%] hover:bg-[#edebeb3e] cursor-pointer p-5 gap-[6px] flex flex-col rounded-[20px] shadow shadow-[#F17300]"
                        >
                          <div
                            className={`absolute top-0 right-3 rounded-b-[10px] h-7 w-4 ${
                              items?.organization === "Company"
                                ? "bg-orange-100"
                                : "bg-blue-50"
                            }`}
                          />

                          <div className="text-[20px] lg:text-[24px] font-bold">
                            <p>{items?.company}</p>
                          </div>
                          <div className="text-[13px] font-semibold">
                            <p>{items?.city}</p>
                            <p>{items?.country}</p>
                          </div>
                          <div className="flex gap-3 medium">
                            {" "}
                            {/* Fixed closing parenthesis here */}
                            <div className="px-2 text-[10px] flex justify-center rounded-[5px] items-center shadow shadow-[#be9163] h-[30px] bg-[#D9D9D9]">
                              {items?.categories}
                            </div>
                            <div className="px-2 text-[10px] flex justify-center rounded-[5px] items-center shadow shadow-[#be9163] h-[30px] bg-[#D9D9D9]">
                              {items?.workType}
                            </div>
                          </div>

                          <div className="bg-[#FAFAFA] p-2 my-[40px]">
                            <div>
                              <p className="text-[13px]">
                                we are committed to improving success and
                                opportunities for all individuals including
                                those with disabilities by identifying and
                                removing barriers.......
                              </p>

                              <div className="flex w-full text-[10px] my-5 font-semibold">
                                <TimeCalculator createdAt={items.createdAt} />
                              </div>

                              <hr />

                              <div className="flex flex-col text-[12px] my-[22px] Lato-Light">
                                <p>
                                  View All{" "}
                                  <span className="text-[#F17300]">
                                    Oracle Company
                                  </span>{" "}
                                  .{" "}
                                  <span className="text-[#F17300]">
                                    Lagos, Nigeria
                                  </span>{" "}
                                  .{" "}
                                  <span className="text-[#F17300]">
                                    VFX Jobs In Lagos, Nigeria
                                  </span>
                                </p>
                                <p>
                                  Check Price Range Here In{" "}
                                  <span className="text-[#F17300]">
                                    Oracle Company, Lagos, Nigeria
                                  </span>
                                </p>
                              </div>

                              <div>
                                <p className="text-[12px] Lato-Light text-[#F17300] underline">
                                  View Similar Jobs With This Employer
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="flex justify-start w-full">
                        <div className="flex items-center justify-center">
                          <img src={rightdir} alt="" />
                          <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                          >
                            Previous
                          </button>
                        </div>
                        <div className="flex">
                          <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={
                              currentPage ===
                              Math.ceil(allItems.length / itemsPerPage)
                            }
                          >
                            Next
                          </button>
                          <img src={leftdir} alt="" />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {/* <JobList handleJobClick={() => jobClick(items)}/> */}
                    <>
                      {currentItems && (
                        <div className="flex justify-end w-full text-[13px]">
                          <div className="flex items-end gap-[25px] xs:gap-[35px] px-4">
                            <p className="Lato-Italics">
                              {currentItems.length} Jobs
                            </p>

                            <div className="bg-black h-[25px] w-[25px] rounded-[30px] text-white flex justify-center items-center font-bold">
                              ?
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="flex flex-col gap-[50px] items-center mt-[50px]">
                        {currentItems.map((items: any) => (
                          <div
                            onClick={() => jobClick(items)}
                            className="w-full xs:w-[95%] relative hover:bg-[#edebeb3e] cursor-pointer p-5 gap-[6px] flex flex-col rounded-[20px] shadow shadow-[#F17300]"
                          >
                            <div
                              className={`absolute top-0 right-3 rounded-b-[10px] h-7 w-4 ${
                                items.organization === "Company"
                                  ? "bg-orange-100"
                                  : "bg-blue-50"
                              }`}
                            />

                            <div className="text-[20px] lg:text-[24px] font-bold">
                              <p>{items?.company}</p>
                            </div>
                            <div className="text-[13px] font-semibold">
                              <p>{items?.city}</p>
                              <p>{items?.country}</p>
                            </div>
                            <div className="flex gap-3 medium">
                              <div className="px-2 text-[10px] flex justify-center rounded-[5px] items-center shadow shadow-[#be9163] h-[30px] bg-[#D9D9D9]">
                                {items?.categories}
                              </div>
                              <div className="px-2 text-[10px] flex justify-center rounded-[5px] items-center shadow shadow-[#be9163] h-[30px] bg-[#D9D9D9]">
                                {items?.contract}
                              </div>
                            </div>

                            <div className="bg-[#FAFAFA] p-2 my-[40px]">
                              <div>
                                <p className="text-[13px]">
                                  we are committed to improving success and
                                  opportunities for all individuals including
                                  those with disabilities by identifying and
                                  removing barriers.......
                                </p>

                                <div className="flex w-full text-[10px] my-5 font-semibold">
                                  <TimeCalculator
                                    createdAt={items?.createdAt}
                                  />
                                </div>

                                <hr />

                                <div className="flex flex-col text-[12px] my-[22px] Lato-Light">
                                  <p>
                                    View All{" "}
                                    <span className="text-[#F17300]">
                                      Oracle Company
                                    </span>{" "}
                                    .{" "}
                                    <span className="text-[#F17300]">
                                      Lagos, Nigeria
                                    </span>{" "}
                                    .{" "}
                                    <span className="text-[#F17300]">
                                      VFX Jobs In Lagos, Nigeria
                                    </span>
                                  </p>
                                  <p>
                                    Check Price Range Here In{" "}
                                    <span className="text-[#F17300]">
                                      Oracle Company, Lagos, Nigeria{" "}
                                    </span>
                                  </p>
                                </div>

                                <div>
                                  <p className="text-[12px] Lato-Light text-[#F17300] underline">
                                    View Similar Jobs With This Employer
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="flex justify-start w-full">
                          <div className="flex items-center justify-center">
                            <img src={rightdir} alt="" />
                            <button
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                          </div>
                          <div className="flex">
                            <button
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={
                                currentPage ===
                                Math.ceil(allItems.length / itemsPerPage)
                              }
                            >
                              Next
                            </button>
                            <img src={leftdir} alt="" />
                          </div>
                        </div>
                      </div>
                    </>
                  </>
                )}
              </div>

              <div className="w-full hidden md:flex">
                {clickedItemDetails && (
                  <JobDetails
                    popupClick={handlePop}
                    onDetailsClick={() => setClickedItemDetails(null)}
                    jobDetails={clickedItemDetails}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <Empty_State />
        )}
      </div>

      {showPopup ? (
        <>
          <div className=" fixed top-0 left-0 w-full h-full z-[9999] flex items-center justify-center">
            <Login_Pop
              TogglePop={handleToggle}
              ActivePopup={() => setShowPopup(false)}
            />
          </div>
          <div className="fixed w-screen z-[999] h-screen bg-[#00000055] top-0 left-0" />
        </>
      ) : (
        ""
      )}

      {showSignupPopup && (
        <>
          <div className=" fixed top-0 left-0 w-full h-full z-[9999] flex items-center justify-center">
            <Signup_Pop
              ActiveSignupProps={() => {
                setShowSignupPopup(false);
              }}
              ToggleLogin={handleLogginToggle}
            />
          </div>
          <div className="fixed w-screen h-screen z-[999] bg-[#00000055] top-0 left-0" />
        </>
      )}
    </>
  );
};

export default ExploreJobsPage;
