import React from "react";
import Acct_Layout from "../../../comonent/Acctount_Layout";
import arrow from "../../../assets/icons/auth_arrow.svg";
import EmployerProfilePage from "./EmployerProfile";
import { useNavigate } from "react-router-dom";
import UserProfileLayout from "../../../comonent/UserProfile_Layout";

const ProfileLayout = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-[15px] sm:gap-[30px]">
      <UserProfileLayout>
        <EmployerProfilePage />
      </UserProfileLayout>
    </div>
  );
};

export default ProfileLayout;
