import React from 'react'
import LoggedIn_Layout from '../../../comonent/loggedIn_Layout'
import SideLayout from '../../../comonent/SideLayout'
// import SkillsPage from './skillspage'
import ExperiencePage from '../experience/experiencePage'
import SkillPage from './skillPage'


const Skill = () => {
  return (
    <LoggedIn_Layout>
        <SideLayout>
            <SkillPage/>
        </SideLayout>
    </LoggedIn_Layout>
  )
}

export default Skill