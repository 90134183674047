import React from "react";
import illustration from "../../assets/img/Illustration.png";

const Empty_State = () => {
  return (
    <div className="h-[80vh] flex flex-col gap-5 justify-center items-center w-full">
      <img className="h-60 xs:h-72" src={illustration} alt="/" />
      <div className="flex flex-col items-center text-center">
        <p className="text-[26px]">Error loading interface</p>
        <p className="Lato-Italics">Check internet connection...</p>
      </div>
    </div>
  );
};

export default Empty_State;
