import React, { ReactNode, useState } from "react";
import Sidebar from "./sidebar";
import arrow from "../../assets/icons/auth_arrow.svg";
import Community_xs from "../Community_xs";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../logoutModal/logoutModal";
import SIdeModal from "../sideModal";

interface DashboardLayoutProps {
  children: ReactNode;
}

const UserProfileLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSideModal, setShowSideModal] = useState(false);
  const User_id = localStorage.getItem("ids");

  const handleClick = () => {
    setShowLoginModal(!showLoginModal);
  };

  const handleLogout = () => {
    setShowSideModal(false);
    setShowLoginModal(true);
  };

  return (
    <div className="flex flex-col gap-[20px] xs:gap-[30px] relative">
      <div className="mt-[25px] flex flex-row items-center justify-between gap-2 mx-[10px] xs:mx-[30px]">
        <div>
          {" "}
          <img
            onClick={
              User_id ? () => navigate("/Dashboard") : () => navigate("/")
            }
            className="cursor-pointer"
            src={arrow}
          />
        </div>

        <div
          onClick={() => setShowSideModal(true)}
          className="bg-[#f1740079] cursor-pointer font-black rounded-[5px] flex lg:hidden h-[27px] w-[40px] justify-center items-center"
        >
          ...
        </div>
      </div>
      <div className="flex gap-[16px] px-[10px] xs:px-[30px] mb-14">
        <div className="w-[25%] hidden lg:flex shadow shadow-[#F17300] rounded-[10px]">
          <Sidebar onLogoutClick={handleClick} />
        </div>
        <main className="w-full lg:w-[70%] xl:w-[75%] shadow shadow-[#F17300] rounded-[10px] ">
          {children}
        </main>
        {/* <div className="w-[45%] lg:w-[45%] xl:w-[35%] hidden md:flex shadow shadow-[#F17300] rounded-[5px]">
          <Community_xs />
        </div> */}
      </div>

      {showLoginModal && (
        <>
          <div className=" fixed top-0 left-0 w-full h-full z-[999] flex items-center justify-center">
            <LogoutModal onClose={() => setShowLoginModal(false)} />
          </div>
          <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
        </>
      )}

      {showSideModal && (
        <>
          <div className="fixed top-0 right-0 z-[999] flex lg:hidden">
            <SIdeModal
              onLogoutClick={handleLogout}
              onClose={() => setShowSideModal(false)}
            />
          </div>
          <div className="fixed w-screen h-screen bg-[#00000097] top-0 left-0" />
        </>
      )}
    </div>
  );
};

export default UserProfileLayout;
