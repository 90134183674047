import React, { useState } from "react";
import right_arrow from "../../assets/icons/right_arrow.svg";
import search from "../../assets/icons/search.svg";
import CategoryRow from "../../comonent/Category_Row";
import { scrolls } from "../../utils/data";
import dropdown from "../../assets/icons/dropdown.svg";
import { useNavigate } from "react-router-dom";

const SectionOne = () => {
  // const [open, setOpen] = useState(true);
  // const navigate = useNavigate();

  // // const handleOpen = () => {
  // //   setOpen(!open);
  // // };

  return (
    <div>
      {/*<div className="flex justify-center mt-[35px]">
         <div className="flex flex-col items-center relative">
          <div className="border flex w-[88%] sm:w-[500px] md:w-[600px] lg:w-[800px] rounded-[10px] shadow py-1">
            <div
              onClick={handleOpen}
              className="cursor-pointer flex justify-between rounded-l-[10px] px-[15px] border-r w-[290px] xs:w-[330px]"
            >
              <p className="">categories</p>
              {open ? <img src={right_arrow} /> : <img src={dropdown} />}
            </div>
            <input
              placeholder="search"
              className="w-full text-sm px-[10px] border-r-0"
            />
            <div className="rounded-r-[10px] w-[45px] sm:w-[35px] flex justify-center cursor-pointer items-center">
              <img src={search} className="w-[23px] h-[23px]" />
            </div>
          </div>

          {open ? (
            ""
          ) : (
            <div className="flex flex-col z-[9] shadow text-[14px] lg:text-[16px] bg-white rounded-[5px] w-[150px] xs:w-[190px] sm:w-[200px] md:w-[210px] lg:w-[230px] absolute top-[34px] left-[25px] xs:left-[32px] sm:left-0">
              {scrolls.map(({ name, link }) => (
                <p
                  onClick={() => navigate(link)}
                  className="px-4 py-[1px] border-b hover:font-semibold hover:bg-[#F17300] border-[#1e222e3a] cursor-pointer"
                >
                  {name}
                </p>
              ))}
            </div>
          )}
        </div>
      </div> */}

      <div className="mt-[73px]">
        <CategoryRow />
      </div>
    </div>
  );
};

export default SectionOne;
