import React from 'react'
import Layout from '../../../comonent/Layout'
import FollowPage from './followPage'
import UserProfileLayout from '../../../comonent/UserProfile_Layout'

const Follows = () => {
  return (
    <UserProfileLayout>
        <FollowPage/>
    </UserProfileLayout>
  )
}

export default Follows