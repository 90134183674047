import React, { useRef, useState } from "react";
import illustration from "../../assets/img/Illustration.png";
import arrow from "../../assets/icons/auth_arrow.svg";
import { useNavigate } from "react-router-dom";

const Verification = () => {
  const codeInputs = useRef([]);

  const [codeOne, setCodeOne] = useState("");
  const [codeTwo, setCodeTwo] = useState("");
  const [codeThree, setCodeThree] = useState("");
  const [codeFour, setCodeFour] = useState("");
  const [codeFive, setCodeFive] = useState("");
  const [codeSix, setCodeSix] = useState("");

  const validate =
    !codeOne || !codeTwo || !codeThree || !codeFour || !codeFive || !codeSix;

  const handleSubmit = () => {
    // e.preventDefault();
    // console.log("cod");
    const codeArray = [codeOne, codeTwo, codeThree, codeFive, codeSix].join("");
    navigate('/Verified')
  };

  //   console.log(codeOne, codeTwo);

  const navigate = useNavigate();
  return (
    <div className="flex h-[100vh]">
      <div className="bg-[#f1730066] px-7 lg:px-0 w-[500px] md:w-[700px] lg:w-[900px] hidden md:flex justify-center items-center h-[100vh]">
        <div className="flex flex-col items-center justify-center gap-[50px]">
          <img src={illustration} />
          <p className="text-[26px] text-white lg:text-[31px] font-semibold lg:w-[95%] text-center">
            Online Community For Post-production Professionals
          </p>
        </div>
      </div>
      <div className="bg-white w-full">
        <div className="pt-[15px] px-[15px] xs:px-[30px]">
          <img
            onClick={() => navigate("/Dashboard")}
            className="cursor-pointer"
            src={arrow}
          />
        </div>
        <div className="w-full flex items-center h-[100vh] justify-center mb-10">
          <div className="font-semibold items-center justify-center w-[80%] sm:w-[65%] lg:w-[60%] pb-10 xl:w-[50%] flex flex-col gap-[32px]">
            <div className="flex flex-col gap-2">
              <p className="text-[24px]">Email Verification</p>
              <p className="text-[12px] Lato-Thin">
                We have sent a code to your email
              </p>
            </div>

            <form
              onSubmit={handleSubmit}
              className="flex flex-col items-center justify-center mt-3"
            >
              <div className="flex flex-col gap-14">
                <div className="flex flex-row gap-3 text-[#1D4C41]">
                  <label className="flex flex-col">
                    <input
                      ref={(el) => codeInputs.current[0]}
                      name="codeOne"
                      // type="number"
                      value={codeOne}
                      onChange={(e) => setCodeOne(e.target.value[0])}
                      className="border rounded-md w-8 h-8 outline-none text-center"
                    />
                  </label>

                  <label className="flex flex-col">
                    <input
                      ref={(el) => codeInputs.current[0]}
                      name="codeTwo"
                      // type="number"
                      value={codeTwo}
                      onChange={(e) => setCodeTwo(e.target.value[0])}
                      className="border rounded-md w-8 h-8 outline-none text-center"
                    />
                  </label>

                  <label className="flex flex-col">
                    <input
                      ref={(el) => codeInputs.current[0]}
                      name="codeThree"
                      // type="number"
                      value={codeThree}
                      onChange={(e) => setCodeThree(e.target.value[0])}
                      className="border rounded-md w-8 h-8 outline-none text-center"
                    />
                  </label>

                  <label className="flex flex-col">
                    <input
                      ref={(el) => codeInputs.current[0]}
                      name="codeFour"
                      // type="number"
                      value={codeFour}
                      onChange={(e) => setCodeFour(e.target.value[0])}
                      className="border rounded-md w-8 h-8 outline-none text-center"
                    />
                  </label>

                  <label className="flex flex-col">
                    <input
                      ref={(el) => codeInputs.current[0]}
                      name="codeFive"
                      // type="number"
                      value={codeFive}
                      onChange={(e) => setCodeFive(e.target.value[0])}
                      className="border rounded-md w-8 h-8 outline-none text-center"
                    />
                  </label>

                  <label className="flex flex-col">
                    <input
                      ref={(el) => codeInputs.current[0]}
                      name="codeSix"
                      // type="number"
                      value={codeSix}
                      onChange={(e) => setCodeSix(e.target.value[0])}
                      className="border rounded-md w-8 h-8 outline-none text-center"
                    />
                  </label>
                </div>

                <div className="w-full">
                  <button
                    disabled={validate}
                    type="submit"
                    className={`flex shadow w-full shadow-[#F17300] ${
                      !validate ? "bg-[#F17300] text-white" : ""
                    } h-[38px] font-semibold justify-center items-center rounded-[5px]`}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
