// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollable-container {
    display: flex;
    align-items: center;
  }
  
  .scrollable-div {
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
    margin: 10px;
  }
  
  button {
    margin: 0 10px;
  }

  .video-scroll-container {
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: hidden; /* Prevent the scrollable div from overflowing the container */
  }
  
  .video-scroll {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 10px;
  }
  
  .scroll-button {
    height: 20px;
    width: 20px;
    /* Add any additional styling for the scroll buttons */
  }
`, "",{"version":3,"sources":["webpack://./src/pages/community/individualMovies/ScrollableDivWithNavigation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,YAAY;EACd;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,gBAAgB,EAAE,8DAA8D;EAClF;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,uBAAuB;IACvB,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,sDAAsD;EACxD","sourcesContent":[".scrollable-container {\n    display: flex;\n    align-items: center;\n  }\n  \n  .scrollable-div {\n    overflow-x: auto;\n    white-space: nowrap;\n    padding: 10px;\n    margin: 10px;\n  }\n  \n  button {\n    margin: 0 10px;\n  }\n\n  .video-scroll-container {\n    white-space: nowrap;\n    display: flex;\n    align-items: center;\n    overflow: hidden; /* Prevent the scrollable div from overflowing the container */\n  }\n  \n  .video-scroll {\n    display: flex;\n    overflow-x: auto;\n    scroll-behavior: smooth;\n    padding: 10px;\n  }\n  \n  .scroll-button {\n    height: 20px;\n    width: 20px;\n    /* Add any additional styling for the scroll buttons */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
