import React, { useState } from "react";
import CommentSection from "./commentSection";
import ReactPlayer from "react-player";

const GeneralWorkspacePage = () => {
  const [messages, setMessages] = useState("");
  const handleSubmit = () => {};

  return (
    <div className="flex flex-col md:flex-row w-full gap-16 md:gap-2 pb-2 px-3 my-[30px]">
      <div className="flex flex-col items-center gap-5 w-full md:w-[70%]">
        {/* <div className="bg-blue-100 w-full  h-96 md:h-[460px]"></div> */}
        <div className="w-full h-[430px] lg:h-[470px]">
          <ReactPlayer
            url="https://www.example.com/media4.mp4"
            controls 
            width="100%"
            height="100%"
          />
        </div>
        <form onSubmit={handleSubmit} className="w-[80%] relative">
          <textarea
            value={messages}
            onChange={(e) => setMessages(e.target.value)}
            placeholder="Start a conversation..."
            className="nonResizableTextarea border-[#9b979772] border border-b-4 shadow shadow-[#F1730080] p-[7px] w-full h-[100px] rounded-[10px]"
          />
          <div className="absolute right-1">
            <button className="shadow shadow-[#F1730080] py-[2px] font-semibold text-sm px-3 bottom-4">
              Post
            </button>
          </div>
        </form>
      </div>

      <CommentSection />
    </div>
  );
};

export default GeneralWorkspacePage;
