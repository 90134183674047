import React from "react";
import { Toptrends, TrendList } from "../../../utils/data";

const Trend_xs = () => {
  const onTrendClick = () => {};
  return (
    <div className="flex flex-col gap-3 pb-2">
      <div className="flex justify-start w-full mb-3">
        <p className="text-[#00000080] font-semibold text-[18px] xs:text-[20px]">Top Trends</p>
      </div>
      {TrendList.map(({ name, users }) => (
        <div
          onClick={onTrendClick}
          className="w-full sm:w-[85%] flex flex-col gap-[2px] bg-[#f2f2f2b5] border-b border-[#f174003a] shadow px-3  justify-center h-[60px] rounded-[5px] cursor-pointer"
        >
          <p className="text-[#00000080] font-semibold text-[14px] lg:text-[16px]">
            {name}
          </p>
          <p className="text-[8px] xl:text-[9px] text-[#0000005b] font-bold">
            {users} Users in Conversation
          </p>
        </div>
      ))}
    </div>
  );
};
// bg-[#d5d4d419]
export default Trend_xs;
