import React from "react";
import Settings_Layout from "../../comonent/settingsLayout";
import SettingsPage from "./settingsPage";
import SecuritySettings from "./securitySettings";
import UserProfileLayout from "../../comonent/UserProfile_Layout";
import LoggedIn_Layout from "../../comonent/loggedIn_Layout";
import SideLayout from "../../comonent/SideLayout";

const Settings = () => {
  return (
    <LoggedIn_Layout>
      <SideLayout>
        <SettingsPage />
      </SideLayout>
    </LoggedIn_Layout>
  );
};

export default Settings;
