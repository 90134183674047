import React from "react";

const Advertisement = () => {
  return (
    <div className="flex justify-center pb-12 xs:pb-0">
      <div className="bg-gray-100 rounded-md font-semibold w-[95%] xs:w-[90%] sm:w-[85%] h-[160px] flex justify-center items-center">
        Advertisment
      </div>
    </div>
  );
};

export default Advertisement;
