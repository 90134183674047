import React from "react";
import UserProfileLayout from "../../../comonent/UserProfile_Layout";
import ReferalsPage from "./referalsPage";
import LoggedIn_Layout from "../../../comonent/loggedIn_Layout";
import SideLayout from "../../../comonent/SideLayout";

const Referals = () => {
  return (
    <LoggedIn_Layout>
      <SideLayout>
        <ReferalsPage />
      </SideLayout>
    </LoggedIn_Layout>
  );
};

export default Referals;
