import React from 'react'
import BecomeEmployerLayout from '../../../../comonent/become_Employer/becomeEmployerComponent'
import BecomeEmployerPage from './becomeEmployerPage'

const BecomeEmployer = () => {
  return (
<BecomeEmployerLayout>
  <BecomeEmployerPage/>
</BecomeEmployerLayout>
  )
}

export default BecomeEmployer