import React, { useEffect, useState } from "react";
import imgIcon from "../../../assets/icons/icons8-image-48.png";
import AxiosInstance from "../../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import lazy_loading from "../../../assets/icons/loading-icon.gif";
import moment from "moment";

type CommentPopupProps = {
  commentDetails?: any;
};

const CommentPopup: React.FC<CommentPopupProps> = ({ commentDetails }) => {
  const [selectedPictures, setSelectedPictures] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingComment, setLoadingComment] = useState(false);
  const [selectedUploadedPictures, setSelectedUploadedPictures] = useState<
    File[]
  >([]);
  const [previewUploadedPictures, setPreviewUploadedPictures] = useState<
    string | null
  >(null);
  const [post, setPost] = useState("");
  const [parentComments, setParentComments] = useState([] as any);
  const User_id = localStorage.getItem("ids");
  const [userId, setUserId] = useState(() => {
    try {
      const storedId = localStorage.getItem("ids");
      if (storedId) {
        return storedId;
      }
    } catch (error) {
      console.error("Error retrieving userId from localStorage:", error);
    }
    return "";
  });

  const handleChangeImage = (e: any) => {
    setSelectedPictures(e.target.files[0]);

    const newImagess = e.target.files || [];
    setSelectedUploadedPictures([...selectedUploadedPictures, ...newImagess]);

    if (newImagess.length > 0 && previewUploadedPictures === null) {
      const reader = new FileReader();
      reader.onload = (event) =>
        setPreviewUploadedPictures((event.target as any).result as string);
      reader.readAsDataURL(newImagess[0]);
    }
  };

  const postId = commentDetails._id;

  const getComments = async () => {
    setLoading(true);

    try {
      const res = await AxiosInstance.get(`/posts/comments/${postId}`);
      const commentData = res.data;

      if (!Array.isArray(commentData)) {
        console.error("Unexpected response format:", commentData);
        return;
      }

      const commentsWithUsers = commentData.reverse().map(async (comment) => {
        const userId = comment.user;

        if (!userId) {
          console.warn("Comment has no user ID:", comment);
          return comment;
        }

        const userRes = await AxiosInstance.get(`/user/${userId}`);
        const user = userRes.data;

        if (!user) {
          console.warn("Failed to fetch user data for comment:", comment._id);
          return { ...comment, user: null };
        }

        return { ...comment, user };
      });

      const comments = await Promise.all(commentsWithUsers);

      comments.sort((a, b) => {
        const dateA = new Date(a.timestamp || 0);
        const dateB = new Date(b.timestamp || 0);
        return dateB.getTime() - dateA.getTime();
      });

      setParentComments(comments);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  const handleSubmit = async () => {
    setLoadingComment(true);
    try {
      const formData = new FormData();
      formData.append("image", selectedPictures);
      formData.append("comment", post);
      formData.append("user", userId);
      AxiosInstance.patch(`posts/${postId}`, formData).then((response) => {
        if (response.status === 200) {
          toast.success(`new post created`);
        }
        setPreviewUploadedPictures(null);
        setSelectedPictures("");
        setPost("");
        getComments();
        setLoadingComment(false);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const validate = !post && !selectedPictures;

  return (
    <>
      <div className="min-h-[420px] relative">
        <div className="h-14 flex items-center">
          <p className="font-semibold text-[22px] w-full">Comments</p>
        </div>

        <div
          className={` ${
            User_id ? "h-[210px]" : "h-[400px] pb-3"
          } flex flex-col gap-4 overflow-y-scroll`}
        >
          {loading ? (
            <div className="h-[50vh] w-full flex justify-center items-center">
              <img src={lazy_loading} />
            </div>
          ) : (
            <>
              {parentComments.length > 0 ? (
                <>
                  <ul className="flex flex-col gap-[30px] mt-5 ">
                    {parentComments?.map((item: any, i: number) => (
                      <div className="flex gap-3 border-b pb-3">
                        <div>
                          <img
                            className="h-10 w-12 rounded-[30px]"
                            src={item?.user?.dp}
                            alt=""
                          />
                        </div>

                        <div className="w-full">
                          <p className="font-semibold text-[15px]">
                            {item?.user?.firstName} {item?.user?.lastName}
                          </p>

                          <div className="flex flex-col gap-2">
                            <div className="text-[12px]">
                              <p>{item.content}</p>
                            </div>

                            {item.commentImage?.length > 0 && (
                              <div className="w-[75%]">
                                <img
                                  className="rounded-[5px] w-full h-[150px]"
                                  src={item.commentImage}
                                  alt="Comment image"
                                />
                              </div>
                            )}
                          </div>

                          <div className="w-full flex justify-end items-end text-gray-00">
                            <p className="Lato-light flex gap-1 italic text-[9px] sm:text-[10px] Axiforma-thin">
                              <p>{item?.createdAt?.split("T")[0]}</p>
                              <div className="bg-gray-400 min-w-[8px] mt-[3px] w-[1px] h-[6px] rounded-full"></div>
                              <p>
                                {" "}
                                {moment(item?.createdAt)?.format(
                                  "hh:mm a"
                                )}{" "}
                              </p>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </ul>
                </>
              ) : (
                <div className="h-[60vh] Axiforma w-full flex justify-center items-center">
                  No comment yet...
                </div>
              )}
            </>
          )}
        </div>

        {User_id && (
          <div className="flex w-full absolute bottom-4">
            <div className="flex flex-col w-full gap-3">
              <div className="w-full">
                <textarea
                  placeholder="Create a post..."
                  className="w-[95%] h-20 border p-1 text-sm drop-shadow-md rounded-md nonResizableTextarea"
                  value={post}
                  onChange={(e) => setPost(e.target.value)}
                />
              </div>

              <div className="flex justify-between items-end">
                <>
                  {" "}
                  {previewUploadedPictures && (
                    <img
                      src={previewUploadedPictures}
                      alt="Selected Icon"
                      className="w-20 h-20 object-cover rounded-md"
                    />
                  )}
                </>

                <div className="flex gap-1">
                  <>
                    <label htmlFor="displayImages">
                      <img
                        src={imgIcon}
                        className="h-9 w-9 drop-shadow-lg cursor-pointer"
                        alt=""
                      />
                      <input
                        id="displayImages"
                        type="file"
                        accept="image/*"
                        onChange={handleChangeImage}
                        className="hidden"
                      />
                    </label>
                  </>

                  <button
                    disabled={validate}
                    onClick={handleSubmit}
                    className={`flex shadow shadow-[#F17300] h-[36px] w-40 ${
                      !validate ? "bg-[#F17300] text-white" : ""
                    } font-semibold justify-center items-center rounded-[5px]`}
                  >
                    {loadingComment ? "loading..." : "Send"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CommentPopup;
