import React, { useEffect, useRef, useState } from "react";
import AxiosInstance from "../../api/axios";
import moment from "moment";

const EventPage = () => {
  const [parentEvent, setParentEvent] = useState([] as any);

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any | null>(parentEvent[0]);

  const handleItemClick = (item: any) => {
    setSelectedItem(item);
  };

  const Items = [
    { name: "Adekola Samson" },
    { name: "Adekola Samson" },
    { name: "Adekola Samson" },
    { name: "Adekola Samson" },
    { name: "Adekola Samson" },
    { name: "Adekola Samson" },
  ];

  useEffect(() => {
    const scrollContainer: HTMLElement | null =
      document.querySelector(".scroll-container");

    function autoScroll(): void {
      if (scrollContainer) {
        scrollContainer.scrollLeft += 70;

        if (
          scrollContainer.scrollLeft + scrollContainer.offsetWidth >=
          scrollContainer.scrollWidth
        ) {
          scrollContainer.scrollLeft = 0;
        }
      }
    }

    setInterval(autoScroll, 1700);
  }, []);

  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollableDivRef.current) {
      if (scrollableDivRef.current.scrollLeft > 200) {
        scrollableDivRef.current.scrollLeft -= 200;
      } else {
        scrollableDivRef.current.scrollLeft = 0;
      }
    }
  };

  const scrollRight = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollLeft += 200;
    }
  };

  const fetchEvents = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get(`admin/events/all`);

      if (Array.isArray(res.data)) {
        setParentEvent(res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getRemainingEvents = (): any => {
    return parentEvent.slice(1);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <div className="flex flex-col gap-[170px] bg-[#e0f2f03a] py-3 pb-16 px-4">
      <div className="pt-[60px] gap-8 flex flex-col items-center justify-center text-center">
        <div>
          <p className="text-[40px] font-black">Upcoming Events</p>
        </div>
        <div className="flex flex-col gap-1 w-[65%] lg:w-[50%] text-[14px] lg:text-[16px] Axiforma">
          <p>
            New events are being added all the time, check back here for updates
          </p>
          <p>
            Enroll to reserve your place at any forthcoming hiring occasion or
            stream live and pre-recorded webinars for the newest insights into
            technology recruitment patterns.
          </p>
        </div>
      </div>

      <div className="flex flex-col items-center gap-8">
        {parentEvent[0] && (
          <div className="w-full sm:w-[95%] flex flex-col-reverse md:flex-row rounded-[8px] shadow shadow-slate-300 md:h-[411px] bg-white">
            <div className="min-w-[320px] lg:min-w-[366px] flex flex-col gap-10 md:gap-0 justify-around p-[20px] xs:p-[33px]">
              <div className="border rounded-[15px] text-[#4f4f4f] p-1 w-fit">
                <p className="text-[10px] Axiforma">
                  {parentEvent[0].eventType}
                </p>
              </div>

              <div className="text-[14px] text-[#58636D]">
                <div className="flex gap-2 font-semibold items-center">
                  <p>{parentEvent[0].eventDate?.split("T")[0]}</p>
                  <div className="bg-gray-400 min-w-[8px] w-2 h-2 rounded-full"></div>
                  <p> {moment(parentEvent[0].eventDate)?.format("hh:mm a")} </p>
                </div>
              </div>

              <div className="text-[22px] text-[#4f4f4f] font-semibold">
                <p>{parentEvent[0].eventName}</p>
              </div>

              <div>
                <button className="text-white bg-[#0675ce] w-[150px] h-[43px] font-semibold text-sm rounded-md">
                  See Details
                </button>
              </div>
            </div>
            <div className="bg-gray-50 w-full h-[390px] rounded-r-[10px] md:h-full opacity-75">
              <img
                src={parentEvent[0].eventImage}
                className={`w-full h-[390px] rounded-r-[10px] md:h-full opacity-75`}
                alt=""
              />
            </div>
          </div>
        )}

        <div className="w-full">
          {selectedItem && (
            <div className="flex justify-center">
              <div className="w-full sm:w-[95%] flex flex-col-reverse md:flex-row rounded-[8px] shadow shadow-slate-300 md:h-[411px] bg-white">
                <div className="min-w-[320px] lg:min-w-[366px] flex flex-col gap-10 md:gap-0 justify-around p-[20px] xs:p-[33px]">
                  <div className="border rounded-[15px] text-[#4f4f4f] p-1 w-fit">
                    <p className="text-[10px] Axiforma">
                      {selectedItem.eventType}
                    </p>
                  </div>

                  <div className="text-[14px] text-[#58636D]">
                    {/* <p>{selectedItem.eventDate}</p> */}
                    <div className="flex gap-2 font-semibold items-center">
                  <p>{selectedItem.eventDate?.split("T")[0]}</p>
                  <div className="bg-gray-400 min-w-[8px] w-2 h-2 rounded-full"></div>
                  <p> {moment(selectedItem.eventDate)?.format("hh:mm a")} </p>
                </div>
                  </div>

                  <div className="text-[22px] text-[#4f4f4f] font-semibold">
                    <p>{selectedItem.eventName}</p>
                  </div>

                  <div>
                    <button className="text-white bg-[#0675ce] w-[150px] h-[43px] font-semibold text-sm rounded-md">
                      See Details
                    </button>
                  </div>
                </div>
                <div
                  className={`bg-gray-50 w-full h-[390px] rounded-r-[10px] md:h-full opacity-75 ${selectedItem.eventImage}`}
                >
                  <img
                    src={selectedItem.eventImage}
                    className={`w-full h-[390px] rounded-r-[10px] md:h-full opacity-75`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="w-fit flex justify-center gap-3">
          {getRemainingEvents().map((item: any) => (
            <div key={item.id} className="w-full flex items-center">
              <div
                onClick={() => handleItemClick(item)}
                className={`rounded-sm ${
                  selectedItem && selectedItem.id === item.id
                    ? "h-[6px] w-3 bg-gray-400 cursor-pointer" // CSS class for active state
                    : "h-[8px] w-2 bg-gray-200 cursor-pointer" // CSS class for null state
                }`}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-20">
        <div className="flex flex-col gap-12">
          <div className="flex justify-center">
            <p className="text-[28px] font-bold">Why Choose Us</p>
          </div>

          <div className="flex flex-col gap-7 items-center">
            <div className="flex flex-col md:flex-row gap-5 px-5">
              <div className="p-[40px] w-[377px] md:w-[350px] lg:w-[377px] h-[280px] bg-white shadow flex flex-col rounded-[5px] justify-around">
                <div className="w-14 h-14 bg-red-50 rounded-[30px]" />
                <div>
                  <p className="font-semibold text-[22px]">Hire Fast</p>
                </div>
                <div>
                  <p>
                    Fill multiole roles fron a single event and cut down your
                    hiring lead times
                  </p>
                </div>
              </div>

              <div className="p-[40px] w-[377px] md:w-[350px] lg:w-[377px] h-[280px] bg-white shadow flex flex-col rounded-[5px] gap-5">
                <div className="w-14 h-14 bg-red-50 rounded-[30px]" />
                <div>
                  <p className="font-semibold text-[22px]">Hire Fast</p>
                </div>
                <div>
                  <p>
                    Fill multiole roles fron a single event and cut down your
                    hiring lead times
                  </p>
                </div>
              </div>
            </div>

            <div className="p-[40px] w-[377px] md:w-[350px] lg:w-[377px] h-[280px] bg-white shadow flex flex-col rounded-[5px] gap-5">
              <div className="w-14 h-14 bg-red-50 rounded-[30px]" />
              <div>
                <p className="font-semibold text-[22px]">Hire Fast</p>
              </div>
              <div>
                <p>
                  Fill multiple roles fron a single event and cut down your
                  hiring lead times
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex gap-3">
            <button
              className=" px-3 py-1 bg-gray-200 rounded-l-md"
              onClick={scrollLeft}
            >
              {"<"}
            </button>
            <button
              className=" px-3 py-1 bg-gray-200 rounded-r-md"
              onClick={scrollRight}
            >
              {">"}
            </button>
          </div>

          <div
            id="scroll-image"
            ref={scrollableDivRef}
            className="flex gap-5 scroll-containers Axiforma text-[14px]"
          >
            {Items.map((item) => (
              <div className="h-[415px] ">
                <div className="bg-white w-[307px] h-[380px] p-[25px] relative rounded-[10px] shadow shadow-gray-300">
                  <div className="flex flex-col gap-16 items-center text-center">
                    <p className="leading-8">
                      "I've gone from interviewing a small number of people and
                      thinking that it's maybe not quite a fit to interviewing a
                      ton of people and having the ability to pick and choose
                      from an amazing pool of candidates."
                    </p>

                    <p className="font-semibold text-[17px]">{item.name}</p>
                  </div>

                  <div className="w-full flex justify-center absolute left-0 bottom-[-35px]">
                    <div className="w-16 h-16 rounded-[35px] bg-slate-300 "></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventPage;
