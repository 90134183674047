import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/Auth/login";
import SignUp from "./pages/Auth/signUp";
import ForgetPassword from "./pages/Auth/forgetPassword";
import ResetPassword from "./pages/Auth/resetPassword";
import PremiumPage from "./pages/Auth/premium";
import ProfileLayout from "./pages/Profile/employerProfile";
import UserProfile from "./pages/Profile/userProfile";
import ExploreJobs from "./pages/explore-jobs";
import Editors from "./pages/categories/editors/editors";
import Motn_graphics from "./pages/categories/motn_grapgics/motn_graphics";
import Still_graphics from "./pages/categories/still_graphics/still_graphic";
import Cinematography from "./pages/categories/cinematography/cinematography";
import Visual_Effect from "./pages/categories/visual_effect/visual_effect";
import Voice_Over from "./pages/categories/voice_over/Voice_over";
import Colourist from "./pages/categories/colourist/colourist";
import Animatorss from "./pages/categories/animators/animatorss";
import Scored_AudioMix from "./pages/categories/Scored_AudioMix/scored&audio_mix";
import Sound_designn from "./pages/categories/sound_designs/sound_design";
import Settings from "./pages/settings";
import Job_description_xs from "./pages/explore-jobs/jobDescription_xs";
import Community from "./pages/community";
import UserType from "./pages/Auth/userType";
import Verification from "./pages/Auth/verification";
import Congratulation from "./pages/Auth/congratulation";
import SecuritySettings from "./pages/settings/securitySettings";
import Referals from "./pages/additional/referals/referals";
import Tips_Guides from "./pages/community/Tips_Guides";
import Follows from "./pages/Profile/follow";
import GeneralWorkspace from "./pages/workSpace/GeneralWorkspace";
import LoggedIn_Homepage from "./pages/loggedIn-Homepage";
import Premium_Membership from "./pages/additional/premium-membership";
import IndividualMovie from "./pages/community/individualMovies";
import Event from "./pages/event";
import TalentPool from "./pages/talent-pool";
import Skills from "./pages/Auth/onBoarding/skills";
import Awards from "./pages/Auth/onBoarding/awards";
import Past_employment from "./pages/Auth/onBoarding/past_employment";
import ChooseType from "./pages/Auth/chooseType";
import UploadJobs from "./pages/Auth/uploadJobs";
import JobOffers from "./pages/job_offers";
import CreateJobs from "./pages/create-jobs";
import BecomeEmployer from "./pages/Profile/employerProfile/becomeAnEmployer/becomeEmployer";
import CompanyOption from "./pages/create-jobs/category/company/index";
import IndividualOption from "./pages/create-jobs/category/individual/index";
import OnBoard from "./pages/Auth/onBoarding/onBoard";
import Experience from "./pages/Profile/experience";
import Skill from "./pages/Profile/skills";
import Award from "./pages/Profile/award";
import JobDescriXsComponent from "./pages/explore-jobs/jobDescriXsComponent";
import Help_Support from "./pages/additional/help-support";
import IsLoading from "./pages/additional/isLoading";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/Dashboard" element={<LoggedIn_Homepage />} /> */}
        <Route path="/Dashboard" element={<UserProfile />} />
        <Route path="/Login" element={<LoginPage />} />
        <Route path="/Signup" element={<SignUp />} />
        <Route path="/Create-Jobs" element={<CreateJobs />} />
        <Route path="/Job-offers" element={<JobOffers />} />
        <Route path="/BeAnEmployer" element={<BecomeEmployer />} />     
        <Route path="/Verification-Code" element={<Verification />} />
        <Route path="/Verified" element={<Congratulation />} />
        <Route path="/User-Type" element={<UserType />} />
        <Route path="/ChooseType" element={<ChooseType />} />
        <Route path="/Onboard" element={<OnBoard />} />
        <Route path="/Skills" element={<Skills />} />
        <Route path="/Awards" element={<Awards />} />
        <Route path="/PastEmployment" element={<Past_employment />} />
        <Route path="/UploadJobs" element={<UploadJobs />} />
        <Route path="/Forgot-Password" element={<ForgetPassword />} />
        <Route path="/Reset-Password" element={<ResetPassword />} />
        <Route path="/Premium-Payment" element={<PremiumPage />} />
        <Route path="/Premium" element={<Premium_Membership />} />
        <Route path="/Events" element={<Event />} />
        <Route path="/Talent-Pool" element={<TalentPool />} />
        <Route path="/Employer-Profile" element={<ProfileLayout />} />
        <Route path="/Follow" element={<Follows />} />
        <Route path="/Explore-Jobs" element={<ExploreJobs />} />
        <Route path="/Editors" element={<Editors />} />
        <Route path="/Motion-graphics" element={<Motn_graphics />} />
        <Route path="/Animators" element={<Animatorss />} />
        <Route path="/Still-graphics" element={<Still_graphics />} />
        <Route path="/Scores-Audio" element={<Scored_AudioMix />} />
        <Route path="/Cinematography" element={<Cinematography />} />
        <Route path="/Visual_effect" element={<Visual_Effect />} />
        <Route path="/Voice-over" element={<Voice_Over />} />
        <Route path="/Colourist" element={<Colourist />} />
        <Route path="/Sound-designn" element={<Sound_designn />} />
        <Route path="/Account-settings" element={<Settings />} />
        <Route path="/Security-settings" element={<SecuritySettings />} />
        <Route path="/Job-Details" element={<JobDescriXsComponent />} />
        <Route path="/Community" element={<Community />} />
        <Route path="/Tips&Guides" element={<Tips_Guides />} />
        <Route path="/Refer-A-friend" element={<Referals />} />
        <Route path="/WorkSpace" element={<GeneralWorkspace />} />
        <Route path="/Movies" element={<IndividualMovie />} />
        <Route path="/Company-job" element={<CompanyOption />} />
        <Route path="/Individual-job" element={<IndividualOption />} />
        <Route path="/Experience-Page" element={<Experience />} />        
        <Route path="/Skills-Page" element={<Skill />} />
        <Route path="/Award-Page" element={<Award />} />
        <Route path="/Help-and-Support" element={<Help_Support />} />
        <Route path="/Is+Loading" element={<IsLoading />} />
      </Routes>
    </Router>
  );
}

export default App;
