import React, { useState } from "react";
import illustration from "../../assets/img/Illustration.png";
import { useNavigate } from "react-router-dom";
import CloseBtn from "../CloseButton";

const LogggedOutState = () => {
  // const [showInterface, setShowInterface] = useState(false);
  const navigate = useNavigate();

  return (  
      <div className="h-[73vh] flex flex-col gap-5 justify-center items-center w-full">
        <img className="h-60 xs:h-72" src={illustration} alt="/" />
        <div className="flex flex-col items-center text-center">
          <p className="text-[26px]">Can't load interface</p>
          <p className="Lato-Italics mb-1">Kindly log in...</p>
          <button
            onClick={() => {
              navigate("/Login");
            }}
            className="hover:bg-[#F17300] hover:font-semibold hover:text-white shadow shadow-[#f17400b4] rounded-md w-[80px] py-[2px]"
          >
            Log In
          </button>
        </div>
      </div>
  );
};

export default LogggedOutState;
