import React from "react";
import { NotifUpdates } from "../../utils/data";

const Posts = () => {
  return (
    <div className="w-full flex flex-col h-[75vh] overflow-y-scroll">
      <div className="px-2">
        {NotifUpdates.map((items) => (
          <div className="border-y text-[13px] py-5 flex flex-col gap-1">
            <p>
              <span className="font-semibold cursor-pointer hover:underline">
                {items.name}
              </span>{" "}
              has 10 communities available for you. View all Post City
              Communities
            </p>

            <div className="Axiforma-light text-[10px]">
              <p>Posted 30 mins ago</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Posts;
