import React, { useEffect, useState } from "react";
import edit from "../../../../assets/icons/edit.svg";
import check from "../../../../assets/icons/feCheck1.svg";
import SkillsPopup from "./skillsPopup";
import { toast, ToastContainer } from "react-toastify";
import AxiosInstance from "../../../../api/axios";

type SkillsProps = {
  parentSkill?: any;
};

const Skills: React.FC<SkillsProps> = ({ parentSkill }) => {
  const [showPopup, setShowPopup] = useState(false);

  const User_id = localStorage.getItem("ids");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState<any | null>(null);

  
  const [parentSkills, setParentSkills] = useState([] as any);

  const fetchSkills = async () => {
    try {
      const res = await AxiosInstance.get(`/user/skills/${User_id}`);
      if (Array.isArray(res.data)) {
        setParentSkills(res.data);
      } else {
        console.error("Unexpected response format:", res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteSkills = (i: number, item: any) => {
    setShowDeleteModal(true)
    setSelectedSkills({item, i})
  };

  const handleDeleteSkillContent = async (selectedSkills: any) => {
    setIsLoading(true);

    try {
      const response = await AxiosInstance.delete(
        `user/skills/${User_id}`,
        { data: { skillIndex: selectedSkills.i } }
      );
      toast.success("skill deleted", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShowDeleteModal(false);
      fetchSkills();
    } catch (error) {
      toast.error("error deleting skill", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }

  };

  useEffect(() => {
    fetchSkills();
  }, []);

  const handleClose = () => {
    setShowPopup(false)
    fetchSkills()
  }

  return (
    <>
      <div className="flex flex-col gap-[25px] py-7 shadow shadow-[#f174005f] p-3 text-[13px] md:text-[14px] rounded-[8px]">
        <div className="flex justify-between">
          <p className="font-semibold text-[16px]">Skills</p>

          <img
            onClick={() => setShowPopup(true)}
            src={edit}
            className="cursor-pointer"
            alt="/"
          />
        </div>

        <div>
          <>
            {parentSkills.map((item: any) => (
              <div>
                <div className="flex justify-between">
                  <div className="flex items-start gap-1">
                    -<p className="text-[14px]">{item.skills}</p>{" "}
                  </div>
                  <p className="text-[10px]">{item.experience} yr(s)</p>
                </div>
              </div>
            ))}
          </>
        </div>
      </div>
      {showPopup && (
        <>
          <div className="drop-shadow-lg w-full xs:w-[95%] sm:w-[85%] md:w-[65%] lg:w-[50%] fixed m-auto top-0 xs:top-28 bottom-0 right-0 left-0 z-[999] ">
            <SkillsPopup
              onClose={handleClose}
              parentSkill={parentSkill}
            />
          </div>
          <ToastContainer />
          <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
        </>
      )}
    </>
  );
};

export default Skills;
