import React from "react";
import LoggedIn_Layout from "../../../../comonent/loggedIn_Layout";
import CompanyOptionsFirstPage from "./companyOptionsFirstPage";

const CompanyOption = () => {
  return (
    <LoggedIn_Layout>
      <CompanyOptionsFirstPage />
    </LoggedIn_Layout>
  );
};

export default CompanyOption;
