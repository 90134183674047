import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import deleteMem from "../../../assets/icons/icons8-delete-60.png";
import CloseBtn from "../../../comonent/CloseButton";
import DeleteComponent from "../../../comonent/DeleteComponent";

type ExperienceFormType = {
  company: string;
  role: string;
  employmentType: string;
  start_date: string;
  end_date: string;
};

type ExperienceType =
  | "company"
  | "role"
  | "employmentType"
  | "start_date"
  | "end_date";

const ExperiencePage = () => {
  const [parentList, setParentList] = useState([] as any);
  const User_id = localStorage.getItem("ids");

  const EMPTY_FORM = {
    company: "",
    role: "",
    employmentType: "",
    start_date: "",
    end_date: "",
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState<any | null>(
    null
  );

  const [formData, setFormData] = useState([
    { ...EMPTY_FORM },
  ] as ExperienceFormType[]);

  const handleAddEmp = () => {
    setFormData([...formData, EMPTY_FORM]);
  };

  const handleDelete = (i: number) => {
    const filteredAward = formData.filter((pastExp, index) => index !== i);
    setFormData([...filteredAward]);
  };

  const handleChange = (e: any, i: number, field: ExperienceType) => {
    formData[i][field] = e.target.value;
    setFormData([...formData]);
  };

  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    try {
      const arrayData = Array.from(formData);
      const res = await AxiosInstance.patch(`/user/experiences/${User_id}`, {
        experience: arrayData,
      });
      toast.success("new experience created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormData([EMPTY_FORM])
      fetchData();
    } catch (error) {
      console.error(error);
      toast.error("error creating experience", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const res = await AxiosInstance.get(`/user/experiences/${User_id}`);
      if (Array.isArray(res.data)) {
        setParentList(res.data);
      } else {
        console.error("Unexpected response format:", res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteModal = async (i: number, item: any) => {
    setShowDeleteModal(true);
    setSelectedExperience({ item, i });
  };

  const handleDeleteExperience = async (selectedExperience: any) => {
    setIsLoading(true);

    try {
      const response = await AxiosInstance.delete(
        `user/experiences/${User_id}`,
        { data: { experienceIndex: selectedExperience.i } }
      );
      toast.success("experience deleted", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShowDeleteModal(false);
      fetchData();
    } catch (error) {
      toast.error("error deleting experience", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const validate =
    !formData[0].company ||
    !formData[0].employmentType ||
    !formData[0].end_date ||
    !formData[0].role ||
    !formData[0].start_date;

  return (
    <>
      <div className="p-[10px] pb-[40px]">
        <div className="flex justify-center text-orange-400">
          <p className="font-semibold text-[28px] underline">Experience</p>
        </div>

        <div className="mt-3">
          <>
            {parentList.map((item: any, i: number) => (
              <div>
                <div className="flex justify-between">
                  <div className="flex items-center gap-1">
                    -<p className="text-[20px]">{item.company}</p>{" "}
                    <p className="Lato-Light text-[12px]">{item.role}</p>
                  </div>
                  <div className="flex gap-1 items-center">
                    <div className="flex text-[12px] font-semibold items-center xl:text-[12px] gap-[4px] xl:gap-[6px]">
                      <p>{item.start_date}</p>-<p>{item.end_date}</p>
                      <p>[{item.pastEmployment}]</p>
                    </div>
                    <div>
                      <img
                        src={deleteMem}
                        onClick={() => handleDeleteModal(i, item)}
                        className="h-4 w-4 cursor-pointer"
                        alt="/"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        </div>

        <div className="w-[90%] flex flex-col gap-2 pt-[40px]">
          {formData.map(
            ({ company, role, employmentType, end_date, start_date }, i) => (
              <>
                <div className="flex flex-col gap-7">
                  <div className="w-full">
                    <div className="flex flex-col gap-2 w-[100%]">
                      <p className="text-[14px] font-semibold">
                        Company || Product
                      </p>
                      <div>
                        <input
                          className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                          type="text"
                          name="company"
                          value={company}
                          onChange={(e) => handleChange(e, i, "company")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex gap-4">
                    <div className="flex flex-col gap-2 w-[50%]">
                      <p className="text-[14px] font-semibold">Role</p>
                      <div>
                        <input
                          className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                          type="text"
                          name="role"
                          value={role}
                          onChange={(e) => handleChange(e, i, "role")}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 w-[50%]">
                      <p className="text-[14px] font-semibold">
                        employment type
                      </p>
                      <div>
                        <input
                          className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                          type="text"
                          name="employmentType"
                          value={employmentType}
                          onChange={(e) => handleChange(e, i, "employmentType")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex gap-4">
                    <div className="flex flex-col gap-2 w-[50%]">
                      <p className="text-[14px] font-semibold">start date</p>
                      <div>
                        <input
                          className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                          type="month"
                          name="start_date"
                          value={start_date}
                          onChange={(e) => handleChange(e, i, "start_date")}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 w-[50%]">
                      <p className="text-[14px] font-semibold">end date</p>
                      <div>
                        <input
                          className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                          type="month"
                          name="end_date"
                          value={end_date}
                          onChange={(e) => handleChange(e, i, "end_date")}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end">
                  {formData.length > 1 && (
                    <button
                      onClick={() => handleDelete?.(i)}
                      className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                    >
                      - Remove
                    </button>
                  )}
                </div>
              </>
            )
          )}
        </div>

        <div className="mt-5 flex justify-start w-full px-4">
          <p
            onClick={handleAddEmp}
            className="font-semibold text-orange-400 cursor-pointer w-fit"
          >
            + Add experience
          </p>
        </div>

        <div className="mt-7 flex flex-col items-end w-full gap-3">
          <button
            onClick={handleSubmit}
            disabled={validate}
            className={`flex shadow w-[260px] shadow-[#F17300] ${
              !validate ? "bg-[#F17300] text-white" : ""
            } h-[38px] font-semibold justify-center items-center rounded-[5px]`}
          >
            {isSubmitLoading ? "Loading..." : "Continue"}
          </button>
        </div>
      </div>
      {showDeleteModal && (
        <>
          <div className=" fixed top-9 left-0 w-full h-full z-[999] flex items-center justify-center">
            <DeleteComponent
              onClose={() => setShowDeleteModal(false)}
              componentItems={selectedExperience}
              handleRemove={() => handleDeleteExperience(selectedExperience)}
            />
          </div>
          <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
        </>
      )}
      <ToastContainer />
    </>
  );
};

export default ExperiencePage;
