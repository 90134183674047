import React from "react";
import backArrow from "../../assets/icons/Frame (3).svg";

type showLessProps = {
  onShowLessClick: () => void;
};

const More_projects: React.FC<showLessProps> = ({ onShowLessClick }) => {
  return (
    <div className="w-full">
      <div className="mb-5">
        <div
          onClick={onShowLessClick}
          className="bg-[#f174005f] flex justify-center font-semibold text-[14px] items-center text-red-500 w-6 h-6 rounded-[30px]"
        >
          X
        </div>
      </div>
      <div className="grid grid-cols-2 xs:grid-cols-3 gap-3 pb-10">
        <div className="h-[244px] rounded-[8px] shadow w-full bg-orange-100"></div>
        <div className="h-[244px] rounded-[8px] shadow w-full bg-orange-100"></div>
        <div className="h-[244px] rounded-[8px] shadow w-full bg-orange-100"></div>
        <div className="h-[244px] rounded-[8px] shadow w-full bg-orange-100"></div>
        <div className="h-[244px] rounded-[8px] shadow w-full bg-orange-100"></div>
        <div className="h-[244px] rounded-[8px] shadow w-full bg-orange-100"></div>
        <div className="h-[244px] rounded-[8px] shadow w-full bg-orange-100"></div>
      </div>
    </div>
  );
};

export default More_projects;
