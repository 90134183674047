import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import AxiosInstance from "../../api/axios";
import { toast } from "react-toastify";

type uploadJobsProps = {
  onclose?: () => void;
  getRequest?: () => void;
};

const UploadJobsPopup: React.FC<uploadJobsProps> = ({
  onclose,
  getRequest,
}) => {
  const [previewUploadedJob, setPreviewUploadedJob] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const User_id = localStorage.getItem("ids");

  const [selectedUploadedJob, setSelectedUploadedJob] = useState<File[]>([]);

  const [selectedJob, setSelectedJob] = useState("");

  const handleChangeUploadedJobs = (e: any) => {
    setSelectedJob(e.target.files[0]);

    const newImages = e.target.files || [];
    setSelectedUploadedJob([...selectedUploadedJob, ...newImages]);

    if (newImages.length > 0 && previewUploadedJob === null) {
      const reader = new FileReader();
      reader.onload = (e) =>
        setPreviewUploadedJob((e.target as any).result as string);
      reader.readAsDataURL(newImages[0]);
    }
  };

  const postJob = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("video", selectedJob);

    try {
      AxiosInstance.post(`/user/uploadedProjects/${User_id}`, formData)
        .then((response) => {
          if (response.status === 200) {
            toast.success(`Project uploaded`);
            setSelectedJob("");
            if (onclose) {
              onclose();
            }
            if (getRequest) {
              getRequest();
            }
          }
        })
        .catch((error) => {
          toast.error(error.response);
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const validate = !selectedJob;

  return (
    <div className="w-[500px] bg-white rounded-[5px] drop-shadow-lg">
      <div className="h-14 flex items-center">
        <p className="font-semibold text-[22px] px-5 w-full">Upload Project</p>
      </div>

      <div className="flex justify-center items-center my-5">
        <div className=" w-[80%] h-[250px] shadow shadow-[#f174005f] flex justify-center items-center rounded-[8px]">
          {previewUploadedJob ? (
            <video
              src={previewUploadedJob}
              controls
              className="w-full h-full object-cover rounded-md"
            />
          ) : (
            <>
              <label>
                <p className="flex justify-center items-center text-[38px] cursor-pointer font-black bg-[#f174005f] text-white w-[60px] rounded-[40px]">
                  +
                </p>
                <input
                  name="displayJobs"
                  id="displayJobs"
                  type="file"
                  accept="video/*"
                  onChange={handleChangeUploadedJobs}
                  className="hidden"
                />
              </label>
            </>
          )}
        </div>
      </div>

      <div className="h-14 flex items-center">
        <div className="flex justify-end w-full">
          <button onClick={onclose} className="font-semibold">
            Cancel
          </button>
          <button
            className={`flex shadow w-[110px] shadow-[#F17300] ${
              !validate ? "bg-[#F17300] text-white" : ""
            } h-[38px] font-semibold justify-center items-center rounded-[5px]`}
            onClick={postJob}
            disabled={validate}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadJobsPopup;
