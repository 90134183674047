import React from 'react'
import LoggedIn_Layout from '../../comonent/loggedIn_Layout'
import JobsOfferPage from './jobsOfferPage'

const JobOffers = () => {
  return (
    <LoggedIn_Layout>
        <JobsOfferPage/>
    </LoggedIn_Layout>
  )
}

export default JobOffers