import React, { useEffect, useState } from "react";
import arrow from "../../../assets/icons/icons8-left-24.png";
import Stepper from "../../../comonent/stepper/stepper";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/icons/postcine_logo.png";
import AxiosInstance from "../../../api/axios";
import { toast } from "react-toastify";

type ExperienceFormType = {
  company: string;
  role: string;
  employmentType: string;
  start_date: string;
  end_date: string;
};

type ExperienceType =
  | "company"
  | "role"
  | "employmentType"
  | "start_date"
  | "end_date";

const Past_employment = () => {
  const User_id = localStorage.getItem("ids");
  const EMPTY_FORM = {
    company: "",
    role: "",
    employmentType: "",
    start_date: "",
    end_date: "",
  };

  const data = [
    { name: "Add skills", url: "Skills" },
    { name: "Add awards", url: "Awards" },
    { name: "Add experience", url: "PastEmployment" },
  ];

  const { pathname } = useLocation();
  const [isLastPage, setIsLastPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState([] as any);

  useEffect(() => {
    const path = pathname.slice(
      pathname.lastIndexOf("/add_your_team") + 1,
      pathname.length
    );

    const totalPages = data ? data.length - 1 : 0;
    const isFirstPage = data
      ? data.findIndex((element) => element.url === path)
      : -1;
    setIsFirstPage(isFirstPage === 0);
    setIsLastPage(data && path === data[totalPages]?.url);
  }, [pathname, data]);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState([
    { ...EMPTY_FORM },
  ] as ExperienceFormType[]);

  const handleAddEmp = () => {
    setFormData([...formData, EMPTY_FORM]);
  };

  const handleDelete = (i: number) => {
    const filteredAward = formData.filter((pastExp, index) => index !== i);
    setFormData([...filteredAward]);
  };

  const handleChange = (e: any, i: number, field: ExperienceType) => {
    formData[i][field] = e.target.value;
    setFormData([...formData]);
  };

  const previous = () => {
    navigate("/Awards");
  };

  const next = () => {
    // navigate("/uploadJobs");
    navigate("/Dashboard");
    
  };


  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const arrayData = Array.from(formData);
      const res = await AxiosInstance.patch(`/user/experiences/${User_id}`, {
        experience: arrayData,
      });
      toast.success("new experience created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      next();
    } catch (error) {
      console.error(error);
      toast.error("error creating experience", {
        position: toast.POSITION.TOP_RIGHT,
      });
      
    }
  };


  const validate = (formData: any, index: number): boolean => {

    if (!formData.length) {
      return false;
    }
  
    const firstExperience = formData[index];
    return (
      firstExperience.company &&
      firstExperience.employmentType &&
      firstExperience.end_date &&
      firstExperience.role &&
      firstExperience.start_date
    );
  };


  // const validate =
  //   !formData[0].company ||
  //   !formData[0].employmentType ||
  //   !formData[0].end_date ||
  //   !formData[0].role ||
  //   !formData[0].start_date;

  return (
    <div>
      <div className="border-b h-[70px] w-full flex items-center px-5">
        <div className="">
          <img src={logo} className="h-12 w-14" alt="" />
        </div>
      </div>

      <div className="flex justify-center p-6">
        <div className="w-[95%] xs:w-[85%] sm:w-[75%]">
          <div>
            <p className="text-[28px] xs:text-[38px] font-semibold">
              Add experience
            </p>
            <p className="text-[14px] xs:text-[16px]">
              Kindly past experience along with duration{" "}
            </p>
          </div>

          <div className="flex flex-col gap-2 pt-[40px]">
            {formData.map(
              ({ company, role, employmentType, end_date, start_date }, i) => (
                <>
                  <div className="flex flex-col gap-7">
                    <div className="w-full">
                      <div className="flex flex-col gap-2 w-[100%]">
                        <p className="text-[14px] font-semibold">
                          Company || Product
                        </p>
                        <div>
                          <input
                            className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                            type="text"
                            name="company"
                            value={company}
                            onChange={(e) => handleChange(e, i, "company")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-full flex gap-4">
                      <div className="flex flex-col gap-2 w-[50%]">
                        <p className="text-[14px] font-semibold">Role</p>
                        <div>
                          <input
                            className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                            type="text"
                            name="role"
                            value={role}
                            onChange={(e) => handleChange(e, i, "role")}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col gap-2 w-[50%]">
                        <p className="text-[14px] font-semibold">
                          employment type
                        </p>
                        <div>
                          <input
                            className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                            type="text"
                            name="employmentType"
                            value={employmentType}
                            onChange={(e) =>
                              handleChange(e, i, "employmentType")
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-full flex gap-4">
                      <div className="flex flex-col gap-2 w-[50%]">
                        <p className="text-[14px] font-semibold">start date</p>
                        <div>
                          <input
                            className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                            type="month"
                            name="start_date"
                            value={start_date}
                            onChange={(e) => handleChange(e, i, "start_date")}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col gap-2 w-[50%]">
                        <p className="text-[14px] font-semibold">end date</p>
                        <div>
                          <input
                            className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                            type="month"
                            name="end_date"
                            value={end_date}
                            onChange={(e) => handleChange(e, i, "end_date")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end">
                    {formData.length > 1 && (
                      <button
                        onClick={() => handleDelete?.(i)}
                        className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                      >
                        - Remove
                      </button>
                    )}
                  </div>
                </>
              )
            )}
          </div>

          <div className="mt-5">
            <p
              onClick={handleAddEmp}
              className="font-semibold text-orange-400 cursor-pointer w-fit"
            >
              + Add experience
            </p>
          </div>

          <div className="mt-7 flex flex-col items-center w-fit gap-3">
            <button
              onClick={handleSubmit}
              disabled={!validate(formData, formData.length - 1)}
              className={`flex shadow w-[260px] shadow-[#F17300] ${
                validate(formData, formData.length - 1) ? "bg-[#F17300] text-white" : ""
              } h-[38px] font-semibold justify-center items-center rounded-[5px]`}
            >
              {isLoading ? "Loading..." : "Continue"}
            </button>

            <div>
              <p
                onClick={previous}
                className="font-semibold cursor-pointer hover:text-orange-400"
              >
                Previous
              </p>
            </div>
          </div>
        </div>

        <div className="flex text-[12px] sm:text-[14px] items-center justify-center ">
          <div>
            <Stepper pages={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Past_employment;
