import React, { useState } from "react";
//
import rightdir from "../../assets/icons/right_dir.svg";
import leftdir from "../../assets/icons/left_dir.svg";
import user1 from "../../assets/img/user.png";
import user2 from "../../assets/img/Frame 483 (3).png";
import user3 from "../../assets/img/Frame 483 (1).png";

interface Image {
  url: any;
  text: string;
}

const EmployeeSlide: React.FC = () => {
  const images: Image[] = [
    {
      url: user1,
      text: "An editor with maximum of eight years experience needed now.",
    },
    {
      url: user2,
      text: "A cinematographist with experience you need.",
    },
    {
      url: user3,
      text: "I am free for an amination gig",
    },
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  const goToPrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex Axiforma justify-center items-center">
        <div className="border">
          <div className="flex w-full h-[77px] border-b">
            <div className="text-[24px] font-black w-full border-r flex justify-center items-center">
              <p>ORACLE</p>
            </div>

            <div className="w-full flex justify-center items-center text-[9px]">
              <p className="text-center w-[80%] text-[8px]">
                Oracle is a post-production company that deals with every
                professional fields.
              </p>
            </div>
          </div>

          <div className="flex w-full">
            {/* <div className="w-full border-r User_img" /> */}
            <div className="w-full p-[1px]">
              <img
                className="w-full border-r h-full"
                src={images[currentImageIndex].url}
                alt={`Image ${currentImageIndex + 1}`}
              />
            </div>

            <div className="flex w-full flex-col justify-between mt-[30px]">
              <div className="w-full text-[9px] flex items-center justify-center">
                <div className="w-[85%] flex flex-col gap-[25px]">
                  <div className="flex flex-col gap-[5px]">
                    <p className="font-semibold">JOB DESCRIPTION</p>

                    <p>{images[currentImageIndex].text}</p>
                  </div>

                  <div className="flex flex-col gap-[5px]">
                    <p className="font-semibold">JOB REQUIREMENT</p>

                    <div className="flex flex-col gap-1">
                      <p>male</p>
                      <p>can use davinci resolve</p>
                      <p>on time delivery</p>
                      <p>work from home</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border border-y text-[11px] flex h-[40px] items-center">
                <div className="w-[65%] border-r flex justify-center">
                  <p>$100 - $200</p>
                </div>

                <div className="w-[35%] flex justify-center">
                  <p>BID</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full border-x border-b justify-between items-center py-2 p-1">
        <button className="bg-gray-300 w-[25px] h-[25px] rounded-[30px] flex justify-center items-center" onClick={goToPrevious} >
          <img className="h-3" src={rightdir} />
        </button>

        <button className="bg-gray-300 w-[25px] h-[25px] rounded-[30px] flex justify-center items-center" onClick={goToNext}>
          <img className="h-3" src={leftdir} />
        </button>
      </div>
    </div>
  );
};

export default EmployeeSlide;
