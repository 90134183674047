import React from "react";

type CloseBtnProps = {
  onClose: () => void;
};

const CloseBtn: React.FC<CloseBtnProps> = ({
  onClose,
}) => {
  return (
    <div className="flex justify-end pt-5 pr-3">
      <div
        onClick={onClose}
        className="bg-[#f174005f] cursor-pointer flex justify-center font-semibold text-[14px] items-center text-red-500 w-6 h-6 rounded-[30px]"
      >
        X
      </div>
    </div>
  );
};

export default CloseBtn;
