import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import google from "../../assets/icons/Google svg.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setAccessToken } from "../../api/axios";
import eye from "../../assets/icons/eye.png";

type PopupProps = {
  ActivePopup: () => void;
  TogglePop?: any;
};

const Login_Pop: React.FC<PopupProps> = ({ ActivePopup, TogglePop }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();

  const validate = !email || !password;

  const handleLoginn = () => {
    setIsLoading(true);
    const existingUserData = {
      email: email,
      password: password,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/login`, existingUserData)
      .then((response) => {
        if (response.status === 200) {
          console.log(response)
          setAccessToken(response.data.token);
          localStorage.setItem("firstName", response.data.data[0].firstName);
          localStorage.setItem("lastName", response.data.data[0].lastName);
          localStorage.setItem("email", response.data.data[0].email);
          localStorage.setItem("ids", response.data.data[0]._id);
          localStorage.setItem("sessionId", response.data.token);
          localStorage.setItem("userType", response.data.data[0].userType);
          // navigate("/Explore-Jobs");
          window.location.reload();
          
        }
      })
      .catch(({ error, response }) => {
        // console.error(response);
        if (!response) {
          toast("Please check internet connection", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className=" w-[95%] p-3 sm:p-0 sm:w-[520px] shadow shadow-[#F17300] flex flex-col justify-center items-center bg-gray-50 rounded-[6px]">
      <div className="w-full flex justify-end pt-5 mb-3 pr-4">
        <div
          onClick={ActivePopup}
          className="bg-[#f174005f] cursor-pointer flex justify-center font-semibold text-[14px] items-center text-red-500 w-6 h-6 rounded-[30px]"
        >
          X
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div className="w-[80%] sm:w-[70%] pb-10 flex flex-col gap-[32px]">
          <div className="flex flex-col gap-[48px]">
            <div className="flex flex-col gap-[15px] sm:gap-[30px] font-semibold">
              <div className="flex flex-col gap-[8px]">
                <p>Email</p>
                <input
                  className="w-full h-[40px] p-[5px] font-normal bg-[#F6F6F6] text-[#ABABAB] rounded-[5px]"
                  placeholder="Enter your email address"
                  required
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="flex flex-col gap-[8px]">
                <p>Password</p>
                <div className="flex items-center pr-1 bg-[#F6F6F6]">
                  <input
                    className="w-full h-[40px] p-[5px] font-normal bg-[#F6F6F6] text-[#ABABAB] rounded-[5px]"
                    placeholder="Enter your Password"
                    required
                    value={password}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div onClick={togglePasswordVisibility} className="pl-1 bg-[#f6f6f6]">
                    <img src={eye} className="w-4" alt="/" />
                  </div>
                </div>
              </div>

              <div className="text-[12px] flex">
                <p
                  onClick={() => navigate("/Forgot-Password")}
                  className="cursor-pointer"
                >
                  FORGET PASSWORD?
                </p>
              </div>
            </div>

            <div>
              <div className="flex flex-col gap-[24px] font-semibold mb-2">
                <button
                  onClick={handleLoginn}
                  disabled={validate}
                  className={`flex shadow shadow-[#f17400b4] h-[42px] w-full ${
                    !validate ? "bg-[#F17300] text-white" : ""
                  } justify-center items-center rounded-[5px]`}
                >
                  {isLoading ? "Loading..." : "Log in"}
                </button>

                <div className="flex items-center justify-center text-[#ABABAB] gap-[12px] px-4">
                  <hr className="w-[70px] shadow" />
                  <p>or</p>
                  <hr className="w-[70px] shadow" />
                </div>

                <button className="flex gap-2 shadow shadow-[#f17400b4] justify-center items-center h-[42px] w-full rounded-[5px]">
                  <img src={google} />
                  Continue with Google
                </button>
              </div>

              <div className="flex justify-center w-full text-[12px]">
                <p>
                  Don't have an account?{" "}
                  <span
                    onClick={TogglePop}
                    className="underline cursor-pointer"
                  >
                    Signup
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login_Pop;
