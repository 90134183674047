import React, { useEffect, useState } from "react";
import { grid, professions } from "../../../utils/data";
import seacrh from "../../../assets/icons/search.svg";
import frame2 from "../../../assets/img/Frame 483 (2).png";
import rightdir from "../../../assets/icons/right_dir.svg";
import leftdir from "../../../assets/icons/left_dir.svg";
import star from "../../../assets/icons/stark.png";
import AxiosInstance from "../../../api/axios";
import loadingPic from "../../../assets/icons/loading-1.gif";
import Review_Popup from "../../../comonent/Review_Popup/review_popup";
import CloseBtn from "../../../comonent/CloseButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ColouristPage = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const [searchItem, setSearchItem] = useState("");

  const [loading, setLoading] = useState(false);
  const [parentItem, setParentItem] = useState([] as any);
  const [reviewItems, setReviewItems] = useState(null);

  const fetchCategory = async () => {
    try {
      setLoading(true);
      const res = await AxiosInstance.get(`/user/categories/Colorist`);
      if (Array.isArray(res.data)) {
        const parentItem = res.data.map((item) => ({
          firstName: item.firstName,
          lastName: item.lastName,
          uploadedJobs: item.uploadedJobs,
          reviews: item.reviews,
        }));
        setParentItem(parentItem);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const reviewClick = (items: any) => {
    setReviewItems(items);
  };

  const handleSearch = async (searchItem: any) => {
    setLoading(true);

    try {
      const searchContent = {
        keyword: searchItem,
        categories: "Colorist",
      };

      const res = await AxiosInstance.get("user/nameCategory/keyword", {
        params: searchContent,
      });

      if (Array.isArray(res.data)) {
        setParentItem(res.data);
      }
    } catch (error) {
      toast.error("No user with this keyword in Colorist", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setSearchItem("");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="h-[55px] xs:h-[60px] flex justify-center items-center rounded-[50px] bg-[#f1740079] border-t  font-semibold">
          <div className="flex gap-[10px] items-center">
            <p>{professions[6].name} </p>
            <div className="bg-white h-[30px] rounded-[10px] flex">
              <div className="flex">
                {open ? (
                  <div onMouseLeave={handleOpen} className="flex">
                    <div className="w-[30px] h-[30px] flex justify-center items-center cursor-pointer">
                      <img src={seacrh} />
                    </div>
                    <div className="flex ">
                    <input
                            placeholder="Search"
                            className="p-[10px]  w-[165px] xs:w-[200px] bg-white h-[30px] rounded-r-[10px]"
                            value={searchItem}
                            onChange={(e) => setSearchItem(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleSearch(searchItem); 
                              }
                            }}
                          />
                    </div>
                  </div>
                ) : (
                  <div
                    onMouseOver={handleOpen}
                    className="w-[36px] h-[30px] flex justify-center items-center cursor-pointer"
                  >
                    <img src={seacrh} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="h-[80vh] w-full flex justify-center items-center">
            <img src={loadingPic} />
          </div>
        ) : (
          <div className="mt-[40px] sm:mt-[70px] px-[10px] xs:px-[20px] sm:px-[50px]">
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-y-[30px] md:gap-y-14 gap-2 sm:gap-5 ">
              {parentItem.map((items: any, i: number) => (
                <div
                  key={i}
                  className="flex flex-col gap-1 w-full hover:bg-[#dad6d61a] hover:shadow-md shadow drop-shadow-xl border-b-orange-200 border-b-[2px] p-[6px] rounded-lg"
                >
                  <div className="flex gap-2">
                    <div className="h-full w-16 bg-orange-300 rounded-sm">
                      <img src={frame2} className="h-full" alt="" />
                    </div>
                    <div className="w-full">
                      <div className="flex gap-[6px]">
                        <p className="text-[18px] font-semibold">
                          {items.firstName}
                        </p>
                        <p className="text-[18px] font-semibold">
                          {items.lastName}
                        </p>
                      </div>
                      <div className="flex gap-[2px]">
                        <div>
                          <img className="h-5" src={star} alt="/" />
                        </div>
                        <div>
                          <img className="h-5" src={star} alt="/" />
                        </div>
                        <div>
                          <img className="h-5" src={star} alt="/" />
                        </div>
                        <div>
                          <img className="h-5" src={star} alt="/" />
                        </div>
                        <div>
                          <img className="h-5" src={star} alt="/" />
                        </div>
                      </div>
                      <div className="underline Lato-Italics text-[14px] w-full flex justify-end">
                        <p
                          // onClick={() => setShowReview(true)}
                          onClick={() => reviewClick(items)}
                          className="cursor-pointer hover:font-semibold"
                        >
                          {items.reviews?.length}{" "}
                          {items.reviews?.length > 1 ? "Reviews" : "Review"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between text-[14px]">
                    <p className="hover:underline hover:font-semibold cursor-pointer Lato-Light">
                      Price range
                    </p>
                    <p className="hover:underline hover:font-semibold cursor-pointer Lato-Light">
                      Jobs done
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="font-semibold  flex gap-[50px] justify-end mt-[45px]">
              <div className="cursor-pointer flex items-center gap-2">
                <img src={rightdir} />
                <p>Previous</p>
              </div>

              <div className="flex items-center gap-2 cursor-pointer">
                <p>Next</p> <img src={leftdir} />
              </div>
            </div>
          </div>
        )}
      </div>

      {reviewItems && (
        <>
          {/* <div className="flex justify-between px-[6px] w-[100%] bg-blue-50"> */}

          <div className="drop-shadow-lg pl-2 bg-gray-100 w-[95%] min-h-[350px] max-h-fit fixed m-auto top-0 xs:top-20 bottom-0 right-0 left-0 z-[999] ">
            <CloseBtn onClose={() => setReviewItems(null)} />
            <Review_Popup parentReview={reviewItems} />
          </div>

          {/* </div> */}
          <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
        </>
      )}
      <ToastContainer />
    </>
  );
};

export default ColouristPage;
