import React from 'react'
import LoggedIn_Layout from '../../../comonent/loggedIn_Layout'
import SideLayout from '../../../comonent/SideLayout'

const Help_Support = () => {
  return (
    <LoggedIn_Layout>
       <SideLayout>
       <div>Help_Support</div>
       </SideLayout>
    </LoggedIn_Layout>
  )
}

export default Help_Support