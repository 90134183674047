import React, { useEffect, useState } from "react";
import imgIcon from "../../../assets/icons/icons8-image-48.png";
import AxiosInstance, { handleLogout } from "../../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import lazy_loading from "../../../assets/icons/loading-icon.gif";
import loadingPic from "../../../assets/icons/loading-1.gif";
import CommentPopup from "./commentPopup";
import CloseBtn from "../../../comonent/CloseButton";
import EditPostPopup from "./editPostPopup";
import DeletePostPopup from "./deletePostPopup";

type TimeLineProps = {
  parentCommunity?: any;
};

const TimeLine: React.FC<TimeLineProps> = ({ parentCommunity }) => {
  const communityId = parentCommunity.id;
  const communityName = parentCommunity.name;
  const [selectedUploadedPicture, setSelectedUploadedPicture] = useState<
    File[]
  >([]);
  const [previewUploadedPicture, setPreviewUploadedPicture] = useState<
    string | null
  >(null);
  const [post, setPost] = useState("");
  const [selectedPicture, setSelectedPicture] = useState("");
  const [loading, setLoading] = useState(false);
  const User_id = localStorage.getItem("ids");
  const [parentPosts, setParentPosts] = useState([] as any);
  const [showCommentSection, setShowCommentSection] = useState(null);
  const [showPostModal, setShowPostModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState<any | null>(null);
  

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get(`/posts/community/${communityId}`);
      const postsData = res.data;

      if (!Array.isArray(postsData)) {
        console.error("Unexpected response format:", postsData);
        return;
      }

      const postsWithUsers = await Promise.all(
        postsData.map(async (post) => {
          const userId = post.user;
          const userRes = await AxiosInstance.get(`/user/${userId}`);

          if (!userRes.data) {
            console.warn("Failed to fetch user data for post:", post._id);
            return { ...post, user: null };
          }

          const hasLikedResponse = await AxiosInstance.get(
            `/posts/likes/${post._id}`
          );

          const isLikedArray = hasLikedResponse.data;
          const isLiked = isLikedArray.filter((id: any) => id === User_id);

          return { ...post, user: userRes.data, isLiked };
        })
      );

      const sortedPosts = postsWithUsers.sort(
        (a, b) =>
          new Date(b.timestamp!).getTime() - new Date(a.timestamp!).getTime()
      );
      setParentPosts(sortedPosts);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handleChange = (e: any) => {
    setSelectedPicture(e.target.files[0]);

    const newImages = e.target.files || [];
    setSelectedUploadedPicture([...selectedUploadedPicture, ...newImages]);

    if (newImages.length > 0 && previewUploadedPicture === null) {
      const reader = new FileReader();
      reader.onload = (event) =>
        setPreviewUploadedPicture((event.target as any).result as string);
      reader.readAsDataURL(newImages[0]);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", selectedPicture);
    formData.append("message", post);
    formData.append("communityId", communityId);
    formData.append("communityName", communityName);

    try {
      AxiosInstance.post(`posts/createPost/${User_id}`, formData).then(
        (response) => {
          if (response.status === 200) {
            toast.success(`new post created`);
          }
          setPreviewUploadedPicture(null);
          setSelectedPicture("");
          setPost("");
          fetchPosts();
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async (item: any) => {
    const postId = item._id;

    try {
      const response = await AxiosInstance.patch(`posts/${postId}`, {
        like: item.isLiked?.includes(User_id) ? 0 : 1,
        user: User_id,
      });

      if (response.status === 200) {
        setParentPosts((prevPosts: any) =>
          prevPosts.map((post: any) =>
            post._id === postId
              ? {
                  ...post,
                  like: item.isLiked?.includes(User_id)
                    ? post.like - 1
                    : post.like + 1,
                  isLiked: item.isLiked?.includes(User_id)
                    ? []
                    : [...item.isLiked, User_id],
                }
              : post
          )
        );
        toast.success(
          `Post ${item.isLiked?.includes(User_id) ? "unliked" : "liked"}!`
        );
      } else {
        console.error("Like update failed:", response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function formatTimestamp(timestampString: string) {
    const formattedDate = new Date(timestampString);
    const formattedDateString = formattedDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    const formattedTimeString = formattedDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return `${formattedDateString} ${formattedTimeString}`;
  }

  const validate = !post && !selectedPicture;

  const matchingIds = parentCommunity?.members?.filter(
    (id: any) => id === User_id
  );

  // const handleOption = (i: number, item: any) => {
  //   console.log(i);
  //   console.log(item);
  // };

  const handleToggle = (i: number, item: any) => {
    setSelectedItemIndex(i);
    setShowPostModal(!showPostModal);
    // handleOption(i, item);
  };

  const handleDeletePop = async (i: number, item: any) => {
    setShowDeleteModal(item)
  }

  const handleDelete = async (showDeleteModal: any) => {
    setLoading(true);
    try {
      const response = await AxiosInstance.delete(`posts/${showDeleteModal._id}`);

      if (response.status === 200) {
        toast.success("post deleted", {
          position: toast.POSITION.TOP_RIGHT,
        });
        // handleToggle(i, item);
        setShowDeleteModal(null)
        setSelectedItemIndex(null)
        setShowPostModal(false)
        fetchPosts();
      } else {
        toast.error(`error deleting post`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error("error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (i: number, item: any) => {
    setShowEditModal(item);
  };

  return (
    <>
      <div>
        <div>
          {loading ? (
            <div className="h-[50vh] w-full flex justify-center items-center">
              <img src={loadingPic} />
            </div>
          ) : (
            <>
              {matchingIds?.length > 0 && (
                <div className="w-full items-center flex flex-col gap-3 mt-8">
                  <div className="w-full">
                    <textarea
                      placeholder="Create a post..."
                      className="w-[95%] h-36 border p-1 text-sm drop-shadow-md rounded-md nonResizableTextarea"
                      value={post}
                      onChange={(e) => setPost(e.target.value)}
                    />
                    {previewUploadedPicture && (
                      <img
                        src={previewUploadedPicture}
                        alt="Selected Icon"
                        className="w-24 h-24 object-cover rounded-md"
                      />
                    )}
                  </div>
                  <div className="flex justify-end w-full">
                    <>
                      <>
                        <label htmlFor="displayImage">
                          <img
                            src={imgIcon}
                            className="h-9 w-9 drop-shadow-lg cursor-pointer"
                            alt=""
                          />
                          <input
                            id="displayImage"
                            type="file"
                            accept="image/*"
                            onChange={handleChange}
                            className="hidden"
                          />
                        </label>
                      </>

                      <button
                        disabled={validate}
                        onClick={handleSubmit}
                        className={`flex shadow shadow-[#F17300] h-[36px] w-40 ${
                          !validate ? "bg-[#F17300] text-white" : ""
                        } font-semibold justify-center items-center rounded-[5px]`}
                      >
                        {loading ? "Uploading..." : "Send"}
                      </button>
                    </>
                  </div>
                </div>
              )}
              {parentPosts.length > 0 ? (
                <>
                  <ul className="flex flex-col gap-[30px] mt-5 ">
                    {parentPosts?.map((item: any, i: number) => (
                      <div
                        key={i}
                        className="flex flex-col gap-2 px-[1px] shadow border-b rounded-b-[10px] border-[#615f5f] pt-2"
                      >
                        <div>
                          <div className="flex items-center gap-[3px]">
                            <div className="text-[10px] p-[2px] rounded-[5px]">
                              <p>
                                <span className="font-bold bg-orange-100 lg:font-semibold mr-[2px] text-[#F17300]">
                                  {communityName}{" "}
                                </span>{" "}
                                Created a post on{" "}
                                <span> {formatTimestamp(item.timestamp)}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="shadow shadow-[#cac5c5] w-full rounded-[10px] border-b">
                          <div className="flex justify-between">
                            <div className="p-1 flex gap-1">
                              <div>
                                <img
                                  className="h-[25px] w-[25px] rounded-[30px]"
                                  src={item.user?.dp}
                                  alt=""
                                />
                              </div>
                              <div>
                                <p className="font-semibold text-[13px]">
                                  {communityName}
                                </p>
                                <p className="sm:thin text-[12px]">
                                  {item.user?.firstName} {item.user?.lastName}
                                </p>
                              </div>
                            </div>

                            {(parentCommunity?.user === User_id ||
                              item.user?._id === User_id) && (
                              <div
                                key={i}
                                className="flex flex-col items-end mt-1 mr-3"
                              >
                                <div
                                  onClick={() => handleToggle?.(i, item)}
                                  className="font-bold cursor-pointer w-6 flex justify-center items-center rounded-[30px] hover:shadow"
                                >
                                  :
                                </div>
                                {/* {showPostModal && ( */}
                                {selectedItemIndex === i && showPostModal && (
                                  <div
                                    key={i}
                                    className="h-[33px] w-[130px] flex flex-col gap-1 shadow rounded-[8px]"
                                  >
                                    {/* <div
                                      onClick={() => handleEdit?.(i, item)}
                                      className="w-full border rounded-[8px] shadow flex justify-center items-center h-[32px] border-orange-400 font-semibold text-sm text-orange-400"
                                    >
                                      Edit
                                    </div> */}
                                    <div
                                      // onClick={() => handleDelete?.(i, item)}
                                      onClick={() => handleDeletePop?.(i, item)}
                                      className="w-full border rounded-[8px] shadow flex justify-center items-center h-[32px] border-orange-400 font-semibold text-sm text-orange-400"
                                    >
                                      Delete
                                    </div>
                                  </div>
                                  // onClick={() => setShowCommentSection(item)}
                                )}
                              </div>
                            )}
                          </div>

                          <div className="flex flex-col gap-1">
                            <div>
                              <p className="text-[11px] px-2 sm:Lato-Light">
                                {item.message}
                              </p>
                            </div>

                            {item.postImage &&
                            Array.isArray(item.postImage) &&
                            item.postImage.length > 0 ? (
                              <div>
                                <img
                                  className="rounded-[5px] w-full h-[240px]"
                                  src={item.postImage}
                                />
                              </div>
                            ) : (
                              <div />
                            )}
                          </div>

                          <div className="flex w-full mt-2 text-[10px] rounded-b-3xl rounded-[20px]">
                            <p
                              key={item._id}
                              onClick={() => handleLike(item)}
                              className="flex w-full justify-center border p-[1px] cursor-pointer"
                            >
                              {item.isLiked?.includes(User_id) ? (
                                <span className="text-orange-400 w-full flex justify-center items-center font-bold hover:bg-[#f1740077] hover:text-white">
                                  {item.like} Likes (You liked!)
                                </span>
                              ) : (
                                <span className="text-black w-full flex justify-center items-center hover:bg-[#f1740077] hover:text-white">
                                  {item.like} Likes (Like)
                                </span>
                              )}
                            </p>

                            <p
                              onClick={() => setShowCommentSection(item)}
                              className="flex w-full justify-center border p-[2px] cursor-pointer hover:text-white hover:bg-[#f1740077]"
                            >
                              {item.comments.length} Comments
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </ul>
                </>
              ) : (
                <div className="h-[60vh] Axiforma w-full flex justify-center items-center">
                  No posts found for this community...
                </div>
              )}
            </>
          )}
        </div>
        {showCommentSection && (
          <>
            <div className=" fixed top-5 left-0 w-full h-full z-[999] flex flex-col items-center justify-center">
              <div className="px-5 bg-white rounded-[5px] drop-shadow-lg w-full xs:w-[90%] sm:w-[65%] md:w-[60%] lg:w-[50%]">
                <CloseBtn onClose={() => setShowCommentSection(null)} />
                <CommentPopup commentDetails={showCommentSection} />
              </div>
            </div>
            <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
          </>
        )}

        {showEditModal && (
          <>
            <div className=" fixed top-5 left-0 w-full h-full z-[999] flex flex-col items-center justify-center">
              <div className="px-5 bg-white rounded-[5px] drop-shadow-lg w-full xs:w-[90%] sm:w-[65%] md:w-[60%] lg:w-[50%]">
                <CloseBtn onClose={() => setShowEditModal(null)} />
                <EditPostPopup postDetails={showEditModal} />
              </div>
            </div>
            <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
          </>
        )}

        {showDeleteModal && (
          <>
            <div className=" fixed top-9 left-0 w-full h-full z-[999] flex flex-col items-center justify-center">
              <div className="px-5 bg-white rounded-[5px] drop-shadow-lg w-full xs:w-[90%] sm:w-[65%] md:w-[60%] lg:w-[50%]">
                <DeletePostPopup handleRemove={() => handleDelete(showDeleteModal)} onClose={() => setShowDeleteModal(null)} parentItem={showDeleteModal} />
              </div>
            </div>
            <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
          </>
        )}
      </div>

      <ToastContainer />
    </>
  );
};

export default TimeLine;
