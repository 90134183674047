import React, { useState } from "react";
import { toast } from "react-toastify";
import AxiosInstance from "../../../api/axios";

type CrewFormType = {
  role: string;
  name: string;
};

type crewType = "role" | "name";

type AddCrewProps = {
  parentCommuityId?: any;
};

const AddCrewPopup: React.FC<AddCrewProps> = ({ parentCommuityId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const ids = parentCommuityId;

  const EMPTY_FORM = {
    role: "",
    name: "",
  };

  const [formData1, setFormData1] = useState([
    { ...EMPTY_FORM },
  ] as CrewFormType[]);

  const handleChange = (e: any, i: number, field: crewType) => {
    if (field === "role") {
      formData1[i][field] = e.target.value;
    } else {
      formData1[i][field] = e.target.value;
    }
    setFormData1([...formData1]);
  };

  const handleDelete = (i: number) => {
    const filterCrew = formData1.filter((crew, index) => index !== i);
    setFormData1([...filterCrew]);
  };

  const handleAddDep = () => {
    setFormData1([...formData1, EMPTY_FORM]);
  };

  const validate = !formData1[0].role || !formData1[0].name;

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const crew = Array.from(formData1);
      const res = await AxiosInstance.patch(`/community/crew/${ids}`, {
        crew,
      });
      toast.success("crew member created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormData1([EMPTY_FORM]);
    } catch (error) {
      toast.error("error creating crew member", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-[80px] p-[2px] max-h-[360px] overflow-y-scroll">
      <div className="flex flex-col gap-3">
        <p className="text-[14px] font-semibold">Add Crew</p>
        {formData1.map(({ role, name }, i) => (
          <div>
            <div key={i} className="">
              <div className="w-full flex gap-4">
                <div className="flex flex-col gap-2 w-[50%]">
                  <p className="text-[14px] Lato-Italics">Crew Role</p>
                  <div>
                    <input
                      className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                      type="text"
                      name="role"
                      value={role}
                      onChange={(e) => handleChange(e, i, "role")}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-2 w-[50%]">
                  <p className="text-[14px] Lato-Italics">Name</p>
                  <div>
                    <input
                      className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                      type="text"
                      value={name}
                      name="name"
                      onChange={(e) => handleChange(e, i, "name")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end">
              {formData1.length > 1 && (
                <button
                  onClick={() => handleDelete?.(i)}
                  className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                >
                  - Remove
                </button>
              )}
            </div>
          </div>
        ))}

        <div className="mt-1">
          <p
            onClick={handleAddDep}
            className="font-semibold text-orange-400 text-[14px] cursor-pointer w-fit"
          >
            + Add crew
          </p>
        </div>

        <div className="mt-7 flex flex-col items-end w-full gap-3">
          <button
            onClick={handleSubmit}
            disabled={validate}
            className={`flex shadow w-[260px] shadow-[#F17300] ${
              !validate ? "bg-[#F17300] text-white" : ""
            } h-[38px] font-semibold justify-center items-center rounded-[5px]`}
          >
            {isLoading ? "Loading..." : "Continue"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCrewPopup;
