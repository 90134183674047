import React from "react";
import dp from "../../../assets/icons/Ellipse 42 (2).svg";

const Tips_Guides = () => {
  return (
    <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-3 gap-y-6 pb-5 md:pb-0">
      <div className="flex flex-col gap-2">
        <div className="shadow w-full bg-orange-200 h-[200px] rounded-[10px]"></div>
        <div className="bg-white shadow flex justify-between text-[12px] px-3 p-[2px] rounded-[5px]">
          <div className="flex gap-1 items-center justify-center">
            <img src={dp} className="h-4" alt="/" />
            <p>Julia</p>
          </div>
          <p>Tips on editing</p>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="shadow w-full bg-orange-200 h-[200px] rounded-[10px]"></div>
        <div className="bg-white shadow flex justify-between text-[12px] p-[2px] rounded-[5px]">
          <p>Julia</p>
          <p>Tips on editing</p>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="shadow w-full bg-orange-200 h-[200px] rounded-[10px]"></div>
        <div className="bg-white shadow flex justify-between text-[12px] p-[2px] rounded-[5px]">
          <p>Julia</p>
          <p>Tips on editing</p>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="shadow w-full bg-orange-200 h-[200px] rounded-[10px]"></div>
        <div className="bg-white shadow flex justify-between text-[12px] p-[2px] rounded-[5px]">
          <p>Julia</p>
          <p>Tips on editing</p>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="shadow w-full bg-orange-200 h-[200px] rounded-[10px]"></div>
        <div className="bg-white shadow flex justify-between text-[12px] p-[2px] rounded-[5px]">
          <p>Julia</p>
          <p>Tips on editing</p>
        </div>
      </div>
    </div>
  );
};

export default Tips_Guides;
