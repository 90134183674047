import React from 'react'
import loadingPic from "../../../assets/icons/loading-1.gif";

const IsLoading = () => {
  return (
    <div className="h-[80vh] w-full flex flex-col justify-center items-center">
    <img src={loadingPic} />
    <div className="text-center flex justify-center">
      <p className="font-semibold w-[85%] text-[14px] xs:text-[16px] sm:text-[20px] bg-[#e2940e98] px-2 p-1 rounded-[18px]">
        Kindly close the page and check your mail...
      </p>
    </div>
  </div>
  )
}

export default IsLoading