import React from "react";
import UserProfileLayout from "../../../comonent/UserProfile_Layout";
import ExperiencePage from "./experiencePage";
import LoggedIn_Layout from "../../../comonent/loggedIn_Layout";
import SideLayout from "../../../comonent/SideLayout";

const Experience = () => {
  return (
    <LoggedIn_Layout>
      <SideLayout>
        <ExperiencePage />
      </SideLayout>
    </LoggedIn_Layout>
  );
};

export default Experience;
