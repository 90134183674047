import React from "react";
import dp from "../../../assets/img/Ellipse 42.svg";
import { useNavigate } from "react-router-dom";
import { commmunity_sidebar, employeeSideNav } from "../../../utils/data";

type CommunityPageProps = {
  onCommunity: () => void;
  onTips: () => void;
};

const Sidebar: React.FC<CommunityPageProps> = ({
  onCommunity,
  onTips,
}) => {
  const navigate = useNavigate();

  return (
    <div className="w-full">
      <div className="flex shadow shadow-[#F1730080] rounded-[10px] flex-col gap-[44px] items-center pb-[70px]">
        <div className="py-2 w-full mt-20">
          <div className="Axiforma flex flex-col text-[12px] xl:text-[13px] w-full">
            <button
              onClick={onCommunity}
              className="hover:bg-[#f174005f] hover:font-bold hover:text-white flex items-center justify-start px-4 h-[51px]"
            >
              Community{" "}
            </button>

            <button
              onClick={onTips}
              className="hover:bg-[#f174005f] hover:font-bold hover:text-white flex items-center justify-start px-4 h-[51px]"
            >
              Tips & Guides{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
