import React, { FC } from 'react'
import { handleLogout } from '../../api/axios';

interface LoggoutModalProps {
    // show: boolean;
    onClose: () => void;
}

const LogoutModal: FC<LoggoutModalProps> = ({ onClose }) => {
  return (
    <div className='h-[199px] w-[95%] p-3 sm:p-0 sm:w-[520px] shadow shadow-[#F17300] flex justify-center items-center bg-gray-50 rounded-[6px]'>
        <div className='flex flex-col gap-10 text-center'>
            <p className='text-[24px] sm:text-[26px]'>Are you sure you want to log out ?</p>

            <div className='flex justify-center gap-28'>
                <button onClick={handleLogout} className='shadow shadow-[#F17300] hover:bg-[#F17300] hover:text-white text-sm h-[30px] font-semibold rounded-[5px] w-[80px]'>Yes</button>
                <button onClick={onClose} className='shadow shadow-[#F17300] hover:bg-[#F17300] hover:text-white text-sm h-[30px] font-semibold rounded-[5px] w-[80px]'>Cancel</button>
            </div>
        </div>
    </div>
  )
}

export default LogoutModal