import React, { useEffect, useRef } from "react";
import UserProfileLayout from "../../../comonent/UserProfile_Layout";
import UserProfilePage from "./userProfile";
import LoggedIn_Layout from "../../../comonent/loggedIn_Layout";
import DashboardSideSideModal from "../../../comonent/DashboardSideModal";
import LogggedOutState from "../../../comonent/Empty_State/loggedOutState";

const UserProfile = () => {
  const user_id = localStorage.getItem("ids");

  const homes = useRef(null);

  const handleHomes = () => {
    if (homes.current) {
      (
        homes.current as {
          scrollIntoView: (options: ScrollIntoViewOptions) => void;
        }
      ).scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleHomes();
  }, []);

  return (
    <>
      {user_id ? (
        <div ref={homes}>
          <LoggedIn_Layout>
            <DashboardSideSideModal>
              <UserProfilePage />
            </DashboardSideSideModal>
          </LoggedIn_Layout>
        </div>
      ) : (
        <LogggedOutState />
      )}
    </>
  );
};

export default UserProfile;
