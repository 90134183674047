import React, { useEffect, useState } from "react";
import { dropDown, seekerDropDown } from "../../utils/data";
import { useNavigate } from "react-router-dom";

interface ProfileDropdowmProps {
  onDropdownClick: () => void;
}

const ProfileDropdowm: React.FC<ProfileDropdowmProps> = ({
  onDropdownClick,
}) => {
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();
  const User_type = localStorage.getItem("userType");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;

      if (scrollPosition > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={`bg-white absolute right-1 top-[71px] xs:top-[91px] w-[185px] xs:w-[220px] p-2 rounded-[5px] shadow z-[99] ${
        isSticky ? "dropdown_sticky" : ""
      } `}
    >
      {User_type === "recruiter" ? (
        <>
          {" "}
          {dropDown.map(({ name, linkk }) => (
            <p
              onClick={() => navigate(linkk)}
              className="px-4 text-[14px] sm:text-[15px] py-[4px] font-semibold hover:bg-gray-200 hover:text-white border-[#1e222e3a] cursor-pointer"
            >
              {name}
            </p>
          ))}
        </>
      ) : (
        <>
          {" "}
          {seekerDropDown.map(({ name, linkk }) => (
            <p
              onClick={() => navigate(linkk)}
              className="px-4 text-[14px] sm:text-[15px] py-[4px] font-semibold hover:bg-gray-200 hover:text-white border-[#1e222e3a] cursor-pointer"
            >
              {name}
            </p>
          ))}
        </>
      )}

      <p
        onClick={onDropdownClick}
        className="px-4 py-[4px] font-semibold hover:bg-gray-200 hover:text-red-500 border-[#1e222e3a] cursor-pointer text-red-500"
      >
        Log out
      </p>
    </div>
  );
};

export default ProfileDropdowm;
