import React from "react";
import { useNavigate } from "react-router-dom";
import graphic1 from "../../assets/img/Working-rafiki (1) 1.png";
import graphics2 from "../../assets/img/Profiling-rafiki (1) 1 (1).png";

const SectionTwo = () => {
  const navigate = useNavigate();

  return (
    <div className="Axiforma flex flex-col gap-[80px] xs:gap-[50px] mb-6 px-[15px] xs:px-[40px] lg:px-10">
      <div className="w-full flex flex-col md:flex-row gap-[40px] md:gap-3 pt-[20px] md:pt-[70px]">
        <div className="flex flex-col items-center w-full gap-[30px] text-center">
          <p className="text-[18px] lg:text-[20px] ">Need service ?</p>
          <p className="text-[25px] xs:text-[28px] md:text-[25px] lg:text-[35px] font-semibold">
            Find your next creative
          </p>
          <p className="text-[16px] lg:text-[20px] ">
            explore and collaborate with creative professionals
          </p>
          <button
            onClick={() => navigate("/Employer-Profile")}
            className="rounded-[5px] text-[16px] lg:text-[18px] font-semibold shadow h-[39px] lg:h-[42px] w-[110px] lg:w-[130px] text-[#F17300] shadow-[#F17300] hover:bg-[#F17300] hover:text-white"
          >
            Hire now
          </button>
        </div>

        {/* <div className="w-full flex justify-center items-center shadow bg-[#ffd0a4b1] h-[250px]"></div> */}
        <div className="flex justify-center items-center">
          <img src={graphic1} alt="" />
        </div>
      </div>

      <div className="w-full flex md:pt-[100px]">
        <div className="w-full flex flex-col-reverse md:flex-row gap-[100px] md:gap-2">
          {/* <div className="w-full flex justify-center items-center shadow bg-[#ffd0a4b1] h-[250px]"></div> */}
          <div className="flex justify-center items-center">
            <img src={graphics2} alt="" />
          </div>

          <div className="flex flex-col items-center w-full gap-[30px]">
            <p className="  text-[18px] lg:text-[20px]">
              In search of job opportunities ?
            </p>
            <p className="text-[24px] xs:text-[28px] md:text-[25px] lg:text-[34px] font-semibold">
              Discover new opportunities{" "}
            </p>
            <p className="text-[16px] lg:text-[20px] ">
              explore and find your next big break
            </p>
            <button
              onClick={() => navigate("/Explore-Jobs")}
              className="rounded-[5px] text-[16px] lg:text-[18px] font-semibold shadow h-[39px] lg:h-[42px] text-[#F17300] w-[110px] lg:w-[130px] shadow-[#F17300] hover:bg-[#F17300] hover:text-white"
            >
              Get hired
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
