import React, { useState } from "react";
import dp from "../../assets/icons/Ellipse 42 (2).svg";
import CandidatePopup from "../../comonent/Candidate_Popup";
import CloseBtn from "../../comonent/CloseButton";
import ReactPlayer from "react-player";

type parentItemProps = {
  parentItem?: any;
};

const TalentpoolComponent: React.FC<parentItemProps> = ({ parentItem }) => {
  const handleChildClick = (event: any) => {
    event.stopPropagation();
    // console.log("Child div clicked");
  };
  const [clickedItemDetails, setClickedItemDetails] = useState(null);


  return (
    <>
      <div className="flex mt-7 flex-col gap-7">
        {parentItem?.map((item: any, i: number) => (
          <div
            onClick={() => setClickedItemDetails(item)}
            className="flex cursor-pointer flex-col sm:flex-row border-b pb-7 border-gray-200 gap-7 sm:gap-0 justify-between"
          >
            <div className="flex flex-col gap-3">
              <div className="flex gap-3">
                <div>
                  {item.dp === '' ? (
                    <div className="h-[80px] w-[80px] bg-orange-100" />
                  ) : (
                    <img
                      className="h-[80px] w-[80px] rounded-[80px]"
                      src={item.dp}
                      alt="/"
                    />
                  )}
                </div>
                <div>
                  <div className="flex gap-[6px]">
                    <p className="text-[22px] font-bold">{item.firstName}</p>
                    <p className="text-[22px] font-bold">{item.lastName}</p>
                  </div>
                  <p className="text-[13px]">Type a status message</p>
                </div>
              </div>

              <div className="flex gap-3">
                <div className="w-[64px] flex justify-center">ii</div>
                <div className="w-full">
                  <div className="items-center gap-[5px] md:gap-[2px] gap-x-2 justify-center grid grid-cols-2 xs:grid-cols-3">
                    {item?.categories ? (
                      item?.categories.map(
                        (categoriesItem: any, index: any) => (
                          <p
                            key={index}
                            className="font-semibold text-[13px] w-fit"
                          >
                            {categoriesItem}
                          </p>
                        )
                      )
                    ) : (
                      <p>No category.</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex gap-3">
                <div className="w-[64px] flex justify-center relative md:left-1">
                  ii
                </div>
                <div className="w-full">
                  <div className="items-center gap-[5px] md:gap-[2px] gap-x-2 justify-center grid grid-cols-2 xs:grid-cols-3">
                    {item?.skills ? (
                      item?.skills.map((skillsItem: any, index: any) => (
                        <p
                          key={index}
                          className="font-semibold text-[13px] w-fit"
                        >
                          <p className="font-semibold text-[13px] flex gap-1 w-fit items-end">
                            {skillsItem.skills}
                            <span className="font-normal text-[12px]">
                              {skillsItem.experience} yr(s)
                            </span>
                          </p>
                        </p>
                      ))
                    ) : (
                      <p>No skill.</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex gap-3 items-center">
                <div className="w-[64px] flex justify-center">ii</div>

                <div className="w-full md:w-fit">
                  <p className="Lato-Italics">Not informed</p>
                </div>
              </div>

              <div className="flex gap-3 items-center">
                <div className="w-[64px] flex justify-center">ii</div>

                <div className="w-full md:w-fit">
                  <div className="items-center gap-[5px] md:gap-[2px] gap-x-2 justify-center grid grid-cols-2 xs:grid-cols-3">
                    <p className="font-semibold text-[13px] w-fit">
                      {" "}
                      AMAA{" "}
                      <span className="font-normal text-[10px]">
                        (Colorist of the year)
                      </span>{" "}
                    </p>
                    <p className="font-semibold text-[13px] w-fit">
                      {" "}
                      NFA{" "}
                      <span className="font-normal text-[10px]">
                        (Best Motion Editing)
                      </span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              onClick={handleChildClick}
              className="flex w-full  sm:w-[266px] items-center justify-center"
            >
              <div className="w-full xs:w-[80%] sm:w-[266px] h-[216px]">
                <ReactPlayer
                  url={item.talentVideo}
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      {clickedItemDetails && (
        <>
          <div className="drop-shadow-2xl w-full xs:w-[95%] rounded-[7px] fixed m-auto top-24 right-0 left-0 z-[999] h-[600px] xs:h-[520px] bg-white">
            <div>
              <CloseBtn onClose={() => setClickedItemDetails(null)} />
            </div>
            <CandidatePopup parentDetails={clickedItemDetails} />
          </div>
          <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
        </>
      )}
    </>
  );
};

export default TalentpoolComponent;
