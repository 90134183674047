import React, {
  useMemo,
  useRef,
  useCallback,
  useState,
  useEffect,
} from "react";
import img1 from "../../assets/icons/category_icons/Ellipse 22 (1).svg";
import img2 from "../../assets/icons/category_icons/Ellipse 22 (2).svg";
import img3 from "../../assets/icons/category_icons/Ellipse 22 (3).svg";
import img4 from "../../assets/icons/category_icons/Ellipse 22 (4).svg";
import img6 from "../../assets/icons/category_icons/Ellipse 23.svg";
import { professions, scrolls } from "../../utils/data";
import { useNavigate } from "react-router-dom";

const CategoryRow: React.FC = () => {
  const imgArray = [
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img6 },
    { img: img3 },
    { img: img4 },
    { img: img6 },
    { img: img3 },
    { img: img4 },
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
  ];

  useEffect(() => {
    const scrollContainer: HTMLElement | null =
      document.querySelector(".scroll-container");

    // Function to scroll automatically
    function autoScroll(): void {
      if (scrollContainer) {
        // Scroll to next content
        scrollContainer.scrollLeft += 50; // Adjust the value based on content width and spacing

        // If reached the end, reset to start
        if (
          scrollContainer.scrollLeft + scrollContainer.offsetWidth >=
          scrollContainer.scrollWidth
        ) {
          scrollContainer.scrollLeft = 0;
        }
      }
    }

    // Automatically scroll every 2 seconds (adjust as needed)
    setInterval(autoScroll, 1700);

    setInterval(autoScroll, 2500);
  }, []);

  const navigate = useNavigate();
  // scroll-smooth snap-mandatory snap-x scrolling-pictures
  return (
    <div className="flex flex-col gap-[70px] mb-[50px]">
      <div id="scroll-image" className="scroll-container">
        {imgArray.map((items, i) => (
          <div className="scroll-content">
            <img src={items.img} className="xs:w-[180px] w-[200px] md:w-[230px] lg:w-fit" />
          </div>
        ))}
      </div>

      <div
        id="Services"
        className="grid grid-cols-2 xs:grid-cols-3 gap-5 lg:gap-2 mx-10 md:grid-cols-5 xl:grid-cols-5 justify-center font-semibold"
      >
        {scrolls.map((items) => (
          <p
            onClick={() => navigate(items.link)}
            className="border-l pl-2 xs:pl-0 xs:text-center text-[13px] lg:text-[15px] cursor-pointer"
          >
            {items.name}
          </p>
        ))}
        <div className="border-l" />
      </div>
    </div>
  );
};

export default CategoryRow;
