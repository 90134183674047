import React, { useEffect, useState } from "react";
import CloseBtn from "../CloseButton";
import ReactPlayer from "react-player";
import AxiosInstance from "../../api/axios";
import Review from "../../pages/Profile/userProfile/Review/review";
import Review_Popup from "../Review_Popup/review_popup";

type candidatePopupProps = {
  parentDetails?: any;
};

const CandidatePopup: React.FC<candidatePopupProps> = ({ parentDetails }) => {
  // const [parentReview, setParentReview] = useState([] as any);

  // const fetchReview = async () => {
  //   try {
  //     const res = await AxiosInstance.get(`/user/reviews/${talentId}`);
  //     if (Array.isArray(res.data)) {
  //       const parentReview = res.data.map((item) => ({
  //         review: item.review,
  //         reviewer_firstName: item.reviewer.firstName,
  //         reviewer_lastName: item.reviewer.lastName,
  //         createdAt: item.createdAt,
  //       }));
  //       setParentReview(parentReview);
  //     } else {
  //       console.error("Unexpected response format:", res.data);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchReview();
  // }, []);

  return (
    <div className="p-3 flex flex-col md:flex-row gap-12 md:gap-3 h-[530px] xs:h-[460px] overflow-y-scroll mt-3">
      <div className="bg-gray-50 shadow-md p-3 md:p-0 border-t-2 border-t-gray-100 rounded-[5px] min-w-[240px] h-[240px] flex flex-col gap-8">
        <div className="flex gap-4 flex-col">
          <div className="w-full justify-center flex pt-3">
            {" "}
            <div className="relative">
              {/* <div className="w-[120px] h-[120px] bg-white rounded-[80px]"></div> */}
              <div>
                <img
                  className="w-[120px] h-[120px] rounded-[80px]"
                  src={parentDetails.dp}
                  alt=""
                />
              </div>
              <div className="w-3 h-3 bg-green-600 absolute bottom-1 right-[18px] rounded-[20px]" />
            </div>
          </div>

          <div className="text-[13px] flex gap-[6px] justify-center font-semibold">
            <p>{parentDetails.firstName}</p>
            <p>{parentDetails.lastName}</p>
          </div>
        </div>

        <div className="flex justify-center text-sm">
          <p className="cursor-pointer">Resume</p>
        </div>
      </div>

      <div className="">
        <div className="flex flex-col gap-3 overflow-y-scroll">
          <div>
            <div className="border-b">
              <p className="border-b border-b-orange-300 w-fit pb-[5px] font-semibold">
                Details
              </p>
            </div>

            <div className="w-full flex flex-col lg:flex-row py-5">
              <div className="w-full">
                <div className="py-[24px] text-[16px] leading-8">
                  <p className="text-[14px]">
                    This candidate is a Animator with 10+ years of experience
                    working with skills such as voice over, Node.js, React.js,
                    Jest, Typescript, Cypress and Scss. Now looking for remote
                    only jobs
                  </p>
                </div>
              </div>

              <div className="w-full flex justify-center">
                <div className="flex w-full  sm:w-[266px] items-center justify-center">
                  <div className="w-full xs:w-[80%] sm:w-[266px] h-[216px]">
                    <ReactPlayer
                      url={parentDetails.talentVideo}
                      controls
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-5 px-[2px]">
            <div className="border-b">
              <p className="border-b border-b-orange-300 w-fit pb-[5px] font-semibold">
                Categories
              </p>
            </div>

            <div className="grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-5 gap-y-4 text-[12px] font-semibold py-[24px]">
              {parentDetails?.categories.map(
                (categoriesItem: any, index: any) => (
                  <p
                    className="p-1 border rounded-[10px] w-fit shadow shadow-orange-500"
                    key={index}
                  >
                    {categoriesItem}
                  </p>
                )
              )}
            </div>
          </div>

          <div className="py-5 px-[2px]">
            <div className="border-b">
              <p className="border-b border-b-orange-300 w-fit pb-[5px] font-semibold">
                Skills
              </p>
            </div>

            <div className="grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-5 gap-y-4 text-[12px] font-semibold py-[24px]">
              {parentDetails?.skills.map((skillsItem: any, index: any) => (
                <p
                  className="p-1 border rounded-[10px] w-fit shadow shadow-orange-500"
                  key={index}
                >
                  {skillsItem.skills}
                </p>
              ))}
            </div>
          </div>

          <div className="py-5 px-[2px]">
            <div className="border-b">
              <p className="border-b border-b-orange-300 w-fit pb-[5px] font-semibold">
                Certification
              </p>
            </div>

            <div className="py-5">
              <p>Bachelors of Art & Culture, Oxford University USA</p>
              <p>Jul 2018 - Aug 2023</p>
            </div>
          </div>

          <div className="py-5 px-[2px]">
            <div className="border-b">
              <p className="border-b border-b-orange-300 w-fit pb-[5px] font-semibold">
                Awards
              </p>
            </div>

            <div className="py-5 flex flex-col gap-3">
              <p className="text-[10px] font-semibold">
                {" "}
                <span className="text-[16px] font-medium">AMAA</span> (Colorist
                of the year)
              </p>

              <p className="text-[10px] font-semibold">
                {" "}
                <span className="text-[16px] font-medium">NFA</span> (Best
                Motion Editing)
              </p>
            </div>
          </div>

          <div className="py-5 px-[2px]">
            <div className="border-b">
              <p className="border-b border-b-orange-300 w-fit pb-[5px] font-semibold">
                Past Employment
              </p>
            </div>

            <div className="py-5 grid grid-cols-1 xs:grid-cols-2 gap-4">
              {parentDetails?.experience.map(
                (experienceItem: any, index: any) => (
                  <div
                    key={index}
                    className="flex w-full gap-3 shadow p-1 rounded-md"
                  >
                    <div className="w-12">
                      <div className="h-10 w-10 rounded-[30px] bg-blue-200"></div>
                    </div>
                    <div className="w-full">
                      <p className="text-[17px]">{experienceItem.role}</p>
                      <div>
                        <p>{experienceItem.company}</p>
                        <p className="text-[14px] Axiforma-light">
                          {experienceItem.start_date} -{" "}
                          {experienceItem.end_date}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>

          <div className="py-5 px-[2px]">
            <div className="border-b">
              <p className="border-b border-b-orange-300 w-fit pb-[5px] font-semibold">
                Past Project
              </p>
            </div>

            <div>
              <p>No project uploaded yet.</p>
            </div>
          </div>

          <div className="pb-10 max-w-[520px] sm:max-w-[640px] md:max-w-[550px] lg:max-w-[670px] xl:max-w-[900px] 2xl:max-w-[1020px]">
            <Review_Popup parentReview={parentDetails} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidatePopup;
