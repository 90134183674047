import React, { useEffect, useState } from "react";
import dp from "../../assets/icons/Ellipse 42 (2).svg";

const Notification = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;

      if (scrollPosition > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={`bg-white w-[85%] xs:w-[370px] absolute right-1 top-[71px] xs:top-[91px] p-2 xs:p-5 rounded-[5px] shadow overflow-y-scroll max-h-[270px] ${
        isSticky ? "notif_sticky" : ""
      } `}
    >
      <div className="flex mb-5 xs:mb-[45px]">
        <p className="font-semibold">Notification</p>
      </div>

      <div className="border-[#9b979772] border-b-4 cursor-pointer rounded-[6px] mb-3 shadow shadow-[#F17300] py-[15px] px-[5px]">
        <div className="flex gap-[12px] sm:gap-[20px] items-center">
          <img src={dp} className="h-8" alt="" />
          <div className="flex flex-col">
            <p className="text-[14px]">Postcity sent you a welcome note</p>
            <p className="text-[10px]">today at 5:27pm</p>
          </div>
        </div>
      </div>

      <div className="border-[#9b979772] border-b-4 cursor-pointer rounded-[6px] mb-3 shadow shadow-[#F17300] py-[15px] px-[5px]">
        <div className="flex gap-[12px] sm:gap-[20px] items-center">
          <img src={dp} className="h-8" alt="" />
          <div className="flex flex-col">
            <p className="text-[14px]">Postcity sent you a welcome note</p>
            <p className="text-[10px]">today at 5:27pm</p>
          </div>
        </div>
      </div>

      <div className="border-[#9b979772] border-b-4 cursor-pointer rounded-[6px] mb-3 shadow shadow-[#F17300] py-[15px] px-[5px]">
        <div className="flex gap-[12px] sm:gap-[20px] items-center">
          <img src={dp} className="h-8" alt="" />
          <div className="flex flex-col">
            <p className="text-[14px]">Postcity sent you a welcome note</p>
            <p className="text-[10px]">today at 5:27pm</p>
          </div>
        </div>
      </div>

      <div className="border-[#9b979772] border-b-4 cursor-pointer rounded-[6px] mb-3 shadow shadow-[#F17300] py-[15px] px-[5px]">
        <div className="flex gap-[12px] sm:gap-[20px] items-center">
          <img src={dp} className="h-8" alt="" />
          <div className="flex flex-col">
            <p className="text-[14px]">Postcity sent you a welcome note</p>
            <p className="text-[10px]">today at 5:27pm</p>
          </div>
        </div>
      </div>

      <div className="border-[#9b979772] border-b-4 cursor-pointer rounded-[6px] mb-3 shadow shadow-[#F17300] py-[15px] px-[5px]">
        <div className="flex gap-[12px] sm:gap-[20px] items-center">
          <img src={dp} className="h-8" alt="" />
          <div className="flex flex-col">
            <p className="text-[14px]">Postcity sent you a welcome note</p>
            <p className="text-[10px]">today at 5:27pm</p>
          </div>
        </div>
      </div>

      {/* <div className="flex justify-end absolute bottom-0 right-5 mt-2">
        <div className="text-[10px] cursor-pointer">View all</div>
      </div> */}
    </div>
  );
};

export default Notification;
