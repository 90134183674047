import React from "react";
import WorkSpaceLayout from "../../comonent/workSpaceLayout";
import GeneralWorkspacePage from "./GeneralWorkspacePage";

const GeneralWorkspace = () => {
  return (
    <WorkSpaceLayout>
      <GeneralWorkspacePage />
    </WorkSpaceLayout>
  );
};

export default GeneralWorkspace;
