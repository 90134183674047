import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import AxiosInstance from "../../../api/axios";

type StoryLinePopupProps = {
  parentCommunity?: any;
  getRequest?: () => void;
  onclose?: () => void;
};

const StoryLinePopup: React.FC<StoryLinePopupProps> = ({
  parentCommunity,
  getRequest,
  onclose
}) => {
  const [isLoading, setIsloading] = useState(false);
  const [storyline, setStoryline] = useState("");
  const [onChange, setOnChange] = useState(false);

  const ids = parentCommunity?._id;
  const initialStoryline = parentCommunity?.storyLine;

  const [showStory, setShowStory] = useState(initialStoryline || "");

  useEffect(() => {
    const initialStory = initialStoryline;
    if (initialStory) {
      setStoryline(initialStory);
    }
  }, []);

  const validate = !storyline;

  const handleChange = (event: any) => {
    setStoryline(event.target.value);
    setOnChange(true);
  };

  const handleSubmit = async () => {
    setIsloading(true);
    try {
      const res = await AxiosInstance.patch(`/community/storyLine/${ids}`, {
        storyLine: storyline,
      });
      toast.success("storyline updated", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setStoryline(storyline);
      if (onclose) {
        onclose();
      }
      if (getRequest) {
        getRequest();
      }
    } catch (error) {
      console.error(error);
      toast.error("error updating storyline", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsloading(false);
    }
  };

  return (
    <div className="min-h-[80px] p-[2px] max-h-[415px] overflow-y-scroll">
      <div className="flex flex-col gap-3">
        <p className="text-[15px] font-semibold mb-2">Edit Storyline</p>
        {/* <p className="text-[14px] font-semibold">Additional Info</p> */}
        <div>
          <textarea
            className="border bg-[#f4f5f5] rounded-lg h-[150px] w-full p-[10px] nonResizableTextarea"
            name="storyLine"
            value={storyline}
            onChange={handleChange}
          />
        </div>

        <div className="mt-7 flex flex-col items-end w-full gap-3">
          <button
            onClick={handleSubmit}
            disabled={validate}
            className={`flex shadow w-[260px] shadow-[#F17300] ${
              !validate ? "bg-[#F17300] text-white" : ""
            } h-[38px] font-semibold justify-center items-center rounded-[5px]`}
          >
            {isLoading ? "Loading..." : "Continue"}
          </button>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default StoryLinePopup;
