import React, { useState } from "react";
import illustration from "../../assets/img/Illustration.png";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import AxiosInstance from "../../api/axios";
import "react-toastify/dist/ReactToastify.css";

interface Option {
  value: string;
  label: string;
}

const UserType = () => {
  const options = [
    { value: "Editor", label: "Editor" },
    { value: "Motion Graphics", label: "Motion Graphics" },
    { value: "Animator", label: "Animator" },
    { value: "Still Graphics", label: "Still Graphics" },
    { value: "Sound Design", label: "Sound Design" },
    { value: "Scores & Audio Mix", label: "Scores & Audio Mix" },
    { value: "Colorist", label: "Colorist" },
    { value: "Cinematography", label: "Cinematography" },
    { value: "Visual Effect", label: "Visual Effect" },
    { value: "Voice Over", label: "Voice Over" },
  ];

  const User_id = localStorage.getItem("ids");
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handleSelectChange = (selected: any) => {
    setSelectedOptions(selected);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const categoryArray = selectedOptions.map((option) => option.value);
      const res = await AxiosInstance.patch(`/user/categories/${User_id}`, {
        categories: categoryArray,
      });
      toast.success("categories added", {
        position: toast.POSITION.TOP_RIGHT,
      });
      navigate("/Onboard");
    } catch (error) {
      toast.error("error creating category", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setSelectedOptions([]);
    }
  };

  const validate = selectedOptions.length === 0;

  return (
    <div className="flex h-[100vh]">
      <div className="bg-[#f1730066] px-7 lg:px-0 w-[500px] md:w-[700px] lg:w-[900px] hidden md:flex justify-center items-center h-[100vh]">
        <div className="flex flex-col items-center justify-center gap-[50px]">
          <img src={illustration} />
          <p className="text-[26px] text-white lg:text-[31px] font-semibold lg:w-[95%] text-center">
            Online Community For Post-production Professionals
          </p>
        </div>
      </div>
      <div className="bg-white w-full">
        <div className="w-full flex-col flex gap-5 items-center h-[100vh] justify-center mt-10">
          <div className="text-[18px] font-semibold">
            <p className="text-center">
              kindly select the categories that apply to you
            </p>
          </div>
          <div className="w-[75%] flex flex-col gap-1">
            <h3 className="font-semibold">Select Category:</h3>
            <Select
              options={options}
              isMulti
              value={selectedOptions}
              onChange={handleSelectChange}
            />
          </div>

          <div className="w-full flex justify-center">
            <button
              onClick={handleSubmit}
              disabled={validate}
              className={`flex shadow w-[280px] shadow-[#F17300] ${
                !validate ? "bg-[#F17300] text-white" : ""
              } h-[38px] font-semibold justify-center items-center rounded-[5px]`}
            >
              {isLoading ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UserType;
