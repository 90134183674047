import React, { useEffect, useState } from "react";
import ScrollableDivWithNavigation from "./videoNav";
// import VideoScrolls from "./videoScrolls";
import dp from "../../../assets/icons/Ellipse 42 (2).svg";
import auth_arrow from "../../../assets/icons/auth_arrow.svg";
import arrow from "../../../assets/icons/right_arrow.svg";
import { IndividualCommunityState } from "../../../utils/data";
import TimeLine from "./TimeLine";
import AxiosInstance from "../../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fullFav from "../../../assets/icons/icons8-favourite-48.png";
import partFav from "../../../assets/icons/icons8-favourite-50.png";
import deleteMem from "../../../assets/icons/icons8-delete-60.png";
import DeleteMemberPopup from "./deleteMemberPopup";
import edit from "../../../assets/icons/edit.svg";
import AddCrewPopup from "./addCrewPopup";
import CloseBtn from "../../../comonent/CloseButton";
import AddSoundPopup from "./addSoundPopup";
import PhotoNav from "./photoNav";
import VideoNav from "./videoNav";
import ScrollableDiv from "./scrollableDiv";
import UploadVideoPopup from "./uploadVideoPopup";
import UploadImagePopup from "./uploadImagePopup";
import StoryLinePopup from "./storyLinePopup";
import DeleteSoundTrack from "./deleteSoundTrack";
import DeleteCrewPopup from "./deleteCrewPopup";
import ResizeDetector from 'react-resize-detector';

type IndividualMovieLayoutProps = {
  handleBack?: () => void;
  parentCommuity?: any;
};

const IndividualMovie: React.FC<IndividualMovieLayoutProps> = ({
  handleBack,
  parentCommuity,
}) => {
  const [activeTab, setActiveTab] = useState(IndividualCommunityState[0].name);
  const User_id = localStorage.getItem("ids");
  const communityId = parentCommuity.id;
  const [selectMember, setSelectMember] = useState(false);
  const [showSearch, setShowSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [parentSearch, setParentSearch] = useState([] as any);
  const [parentMemberInfo, setParentMemberInfo] = useState([] as any);
  const [parentCommunity, setParentCommunity] = useState([] as any);
  const [favouriteCommunity, setFavouriteCommunity] = useState([] as any);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMemberItem, setSelectedMemberItem] = useState<any | null>(
    null
  );
  const [selectedSoundItem, setSelectedSoundItem] = useState<any | null>(null);
  const [selectedCrewItem, setSelectedCrewItem] = useState<any | null>(null);
  const [showUpdateCrewModal, setShowUpdateCrewModal] = useState(false);
  const [showSoundTrackModal, setShowSoundTrackModal] = useState(false);
  const [showStorylineModal, setShowStorylineModal] = useState(false);
  const [showCreateVideoPopup, setShowCreateVideoPopup] = useState(false);
  const [showCreateImagePopup, setShowCreateImagePopup] = useState(false);
  const [showDeleteSoundModal, setShowDeleteSoundModal] = useState(false);
  const [showDeleteCrewModal, setShowDeleteCrewModal] = useState(false);

  const handleToggle = () => {
    setSelectMember(!selectMember);
    handleCancel();
  };

  const handleShowSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowSearch(e.target.value);
  };

  const getCommunity = async () => {
    setLoading(true);

    try {
      const res = await AxiosInstance.get(`/community/${communityId}`);
      const items = res.data;
      setParentCommunity(items);
    } catch (error) {
      console.log(error);
      setSearchLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCommunity();
  }, []);

  const handleFIndUser = async () => {
    setLoading(true);
    setSearchLoading(true);
    try {
      const searchContent = {
        keyword: showSearch,
      };
      const res = await AxiosInstance.get("/user/name/keyword", {
        params: searchContent,
      });

      if (Array.isArray(res.data)) {
        const parentSearch = res.data.map((item) => ({
          name: `${item.firstName} ${item.lastName}`,
          id: item._id,
        }));
        setParentSearch(parentSearch);
      }
    } catch (error) {
      toast.error("No User found", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSearchLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setSearchLoading(false);
    setShowSearch("");
    setParentSearch([]);
  };

  const handleAddMember = async (items: any) => {
    const membersId = items.id;

    setLoading(true);
    try {
      AxiosInstance.patch(`/community/member/${communityId}`, {
        user: membersId,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("User added as a member", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          handleCancel();
          setSelectMember(false);
          fetchMembers(arrayId);
          getCommunity();
          fetchMembers(arrayId);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      console.error("Error adding user:", error);
      toast.error("error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const arrayId = parentCommunity.members;

  const fetchMembers = async (arrayId: any) => {
    if (!arrayId) {
      return;
    }

    const userInfo = [];
    for (const id of arrayId) {
      try {
        const response = await AxiosInstance.get(`/user/${id}`);
        userInfo.push(response.data);
        setParentMemberInfo(userInfo);
      } catch (error) {
        console.error(`Error fetching user info for ID ${id}:`, error);
      }
    }
  };

  useEffect(() => {
    fetchMembers(arrayId);
  }, [arrayId]);

  const checkFavourite = async () => {
    try {
      const res = await AxiosInstance.get(`/user/favCommunities/${User_id}`);
      setFavouriteCommunity(res.data);
    } catch (error) {
      console.error(`Error fetching favourites:`, error);
    }
  };

  useEffect(() => {
    checkFavourite();
  }, []);

  const favouriteComm = favouriteCommunity.filter(
    (id: any) => id === communityId
  );

  const addFavourite = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.patch(
        `community/favourite/${communityId}`,
        {
          user: User_id,
        }
      );
      toast.success("Community added as favourite", {
        position: toast.POSITION.TOP_RIGHT,
      });
      checkFavourite();
    } catch (error) {
      toast.error("error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const removeFav = async () => {
    setLoading(true);

    try {
      const response = await AxiosInstance.delete(
        `community/favourite/${communityId}`,
        { data: { user: User_id } }
      );

      if (response.status === 200) {
        toast.success("Community removed as favourite", {
          position: toast.POSITION.TOP_RIGHT,
        });
        await checkFavourite();
      } else {
        toast.error(`Error removing favourite (status: ${response.status})`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error("error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteSound = async (selectedSoundItem: any) => {
    setLoading(true);

    try {
      const response = await AxiosInstance.delete(
        `community/soundTrack/${communityId}`,
        { data: { index: selectedSoundItem.index } }
      );
      toast.success("Sound track deleted", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShowDeleteSoundModal(false);
      getCommunity();
    } catch (error) {
      toast.error("error deleting sound track", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteCrew = async (selectedCrewItem: any) => {
    setLoading(true);

    try {
      const response = await AxiosInstance.delete(
        `community/crew/${communityId}`,
        { data: { index: selectedCrewItem.index } }
      );
      toast.success("crew deleted", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShowDeleteCrewModal(false);
      getCommunity();
    } catch (error) {
      toast.error("error deleting crew", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteMember = async (selectedMemberItem: any) => {
    setLoading(true);

    try {
      const response = await AxiosInstance.delete(
        `community/member/${communityId}`,
        { data: { user: selectedMemberItem._id } }
      );

      if (response.status === 200) {
        toast.success("User removed as member", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setShowDeleteModal(false);
        getCommunity();
      } else {
        toast.error(`Error removing member (status: ${response.status})`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error("error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (items: any) => {
    setShowDeleteModal(true);
    setSelectedMemberItem(items);
  };

  const handdleAddCrew = () => {
    setShowUpdateCrewModal(false);
    getCommunity();
  };

  const handleCloseSound = () => {
    setShowSoundTrackModal(false);
    setShowDeleteSoundModal(false);
    getCommunity();
  };

  const handleDeleteSound = (soundItem: any, index: number) => {
    setShowDeleteSoundModal(true);
    setSelectedSoundItem({ soundItem, index });
  };

  const handleDeleteCrew = (crewItem: any, index: any) => {
    setShowDeleteCrewModal(true);
    setSelectedCrewItem({ crewItem, index });
  };

  return (
    <>
      <div>
        <div>
          <div className="flex w-full justify-between items-center mb-3">
            <div className="flex gap-2">
              <img
                onClick={handleBack}
                className="cursor-pointer"
                src={auth_arrow}
                alt=""
              />

              <div className="flex items-center">
                <p className="text-[24px] font-semibold">
                  {parentCommunity.name}
                </p>
              </div>
            </div>

            {parentCommunity?.user === User_id && (
              <div className="">
                <button
                  onClick={() => handleToggle()}
                  className="bg-orange-400 font-semibold text-sm text-white shadow shadow-[#f174006c] cursor-pointer w-[100px] h-[35px] rounded-[6px] flex justify-center items-center "
                >
                  {selectMember ? "Cancel" : "Add Member"}
                </button>
              </div>
            )}
          </div>

          {selectMember && (
            <>
              <div className="pb-2 flex gap-1 items-center mb-2">
                <input
                  className="w-full rounded-[6px] h-[28px] p-[10px] text-[14px] shadow shadow-[#bebdba]"
                  placeholder="Add a member"
                  value={showSearch}
                  onChange={handleShowSearch}
                />
                <div className="">
                  <div
                    onClick={handleFIndUser}
                    className="h-[28px] w-[28px] cursor-pointer rounded-[8px] bg-orange-300 flex justify-center items-center"
                  >
                    <img className="h-4" src={arrow} alt="/" />
                  </div>
                </div>
              </div>

              {searchLoading && (
                <div className="w-full flex flex-col gap-2 bg-orange-100 p-2 px-1 max-h-[280px] overflow-y-scroll pb-4 drop-shadow-md mb-4">
                  <div className="flex justify-end text-[12px]">
                    <p
                      onClick={handleCancel}
                      className="w-[18px] h-[18px] rounded-[30px] cursor-pointer  flex justify-center font-bold drop-shadow-lg border items-center bg-white"
                    >
                      x
                    </p>
                  </div>
                  {loading ? (
                    <div className="h-[280px] flex justify-center items-center">
                      loading....
                    </div>
                  ) : (
                    <>
                      {" "}
                      {parentSearch.map((items: any, i: number) => (
                        <div
                          key={i}
                          onClick={() => handleAddMember(items)}
                          className="relative cursor-pointer shadow flex items-center gap-1 px-1 bg-white rounded-[5px]"
                        >
                          <div className="bg-blue-200 h-[30px] w-[30px] rounded-[30px]" />
                          <div className="h-[55px] flex items-center">
                            <p className="font-semibold text-[14px]">
                              {items.name}
                            </p>
                          </div>
                          {/* <div className="absolute bottom-1 right-2 Lato-Light text-xs">
                        <p>{today.toLocaleDateString()}</p>
                      </div> */}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
            </>
          )}

          <div className="flex gap-2 mb-2">
            <div className="w-[290px] h-[340px] shadow hidden xs:flex">
              <img
                src={parentCommunity.iconImage}
                className="h-[340px] w-full"
                alt="/"
              />
            </div>
            <div className="w-full bg-orange-100 h-[340px] shadow">
              <img
                src={parentCommunity.displayImage}
                className="h-[340px] w-full"
                alt="/"
              />
            </div>
          </div>

          <div className="flex justify-end">
            {favouriteComm?.length > 0 ? (
              <img
                onClick={removeFav}
                className="h-7 w-7 cursor-pointer"
                src={fullFav}
                alt=""
              />
            ) : (
              <img
                onClick={addFavourite}
                className="h-7 w-7 cursor-pointer"
                src={partFav}
                alt=""
              />
            )}
          </div>

          <div className="h-[45px] mt-7 shadow shadow-[#f1740030] flex justify-between border-t border-[#f1740028] rounded-[13px] font-semibold text-[18px] ">
            {IndividualCommunityState.map((items) => (
              <div
                onClick={() => setActiveTab(items.name)}
                className={`shadow shadow-[#f174006c] cursor-pointer w-full rounded-[13px] flex justify-center items-center ${
                  activeTab === items.name
                    ? "bg-orange-400 text-white"
                    : "bg-white text-black"
                } `}
              >
                {items.name}
              </div>
            ))}
          </div>

          {activeTab === IndividualCommunityState[0].name && (
            <div className="mt-10 flex flex-col gap-7">
              <div className="flex flex-col gap-5">
                <div className="flex justify-between">
                  <div className="flex gap-1.5 items-center ">
                    <div className="flex w-2 h-6 bg-orange-400" />

                    <p className="text-[25px] Axiforma-bold top-1 relative">
                      Videos
                    </p>
                  </div>

                  {parentCommunity?.user === User_id && (
                    <img
                      className="cursor-pointer"
                      onClick={() => setShowCreateVideoPopup(true)}
                      src={edit}
                      alt=""
                    />
                  )}
                </div>

                <div>
                  <VideoNav videoNav={parentCommunity} />
                  {/* video */}
                </div>
              </div>

              <div className="flex flex-col gap-5">
                <div className="flex justify-between">
                  <div className="flex gap-1.5 items-center ">
                    <div className="flex w-2 h-6 bg-orange-400" />

                    <p className="text-[25px] Axiforma-bold top-1 relative">
                      Images
                    </p>
                  </div>

                  {parentCommunity?.user === User_id && (
                    <img
                      className="cursor-pointer"
                      onClick={() => setShowCreateImagePopup(true)}
                      src={edit}
                      alt=""
                    />
                  )}
                </div>

                <div>
                  {/* <ScrollableDiv /> */}
                  {/* photo */}
                  <PhotoNav photoNav={parentCommunity} />
                </div>
              </div>

              <div className="flex flex-col gap-5">
                <div className="flex justify-between items-end">
                  <div className="flex gap-1.5 items-center ">
                    <div className="flex w-2 h-6 bg-orange-400" />

                    <p className="text-[25px] Axiforma-bold top-1 relative">
                      Top Crew
                    </p>
                  </div>

                  {parentCommunity?.user === User_id && (
                    <img
                      className="cursor-pointer"
                      onClick={() => setShowUpdateCrewModal(true)}
                      src={edit}
                      alt=""
                    />
                  )}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 gap-y-5">
                  {parentCommunity?.crew?.map((crewItem: any, index: any) => (
                    <div className="flex justify-between">
                      <div
                        key={index}
                        className="flex gap-[10px] Axiforma text-[14px] xl:text-[16px]"
                      >
                        <div className="flex items-center">
                          <span>{crewItem.name}</span> :
                          {crewItem.role && (
                            <span className="ml-2 text-gray-500">
                              {crewItem.role}
                            </span>
                          )}
                        </div>
                      </div>

                      {parentCommunity?.user === User_id && (
                        <div>
                          <img
                            onClick={() => handleDeleteCrew(crewItem, index)}
                            key={index}
                            className="h-6 w-6 cursor-pointer"
                            src={deleteMem}
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-col gap-5">
                <div className="flex gap-1.5 items-center ">
                  <div className="flex w-2 h-6 bg-orange-400" />

                  <p className="text-[25px] Axiforma-bold top-1 relative">
                    Members
                  </p>
                </div>

                <div className="grid grid-cols-1 xs:grid-cols-2 gap-2 gap-y-5">
                  {parentMemberInfo?.map((items: any, index: any) => (
                    <div className="flex justify-between">
                      <div className="flex gap-1">
                        -<p>{items.firstName}</p>
                        <p>{items.lastName}</p>
                      </div>

                      {parentCommunity?.user === User_id && (
                        <div>
                          <img
                            onClick={() => handleDelete(items)}
                            key={items._id}
                            className="h-6 w-6 cursor-pointer"
                            src={deleteMem}
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-5">
                <div className="flex flex-col gap-2 shadow shadow-slate-300 w-full border p-4 rounded-[10px]">
                  <div className="flex justify-between">
                    <p className="font-semibold text-[25px]">Soundtrack</p>

                    {parentCommunity?.user === User_id && (
                      <img
                        className="cursor-pointer"
                        onClick={() => setShowSoundTrackModal(true)}
                        src={edit}
                        alt=""
                      />
                    )}
                  </div>

                  <div className="grid grid-cols-1 gap-2 gap-y-5">
                    {parentCommunity?.soundTrack?.map(
                      (soundItem: any, index: any) => (
                        <div className="flex justify-between">
                          <div
                            key={index}
                            className="flex flex-col gap-[5px] Axiforma text-[14px] xl:text-[16px]"
                          >
                            <div className="flex items-center">
                              <span>Song</span> :
                              {soundItem.song && (
                                <span className="ml-2 text-gray-500">
                                  {soundItem.song}
                                </span>
                              )}
                            </div>
                            <div className="flex items-center">
                              <span>Released</span> :
                              {soundItem.released && (
                                <span className="ml-2 text-gray-500">
                                  {soundItem.released}
                                </span>
                              )}
                            </div>
                            <div className="flex items-center">
                              <span>Album</span> :
                              {soundItem.album && (
                                <span className="ml-2 text-gray-500">
                                  {soundItem.album}
                                </span>
                              )}
                            </div>
                            <div className="flex items-center">
                              <span>Artiste</span> :
                              {soundItem.artiste && (
                                <span className="ml-2 text-gray-500">
                                  {soundItem.artiste}
                                </span>
                              )}
                            </div>
                          </div>

                          {parentCommunity?.user === User_id && (
                            <div>
                              <img
                                onClick={() =>
                                  handleDeleteSound(soundItem, index)
                                }
                                key={index}
                                className="h-6 w-6 cursor-pointer"
                                src={deleteMem}
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <div className="flex flex-col gap-2 shadow shadow-slate-300 w-full border p-4 rounded-[10px]">
                  <div className="flex justify-between">
                    <p className="font-semibold text-[25px]">Storyline</p>

                    {parentCommunity?.user === User_id && (
                      <img
                        className="cursor-pointer"
                        onClick={() => setShowStorylineModal(true)}
                        src={edit}
                        alt=""
                      />
                    )}
                  </div>

                  <div className="flex flex-col gap-1 text-[14px] xl:text-[16px]">
                    <p>{parentCommunity?.storyLine}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === IndividualCommunityState[1].name && (
            <TimeLine parentCommunity={parentCommuity} />
          )}
        </div>

        {showDeleteModal && (
          <>
            <div className=" fixed top-0 left-0 w-full h-full z-[999] flex items-center justify-center">
              <DeleteMemberPopup
                onClose={() => setShowDeleteModal(false)}
                MemberItem={selectedMemberItem}
                handleRemove={() => deleteMember(selectedMemberItem)}
              />
            </div>
            <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
          </>
        )}
        {showDeleteSoundModal && (
          <>
            <div className="fixed top-0 left-0 w-full h-full z-[999] flex items-center justify-center">
              <DeleteSoundTrack
                onClose={() => setShowDeleteSoundModal(false)}
                soundTrack={selectedSoundItem}
                handleRemove={() => deleteSound(selectedSoundItem)}
              />
            </div>
            <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
          </>
        )}
        {showDeleteCrewModal && (
          <>
            <div className="fixed top-0 left-0 w-full h-full z-[999] flex items-center justify-center">
              <DeleteCrewPopup
                handleRemove={() => deleteCrew(selectedCrewItem)}
                onClose={() => setShowDeleteCrewModal(false)}
                crewMember={selectedCrewItem}
              />
            </div>
            <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
          </>
        )}
        {showUpdateCrewModal && (
          <>
            <div className=" fixed top-9 left-0 w-full h-full z-[999] flex items-center justify-center">
              <div className="p-5 bg-white rounded-[5px] drop-shadow-lg w-full xs:w-[90%] sm:w-[65%] md:w-[60%] lg:w-[50%]">
                <CloseBtn onClose={handdleAddCrew} />
                <AddCrewPopup parentCommuityId={communityId} />
              </div>
            </div>
            <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
          </>
        )}
        {showSoundTrackModal && (
          <>
            <div className=" fixed top-9 left-0 w-full h-full z-[999] flex items-center justify-center">
              <div className="p-5 bg-white rounded-[5px] drop-shadow-lg w-full xs:w-[90%] sm:w-[65%] md:w-[60%] lg:w-[50%]">
                <CloseBtn onClose={handleCloseSound} />
                <AddSoundPopup parentCommuityId={communityId} />
              </div>
            </div>
            <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
          </>
        )}
        {showCreateVideoPopup && (
          <>
            <div className=" fixed top-0 left-0 w-full h-full z-[9999] flex items-center justify-center">
              <UploadVideoPopup
                parentCommunity={parentCommunity}
                onclose={() => setShowCreateVideoPopup(false)}
                getRequest={() => getCommunity()}
              />
            </div>
            <div className="fixed w-screen z-[999] h-screen bg-[#00000055] top-0 left-0" />
          </>
        )}
        {showCreateImagePopup && (
          <>
            <div className=" fixed top-0 left-0 w-full h-full z-[9999] flex items-center justify-center">
              <UploadImagePopup
                parentCommunity={parentCommunity}
                onclose={() => setShowCreateImagePopup(false)}
                getRequest={() => getCommunity()}
              />
            </div>
            <div className="fixed w-screen z-[999] h-screen bg-[#00000055] top-0 left-0" />
          </>
        )}
        {showStorylineModal && (
          <>
            <div className=" fixed top-0 left-0 w-full h-full z-[999] flex items-center justify-center">
              <div className="p-5 bg-white rounded-[5px] drop-shadow-lg w-full xs:w-[90%] sm:w-[65%] md:w-[60%] lg:w-[50%]">
                <CloseBtn onClose={() => setShowStorylineModal(false)} />
                <StoryLinePopup
                  getRequest={() => getCommunity()}
                  parentCommunity={parentCommunity}
                  onclose={() => setShowStorylineModal(false)}
                />
              </div>
            </div>
            <div className="fixed w-screen h-screen bg-[#00000055] top-0 left-0" />
          </>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default IndividualMovie;
