import React, { useEffect, useState } from "react";
import dp from "../../../assets/icons/Ellipse 42 (2).svg";
import arrow from "../../../assets/icons/right_arrow.svg";
import AxiosInstance from "../../../api/axios";
import lazy_loading from "../../../assets/icons/loading-icon.gif";
import ChatPage from "./chatPage";
import SearchChatPage from "./searchChatPage";

interface ChatObject {
  _id?: string;
  senderId?: string;
  receiverId?: string;
  message?: string;
  timestamp?: string;
  __v?: number;
  receiverName?: string;
}

const Messages = () => {
  const User_id = localStorage.getItem("ids");
  const [searchLoading, setSearchLoading] = useState(false);
  const [showSearch, setShowSearch] = useState("");
  const [parentList, setParentList] = useState([] as any);
  const [parentSearch, setParentSearch] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [chatPage, setChatPage] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const handleShowSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowSearch(e.target.value);
  };

  const fetchSentMessages = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get(`/chat/messages/${User_id}`);

      if (typeof res.data === "object" && !Array.isArray(res.data)) {
        const chatObjects: {
          userId: string;
          messages: ChatObject[];
          lastSentMessage?: string;
          dp?: string;
        }[] = [];

        for (const userId in res.data) {
          const messages = res.data[userId]; // Array of chat objects for the current user

          const processedMessages = await Promise.all(
            messages.map(async (message: any) => {
              const receiverId = message.receiverId;

              const receiverDataResponse = await AxiosInstance.get(
                `/user/${receiverId}`
              );
              const name = `${receiverDataResponse.data.firstName} ${receiverDataResponse.data.lastName}`;
              const dp = `${receiverDataResponse.data.dp}`;
              if (name !== "") {
                return {
                  ...message,
                  receiverName: name,
                  dp: dp,
                };
              } else {
                console.warn(
                  `Failed to fetch user data for receiver ID: ${receiverId}`
                );
                return {
                  ...message,
                };
              }
            })
          );

          const lastSentMessage =
            processedMessages.length > 0
              ? processedMessages[processedMessages.length - 1].message
              : "";

          chatObjects.push({
            userId,
            messages: processedMessages,
            lastSentMessage,
          });
        }

        setParentList(chatObjects);
      } else {
        console.error("Unexpected response format:", res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRecievedMessaged = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get(`/chat/recievedMessages/${User_id}`);

      if (typeof res.data === "object" && !Array.isArray(res.data)) {
        const chatObjects: {
          userId: string;
          messages: ChatObject[];
          lastSentMessage?: string;
        }[] = [];

        for (const userId in res.data) {
          const messages = res.data[userId]; // Array of chat objects for the current user

          const processedMessages = await Promise.all(
            messages.map(async (message: any) => {
              const senderId = message.senderId;

              const receiverDataResponse = await AxiosInstance.get(
                `/user/${senderId}`
              );
              const name = `${receiverDataResponse.data.firstName} ${receiverDataResponse.data.lastName}`;
              const dp = `${receiverDataResponse.data.dp}`;
              if (name !== "") {
                return {
                  ...message,
                  receiverName: name,
                  dp: dp,
                };
              } else {
                console.warn(
                  `Failed to fetch user data for receiver ID: ${senderId}`
                );
                return {
                  ...message,
                };
              }
            })
          );

          const lastSentMessage =
            processedMessages.length > 0
              ? processedMessages[processedMessages.length - 1].message
              : "";

          chatObjects.push({
            userId,
            messages: processedMessages,
            lastSentMessage,
          });
        }

        setParentList(chatObjects);
      } else {
        console.error("Unexpected response format:", res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchSentMessages();
    fetchRecievedMessaged();
  }, []);

  const handleChat = (items: any) => {
    setChatPage(true);
    setSelectedOptions(items);
  };

  const handleBack = () => {
    setChatPage(false);
    setSelectedOptions("");
  };

  const handleFIndUser = async () => {
    setLoading(true);
    setSearchLoading(true);
    try {
      const searchContent = {
        keyword: showSearch,
      };
      const res = await AxiosInstance.get("/user/name/keyword", {
        params: searchContent,
      });

      if (Array.isArray(res.data)) {
        const parentSearch = res.data.map((item) => ({
          name: `${item.firstName} ${item.lastName}`,
          id: item._id,
          dp: item.dp,
        }));
        setParentSearch(parentSearch);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setSearchLoading(false);
    setShowSearch("");
    setParentSearch([]);
  };

  const today = new Date();

  const handleSearchedChat = (items: any) => {
    setParentResult(items);
    setSearchByChat(true);
    handleCancel();
    setParentList([]);
  };

  const handleSearchBack = () => {
    setSearchByChat(false);
    setParentResult("");
    fetchSentMessages();
  };

  const [searchByChat, setSearchByChat] = useState(false);
  const [parentResult, setParentResult] = useState([] as any);



  return (
    <>
      <div className="relative">
        {chatPage ? (
          <ChatPage
            handleBack={() => handleBack()}
            parentChat={selectedOptions}
          />
        ) : (
          <>
            <div className="pb-2 flex gap-1 items-center">
              <input
                className="w-full rounded-[10px] h-[28px] p-[10px] text-[14px] shadow shadow-[#bebdba]"
                placeholder="What"
                value={showSearch}
                onChange={handleShowSearch}
              />
              <div className="">
                <div
                  onClick={handleFIndUser}
                  className="h-[28px] w-[28px] cursor-pointer rounded-[12px] bg-orange-200 flex justify-center items-center"
                >
                  <img className="h-4" src={arrow} alt="/" />
                </div>
              </div>
            </div>
            {searchLoading ? (
              <>
                <div className="w-full flex flex-col gap-2 bg-orange-50 p-2 px-1 max-h-[280px] overflow-y-scroll pb-4 drop-shadow-md">
                  <div className="flex justify-end text-[12px]">
                    <p
                      onClick={handleCancel}
                      className="w-[18px] h-[18px] rounded-[30px] cursor-pointer  flex justify-center font-bold drop-shadow-lg border items-center bg-white"
                    >
                      x
                    </p>
                  </div>
                  {loading ? (
                    <div className="h-[280px] flex justify-center items-center">
                      loading....
                    </div>
                  ) : (
                    <>
                      {" "}
                      {parentSearch.map((items: any, i: number) => (
                        <div
                          key={i}
                          onClick={() => handleSearchedChat(items)}
                          className="relative cursor-pointer shadow flex items-center gap-1 px-1 bg-white rounded-[5px]"
                        >
                          {items.dp === "" ? (
                            <div className="bg-blue-200 h-[30px] w-[30px] rounded-[30px]" />
                          ) : (
                            <div>
                              <img
                                className="h-[30px] w-[30px] rounded-[30px]"
                                src={items.dp}
                                alt=""
                              />
                            </div>
                          )}
                          <div className="h-[55px] flex items-center">
                            <p className="font-semibold text-[14px]">
                              {items.name}
                            </p>
                          </div>
                          <div className="absolute bottom-1 right-2 Lato-Light text-xs">
                            <p>{today.toLocaleDateString()}</p>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className="w-full flex flex-col">
                <>
                  {loading ? (
                    <div className="h-[50vh] w-full flex justify-center items-center">
                      <img src={lazy_loading} />
                    </div>
                  ) : (
                    <>
                      {parentList.map((items: any, i: number) => (
                        <div
                          key={i}
                          onClick={() => handleChat(items)}
                          className="border-y text-[13px] py-3 hover:bg-slate-100 cursor-pointer flex flex-col gap-1"
                        >
                          <div className="flex gap-2">
                            <div>
                              {items.messages[0]?.dp === "" ? (
                                <div className="bg-orange-100 w-10 h-10 rounded-[30px]" />
                              ) : (
                                <img
                                  className="h-10 w-10 rounded-[30px]"
                                  src={items.messages[0]?.dp}
                                  alt="/"
                                />
                              )}
                            </div>

                            <div className="flex flex-col gap-[2px]">
                              <p className="text-[14px]">
                                {items.messages[0]?.receiverName}
                              </p>
                              <p className="text-[10px]">
                                {" "}
                                {items.lastSentMessage
                                  ? items.lastSentMessage.length > 40
                                    ? items.lastSentMessage.substring(0, 40) +
                                      "..."
                                    : items.lastSentMessage
                                  : "No messages yet"}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              </div>
            )}

            {searchByChat ? (
              <SearchChatPage
                handleBack={() => handleSearchBack()}
                parentChat={parentResult}
              />
            ) : (
              <div />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Messages;
