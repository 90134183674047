import React, { FC, useState } from "react";
import {
  TimeframeOption,
  categoryOption,
  contractOption,
  jobTypeOption,
  locatonOption,
  paymentOption,
} from "../../utils/data";
import dropdown from "../../assets/icons/Frame 243.svg";

interface Option {
  value: string;
  label: string;
}

type optionsProps = {
  onSendContract?: any;
  onSendPayment?: any;
  onSendCategory?: any;
  onSendJobType?: any;
  onSendTimeframe?: any;
};

const Options_component: FC<optionsProps> = ({
  onSendContract,
  onSendPayment,
  onSendCategory,
  onSendJobType,
  onSendTimeframe,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [selectedDate, settSelectedDate] = useState("");

  const [selectedCategory, settSelectedCategory] = useState("");
  const [selectedLocation, settSelectedLocation] = useState("");
  const [selectedPayment, settSelectedPayment] = useState("");
  const [selectedJobType, settSelectedJobType] = useState("");
  const [selectedContract, settSelectedContract] = useState("");

  const handleContractChange = (option: any) => {
    setSelectedOptions([option]);
    settSelectedContract(option.label);
    onSendContract(option);
    setShowContract(false);

    settSelectedDate("");
    settSelectedCategory('');
    settSelectedDate("");
    settSelectedLocation("");
    settSelectedJobType("");
  };

  const handlePaymentChange = (option: any) => {
    setSelectedOptions([option]);
    settSelectedPayment(option.label);
    onSendPayment(option);
    setShowPayment(false);

    settSelectedDate("");
    settSelectedCategory('');
    settSelectedDate("");
    settSelectedLocation("");
    settSelectedContract("");
  };

  const handleJobTypeOption = (option: any) => {
    setSelectedOptions([option]);
    settSelectedJobType(option.value)
    onSendJobType(option);
    setShowJobtype(false);

    settSelectedCategory('');
    settSelectedDate("");
    settSelectedLocation("");
    settSelectedPayment("");
    settSelectedContract("");
  };

  const handleCategoryChange = (option: any) => {
    setSelectedOptions([option]);
    settSelectedCategory(option.label);
    onSendCategory(option);
    setShowOption(false);

    settSelectedDate("");
    settSelectedLocation("");
    settSelectedPayment("");
    settSelectedJobType("");
    settSelectedContract("");
  };

  const handleTimeframeChange = (option: any) => {
    setSelectedOptions([option]);
    onSendTimeframe(option);
    setShowOptions(false);

    settSelectedDate(option.label);
    settSelectedCategory("");
    settSelectedLocation("");
    settSelectedPayment("");
    settSelectedJobType("");
    settSelectedContract("");
  };

  const [showOptions, setShowOptions] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showJobtype, setShowJobtype] = useState(false);
  const [showContract, setShowContract] = useState(false);

  const handleOption1 = () => {
    setShowOption(false);
    setShowLocation(false);
    setShowPayment(false);
    setShowJobtype(false);
    setShowContract(false);
    setShowOptions(!showOptions);
  };

  const handleOption2 = () => {
    setShowOptions(false);
    setShowLocation(false);
    setShowPayment(false);
    setShowJobtype(false);
    setShowContract(false);
    setShowOption(!showOption);
  };

  const handleOption3 = () => {
    setShowOptions(false);
    setShowPayment(false);
    setShowJobtype(false);
    setShowContract(false);
    setShowOption(false);
    setShowLocation(!showLocation);
  };

  const handleOption4 = () => {
    setShowOptions(false);
    setShowJobtype(false);
    setShowContract(false);
    setShowOption(false);
    setShowLocation(false);
    setShowPayment(!showPayment);
  };

  const handleOption5 = () => {
    setShowOptions(false);
    setShowContract(false);
    setShowOption(false);
    setShowLocation(false);
    setShowPayment(false);
    setShowJobtype(!showJobtype);
  };

  const handleOption6 = () => {
    setShowOptions(false);
    setShowOption(false);
    setShowLocation(false);
    setShowPayment(false);
    setShowJobtype(false);
    setShowContract(!showContract);
  };

  return (
    <div className="gap-x-4 md:gap-x-12 w-[95%] justify-items-center sm:w-auto gap-y-3 lg:gap-3 grid grid-cols-3 lg:grid-cols-6">
      <div className="relative">
        <div
          onClick={handleOption1}
          className="w-[95px] xs:w-[115px] sm:w-[140px] lg:w-[150px] cursor-pointer text-[13px] sm:text-[15px] h-[47px] xs:h-[50px] sm:h-[55px] bg-[#D9D9D9] rounded-[10px] shadow shadow-[#be9163] flex items-center justify-between px-3"
        >
          <p>{selectedDate === "" ? "Date Posted" : selectedDate}</p>
          <img className="h-6 sm:h-10" src={dropdown} />
        </div>
        {showOptions && (
          <ul className="z-[99] flex flex-col absolute font-semibold cursor-pointer hover:Axiforma-bold text-[13px] gap-1 px-2 py-2 top-12 xs:top-14 w-full bg-[#efefef] rounded-[10px] shadow shadow-[#be9163]">
            {TimeframeOption.map((option) => (
              <li
                className="hover:bg-[#00000029] text-[#6e6d6d] hover:text-black"
                key={option.value}
                onClick={() => handleTimeframeChange(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="relative">
        <div
          onClick={handleOption2}
          className="w-[95px] xs:w-[115px] sm:w-[140px] lg:w-[150px] cursor-pointer text-[13px] sm:text-[15px] h-[47px] xs:h-[50px] sm:h-[55px] bg-[#D9D9D9] rounded-[10px] shadow shadow-[#be9163] flex items-center justify-between px-3"
        >
          <p>{selectedCategory === "" ? "Category" : selectedCategory}</p>
          <img className="h-6 sm:h-10" src={dropdown} />
        </div>
        {showOption && (
          <ul className="z-[99] flex flex-col absolute font-semibold cursor-pointer hover:Axiforma-bold text-[13px] gap-1 px-2 py-2 top-12 xs:top-14 w-full bg-[#efefef] rounded-[10px] shadow shadow-[#be9163]">
            {categoryOption.map((option) => (
              <li
                className="hover:bg-[#00000029] text-[#6e6d6d] hover:text-black"
                key={option.value}
                onClick={() => handleCategoryChange(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="relative">
        <div
          onClick={handleOption3}
          className="w-[95px] xs:w-[115px] sm:w-[140px] lg:w-[150px] cursor-pointer text-[13px] sm:text-[15px] h-[47px] xs:h-[50px] sm:h-[55px] bg-[#D9D9D9] rounded-[10px] shadow shadow-[#be9163] flex items-center justify-between px-3"
        >
          <p>{selectedLocation === "" ? "Location" : selectedLocation}</p>
          <img className="h-6 sm:h-10" src={dropdown} />
        </div>
        {showLocation && (
          <ul className="z-[99] flex flex-col max-h-[285px] overflow-y-scroll absolute font-semibold cursor-pointer hover:Axiforma-bold text-[13px] gap-1 px-2 py-2 top-12 xs:top-14 w-full bg-[#efefef] rounded-[10px] shadow shadow-[#be9163]">
            {locatonOption.map((option) => (
              <li
                className="hover:bg-[#00000029] text-[#6e6d6d] hover:text-black"
                key={option.value}
                // onClick={() => handleCategoryChange(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="relative">
        <div
          onClick={handleOption4}
          className="w-[95px] xs:w-[115px] sm:w-[140px] lg:w-[150px] cursor-pointer text-[13px] sm:text-[15px] h-[47px] xs:h-[50px] sm:h-[55px] bg-[#D9D9D9] rounded-[10px] shadow shadow-[#be9163] flex items-center justify-between px-3"
        >
          <p>{selectedPayment === "" ? "Payment" : selectedPayment}</p>
          <img className="h-6 sm:h-10" src={dropdown} />
        </div>
        {showPayment && (
          <ul className="z-[99] flex flex-col absolute font-semibold cursor-pointer hover:Axiforma-bold text-[13px] gap-1 px-2 py-2 top-12 xs:top-14 w-full bg-[#efefef] rounded-[10px] shadow shadow-[#be9163]">
            {paymentOption.map((option) => (
              <li
                className="hover:bg-[#00000029] text-[#6e6d6d] hover:text-black"
                key={option.value}
                onClick={() => handlePaymentChange(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="relative">
        <div
          onClick={handleOption5}
          className="w-[95px] xs:w-[115px] sm:w-[140px] lg:w-[150px] cursor-pointer text-[13px] sm:text-[15px] h-[47px] xs:h-[50px] sm:h-[55px] bg-[#D9D9D9] rounded-[10px] shadow shadow-[#be9163] flex items-center justify-between px-3"
        >
          <p>{selectedJobType === "" ? "Job Type" : selectedJobType}</p>
          <img className="h-6 sm:h-10" src={dropdown} />
        </div>
        {showJobtype && (
          <ul className="z-[99] flex flex-col absolute font-semibold cursor-pointer hover:Axiforma-bold text-[13px] gap-1 px-2 py-2 top-12 xs:top-14 w-full bg-[#efefef] rounded-[10px] shadow shadow-[#be9163]">
            {jobTypeOption.map((option) => (
              <li
                className="hover:bg-[#00000029] text-[#6e6d6d] hover:text-black"
                key={option.value}
                onClick={() => handleJobTypeOption(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="relative">
        <div
          onClick={handleOption6}
          className="w-[95px] xs:w-[115px] sm:w-[140px] lg:w-[150px] cursor-pointer text-[13px] sm:text-[15px] h-[47px] xs:h-[50px] sm:h-[55px] bg-[#D9D9D9] rounded-[10px] shadow shadow-[#be9163] flex items-center justify-between px-3"
        >
          <p>{selectedContract === "" ? "Contract" : selectedContract}</p>
          <img className="h-6 sm:h-10" src={dropdown} />
        </div>
        {showContract && (
          <ul className="z-[99] flex flex-col absolute font-semibold cursor-pointer hover:Axiforma-bold text-[13px] gap-1 px-2 py-2 top-12 xs:top-14 w-full bg-[#efefef] rounded-[10px] shadow shadow-[#be9163]">
            {contractOption.map((option) => (
              <li
                className="hover:bg-[#00000029] text-[#6e6d6d] hover:text-black"
                key={option.value}
                onClick={() => handleContractChange(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Options_component;
