import React, { ReactNode } from "react";
import Header from "./Header";

type WorkSpaceLayoutProps = {
  children: ReactNode;
};

const WorkSpaceLayout: React.FC<WorkSpaceLayoutProps> = ({ children }) => {
  return (
    <div>
      <Header />
      <main>{children}</main>
    </div>
  );
};

export default WorkSpaceLayout;
