import React, { useState } from "react";
import { toast } from "react-toastify";
import AxiosInstance from "../../../api/axios";

type SoundtrackFormType = {
  song: string;
  released: string;
  album: string;
  artiste: string;
};

type SoundtrackType = "artiste" | "song" | "released" | "album";
type SoundCrewProps = {
  parentCommuityId?: any;
};

const AddSoundPopup: React.FC<SoundCrewProps> = ({ parentCommuityId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const ids = parentCommuityId;

  const EMPTY_FORM_2 = {
    artiste: "",
    song: "",
    released: "",
    album: "",
  };

  const [formData2, setFormData2] = useState([
    { ...EMPTY_FORM_2 },
  ] as SoundtrackFormType[]);

  const handleAddSound = () => {
    setFormData2([...formData2, EMPTY_FORM_2]);
  };

  const handleDeleteSound = (i: number) => {
    const filterSound = formData2.filter((soundTrack, index) => index !== i);
    setFormData2([...filterSound]);
  };

  const handleChangeSound = (e: any, i: number, field: SoundtrackType) => {
    if (field === "artiste") {
      formData2[i][field] = e.target.value;
    } else {
      formData2[i][field] = e.target.value;
    }
    setFormData2([...formData2]);
  };

  const validate =
    !formData2[0].artiste ||
    !formData2[0].song ||
    !formData2[0].released ||
    !formData2[0].album;

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const soundTrack = Array.from(formData2);
      const res = await AxiosInstance.patch(`/community/soundTrack/${ids}`, {
        soundTrack,
      });
      toast.success("sound track added", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormData2([EMPTY_FORM_2]);
    } catch (error) {
      toast.error("error adding sound track", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-[2px] max-h-[360px] overflow-y-scroll">
      <div className="flex flex-col gap-3">
        <p className="text-[14px] font-semibold">Add Sound Track</p>
        {formData2.map(({ artiste, song, album, released }, i) => (
          <div>
            <div key={i} className="flex flex-col gap-5">
              <div className="w-full flex gap-4">
                <div className="flex flex-col gap-2 w-[50%]">
                  <p className="text-[14px] Lato-Italics">Song</p>
                  <div>
                    <input
                      className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                      type="text"
                      name="song"
                      value={song}
                      onChange={(e) => handleChangeSound(e, i, "song")}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-2 w-[50%]">
                  <p className="text-[14px] Lato-Italics">Released</p>
                  <div>
                    <input
                      className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                      type="text"
                      value={released}
                      name="released"
                      onChange={(e) => handleChangeSound(e, i, "released")}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2 w-[100%]">
                <p className="text-[14px] Lato-Italics">Album</p>
                <div>
                  <input
                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                    type="text"
                    value={album}
                    name="album"
                    onChange={(e) => handleChangeSound(e, i, "album")}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2 w-[100%]">
                <p className="text-[14px] Lato-Italics">Artiste</p>
                <div>
                  <input
                    className="border bg-[#f4f5f5] h-[42px] rounded-lg w-full p-[10px]"
                    type="text"
                    value={artiste}
                    name="artiste"
                    onChange={(e) => handleChangeSound(e, i, "artiste")}
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end pt-1">
              {formData2.length > 1 && (
                <button
                  onClick={() => handleDeleteSound?.(i)}
                  className="text-red-5 cursor-pointer text-[14px] font-semibold text-red-600"
                >
                  - Remove
                </button>
              )}
            </div>
          </div>
        ))}

        <div className="mt-1">
          <p
            onClick={handleAddSound}
            className="font-semibold text-orange-400 cursor-pointer text-[14px] w-fit"
          >
            + Add sound track
          </p>
        </div>

        <div className="mt-7 flex flex-col items-end w-full gap-3">
          <button
            onClick={handleSubmit}
            disabled={validate}
            className={`flex shadow w-[260px] shadow-[#F17300] ${
              !validate ? "bg-[#F17300] text-white" : ""
            } h-[38px] font-semibold justify-center items-center rounded-[5px]`}
          >
            {isLoading ? "Loading..." : "Continue"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSoundPopup;
