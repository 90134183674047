import React, { useEffect, useState } from "react";
import pic1 from "../../assets/img/Frame 3.png";
import AxiosInstance from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AccountSettings = () => {
  const [loading, setLoading] = useState(false);
  const [dpLoading, setDpLoading] = useState(false);
  const User_id = localStorage.getItem("ids");
  const [parentDp, setParentDp] = useState<any>(null);
  const [parentVideo, setParentVideo] = useState<any>([]);
  const [selectedPicture, setSelectedPicture] = useState("");
  const [selectedVideo, setSelectedVideo] = useState("");
  const [parentTalentVideo, setParentTalentVideo] = useState<any>([]);

  // const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");


  const handleChange = (e: any) => {
    setSelectedPicture(e.target.files[0]);
  };

  const handleChangeVideo = (e: any) => {
    setSelectedVideo(e.target.files[0]);
  };

  const validate = !selectedPicture;
  const validateVideo = !selectedVideo;

  const fetchDp = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get(`/user/dp/${User_id}`);
      const imageURL = res.data;
      setParentDp(imageURL);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTalentVideo = async () => {
    setLoading(true);
    try {
      const res = await AxiosInstance.get(`/user/talentVideo/${User_id}`);
      const videoURL = res.data;
      setParentVideo(videoURL);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const postDp = async () => {
    setDpLoading(true);

    const formData = new FormData();
    formData.append("image", selectedPicture);

    try {
      AxiosInstance.post(`/user/dp/${User_id}`, formData)
        .then((response) => {
          if (response.status === 200) {
            toast.success(`image updated`);
            setSelectedPicture("");
            setDpLoading(false)
            fetchDp();
          }
        })
        .catch((error) => {
          toast.error(error.response);
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const postTalentVideo = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("video", selectedVideo);

    try {
      AxiosInstance.post(`/user/talentVideo/${User_id}`, formData)
        .then((response) => {
          if (response.status === 200) {
            toast.success(`Talent Video uploaded`);
            setSelectedVideo("");
            setLoading(false);
            fetchTalentVideo();
          }
        })
        .catch((error) => {
          toast.error(error.response);
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } catch (error) {
      console.error(error);
    } 
  };

  useEffect(() => {
    fetchDp();
    fetchTalentVideo();
  }, []);

  const storedFirstName = localStorage.getItem("firstName");
  const storedLastName = localStorage.getItem("lastName");
  const storedEmail = localStorage.getItem("email");
  // const lastName = localStorage.getItem("lastName");

  const [firstName, setFirstName] = useState(storedFirstName || "");
  const [lastName, setLastName] = useState(storedLastName || "");
  const [onChange, setOnChange] = useState(false);
  // const [onLastChange, setOnLastChange] = useState(false);

  useEffect(() => {
    const storedfirstName = storedFirstName;
    if (storedfirstName) {
      setFirstName(storedfirstName);
    }

    const storedlastName = storedLastName;
    if (storedlastName) {
      setLastName(storedlastName);
    }
  }, []);

  const handleNameChange = (event: any) => {
    setFirstName(event.target.value);
    setOnChange(true);
  };

  const handleLastNameChange = (event: any) => {
    setLastName(event.target.value);
    setOnChange(true);
  };

  return (
    <>
    <div className="flex flex-col gap-5">
      <div className="flex flex-col md:flex-row gap-10 md:gap-0 w-full px-[20px] xs:px-[30px] mt-[40px]">
        <div className="w-full border-0 md:border-r flex flex-col">
          <div className="pb-[20px] border-b w-[95%] xs:w-[85%]">
            <p className="font-semibold">Edit Profile</p>
          </div>

          <div className="flex flex-col gap-[16px] mt-[23px]">
            <div className="flex flex-col gap-[7px]">
              <p className="Lato-Italics font-semibold">First Name</p>
              <div className="w-full">
                <input
                  className="p-[10px] w-[95%] xs:w-[85%] h-[37px] shadow shadow-[#F17300]"
                  name="firstname"
                  type="text"
                  value={firstName}
                  onChange={handleNameChange}
                />
              </div>
            </div>

            <div className="flex flex-col gap-[7px]">
              <p className="Lato-Italics font-semibold">Last Name</p>
              <div className="w-full">
                <input
                  className="p-[10px] w-[95%] xs:w-[85%] h-[37px] shadow shadow-[#F17300]"
                  name="lastname"
                  type="text"
                  value={lastName}
                  onChange={handleLastNameChange}
                />
              </div>
            </div>
            {/* 
            <div className="flex flex-col gap-[7px]">
              <p className="Lato-Italics font-semibold">User Name</p>
              <div className="w-full">
                <input className="p-[10px] w-[95%] xs:w-[85%] h-[37px] shadow shadow-[#F17300]" />
              </div>
            </div> */}

            {/* <div className="flex flex-col gap-[7px]">
              <p className="Lato-Italics font-semibold">Email</p>
              <div className="w-full">
                <input className="p-[10px] w-[95%] xs:w-[85%] h-[37px] shadow shadow-[#F17300]" />
              </div>
            </div> */}

            <div className="flex flex-col gap-[7px]">
              <p className="Lato-Italics font-semibold">Phone Number</p>
              <div className="w-full">
                <input className="p-[10px] w-[95%] xs:w-[85%] h-[37px] shadow shadow-[#F17300]" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full justify-end xs:px-[30px] mt-[10px] gap-5">
          <div className="border w-full sm:w-[90%] md:w-full">
            <div className="border-b w-full flex justify-center items-center py-[20px]">
              <p className="text-[16px] font-semibold text-center">
                Kindly Upload Profile Picture Below
              </p>
            </div>

            <div className="flex flex-col py-5 justify-center items-center gap-[6px]">
              <div className="border flex flex-col items-center justify-center gap-2 p-1">
                <div>
                  {parentDp && <img className="h-44" src={parentDp} alt="/" />}
                </div>

                <label className="text-[10px] cursor-pointer Axiforma font-semibold text-gray-400">
                  {" "}
                  {validate ? (
                    <>
                      Change Profile Picture
                      <input
                        name="profileImage"
                        id="profileImage"
                        type="file"
                        accept="image/*"
                        onChange={handleChange}
                        className="hidden"
                      />
                    </>
                  ) : (
                    <button
                      onClick={postDp}
                      className="text-[9px] text-gray-500 bg-gray-300 border rounded-[4px] p-1"
                    >
                      {dpLoading ? "uploading..." : "upload"}
                    </button>
                  )}
                </label>
              </div>
            </div>
          </div>

          <div className="border w-full sm:w-[90%] md:w-full">
            <div className="border-b w-full flex justify-center items-center py-[20px]">
              <p className="text-[16px] font-semibold text-center">
                Kindly Upload Talent Video Below
              </p>
            </div>

            <div className="flex flex-col py-5 justify-center items-center gap-[6px]">
              <div className="border flex flex-col items-center justify-center gap-2 p-1">
                <div>
                  {parentVideo && (
                    <video
                      src={parentVideo}
                      controls
                      className="w-full h-full object-cover rounded-md"
                    />
                  )}
                </div>

                <label className="text-[10px] cursor-pointer Axiforma font-semibold text-gray-400">
                  {" "}
                  {validateVideo ? (
                    <>
                      Change Talent Video
                      <input
                        name="TalentVideo"
                        id="TalentVideo"
                        type="file"
                        accept="video/*"
                        onChange={handleChangeVideo}
                        className="hidden"
                      />
                    </>
                  ) : (
                    <button
                      onClick={postTalentVideo}
                      className="text-[9px] text-gray-500 bg-gray-300 border rounded-[4px] p-1"
                    >
                      {/* upload */}
                      {loading ? "loading..." : "change talent video"}
                    </button>
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="flex justify-center px-5 xs:px-10 mt-[40px]">
        <button className="shadow bg-[#F17300] text-white text-sm font-semibold h-[35px] w-[130px] rounded-[8px]">
          Save
        </button>
      </div> */}
    </div>
    <ToastContainer />
    </>
  );
};

export default AccountSettings;
